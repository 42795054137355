import reminderDelayMap from '../utils/reminderDelayMap';
import types from '../constants';
import { AnyAction } from 'redux';
import { Relatedreminders } from '../redux/graph';

export const defaultState = {};

export default (
  state: Relatedreminders = defaultState,
  { payload, type }: AnyAction,
) => {
  switch (type) {
    case types.reminders.mapReminders:
      return {
        ...reminderDelayMap(payload.reminders, payload.tiles),
      };

    case types.reminders.mapRemindersClear:
      return {
        ...state,
        ...defaultState,
      };

    default:
      return state;
  }
};
