export default resource => {
  switch (resource) {
    case `graph`:
      return `graph`;
    case `sequence`:
    case `sequences`:
      return `sequences`;
    case `tile`:
    case `tiles`:
      return `tiles`;
    case `reminder`:
    case `reminders`:
    case `delay`:
    case `delays`:
      return `reminders`;
    default:
      console.error(
        `Your edit panel does not contain an apropriate type. Type: ${resource}`,
      );
      return resource;
  }
};
