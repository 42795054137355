import _ from 'lodash';

const formatStatus = (status) => {
  if (status.toLowerCase() === 'qa') {
    return status.toUpperCase();
  }

  return status.split('_').map(_.capitalize).join(' ');
};

export default formatStatus;
