import * as C from '../common';

import _ from 'lodash';
import { auth_token_boolean } from '../common';
import { outgoingEmailData } from '../email';

export default data => {
  const follow_ups = {};

  if (data.content.body) {
    data.content.body = outgoingEmailData(data.content.body);
  }

  if (Array.isArray(data.content.follow_up)) {
    data.content.follow_up.forEach(follow_up => {
      follow_ups[follow_up['answer_value']] = {};
      follow_ups[follow_up['answer_value']][follow_up['resource_type']] =
        follow_up['resource_value_node'] || follow_up['resource_value_web'];
    });
    data.content.follow_up = follow_ups;
  }

  if (
    _.has(data.content, auth_token_boolean) &&
    !data.content[auth_token_boolean]
  ) {
    delete data.content.auth_token_expiration_amount;
    delete data.content.auth_token_expiration_units;
  }

  return C.common_outgoing(data);
};
