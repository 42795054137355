import * as C from '../common';
import { incomingEmailData } from '../email';

export default data => {
  if (data.content.body) {
    data.content.body = incomingEmailData(data.content.body);
  }

  return C.common_incoming(data);
};
