import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import store from '../../../../reducers';
import { getInputsForExperience } from '../utils';

const hideIfUsingSendgrid = (input) => ({
  ...input,
  condition: C.negate(C.showInterfaceForSendgridId),
});

export const EmailMessageTileSettings = (
  experience,
  displayGroupNames,
  id,
  masonryEnabled = false,
) => {
  const application_state = store.getState();
  const tile_options = C.getFollowUpTileOptions(application_state, id, masonryEnabled);

  return {
    label: 'Settings',
    name: 'content',
    input: getInputsForExperience({
      experience,
      mandatoryInputs: [
        C.experienceTileTitle(masonryEnabled),
        C.experienceTileDescription(masonryEnabled),
        C.optional,
        C.experience_attribute,
        C.display_group_name(displayGroupNames),
        C.merge_data,
        C.one_click_contribution_keys,
        ...C.followUp(tile_options),
      ],
      optionalInputs: [
        ...C.dependencyCriteria({
          emitEvent: true,
          availability: true,
          visibility: true,
          cancellation: true,
        }),
      ],
    }),
  };
};

export default ({
  arms,
  id,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const application_state = store.getState();
  const masonryEnabled = dataForTile?.email_message?.masonryEnabled;
  const graph_id = application_state.graph.id;

  const experienceName = masonryEnabled
  ? `content.experiences.${experience}.settings`
  : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.use_sendgrid,
        C.send_email_preview({
          graph_id,
          tile_id: id,
        }),
        C.sendgrid_template_id,
        C.subject,
        ...[C.html_formatter, C.body].map(hideIfUsingSendgrid),
        C.email_personalizations,
        C.specify_recipients,
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(EmailMessageTileSettings(experience, displayGroupNames, id));
  }

  return [schema];
};
