import Title, { BranchArms } from '../title';
import { func, node, object, oneOfType, string } from 'prop-types';

import { HeadingContainer } from '../tile/container';
import React from 'react';
import _ from 'lodash';
import getTileConfig from '../tile/config';
import styled from 'styled-components';
import { AuthWrap } from '../../../../components/auth';
import { AuthActions } from '../../../../constants';

const ADDITIVE = '(Additive)';
const REDIRECT = '(Redirect)';

const colors = [
  `#e23862`,
  `#9529b2`,
  `#ef2fb8`,
  `#4310a9`,
  `#0a8203`,
  `#4aa3b0`,
];

const SequenceHeadingContainer = styled(HeadingContainer)`
  min-height: 50px;
  padding: 0 10px 7px;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const Heading = ({
  onClick,
  dropdown,
  arms,
  branch_arms = [],
  show_branch_arms = true,
  ...props
}) => {
  // allowing custom tile configuration to be passed
  const config = getTileConfig(props);
  const RenderBranchArms = (
    <BranchArms style={{ flex: `0 0 100%` }}>
      {_.map(branch_arms, (name) => {
        const index = _.findIndex(arms, { name });
        return (
          <BranchArms.Li
            key={name}
            background={colors[index]}
            id={`#t-brancharm-name${_.snakeCase(name)}`}
          >
            {name}
          </BranchArms.Li>
        );
      })}
    </BranchArms>
  );

  return (
    <SequenceHeadingContainer {...props.sequenceDragHandleProps}>
      {show_branch_arms && RenderBranchArms}
      <Title
        {...config}
        headingColor="#FFF"
        fontWeight={400}
        onClick={onClick}
        data-test="sequenceHeading"
      >
        {props.content.title}{' '}
        {props.content.is_additive !== undefined
          ? props.content.is_additive
            ? ADDITIVE
            : REDIRECT
          : null}
      </Title>
      <AuthWrap
        requiredActions={[
          AuthActions.graphs.publish,
          AuthActions.sequences.create,
        ]}
      >
        {dropdown}
      </AuthWrap>
    </SequenceHeadingContainer>
  );
};

Heading.propTypes = {
  type: string,
  title: string,
  description: string,
  onClick: func,
  onHover: func,
  tileConfig: object,
  configOverride: object,
  children: oneOfType([func, node]),
};

Heading.defaultProps = {
  description: undefined,
  object: {},
  type: ``,
  onClick: () => false,
  onMouseOver: () => false,
};

export default Heading;
