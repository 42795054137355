/// Create example object
// {
//   "study_slug": "mentalhealth",
//   "email": "jganz@evidation.com",
//   "name": "owner",
//   "effective_at": 1245343, #(optional, will default to now)
//   "expires_at": 123433 #(optional, will default to null)
// }
import { triageV2 } from './triage';

export const create_role = (graph_id, data) =>
  triageV2({
    method: 'POST',
    url: 'roles',
    params: {
      graph_id,
    },
    data,
  });

// User management endpoints
//    roles
//   / = GET (listing) - study_slug query param req
//   / = POST (create) - study_slug query param req
//   /id = PUT (update) (can only update expires_at), no study_slug needed
export const get_roles = graph_id =>
  triageV2({
    method: 'GET',
    url: 'roles',
    params: {
      graph_id,
    },
  });

// Update example object
// {
//   "study_slug": "hecka1231",
//   "expires_at": 123453535
// }
export const update_role = (graph_id, data, id) =>
  triageV2({
    method: 'PUT',
    url: `roles/${id}`,
    params: {
      graph_id,
    },
    data,
  });
