import React from 'react';
import CuriousPanda from '../../fields/CuriousPanda';
import { schema } from './schema';
import styled from 'styled-components';
import { Button, Alert } from '../components';
import { Webhook } from '../../../api/webhooks';

type FormHttpHeaderRecord = {
  key: string;
  value: string;
};
/** 
 * The form values are a curious panda
 * ready transformation of the webhook
 */
type WebhookFormValuesContent = Omit<
  Webhook,
  | 'body'
  | 'http_headers'
  | 'on_webhook_success_event'
  | 'on_webhook_failure_event'
> & {
  on_webhook_success_event_status: string;
  on_webhook_success_event_name: string;
  on_webhook_failure_event_name: string;
  on_webhook_failure_event_status: string;
  http_headers?: FormHttpHeaderRecord[];
  body: string;
};

export type WebhookFormValues = {
  content: WebhookFormValuesContent;
};

type WebhookFormProps = {
  initialValues?: WebhookFormValues;
  onSubmit: (values: WebhookFormValues) => any;
  error?: unknown;
};

const FormWrapper = styled.div`
  padding: 2rem;
  select {
    padding: 14px 10px;
  }
  // help my soul writing css like this...
  .__curious_panda {
    p {
      font-weight: 600;
      font-size: 12px !important;
    }
    input {
      padding: 10px;
      margin-bottom: 0px !important;
    }
    select {
      padding: 10px;
    }
    fieldset {
      display: grid !important;
      gap: 0.5rem;
      row-gap: 1rem;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .array {
        ul {
          li {
            ul {
              display: grid;
              gap: 0.5rem;
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }

          li.row {
            padding: 0.5rem 0rem 0rem 0rem !important;
          }

          li.delete {
            z-index: 30;
          }
        }
      }
    }

    .col-span-2 {
      grid-column: span 2 / span 2;
    }

    .col-span-3 {
      grid-column: span 3 / span 3;
    }

    .col-span-4 {
      grid-column: span 4 / span 4;
    }

    .headers-title {
      font-size: ${24/16}rem;
    }
    .headers-desc {
      color: #777;
    }
  }


`;

export const WebhookForm: React.FC<WebhookFormProps> = ({
  initialValues,
  onSubmit,
  error,
}) => {

  return (
    <>
      {error && typeof error === 'string' && <Alert>{error}</Alert>}
      <FormWrapper>
        <CuriousPanda
          schema={schema}
          initialValues={initialValues}
          form="webhook_form"
          onSubmit={onSubmit}
        >
          {(p: unknown) => (
            <Button buttonStyle="achievement" type="submit">
              {initialValues ? 'Update' : 'Save'} Webhook
            </Button>
          )}
        </CuriousPanda>
      </FormWrapper>
    </>
  );
};
