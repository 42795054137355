import PropTypes from 'prop-types';
import styled from 'styled-components';

const Sectionheading = styled.div`
  color: #1a1a1a;
  font-size: 24px;
  background: #fafafa;
  padding: 24px 32px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 9999;
`;

Sectionheading.defaultProps = {
  style: {},
};

Sectionheading.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default Sectionheading;
