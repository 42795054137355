import * as C from './common';

import { default_experience_key } from '../../../constants';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [[{ name: `content`, input: [C.title, C.type] }]];
};

export const no_type_outgoing = data => {
  return data;
};

export const no_type_incoming = data => {
  return data;
};
