import {
  HeadingContainer,
  IconContainer,
  TileContent,
} from '../tile/container';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { bool, func, string } from 'prop-types';

import AddIcon from '../../../../assets/icons/Add';
import { Nodes } from '../../../../constants';
import SelectType from './SelectType';
import { SequenceAction } from '..';
import { TextareaAutosize } from '@evidation/form-elements';
import styled from 'styled-components';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
const AddTileMenuHolder = styled.div`
  display: flex;
  font-size: 12px;
  text-align: center;
  padding: 0 14px 3px;

  button {
    padding: 0;
    flex: 1 0 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  & button:first-child {
    text-align: left;
  }
  & button:last-child {
    text-align: right;
  }

  button {
    color: gray;
    &:hover {
      color: #00bbd5;
      text-decoration: none;
    }
  }
`;

const Container = styled(SequenceAction)`
  padding: 0;
  position: relative;
  background: #f3f3f3;

  border-top: ${(props) =>
    !props.has_tiles && `1px solid rgba(0, 0, 0, 0.15);`};
  ${(props) =>
    props.has_tiles
      ? `border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;`
      : `border-radius: 5px !important;`};
`;

const AddTile = ({
  cancel,
  onSubmit,
  type = '',
  title = '',
  allowChangeType = true,
  has_tiles,
  ...props
}) => {
  const addTileRef = useRef();
  const [selectingType, setSelectingType] = useState(false);
  const [textHasFocus, setTextHasFocus] = useState(false);

  useOnClickOutside(addTileRef, () => cancel());

  const onKeyboardAction = useCallback(
    (e) => {
      // esc = 27
      // enter = 13

      if (!selectingType) {
        if (e.keyCode === 27) {
          cancel();
        }

        if (e.keyCode === 13) {
          if (!textHasFocus) {
            // still submitting if focus is not on the input
            onSubmit();
          }
        }
      }
    },
    [cancel, onSubmit, selectingType, textHasFocus],
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyboardAction);
    return () => window.removeEventListener('keyup', onKeyboardAction);
  }, [onKeyboardAction]);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // this prevents 'enter' key from creating a new line
      // instead submitting the form, however, we can still paste
      // multi line text into the field
      e.preventDefault(e);
      onSubmit();
    }
  };
  return (
    <Container has_tiles={has_tiles} data-test="addingTile" ref={addTileRef}>
      {selectingType ? (
        <SelectType
          {...props}
          cancel={() => {
            setSelectingType(false);
            cancel();
          }}
          selectedType={type ? Nodes[type].title : ``}
          onSelect={(type) => {
            setSelectingType(false);
            props.onChangeField({ type: type });
          }}
        />
      ) : (
        <React.Fragment>
          <HeadingContainer>
            <IconContainer
              data-test="addTileType"
              onClick={() => allowChangeType && setSelectingType(true)}
            >
              {type ? Nodes[type].icon() : <AddIcon />}
            </IconContainer>
            <TileContent style={{ paddingBottom: 0 }}>
              <TextareaAutosize
                data-test="addTileTitle"
                autoFocus
                value={title}
                onKeyDown={onKeyDown}
                onChange={({ target: { value: title } }) =>
                  props.onChangeField({ title })
                }
                onBlur={() => setTextHasFocus(false)}
                onFocus={() => setTextHasFocus(true)}
                inputStyle={`sm_minimal`}
                style={{ width: `95%` }}
              />
            </TileContent>
          </HeadingContainer>
          <AddTileMenuHolder has_tiles={!has_tiles}>
            <button data-test="addTileCancel" onClick={cancel}>
              Cancel (esc)
            </button>
            <button data-test="addTileEdit">Edit</button>
            <button data-test="addTileExecute" onClick={onSubmit}>
              Add (enter)
            </button>
          </AddTileMenuHolder>
        </React.Fragment>
      )}
    </Container>
  );
};

AddTile.propTypes = {
  allowChangeType: bool,
  cancel: func.isRequired,
  onSubmit: func.isRequired,
  title: string,
  type: string,
  sequence_uuid: string.isRequired,
};

export default AddTile;
