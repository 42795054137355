import CuriousPanda, {
  BillboardNavigation as BBN,
  GetFieldDictionary as GFD,
  CuriousPandaFinalForm,
} from '@evidation/curious-panda';

import { editableFields, editableInterface } from './editableFields';

import ComponentDictionary from './componentDictionary';
import React from 'react';

export const BillboardNavigation = BBN;
export const GetFieldDictionary = GFD;

const LocalCuriousPanda = (props) => {
  return (
    <CuriousPanda
      components={ComponentDictionary}
      editableFields={editableFields}
      editableInterface={editableInterface}
      {...props}
    />
  );
};
const LocalCuriousPandaFinalForm = (props) => (
  <CuriousPandaFinalForm
    components={ComponentDictionary}
    editableFields={editableFields}
    editableInterface={editableInterface}
    {...props}
  />
);

export { LocalCuriousPandaFinalForm as CuriousPandaFinalForm };

export default LocalCuriousPanda;
