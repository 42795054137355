import { connect } from 'react-redux';
import { RootState } from '../reducers';

type MasonryFlag = { masonryEnabled?: boolean };

type MasonryProps = {
  renderEnabled: () => JSX.Element;
  renderDisabled: () => JSX.Element;
} & MasonryFlag;

/**
 * @description This is a utility that will make using components that are
 *  affected by the use of the Masonry architecture flag
 *  masonry_enabled supplied by Triage simpler by not having
 *  to write the logic to retrieve the flag from redux. As the Masonry
 *  architecture becomes the standard we can deprecate this
 *  util component and just use the code set up for Masonry.
 */
const Masonry: React.FC<MasonryProps> = ({
  renderEnabled,
  renderDisabled,
  masonryEnabled = false,
}) => {
  if (masonryEnabled) {
    return renderEnabled();
  }

  return renderDisabled();
};

export default connect(
  ({ graph }: RootState): MasonryFlag => ({
    masonryEnabled: graph.masonry_enabled,
  }),
)(Masonry);
