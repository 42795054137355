export default () => {
  return {
    content: {
      title: 'Multi-Factor Authentication',
      description: `Verify a participant’s identity by sending a text message with a 7-digit verification code. Standard rates will apply to the participant`,
      mfa_phone_input_label:
        'Please enter a phone number. We’ll send you a text message with a 7-digit verification code. Standard rates apply.',
      mfa_token_input_label:
        'We just sent a 7-digit verification code by text. Please enter that code below.',
    },
  };
};
