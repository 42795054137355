import ConnectAppForm from './ConnectAppForm';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: {
    Settings: () => [],
  },
  Component: ConnectAppForm,
  incoming,
  outgoing,
};
