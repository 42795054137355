/**
 * @description Removes instances of strings that are the same.
 *
 * @param {array} ids - array of strings
 * @returns {array} - returns array of unique strings
 */
export const uniqueIds = ids =>
  ids.filter((item, index, array) => array.indexOf(item) === index);

/**
 * @description Retrieves list of external ids set in the Assign External ID
 * tiles.
 *
 * @param {object} tiles - object of tile objects
 * @returns {array} - returns array of strings
 **/
export const getExternalIdTiles = tiles => {
  const ids = Object.values(tiles)
    .filter(tile => {
      return tile.content?.type === 'assign_external_id';
    })
    .reduce((results, tile) => {
      if (tile.content?.external_ids) {
        tile.content.external_ids.forEach(value => results.push(value.client));
      }
      return results;
    }, []);

  return uniqueIds(ids);
};

/**
 * @description Returns list of external ids set in the External
 * tab of the settings page in study manager.
 *
 * @param {array} shippingClients - array of objects (set in settings)
 * @returns {array} - array of strings
 */
export const getExternalIdSettings = (shippingClients = []) => {
  const ids = shippingClients.map(client => client.name);
  return uniqueIds(ids);
};

/**
 * @description Used to return list of external ids from the Assign External
 * Id tiles and the External tab in the Settings page.
 *
 * @param {object} tiles - object of tile objects
 * @param {array} shipping - array of shipping setting objects
 * @returns {array} - returns array of strings
 */
const getExternalIds = (tiles, shipping) => {
  return [...getExternalIdTiles(tiles), ...getExternalIdSettings(shipping)];
};

export default getExternalIds;
