import React, { Component } from 'react';

import TransformTree from '../../../utils/translateToTree';
import _ from 'lodash';
import { apiGraph } from '../../../api';
import { connect } from 'react-redux';
import { setGraphSequencesTiles } from '../../../actions/combination';

export default UpdateGraphWrapped => {
  class UpdateGraph extends Component {
    constructor(props) {
      super(props);

      this.updateGraph = this.updateGraph.bind(this);
    }

    updateGraph(insert) {
      const {
        setGraphSequencesTiles,
        graph: { id },
      } = this.props;

      const payload = TransformTree({
        ...this.props,
        insert: _.isBoolean(insert) ? {} : insert,
      });

      apiGraph.update(id, payload).then(setGraphSequencesTiles);
    }

    render() {
      return (
        <UpdateGraphWrapped {...this.props} updateGraph={this.updateGraph} />
      );
    }
  }

  return connect(
    state => ({
      ...state,
    }),
    {
      setGraphSequencesTiles,
    },
  )(UpdateGraph);
};
