import Content from './content';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: {
    Content,
  },
  incoming,
  outgoing,
};
