import { AddSequenceButton } from '.';
import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import DropdownMenu from 'react-dd-menu';
import { Link } from 'react-router-dom';

class AddSequence extends PureComponent {
  state = {
    isMenuOpen: false,
  };

  toggle = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });
  close = () => this.setState({ isMenuOpen: false });

  render() {
    const { match } = this.props;

    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      toggle: (
        <React.Fragment>
          <Tooltip
            place="left"
            id="t-add_sequence_archipelago"
            type="dark"
            effect="solid"
          >
            Add a Sequence or Alternative Route
          </Tooltip>
          <AddSequenceButton
            data-tip
            data-for="t-add_sequence_archipelago"
            onClick={this.toggle}
          >
            +
          </AddSequenceButton>
        </React.Fragment>
      ),
      align: 'right',
    };

    return (
      <DropdownMenu {...menuOptions}>
        <li>
          <Link
            id="t-add_sequence"
            to={{
              pathname: `/study/${match.params.graph_id}/edit/slate/add-new`,
              state: { resource: `sequence` },
            }}
          >
            Add Sequence
          </Link>
        </li>
        <li>
          <Link
            id="t-add_alternative_route"
            to={{
              pathname: `/study/${match.params.graph_id}/edit/slate/add`,
              state: { resource: `add_archipelago` },
            }}
          >
            Add Alternative Route
          </Link>
        </li>
      </DropdownMenu>
    );
  }
}

export default AddSequence;
