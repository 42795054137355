import _ from 'lodash';
import React from 'react';

const transformAnswerArray = (acc, { answer, value }) => [
  ...acc,
  { label: answer, value },
];

const transformStringArray = (acc, item) => [
  ...acc,
  { label: item, value: item },
];

export const convertOptionsArrayToKeyValue = options => {
  if (options && _.isString(options[0])) {
    // options is a list of strings
    return _.reduce(options, transformStringArray, []);
  } else if (_.has(options[0], `answer`)) {
    // options is a {answer, value} object
    return _.reduce(options, transformAnswerArray, []);
  } else {
    return options;
  }
};

export const Select = ({
  options,
  disabled,
  defaultValue,
  omitPlaceholder = false,
  ...restProps
}) => {
  options = convertOptionsArrayToKeyValue(options);
  return (
    <select disabled={disabled} {...restProps}>
      {!omitPlaceholder && <option>{defaultValue}</option>}
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
