import {
  DashboardSpacer,
  EmptyDashboard,
  Islands,
  OuterDashboardContainer,
} from './components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { PureComponent } from 'react';
import TransformTree, { reduceSequences } from '../../utils/translateToTree';
import {
  archipelagoGroupSelector,
  sequencesSelector,
} from '../../selectors/sequences';
import {
  generateSourceSeqOrder,
  generateTargetSeqOrder,
} from './generateSequenceOrder';

import AlternativeRouteContainer from './AlternativeRouteContainer';
import ArchipelagoDropdown from './components/archipelago_dropdown';
import DashboardSequence from './DashboardSequence';
import NestedSequence, { getItemStyle } from './nestedSequence';
import { SEQUENCE_WIDTH, AuthActions } from '../../constants';
import _ from 'lodash';
import { apiGraph } from '../../api';
import { connect } from 'react-redux';
import generatePayload from '../../utils/generatePayload';
import { getReminderListFromObjects } from '../../utils/getReminderListFromObjects';
import getSequenceTypes from './utils/getSequenceTypes';
import { modify_resource } from '../../actions/slate_actions';
import moveResource from '../../utils/moveResource';
import { pairReminders } from '../../utils/pairReminders';
import { toastr } from 'react-redux-toastr';
import { updateGraph } from '../../actions/graph_actions';
import { updateSequence } from '../../actions/sequences_actions';
import { updateTile } from '../../actions/tiles_actions';
import { logError } from '@evidation/logger';
import { checkIsAuthorized } from '../../components/auth';
import Loader from '../../components/Loader';

export class SlateDashboard extends PureComponent {
  getSequenceIdFromTile = (tile_id) => {
    const sequence = _.find(this.props.sequences_from_redux, (sequence) =>
      _.includes(sequence['tile_ids'], tile_id),
    );

    return sequence['id'];
  };

  moveReminder = (tileId, reminderId, index) => {
    const tile = this.props.tiles[tileId];
    const sorted_tiles = pairReminders(
      tile.reminder_ids,
      this.props.related_reminders,
    );
    const item = {};

    item[reminderId] = this.props.related_reminders[reminderId];
    const ordered_tiles = moveResource(item, sorted_tiles, index);

    const newOrder = getReminderListFromObjects(ordered_tiles);

    const newTile = {
      ...tile,
      reminder_ids: newOrder,
    };

    const payload = {
      clear: false,
      data: newTile,
      props: {
        resource: 'tile',
      },
    };

    this.props.modify_resource(payload);
  };

  moveSequence = (sequence_uuid, position) => {
    const { graph, sequences_from_redux } = this.props;

    const newOrder = moveResource(sequence_uuid, graph.sequence_ids, position);
    const newGraph = {
      ...graph,
      sequence_ids: newOrder,
    };

    const payload = TransformTree({
      ...this.props,
      sequences: sequences_from_redux,
      graph: newGraph,
    });

    //needed to prevent delay between onDragEnd and rerendering the graph
    this.props.updateGraph({
      ...newGraph,
    });

    apiGraph
      .update(graph.id, payload)
      .then(({ id, content, sequence_ids, status }) =>
        updateGraph({ id, content, sequence_ids, status }),
      );
  };

  moveTile = (sequence_uuid, target, position) => {
    const { graph, sequences_from_redux } = this.props;

    const newOrder = moveResource(
      target,
      sequences_from_redux[sequence_uuid].tile_ids,
      position,
    );

    const graphData = {
      ...this.props,
      sequences: {
        ...sequences_from_redux,
        [sequence_uuid]: {
          ...sequences_from_redux[sequence_uuid],
          tile_ids: newOrder,
        },
      },
    };

    this.props.updateSequence({
      ...sequences_from_redux[sequence_uuid],
      tile_ids: newOrder,
    });

    const payload = generatePayload(
      sequence_uuid,
      reduceSequences,
      graphData,
      `sequences`,
      {},
    );
    apiGraph
      .update_sequence(graph.id, sequence_uuid, payload[0])
      .then((data) => {
        this.props.updateSequence(data);
      });
  };

  transferReminder = (destination, sourceId, reminderId) => {
    const sequence_id = this.getSequenceIdFromTile(destination.droppableId);
    const adjustedIndex = destination.index * 2; // adjust index to account for hidden delay reminders
    const payload = {
      source_id: sourceId,
      destination_id: destination.droppableId,
      email_reminder_id: reminderId,
      delay_reminder_id: this.props.related_reminders[reminderId],
      index: adjustedIndex,
    };

    apiGraph
      .transfer_reminder(this.props.match.params.graph_id, sequence_id, payload)
      .then((data) => {
        [data.source, data.destination].forEach((tile) => {
          const tile_payload = {
            clear: false,
            data: {
              id: tile.id,
              content: tile.content,
              reminder_ids: tile.reminder_ids,
            },
            props: {
              resource: 'tile',
            },
          };

          this.props.modify_resource(tile_payload);
        });
      });
  };

  transferTile = (destination, source, draggableId) => {
    const { graph, sequences_from_redux } = this.props;

    const sourceSeq = sequences_from_redux[source.droppableId];
    const targetSeq = sequences_from_redux[destination.droppableId];

    const newSourceOrder = generateSourceSeqOrder(sourceSeq, draggableId);
    const newTargetOrder = generateTargetSeqOrder(
      targetSeq,
      draggableId,
      destination.index,
    );

    this.props.updateSequence({ ...sourceSeq, tile_ids: newSourceOrder });
    this.props.updateSequence({ ...targetSeq, tile_ids: newTargetOrder });

    apiGraph
      .transfer_tile(graph.id, {
        source: { id: source.droppableId, tiles: newSourceOrder },
        destination: { id: destination.droppableId, tiles: newTargetOrder },
        tile: draggableId,
      })
      .then((data) => {
        this.props.updateSequence(data.source);
        this.props.updateSequence(data.destination);
        this.props.updateTile(data.tile);
      })
      .catch((err) => {
        //returning graph to original state if the transfer was unsuccessful
        this.props.updateSequence({ ...sourceSeq });
        this.props.updateSequence({ ...targetSeq });

        const errors = err.response.data.errors;
        if (!_.isUndefined(errors)) toastr.error(errors);
        else toastr.error('There was a problem moving the tile.');
        logError(err);
      });
  };

  onDragEnd = (result) => {
    const { destination, draggableId, source, type } = result;

    if (!destination) return;

    const indexOffset = destination.index - source.index;

    switch (type) {
      case 'SLATE/TILE':
        if (source.droppableId === destination.droppableId) {
          this.moveTile(destination.droppableId, draggableId, indexOffset);
        } else {
          this.transferTile(destination, source, draggableId);
        }
        return;
      case 'SLATE/REMINDER':
        if (source.droppableId === destination.droppableId) {
          this.moveReminder(destination.droppableId, draggableId, indexOffset);
        } else {
          this.transferReminder(destination, source.droppableId, draggableId);
        }
        return;
      case 'SLATE/SEQUENCE':
        if (source.droppableId === destination.droppableId) {
          if (_.has(this.props.sequences_from_redux, draggableId)) {
            this.moveSequence(draggableId, indexOffset);
          }
        } else {
          toastr.error('Action not allowed.');
        }
        return;
      default:
        toastr.error('Action not allowed.');
    }
  };

  render() {
    const {
      graph: { sequence_ids: all_sequence_ids, id },
      authorization,
      match: { url, ...match },
      archipelagos,

      regular_sequences,
      armed_sequences,

      is_publishing,
    } = this.props;

    if (_.isEmpty(all_sequence_ids)) {
      return <EmptyDashboard />;
    }

    const longestArm = _.max(
      _.map(armed_sequences, (_, key) => key).reduce(
        (acc, arm) => [...acc, armed_sequences[arm].length],
        [],
      ),
    );

    const isSeqDragDisabled = !checkIsAuthorized(authorization, id, [
      AuthActions.sequences.update,
      AuthActions.graphs.publish,
    ]);

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Loader show={is_publishing}>
          <DashboardSpacer>
            <OuterDashboardContainer
              width={
                SEQUENCE_WIDTH *
                (regular_sequences.length + (longestArm ? longestArm : 0))
              }
            >
              <NestedSequence
                isSeqDragDisabled={isSeqDragDisabled}
                list_of_sequences={regular_sequences}
                url={url}
              />
              <div id="nested">
                {!_.isEmpty(armed_sequences) &&
                  _.keys(armed_sequences).map((arm) => {
                    return (
                      <OuterDashboardContainer
                        key={arm}
                        height={`auto`}
                        width={SEQUENCE_WIDTH * longestArm}
                      >
                        <NestedSequence
                          isSeqDragDisabled={isSeqDragDisabled}
                          droppableId={`branch_${arm}`}
                          display="arms"
                          list_of_sequences={armed_sequences[arm]}
                          url={url}
                        />
                      </OuterDashboardContainer>
                    );
                  })}
              </div>
            </OuterDashboardContainer>
          </DashboardSpacer>

          {!_.isEmpty(archipelagos) &&
            _.keys(archipelagos).map((key) => {
              const archipelago = archipelagos[key];

              return (
                <OuterDashboardContainer key={key} archipelago>
                  <DashboardSpacer>
                    <Islands.Container>
                      <Droppable
                        type="SLATE/ALTERNATIVE_ROUTE_SEQUENCE"
                        droppableId={`${key}_archipelago`}
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <React.Fragment>
                            <ArchipelagoDropdown
                              archipelago={archipelago}
                              archipelago_name={key}
                              match={match}
                            />
                            <AlternativeRouteContainer>
                              <Islands ref={provided.innerRef}>
                                {_.keys(archipelago).map(
                                  (island_identifier, index) => {
                                    return (
                                      <Draggable
                                        draggableId={island_identifier}
                                        type="ISLAND"
                                        key={island_identifier}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          const sequenceIsDragging =
                                            snapshot.isDragging;
                                          const sequenceDragHandleProps =
                                            provided.dragHandleProps;

                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...getItemStyle(
                                                provided.draggableProps.style,
                                                snapshot.isDragging,
                                              )}
                                            >
                                              <DashboardSequence
                                                {...snapshot}
                                                show_branch_arms={true}
                                                sequenceIsDragging={
                                                  sequenceIsDragging
                                                }
                                                sequenceDragHandleProps={
                                                  sequenceDragHandleProps
                                                }
                                                sequence_uuid={
                                                  island_identifier
                                                }
                                                url={url}
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  },
                                )}
                              </Islands>
                            </AlternativeRouteContainer>
                          </React.Fragment>
                        )}
                      </Droppable>
                    </Islands.Container>
                  </DashboardSpacer>
                </OuterDashboardContainer>
              );
            })}
        </Loader>
      </DragDropContext>
    );
  }
}

SlateDashboard.propTypes = {};

SlateDashboard.defaultProps = {};

export default connect(
  (state_from_props) => {
    const {
      graph,
      user: { authorization },
      slate,
      tiles,
      related_reminders,
      reminders,
      publish: { is_publishing },
      ...state
    } = state_from_props;
    const sequences = sequencesSelector(state_from_props);
    const archipelagos = archipelagoGroupSelector(state_from_props);
    const archipelago_ids = _.reduce(
      archipelagos,
      (acc, i) => [...acc, ..._.keys(i)],
      [],
    );

    let sequence_ids = _.without(graph.sequence_ids, ...archipelago_ids);

    const { regular_sequences, armed_sequences } = getSequenceTypes(
      sequence_ids,
      sequences,
    );

    return {
      graph,
      authorization,
      slate,
      archipelagos,
      archipelago_ids,
      tiles,
      related_reminders,
      reminders,
      sequences,
      sequences_from_redux: state.sequences,
      regular_sequences,
      armed_sequences,
      is_publishing,
    };
  },
  {
    modify_resource,
    updateSequence,
    updateGraph,
    updateTile,
  },
)(SlateDashboard);
