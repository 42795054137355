export default {
  description: (
    <p>
      This status indicates that the study is being configured. This is the
      first status assigned upon creation of a new study. Alterations to the
      configuration are expected and Billboard access can only be done as
      preview and only be possible using a preview token. The study is not
      available for recruiting. Any test data created flows to the Staging
      folder in Data Platform.
    </p>
  ),
  arrow: (
    <ul>
      <li>
        Schedules are activated for the study and participants will start
        receiving scheduled events
      </li>
      <li>
        Only users with evidation.com and evidationtest.com email addresses will
        be allowed to login
      </li>
      <ul>
        <li>Other users will be denied login</li>
      </ul>
      <li>Participant contributions are allowed</li>
      <li>Preview token is needed to access Billboard</li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
