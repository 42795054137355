import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DropdownMenu from 'react-dd-menu';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { setLocale } from '../actions/locale_actions';
import { query_params } from '../constants';

const LangBanner = styled.span`
  cursor: pointer;
  vertical-align: center;
  margin-right: 20px;

  span {
    font-size: 8px;
    line-height: 26px;
    margin-left: 10px;
  }
`;

const StyledLanguageList = styled.li`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  cursor: pointer;
`;

// Unfortunately the importants are needed here to override the default styles used
// for the react-dd-menu
const Flag = styled.div`
  font-size: 16px;
  display: inline-block !important;
  padding: 0 !important;
  min-width: 0 !important;
`;

export const CurrentLanguage = ({
  primary_locale,
  secondary_locales,
  i18n_enabled,
  locale: { currentLocale, languages },
  setLocale,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const localeFromLocation = searchParams.get(query_params.locale);

  const setLocationLocale = useCallback(
    (newLocale, { replace = false } = {}) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(query_params.locale, newLocale);
      const search = searchParams.toString();
      if (replace) {
        history.replace({ search });
      } else {
        history.push({ search });
      }
    },
    [history, location.search],
  );

  useEffect(() => {
    // Sync the locale from the URL with Redux.
    if (localeFromLocation && currentLocale !== localeFromLocation) {
      setLocale(localeFromLocation);
    }
  }, [currentLocale, localeFromLocation, setLocale]);

  useEffect(() => {
    // If the selected locale is invalid, reset it to the primary.
    if (
      localeFromLocation &&
      localeFromLocation !== primary_locale &&
      !secondary_locales.includes(localeFromLocation)
    ) {
      setLocationLocale(primary_locale, { replace: true });
    }
  }, [
    localeFromLocation,
    primary_locale,
    secondary_locales,
    setLocationLocale,
  ]);

  useEffect(() => {
    // This sets the current language to the primary_locale if nothing is set
    // or if the current language is no longer a usable language in the study
    if (
      i18n_enabled &&
      primary_locale &&
      secondary_locales?.length > 0 &&
      !localeFromLocation &&
      (!currentLocale ||
        (currentLocale !== primary_locale &&
          !secondary_locales.includes(currentLocale)))
    ) {
      setLocale(primary_locale);
    }
  }, [
    currentLocale,
    primary_locale,
    secondary_locales,
    setLocale,
    i18n_enabled,
    localeFromLocation,
  ]);

  const close = () => setIsMenuOpen(false);
  const toggle = () => setIsMenuOpen(!isMenuOpen);

  const language = languages[currentLocale];

  const menuOptions = {
    isOpen: isMenuOpen,
    close: close,
    toggle: (
      <LangBanner onClick={toggle} data-test="headingLanguagePicker">
        {language?.label}&nbsp;&nbsp;
        <Flag dangerouslySetInnerHTML={{ __html: language?.icon }} />{' '}
        <span>▼</span>
      </LangBanner>
    ),
    align: 'right',
  };

  const handleOnClick = (value) => setLocationLocale(value);

  const LanguageList = ({ locale, ...rest }) => (
    <StyledLanguageList
      key={locale}
      {...rest}
      onClick={() => handleOnClick(locale)}
    >
      {languages[locale]?.label}&nbsp;&nbsp;
      <Flag dangerouslySetInnerHTML={{ __html: languages[locale]?.icon }} />
    </StyledLanguageList>
  );

  if (i18n_enabled && secondary_locales?.length > 0) {
    return (
      <DropdownMenu {...menuOptions}>
        <LanguageList locale={primary_locale} />
        {secondary_locales?.map((locale) => (
          <LanguageList key={`secondary_local_${locale}`} locale={locale} />
        ))}
      </DropdownMenu>
    );
  }

  return null;
};

export default connect(
  ({ graph: { primary_locale, secondary_locales, i18n_enabled }, locale }) => ({
    primary_locale,
    secondary_locales: secondary_locales ?? [],
    i18n_enabled,
    locale,
  }),
  { setLocale },
)(CurrentLanguage);
