import HorizontalWrapper from './horizontalWrapper';
import { MaskedInput } from '@evidation/form-elements';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { textStyles } from './components';

const Mask = styled(MaskedInput)`
  ${textStyles};
`;

const HorizontalMaskInput = ({ input, mask, ...rest }) => (
  <HorizontalWrapper
    {...rest}
    fieldType="horizontalTextInput horizontalMaskInput"
  >
    <Mask {...input} mask={mask} />
  </HorizontalWrapper>
);

HorizontalMaskInput.defaultProps = {
  mask: `111-111-1111`,
};

HorizontalMaskInput.propTypes = {
  mask: PropTypes.string.isRequired,
};

export default HorizontalMaskInput;
