import * as C from '../common';
import { outgoingEmailData } from '../email';

export default data => {
  if (data.content.body) {
    data.content.body = outgoingEmailData(data.content.body);
  }

  return C.common_outgoing(data);
};
