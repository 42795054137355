import React from 'react';

const NavComp = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/study_review</title>
    <g
      id="icons/nav/study_review"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M7.02848161,3 C7.42279989,2.72621715 7.93050393,2.49414466 8.55159374,2.30378253 C8.82116699,2.21099854 9.11926927,1.9561649 9.21574421,1.63717453 C9.54584054,0.545724842 10.1796474,6.66133815e-15 11.1171647,6.66133815e-15 C12.058101,6.66133815e-15 12.6931075,0.549712459 13.0221844,1.64913738 C13.0957642,1.90008545 13.3541586,2.20716544 13.6623336,2.31048384 C14.2240922,2.49881836 14.6846311,2.72865708 15.0439536,3 L18,3 C18.5522847,3 19,3.44771525 19,4 L19,13.1000181 C21.2822403,13.5632884 23,15.5810421 23,18 C23,20.7614237 20.7614237,23 18,23 C16.3641822,23 14.9118401,22.2144467 13.9996338,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L7.02848161,3 Z"
        id="Combined-Shape"
        stroke={props.color}
        strokeWidth="1.4"
        fillRule="nonzero"
      />
      <path
        d="M9.21574421,1.63717453 C9.54584054,0.545724842 10.1796474,0 11.1171647,0 C12.058101,0 12.6931075,0.549712459 13.0221844,1.64913738 L13.0222248,1.6491253 C13.1154273,1.96050904 13.3541586,2.20716544 13.6623336,2.31048384 C15.2298109,2.8359944 16.0092122,3.68464822 16.0005373,4.85644531 C13.9823851,4.85644531 7.2482184,4.85644531 6.00053261,4.85644531 C5.97560107,3.6835732 6.82595478,2.83268561 8.55159374,2.30378253 L8.55159699,2.30379313 C8.87022683,2.20613405 9.11926927,1.9561649 9.21574421,1.63717453 Z"
        id="Rectangle-Copy-3"
        stroke={props.color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="nonzero"
      />
      <circle
        id="Oval-Copy"
        stroke={props.color}
        strokeWidth="1.4"
        fillRule="nonzero"
        cx="18"
        cy="18"
        r="5"
      />
      <path
        d="M20.2223126,15.6227745 C19.9258092,15.4113569 19.5132971,15.479681 19.3018531,15.7761811 L17.3423579,18.5207615 L16.1266953,17.3050989 C15.868867,17.0472706 15.4511769,17.0472706 15.1933651,17.3050989 C14.9355532,17.5629273 14.9355367,17.9806174 15.1933651,18.2384292 L16.9607885,20.0045656 C17.0858365,20.1296135 17.2534241,20.1979376 17.4274701,20.1979376 C17.4455179,20.1979376 17.4635677,20.1966486 17.4816133,20.1953595 C17.6749853,20.1798898 17.851599,20.0793376 17.9650269,19.9207728 L20.3783,16.5420255 C20.5884305,16.2468125 20.5201064,15.834297 20.2223126,15.6228531 L20.2223126,15.6227745 Z"
        id="Path"
        fill={props.color}
      />
      <path
        d="M6.27605387,9.15971717 L15.4500067,9.15971717 C15.7031178,9.15971717 15.9079192,8.95490896 15.9079192,8.70180471 C15.9079192,8.4486936 15.703111,8.24389226 15.4500067,8.24389226 L6.27605387,8.24389226 C6.02294276,8.24389226 5.81814141,8.44870047 5.81814141,8.70180471 C5.81814141,8.9540229 6.02294963,9.15971717 6.27605387,9.15971717 Z"
        id="Path"
        fill={props.color}
      />
      <path
        d="M6.27605387,12.5045387 L13.4500067,12.5045387 C13.7031178,12.5045387 13.9079192,12.2997305 13.9079192,12.0466263 C13.9079192,11.7935152 13.703111,11.5887138 13.4500067,11.5887138 L6.27605387,11.5887138 C6.02294276,11.5887138 5.81814141,11.793522 5.81814141,12.0466263 C5.81814141,12.2997374 6.02294963,12.5045387 6.27605387,12.5045387 Z"
        id="Path"
        fill={props.color}
      />
      <path
        d="M6.27605387,15.8502761 L10.1005603,15.8502761 C10.3536714,15.8502761 10.5584727,15.6454679 10.5584727,15.3923636 C10.5584727,15.1392594 10.3536645,14.9344512 10.1005603,14.9344512 L6.27605387,14.9344512 C6.02294276,14.9344512 5.81814141,15.1392594 5.81814141,15.3923636 C5.81814141,15.6454679 6.02294963,15.8502761 6.27605387,15.8502761 Z"
        id="Path"
        fill={props.color}
      />
    </g>
  </svg>
);

export default NavComp;
