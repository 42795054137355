import {
  selectedRemindersSelector,
  selectedTilesSelector,
} from '../selectors/selectedResources';
import types, { default_experience_key } from '../constants';

import _ from 'lodash';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { GetState } from '../redux/actions-types';

const getOnlyIds = (arr: Array<any>, target: string = 'id') =>
  _.reduce(arr, (acc: any, item: any) => [...acc, item[target]], []);
const filterDelays = (arr: Array<any>) =>
  _.filter(arr, (i) => i.content.type !== `delay`);

export const setPreviewState = (payload = `available`) => ({
  type: types.configure.setPreviewState,
  payload,
});

export const prepareConfigure =
  (resourceType: string) =>
  (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    let list_of_resources = undefined;
    const state = getState();

    switch (resourceType) {
      case `reminder`:
        const selected_reminders = selectedRemindersSelector(state);
        list_of_resources = !_.isEmpty(selected_reminders)
          ? getOnlyIds(selected_reminders, 'reminder_uuid')
          : getOnlyIds(filterDelays(state.reminders));
        break;

      case `tile`:
      default:
        const selected_tiles = selectedTilesSelector(state);
        list_of_resources = !_.isEmpty(selected_tiles)
          ? getOnlyIds(selected_tiles, 'uuid')
          : getOnlyIds(state.tiles);
        break;
    }

    const payload = {
      list_of_resources: _.intersection(
        list_of_resources,
        state.slate.resource_order,
      ),
    };

    dispatch({ type: types.configure.prepare, payload });
  };

export const setExperiences = (payload: string) => ({
  type: types.configure.setExperiences,
  payload,
});
export const selectExperience = (payload: string = default_experience_key) => ({
  type: types.configure.selectExperience,
  payload,
});
