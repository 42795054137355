import React from 'react';

const NavTable = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_table</title>
    <g
      stroke={props.color}
      strokeWidth={1.4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 18h18V6H3zM3 10h18M3 14h18M9.353 18V6" />
    </g>
  </svg>
);

export default NavTable;
