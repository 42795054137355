import LoadingIndicator from 'react-loading-indicator';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(7, 26, 32, 0.75);
  z-index: ${(props) => props.layerLevel};
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const Loader = styled(LoadingIndicator)`
  margin-right: 8px;
  margin-bottom: -3px;
`;

const StudyLoader = ({
  loaderStyle = {},
  loading = true,
  layerLevel = 9999,
  loadingMessage = 'Please wait....',
  style = {},
}) => {
  if (loading) {
    return (
      <Loading style={{ ...style, ...loaderStyle }}>
        <Container>
          <Loader color={{ red: 255, green: 255, blue: 255, alpha: 1 }} />
          {loadingMessage}
        </Container>
      </Loading>
    );
  }
  return null;
};

StudyLoader.defaultProps = {
  loaderStyle: {},
  loading: true,
  layerLevel: 1000,
  loadingMessage: `Please wait...`,
};

StudyLoader.propTypes = {
  loaderStyle: PropTypes.object,
  layerLevel: PropTypes.number,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
};

export default StudyLoader;
