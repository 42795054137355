import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ContentContainer } from '../../../modal/SimpleModal';
import Button from '../../../inputs/Button';
import CuriousPanda from '../../../fields/CuriousPanda';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../LoadingIndicator';
import styled from 'styled-components';
import { Label } from '../../../fields/components';
import { apiGraph } from '../../../../api';
import { logError } from '@evidation/logger';
import { toastr } from 'react-redux-toastr';
import {
  displayErrors,
  displayWarnings,
} from '../../../../routes/configureNew/utils';

const CenterParagraph = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const text = {
  import_warning_message:
    'This study has been updated after this version. You may overwrite more recent translation work.',
  import_label: 'Import',
  cancel_label: 'Cancel',
  view_label: 'View',
  invalid_label: 'Invalid selection',
  import_is_complete_label: (file_name) => `${file_name} import complete.`,
  import_is_complete_text: (
    primary_language,
    secondary_language,
    file_name,
  ) => `${file_name} has been imported.
When you go to edit the tiles, you will see both the ${primary_language} and ${secondary_language} versions of this study.`,
  import_language_file_title: 'Import Language File',
  importing_title: 'Importing',
  import_complete_title: 'Import Complete',
  import_onerror: 'There was a problem importing the file.',
};

export const importSchema = (languages) => [
  {
    className: 'importStudy',
    input: [
      {
        name: 'import_language',
        label: 'What is the language you are importing?',
        placeholder: 'ex.: English',
        interface: 'selectadvanced',
        required: true,
        possible_answers: languages,
      },
    ],
  },
];

const ImportLangModal = ({
  graphId,
  onClose,
  primary_locale,
  secondary_locales,
  locale: { languages },
}) => {
  const [step, setStep] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const [fileContent, setFileContent] = React.useState(null);
  const [language, setLanguage] = React.useState(null);
  const hiddenFileInput = React.useRef();
  const allLanguages = [primary_locale, ...secondary_locales].map((locale) => {
    return {
      label: languages[locale].label,
      value: locale,
    };
  });

  const onChangeInputFile = useCallback(
    (ev) => {
      if (ev.target.files.length === 0) {
        onClose();
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target.result);
      };
      reader.onerror = (error) => {
        toastr.error(text.import_onerror);
        logError(error);
      };
      reader.readAsText(ev.target.files[0]);
      setFile(ev.target.files[0]);
    },
    [onClose, setFile, setFileContent],
  );

  const onBlurInputFile = useCallback(
    (ev) => {
      if (ev.target.files.length === 0) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    hiddenFileInput.current.click();
  }, []);

  const nextStep = () => setStep((step) => step + 1);

  const getErrors = (error) => {
    if (error.response?.data?.errors?.length > 0) {
      return error.response.data.errors;
    }

    if (error.response?.data?.error) {
      return [error.response.data.error];
    }

    if (error.response?.data?.warnings?.length > 0) {
      return [];
    }

    return [text.import_onerror];
  };

  const importTranslationsFile = useCallback(async () => {
    try {
      const res = await apiGraph.importTranslations(
        graphId,
        language,
        fileContent,
      );
      if (res && res.data) {
        nextStep();
      }
    } catch (error) {
      displayErrors(getErrors(error));
      displayWarnings(error.response?.data?.warnings);
      onClose();
      logError(error);
    }
  }, [graphId, fileContent, language, onClose]);

  useEffect(() => {
    switch (step) {
      case 1:
        importTranslationsFile();
        break;
      default:
        break;
    }
  }, [step, importTranslationsFile]);

  const getTitle = () => {
    switch (step) {
      case 0:
        return text.import_language_file_title;
      case 1:
        return text.importing_title;
      case 2:
        return text.import_complete_title;
      default:
        return text.invalid_label;
    }
  };

  const getContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Label>{text.import_warning_message}</Label>
            <CuriousPanda
              onSubmit={({ import_language }) => {
                setLanguage(import_language);
                nextStep();
              }}
              form="import_lang_file"
              schema={importSchema(allLanguages)}
            >
              {(props) => (
                <>
                  <Button
                    disabled={!props.valid || fileContent === null}
                    type="primary"
                  >
                    {text.import_label}
                  </Button>
                  <input
                    type="file"
                    id="import_translations"
                    name="import_translations"
                    accept=".json,.csv"
                    data-testid="import_translations_test"
                    ref={hiddenFileInput}
                    onChange={onChangeInputFile}
                    onBlur={onBlurInputFile}
                    hidden
                  />
                </>
              )}
            </CuriousPanda>
          </>
        );
      case 1:
        return (
          <>
            <CenterParagraph>
              <LoadingIndicator />
            </CenterParagraph>
            <Button onClick={onClose} type="primary">
              {text.cancel_label}
            </Button>
          </>
        );
      case 2:
        const primaryLanguage = languages[primary_locale].label;
        const secondaryLanguage = languages[language].label;
        return (
          <>
            <CenterParagraph>
              {text.import_is_complete_label(file?.name)}
            </CenterParagraph>
            <CenterParagraph>
              {text.import_is_complete_text(
                primaryLanguage,
                secondaryLanguage,
                file?.name,
              )}
            </CenterParagraph>
            <Button onClick={onClose} type="primary">
              {text.view_label}
            </Button>
          </>
        );
      default:
        throw new Error(`Invalid step ${step} in ImportLangModal component.`);
    }
  };

  return (
    <Modal onClose={onClose} center showX modalTitle={getTitle()}>
      <ContentContainer>{getContent()}</ContentContainer>
    </Modal>
  );
};

ImportLangModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default connect(
  ({ graph: { id, primary_locale, secondary_locales }, locale }) => ({
    graphId: id,
    primary_locale,
    secondary_locales: secondary_locales ?? [],
    locale,
  }),
)(ImportLangModal);
