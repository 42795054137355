import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    screener: { masonryEnabled = false },
  } = dataForTile;

  const input = getInputsForExperience({
    experience,
    mandatoryInputs: masonryEnabled
      ? [C.experienceTileTitle(true), C.experienceTileDescription(true)]
      : [],
    optionalInputs: [
      C.experience_attribute,
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
      }),
    ],
  });

  const schema = masonryEnabled
    ? {
        name: `content`,
        input,
      }
    : [
        {
          name: `content.experiences.${experience}`,
          input: [C.title],
        },
        {
          name: `content`,
          input,
        },
      ];
  return [schema];
};
