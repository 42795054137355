import type { CancelToken } from 'axios';
import _ from 'lodash';

import type { Authorization } from 'src/redux/graph';
import { userAuthorizedActions } from '../../api/index';
import { APP_ADMIN } from '../../constants';

// method to get the user capabilities for the supplied slug, otherwise fallback to default
export const getUserCapabilities = (
  authObj: Authorization,
  identifier: string,
) => {
  let actions = authObj?.[identifier]?.actions ?? [];

  if (authObj[APP_ADMIN]) {
    actions = [...actions, ...authObj[APP_ADMIN]['actions']];
  }

  return actions;
};

export const getUpdatePermissions = (
  graphId: string,
  cancelToken?: CancelToken,
) =>
  userAuthorizedActions({ cancelToken }).then(
    ({ data: authorization }) => authorization[graphId].update_permissions,
  );

/**
 * comparing required capabilities to user capabilities
 * @param authObj list of authorizations for various studies
 * @param graphId current id of study
 * @param requiredAuth list of actions that are required for user to do something
 */
export const checkIsAuthorized = (
  authObj: Authorization,
  graphId: string,
  requiredAuth: string[],
) => {
  const userCapabilities = getUserCapabilities(authObj, graphId);
  return requiredAuth.length > 0
    ? _.difference(requiredAuth, userCapabilities).length === 0
    : true;
};
