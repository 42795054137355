import React from 'react';
import { SettingsHeading } from './components';
import {MarkdownHtml as Markdown} from '@evidation/form-elements';
import PropTypes from 'prop-types';

const Heading = ({ label, description }) => {
  return (
    <React.Fragment>
      <SettingsHeading>
        <Markdown>{label}</Markdown>
      </SettingsHeading>
      {description && (
        <SettingsHeading.Description>
          <Markdown>{description}</Markdown>
        </SettingsHeading.Description>
      )}
    </React.Fragment>
  );
};

Heading.propTypes = {
  label: PropTypes.string.isRequired,
};

Heading.defaultProps = {
  description: undefined,
};

export default Heading;
