import axios from 'axios';
import { logError } from '@evidation/logger';
import { imageGetUrl } from '../../../api';
import { generateCFUrl } from '../../../components/fields/RichTextEditor';

const config = type => ({
  headers: {
    'Content-Type': type,
  },
});

export default ({ data, study_slug }, cb) => {
  const [file] = data;

  imageGetUrl(study_slug)
    .then(async ({ data: { upload_url } }) => {
      const url = generateCFUrl(upload_url);

      const { status } = await axios.put(upload_url, file, config(file.type));

      if (status === 200) {
        cb(url);
      } else {
        cb(false);
      }
    })
    .catch(error => {
      logError(error);
      return cb(false);
    });
};
