import CuriousPanda, {
  BillboardNavigation,
} from '../../../components/fields/CuriousPanda';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import CustomNavigationPrompt from '../../../components/CustomNavigationPrompt';
import PanelEditWrapper from './PanelEditWrapper';
import { PanelNavigation } from '../../configure/components';
import TileWrapper from '../panels/tile-wrapper';
import { checkIsAuthorized } from '../../../components/auth/sharedAuthMethods';
import { AuthActions } from '../../../constants';

const DefaultInnerWrapper = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

class Edit extends PureComponent {
  state = { contentHasChanged: false };

  curiousPandaChangeHandler = (values, dispatch, props, previousValues) => {
    _.forEach(values, (v, k) => {
      if (previousValues[k] && previousValues[k] !== v)
        this.setState({ contentHasChanged: true });
    });
  };

  render() {
    const {
      match: { params },
      InnerWrapper,
      parentPath,
      graphId,
      authorization,
    } = this.props;

    // Check needed to see if the edit form is enabled or disabled when editing sequences as a 'non-publisher'
    const isDisabled = !checkIsAuthorized(authorization, graphId, [
      AuthActions.graphs.publish,
      AuthActions.sequences.update,
    ]);

    return (
      <React.Fragment>
        <CustomNavigationPrompt when={this.state.contentHasChanged} />

        <TileWrapper
          {...this.props}
          parentPath={parentPath}
          heading={`Edit ${_.capitalize(this.props.match.params.resource)}`}
        >
          <PanelEditWrapper {...params}>
            {({
              panel_list = [],
              active_panel,
              changeActivePanel,
              ...rest
            }) => {
              return (
                <InnerWrapper>
                  {!_.isEmpty(panel_list) && (
                    <PanelNavigation>
                      {panel_list.map(panel => (
                        <PanelNavigation.PanelLink
                          data-test={_.snakeCase(panel)}
                          key={panel}
                          active={active_panel === panel}
                          onClick={() => changeActivePanel(panel)}
                        >
                          {panel}
                        </PanelNavigation.PanelLink>
                      ))}
                    </PanelNavigation>
                  )}

                  <CuriousPanda
                    {...rest}
                    onSubmit={d => {
                      this.setState({ contentHasChanged: false }, () => {
                        rest.onSubmit(d);
                      });
                    }}
                    onChange={this.curiousPandaChangeHandler}
                    disabled={isDisabled}
                  >
                    {isDisabled
                      ? () => null
                      : props => (
                          <BillboardNavigation
                            {...props}
                            buttonStyle="achievement"
                          />
                        )}
                  </CuriousPanda>
                </InnerWrapper>
              );
            }}
          </PanelEditWrapper>
        </TileWrapper>
      </React.Fragment>
    );
  }
}

Edit.propTypes = { InnerWrapper: func, match: object.isRequired };

Edit.defaultProps = { InnerWrapper: DefaultInnerWrapper };

export default connect(
  ({ graph: { id: graphId }, user: { authorization } }) => ({
    authorization,
    graphId,
  }),
)(Edit);
