//@ts-nocheck
import IconList from './IconList';
import PropTypes from 'prop-types';
import { Component } from 'react';

class IconLibrary extends Component {
  getChildContext() {
    return {
      iconLibrary: this.props.list,
    };
  }

  render() {
    return this.props.children;
  }
}

IconLibrary.childContextTypes = {
  iconLibrary: PropTypes.array,
};

IconLibrary.defaultProps = {
  list: IconList,
};

IconLibrary.propTypes = {
  list: PropTypes.array,
};

export default IconLibrary;
