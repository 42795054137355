import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <g transform="translate(0 8)">
          <path d="M6.642 3.843a.352.352 0 0 0-.497 0l-.98.98-.98-.98a.352.352 0 0 0-.497.496l.981.98-.98.98a.352.352 0 0 0 .497.497l.98-.98.98.98a.352.352 0 0 0 .497-.497l-.98-.98.98-.98a.351.351 0 0 0-.001-.496zM11.85 3.843l-.98.98-.98-.98a.352.352 0 0 0-.497.496l.98.98-.98.98a.352.352 0 0 0 .497.497l.98-.98.98.98a.352.352 0 0 0 .498-.497l-.98-.98.98-.98a.352.352 0 0 0-.498-.496z" />
          <rect fillRule="nonzero" x="14" y="1" width="1" height="8" rx=".5" />
          <path
            d="M21.407.055H.351a.364.364 0 0 0-.248.098A.328.328 0 0 0 0 .389V9.72c0 .088.038.174.103.236a.364.364 0 0 0 .248.098h21.057a.364.364 0 0 0 .248-.098.328.328 0 0 0 .103-.236V.39a.328.328 0 0 0-.104-.236.364.364 0 0 0-.248-.098zM.702.72h20.354v8.666H.702V.721z"
            fillRule="nonzero"
          />
        </g>
        <path d="M19.566 4.37l-1.554.084 1.204.952c.462.364.602.91.14 1.232-.462.322-.91.042-1.12-.518l-.518-1.386L17.2 6.12c-.21.56-.658.84-1.12.518-.462-.322-.322-.868.14-1.232l1.204-.952-1.554-.084c-.602-.028-1.008-.378-.826-.924.154-.476.7-.546 1.19-.224l1.288.84-.406-1.498c-.154-.56.028-1.064.602-1.064s.756.504.602 1.064l-.406 1.498 1.288-.84c.49-.322 1.036-.252 1.19.224.182.546-.224.896-.826.924z" />
      </g>
    </svg>
  );
}
