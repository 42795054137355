import _ from 'lodash';
import { default_experience_key } from '../../../../constants';

const armsList = (arms = []) => {
  if (_.isEmpty(arms)) {
    return {
      name: `noArms`,
      interface: `paragraph`,
      label: `No Arms`,
    };
  } else {
    return {
      name: `branch_arms`,
      interface: `checkbox`,
      options: _.map(arms, ({ name }) => ({ answer: name, label: name })),
    };
  }
};

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        input: [
          {
            interface: `heading`,
            name: `heading_branch_arms`,
            label: `## Branch arms`,
          },
          armsList(arms),
        ],
      },
    ],
  ];
};
