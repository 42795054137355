"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.validateAvg = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.values.js");

var _lodash = require("lodash");

var _validations = require("../common/validations");

var validateAllQuestionsAnswered = function validateAllQuestionsAnswered(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value) {
      if ((0, _lodash.range)(1, 6).map(function (questionIndex) {
        return "".concat(name, "_").concat(questionIndex);
      }).some(function (name) {
        return !value.hasOwnProperty(name);
      })) {
        return 'Not all questions have been answered';
      }
    }
  };
};

var avg = function avg(values) {
  return values.reduce(function (acc, v) {
    return acc + v;
  }, 0) / values.length;
};

var validateAvg = function validateAvg(_ref2) {
  var name = _ref2.name;
  return function (value) {
    if ((0, _lodash.isUndefined)(value === null || value === void 0 ? void 0 : value.name)) return;
    var individualValues = Object.values((0, _lodash.omit)(value, name));

    if (value[name] !== avg(individualValues)) {
      return 'invalid average';
    }
  };
};

exports.validateAvg = validateAvg;

var _default = function _default(props) {
  return [_validations.validateIsObject, validateAllQuestionsAnswered(props), (0, _validations.validateHasAggregateValue)(props), validateAvg(props)];
};

exports["default"] = _default;