import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
} from 'redux';
import {
  compensation_middleware,
  notify_middleware,
  slate_middleware,
  user_middleware,
  LocaleMiddleware,
} from '../middlewares';

import { apiMiddleware } from 'redux-api-middleware';
import configure from './configure';
import event_delays from './event_delays';
import event_selectors from './event_selectors';
import { reducer as form } from 'redux-form';
import graph from './graph';
import participant from './participant';
import participants from './participants';
import pe_cohorts from './pe_cohorts';
import pe_user_data_events from './pe_user_data_events';
import publish from './publish';
import publish_errors from './publish_errors';
import async_operations from './async_operations';
import related_reminders from './related_reminders';
import reminders from './reminders';
import sequences from './sequences';
import slate from './slate';
import thunk from 'redux-thunk';
import tiles from './tiles';
import { reducer as toastr } from 'react-redux-toastr';
import user from './user';
import locale from './locale';

export const reducers = combineReducers({
  user,
  graph,
  sequences,
  tiles,
  slate,
  reminders,
  related_reminders,
  configure,
  participant,
  participants,
  event_delays,
  event_selectors,
  pe_cohorts,
  pe_user_data_events,
  publish,
  publish_errors,
  async_operations,
  form, // curious panda
  toastr,
  locale,
});

export const middleware: StoreEnhancer<{ dispatch: unknown }, {}> =
  window.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? compose(
        applyMiddleware(
          thunk,
          compensation_middleware,
          user_middleware,
          slate_middleware,
          notify_middleware,
          apiMiddleware,
          LocaleMiddleware,
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    : applyMiddleware(
        thunk,
        compensation_middleware,
        user_middleware,
        slate_middleware,
        notify_middleware,
        apiMiddleware,
        LocaleMiddleware,
      );

const store = createStore(reducers, undefined, middleware);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
