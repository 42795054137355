import React, { PureComponent } from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';
import _ from 'lodash';

import Title, { IconsHolder, SubTitle } from '../title';
import {
  FollowUpContainer,
  HeadingContainer,
  IconContainer,
  ReminderContainer,
  TileComponent,
  TileContent,
  UnconfiguredContainer,
} from './container';
import Dropdown from '../../../../components/dropdown';
import { TitleValue } from '../../../configure/TitleValue';

import Bell from '../../../../assets/nav_icons/reminder';
import Webpage from '../../../../assets/tile_icons/webpage';
import UnconfiguredIcon from '../../../../assets/nav_icons/unconfigured';

import tileDescription from '../../utils/tileDescription';
import tileTitle from '../../utils/tileTitle';
import { e2eTestId } from '../../../../utils/e2eTesting';

export const countReminders = (reminders) => {
  return _.map(reminders, (reminder, id) =>
    reminder.content.type !== 'delay' ? 1 : 0,
  ).reduce((acc, rem) => acc + rem, 0);
};

export const TileDropdown = (props) => {
  const { icon, id } = props;
  return _.isFunction(icon) ? (
    <Dropdown toggle={icon()} className="tile-dd" closeOnInsideClick={false}>
      <li>
        <div>
          <input readOnly onFocus={(e) => e.target.select()} value={id} />
        </div>
      </li>
    </Dropdown>
  ) : (
    icon
  );
};

class Tile extends PureComponent {
  render() {
    const {
      content,
      tileConfig,
      displayReminders,
      dragHandleProps,
      showBell,
      onTileClick,
      toggleReminders,
      children,
      icon,
      id,
      reminders,
      truncateConfig,
      masonryEnabled = false,
      ...rest
    } = this.props;

    const follow_up_list = [];
    let render_follow_up = false;

    if (content.follow_up) {
      _.forEach(content.follow_up, (value, answer) => {
        const resource_type = Object.keys(value)[0];

        if (resource_type === 'node') {
          follow_up_list.push(answer);
        }
      });
      if (follow_up_list.length > 0) {
        render_follow_up = true;
      }
    }

    const tile_title = tileTitle(content);
    const tile_description = tileDescription(content);
    // Note: When viewing disqualifications or events, tileConfig is undefined.
    const title = tileConfig?.title || tile_title;

    return (
      <TileComponent {...rest} id={`t-tile-${_.snakeCase(tile_title)}`}>
        <HeadingContainer>
          <IconContainer>
            <TileDropdown icon={icon} id={id} />
          </IconContainer>

          <TileContent {...dragHandleProps} onClick={onTileClick}>
            <Title
              data-test="title"
              data-title={tile_title} // Save untruncated name for easier ID in tests
              {...tileConfig}
              title={tile_description ? `${title}: ${tile_description}` : title}
            >
              <TitleValue truncateConfig={truncateConfig} val={tile_title} />
            </Title>
            {tile_description && (
              <SubTitle {...tileConfig}>
                {_.truncate(tile_description, truncateConfig)}
              </SubTitle>
            )}
          </TileContent>

          <IconsHolder>
            {!masonryEnabled && content?.initial_state && (
              <UnconfiguredContainer
                {...e2eTestId('unconfigured_icon_container')}
              >
                <UnconfiguredIcon />
              </UnconfiguredContainer>
            )}
            <FollowUpContainer
              active={render_follow_up}
              className="followUpIcon"
              id="t-toggle-followups"
              showIcon={render_follow_up}
            >
              <li>{follow_up_list.length}</li>
              <li>
                <Webpage />
              </li>
            </FollowUpContainer>
            <ReminderContainer
              active={displayReminders}
              className="reminderBell"
              id="t-toggle-reminders"
              onClick={
                _.isFunction(toggleReminders) ? toggleReminders : undefined
              }
              showBell={
                showBell && (countReminders(reminders) > 0 || displayReminders)
              }
            >
              <li>{countReminders(reminders)}</li>
              <li>
                <Bell />
              </li>
              <li>{displayReminders ? '\u25B2' : '\u25BC'}</li>
            </ReminderContainer>
          </IconsHolder>
        </HeadingContainer>
        {children}
      </TileComponent>
    );
  }
}

Tile.propTypes = {
  type: string,
  onClick: func,
  onHover: func,
  tileConfig: object,
  configOverride: object,
  icons: node,
  children: oneOfType([func, node]),
  selected: oneOfType([bool, object]),
  filtered: bool,
  active: bool,
  showBell: bool,
};

Tile.defaultProps = {
  active: false,
  showBell: true,
  object: {},
  type: ``,
  onTileClick: undefined,
  onClick: undefined,
  onMouseOver: () => false,
  icons: undefined,
  toggleReminders: false,
  reminders: [],
  truncateConfig: {
    length: 27,
    separator: /,? +/,
  },
};

export default Tile;
