import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0, 0, 400, 426.3736263736264"
    {...props}
  >
    <path d="M73.029 3.114 C 72.816 3.617,60.977 17.205,46.721 33.309 L 20.800 62.589 25.443 66.933 C 38.997 79.612,45.699 96.701,45.737 118.681 C 45.764 134.043,44.913 138.332,37.030 162.544 C 33.669 172.870,30.055 185.172,29.001 189.881 C 7.044 287.954,58.375 361.746,187.606 417.888 L 200.488 423.484 212.515 418.293 C 355.159 356.739,402.109 276.187,363.019 160.073 C 348.939 118.247,353.457 86.475,376.422 65.828 L 380.012 62.600 355.130 34.414 C 341.445 18.911,329.480 5.320,328.541 4.211 L 326.832 2.195 321.120 6.294 C 282.508 33.997,238.359 33.148,202.475 4.012 L 200.189 2.156 196.981 4.863 C 163.477 33.141,113.346 33.270,77.796 5.170 C 73.594 1.848,73.569 1.838,73.029 3.114 M292.310 148.354 L 308.783 164.840 246.516 227.136 C 177.386 296.299,180.404 293.644,170.696 293.851 C 159.658 294.086,158.874 293.484,121.614 256.122 L 91.947 226.372 108.610 209.710 L 125.272 193.047 147.438 215.203 L 169.603 237.360 222.346 184.614 C 251.354 155.604,275.256 131.868,275.462 131.868 C 275.668 131.868,283.249 139.287,292.310 148.354" />
  </svg>
);
