import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 5)" fill="#000" fillRule="evenodd">
        <path d="M6.642 3.843a.352.352 0 0 0-.497 0l-.98.98-.98-.98a.352.352 0 0 0-.497.496l.981.98-.98.98a.352.352 0 0 0 .497.497l.98-.98.98.98a.352.352 0 0 0 .497-.497l-.98-.98.98-.98a.351.351 0 0 0-.001-.496zM11.85 3.843l-.98.98-.98-.98a.352.352 0 0 0-.497.496l.98.98-.98.98a.352.352 0 0 0 .497.497l.98-.98.98.98a.352.352 0 0 0 .498-.497l-.98-.98.98-.98a.352.352 0 0 0-.498-.496z" />
        <rect fillRule="nonzero" x="14" y="1" width="1" height="8" rx=".5" />
        <path
          d="M21.407.055H.351a.364.364 0 0 0-.248.098A.328.328 0 0 0 0 .389V9.72c0 .088.038.174.103.236a.364.364 0 0 0 .248.098h21.057a.364.364 0 0 0 .248-.098.328.328 0 0 0 .103-.236V.39a.328.328 0 0 0-.104-.236.364.364 0 0 0-.248-.098zM.702.72h20.354v8.666H.702V.721z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
