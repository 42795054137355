import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  color: #243544;
  fontSize: 350px
  lineHeight: 1em;
  fontWeight: 900;
  alignContent: center;
  alignItems: center;
  height: 100%;
  width: 100%;
`;

const Text = styled.div`
  display: block;
  textalign: center;
  width: 100%;
`;

export default () => (
  <Container>
    <Text>
      CRANK<br />IT
    </Text>
  </Container>
);
