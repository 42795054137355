import { triageV2 } from '.';

export const study_get = (study_slug) =>
  triageV2({
    method: `GET`,
    url: `studies/${study_slug}/info`,
  });

export const studies_delete = () => console.warn(`endpoint does not exist`);

// Update study status
// payload expects: { status: 'some string', notes: 'some other string' }
export const study_status = (study_slug, data) =>
  triageV2({
    method: 'PUT',
    url: `studies/${study_slug}`,
    data,
  });

export const get_delays = (study_slug) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/event_delays`,
  });

export const update_enrollments = (study_slug, data) =>
  triageV2({
    method: 'PUT',
    url: `studies/${study_slug}/enrollments/bulk_update`,
    data,
  });
