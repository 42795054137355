import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import _ from 'lodash';

export const Port = styled.div`
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  padding: 50px;
  min-height: 100vh;

  ${({ center }) => {
    if (center) {
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(50, 58, 68, 0.8);
      `;
    }
  }}
`;

Port.Holder = styled.div`
  z-index: 15;
  padding: 20px;
  width: 500px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  position: relative;
`;

Port.Close = styled.div`
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  line-height: 1.8em;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #111;
  color: #fff;
`;

let modalRoot = document.getElementById('portal-root');
// For unit tests.
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'portal-root');
  document.body.appendChild(modalRoot);
}

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.portal = React.createRef();
  }

  componentDidMount() {
    // setting up listeners to close modal on esc or clicking outside modal
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleKeydown, false);
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, false);
    document.removeEventListener('mousedown', this.handleClick, false);
    this.onClose();
  }

  handleKeydown = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  handleClick = (e) => {
    // do nothing on inside click
    if (_.isNull(this.portal.current) || this.portal.current.contains(e.target))
      return;
    this.props.onClose();
  };

  onClose = () => modalRoot.removeChild(this.el);

  render() {
    const PortalContent = (
      <Port {...this.props}>
        {this.props.children({ containerRef: this.portal })}
      </Port>
    );

    return ReactDOM.createPortal(PortalContent, this.el);
  }
}

export default Portal;
