import { triageV3 as triage } from './triage';

const baseUrl = graphId => `graphs/${graphId}/schedule_configs`;
const schedUrl = (graphId, schedId) => `${baseUrl(graphId)}/${schedId}`;

export const get_schedule = graphId =>
  triage({ method: `GET`, url: baseUrl(graphId) });

export const create_schedule = (graphId, data) =>
  triage({ method: `POST`, url: baseUrl(graphId), data });

export const update_schedule = (graphId, schedId, data) =>
  triage({ method: `PATCH`, url: schedUrl(graphId, schedId), data });

export const delete_schedule = (graphId, schedId) =>
  triage({ method: `DELETE`, url: schedUrl(graphId, schedId) });
