import * as C from '../common';
import { cloneDeep, isEmpty, remove } from 'lodash';
import { default_experience_key } from '../../../../constants';
import { trim_string_parse } from '../common';
import onImageAdd from '../onImageAdd';
import { getInputsForExperience } from '../utils';

export const EducationTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [C.experience_attribute, C.emit_event],
  }),
});

export default ({
  content,
  experience = default_experience_key,
  study_slug,
  dataForTile,
}) => {
  const {
    education: { masonryEnabled = false },
  } = dataForTile;

  // SPP-1486: This change protects the logic and allows compatibility between
  // graph blob and triage based tile architecture.
  const experienceContent = content.experiences
    ? content.experiences[experience]
    : {};

  const showTitle = masonryEnabled ? [] : [C.title];
  const experiences = `content.experiences.${experience}`;
  const experiencesName = masonryEnabled
    ? `${experiences}.settings`
    : experiences;

  const schema = [
    {
      label: 'Content',
      name: experiencesName,
      input: [
        ...showTitle,
        {
          name: 'hero_title',
          interface: 'textinput',
          label: 'Hero Title',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'hero_image_path',
          label: 'Hero Background Image',
          interface: 'connected_file',
          config: {
            onFileAdd: (data, cb) => onImageAdd({ data, study_slug }, cb),
          },
          inputStyle: 'sm_minimal',
          parse: trim_string_parse,
        },
        {
          name: 'hero_image_mobile_disabled',
          label: 'Disable Hero Image for Mobile',
          interface: 'checkbox',
        },
        {
          name: 'hero_content',
          label: 'Hero Content',
          interface: 'rte',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'hero_button',
          label: 'Hero Button',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'eligibility_content',
          label: 'Eligibility Content',
          interface: 'rte',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'faq_enroll_form_heading',
          label: 'Eligibility Content - Mobile',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'how_you_help_image_path',
          label: 'How You Help Background Image',
          interface: 'connected_file',
          config: {
            onFileAdd: (data, cb) => onImageAdd({ data, study_slug }, cb),
          },
          inputStyle: 'sm_minimal',
          parse: trim_string_parse,
        },
        {
          name: 'how_you_help_heading',
          label: 'How You Help Heading',
          interface: 'text',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'how_you_help_steps',
          interface: 'array',
          title: 'How You Help Steps',
          isOrdered: true,
          items: [
            {
              name: 'step',
              label: '',
              interface: 'rte',
              inputStyle: 'sm_minimal',
            },
          ],
        },
        {
          name: 'how_you_help_content_collapsed',
          label: 'Collapsible (Show More)',
          interface: 'checkbox',
          inputStyle: 'muted',
        },
        {
          name: 'faq_heading',
          label: 'FAQ Heading',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'faq_array',
          interface: 'array',
          title: 'Frequently Asked Questions',
          isOrdered: true,
          items: [
            {
              name: 'question',
              label: 'question',
              interface: 'textinput',
              required: true,
              inputStyle: 'sm_minimal',
            },
            {
              name: 'answer',
              label: 'answer',
              interface: 'rte',
              required: true,
              inputStyle: 'sm_minimal',
            },
          ],
        },
        {
          name: 'faq',
          label:
            'FAQ Content <small style="color: red">Do not use, please use "Frequently Asked Questions"</small>',
          interface: 'rte',
          title: 'FAQ content',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'faq_collapsed',
          label: 'Collapsible (Show More)',
          interface: 'checkbox',
          inputStyle: 'muted',
        },
        {
          name: 'optional_branding',
          label: 'Optional Branding Image',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
      ],
    },
  ];

  let inputs = cloneDeep(schema[0]['input']);
  if (isEmpty(experienceContent['faq'])) {
    inputs = remove(inputs, (field) => {
      return field.name !== 'faq';
    });
  }
  schema[0]['input'] = inputs;

  if (!masonryEnabled) {
    schema.push(EducationTileSettings(experience));
  }

  return [schema];
};
