/**
 * hasAuthAction
 * given an authorization object, graph_id, and action name
 * This function will check if the given action exists in the authorization object
 * for the current graph_id
 * @param {object} authorization
 * @param {string} graph_id
 * @param {string} actionName
 * @returns {boolean}
 */
export default function hasAuthAction({ authorization, graphId, actionName }) {
  return !!authorization?.[graphId]?.actions?.includes(actionName);
}
