import * as C from '../common';

import { default_experience_key, event_statuses } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        label: 'Content',
        name: `content.experiences.${experience}`,
        input: [C.title],
      },
      {
        label: 'Settings',
        name: `content`,
        input: getInputsForExperience({
          experience,
          mandatoryInputs: [
            C.optional,
            C.experience_attribute,
            {
              name: `event_slug`,
              label: `Event Slug`,
              interface: `textinput`,
            },
            {
              name: `event_status`,
              label: `Event Status`,
              interface: `select`,
              options: event_statuses,
            },
          ],
          optionalInputs: [
            ...C.dependencyCriteria({
              emitEvent: true,
              availability: true,
              visibility: true,
              cancellation: true,
            }),
          ],
        }),
      },
    ],
  ];
};
