//@ts-nocheck
export default (html = ``) =>
  html
    // UL type
    .replace(/ul><br><p/, 'ul><p')
    .replace(/ul><br\/><p/, 'ul><p')
    .replace(/ul><br \/><p/, 'ul><p')
    .replace(/ul><br><li/, 'ul><li')
    .replace(/ul><br\/><li/, 'ul><li')
    .replace(/ul><br \/><li/, 'ul><li')
    .replace(/<li><br><ul>/, '<li><ul>')
    .replace(/<li><br\/><ul>/, '<li><ul>')
    .replace(/<li><br \/><ul>/, '<li><ul>')

    // OL type
    .replace(/ol><br><p/, 'ol><p')
    .replace(/ol><br\/><p/, 'ol><p')
    .replace(/ol><br \/><p/, 'ol><p')
    .replace(/ol><br><li/, 'ol><li')
    .replace(/ol><br\/><li/, 'ol><li')
    .replace(/ol><br \/><li/, 'ol><li')
    .replace(/<li><br><ol>/, '<li><ol>')
    .replace(/<li><br\/><ol>/, '<li><ol>')
    .replace(/<li><br \/><ol>/, '<li><ol>')

    // nested lists
    .replace(/<\/li><ol>/, '</li><li><ol>')
    .replace(/<\/ol><li>/, '</ol></li><li>')

    .replace(/<\/li><ul>/, '</li><li><ul>')
    .replace(/<\/ul><li>/, '</ul></li><li>')

    .replace(/<\/ol><\/ol>/, '</ol></li></ol>')
    .replace(/<\/ul><\/ol>/, '</ul></li></ol>')
    .replace(/<\/ul><\/ul>/, '</ul></li><ul>')
    .replace(/<\/ol><\/ul>/, '</ol></li><ul>')

    .replace(/<ol><ul>/, '<ol><li><ul>')
    .replace(/<ul><ul>/, '<ul><li><ul>')
    .replace(/<ul><ol>/, '<ul><li><ol>')
    .replace(/<ol><ol>/, '<ol><li><ol>');
