import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';
import { shouldShowInterface } from '../common';
import { enforceNoWhiteSpace_parse } from '../common_validations';

export const CastorEdcTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.experience_attribute,
      C.optional,
      C.display_group_name(displayGroupNames),
      ...C.dependencyCriteria({
        emitEvent: true,
        cancellation: true,
        completion: true,
      }),
    ],
  }),
});

export default ({
  externalIds = [],
  dataForTile,
  experience = default_experience_key,
  displayGroupNames,
}) => {
  const {
    castor_edc: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
  ? `content.experiences.${experience}.settings`
  : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          label: 'Use external ID',
          name: 'use_external_id',
          interface: 'checkbox',
        },
        {
          label: 'External ID',
          name: 'external_id',
          interface: 'selectadvanced',
          possible_answers: externalIds,
          required: true,
          condition: (path, state) => {
            return shouldShowInterface(path, state, 'use_external_id');
          },
          parse: enforceNoWhiteSpace_parse,
        },
      ],
    },
  ]

  if (!masonryEnabled) {
    schema.push(CastorEdcTileSettings(experience, displayGroupNames));
  }

  return schema;
};
