import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const CastorEproSurveyTileSettings = ({
  experience,
  displayGroupNames,
  masonryEnabled = false,
}) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.experience_attribute,
      C.display_group_name(displayGroupNames),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
      }),
    ],
  }),
});

export default ({
  displayGroupNames,
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    castor_epro_survey: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
  ? `content.experiences.${experience}.settings`
  : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        C.action_title,
        C.external_title,
        C.tooltip,
        C.completion_message,
        {
          label: 'Castor Survey Package Name',
          name: 'castor_survey_package_name',
          interface: 'textinput',
          required: true,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(CastorEproSurveyTileSettings(experience, displayGroupNames));
  }

  return schema;
};
