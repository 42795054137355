import _ from 'lodash';

export default data => {
  if (
    data.content &&
    data.content.branching &&
    data.content.branching.strategy
  ) {
    data.content.strategy = data.content.branching.strategy;
  }

  if (
    data.content &&
    data.content.branching &&
    data.content.branching.batch_size
  ) {
    data.content.batch_size = data.content.branching.batch_size;
  }

  if (
    data.content &&
    data.content.branching &&
    data.content.branching.caps_by_arm
  ) {
    _.merge(data, {
      content: {
        caps_by_arm: _.reduce(
          _.keys(data.content.branching.caps_by_arm),
          (acc, key) => {
            return [
              ...acc,
              {
                arm_name: key,
                arm_cap: data.content.branching.caps_by_arm[key],
              },
            ];
          },
          [],
        ),
      },
    });
  }

  if (_.has(data, 'content.branching.arm_names'))
    data.content.arm_names = data.content.branching.arm_names;

  //
  //
  //
  if (
    data.content &&
    data.content.branching &&
    data.content.branching.condition
  ) {
    data.content.condition = data.content.branching.condition;
  }

  if (
    data.content &&
    data.content.branching &&
    data.content.branching.default_arm
  ) {
    data.content.default_arm = data.content.branching.default_arm;
  }

  if (
    data.content &&
    data.content.branching &&
    data.content.branching.arms_by_condition_value
  ) {
    _.merge(data, {
      content: {
        arms_by_condition_value: _.reduce(
          _.keys(data.content.branching.arms_by_condition_value),
          (acc, key) => {
            return [
              ...acc,
              {
                condition_value: key,
                arm_name: data.content.branching.arms_by_condition_value[key],
              },
            ];
          },
          [],
        ),
      },
    });
  }

  if (_.get(data, 'content.branching.on_cap_overflow'))
    data.content.on_cap_overflow = data.content.branching.on_cap_overflow;

  _.unset(data, `content.branching`);
  return data;
};
