"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSum = exports.validateHasAggregateValue = exports.validateIsObject = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.reduce.js");

var _lodash = _interopRequireDefault(require("lodash"));

var validateIsObject = function validateIsObject(value) {
  if (value && !_lodash["default"].isObject(value)) return 'malformed data';
};
/**
 * Validates that the component has an overall value for the key with
 * the input name. This is typically used for aggregate values such as
 * sum and average.
 */


exports.validateIsObject = validateIsObject;

var validateHasAggregateValue = function validateHasAggregateValue(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value) {
      if (!_lodash["default"].has(value, name)) return 'no aggregate value present';
    }
  };
};

exports.validateHasAggregateValue = validateHasAggregateValue;

var validateSum = function validateSum(_ref2) {
  var name = _ref2.name;
  return function (value) {
    if (_lodash["default"].has(value, name)) {
      var values = _lodash["default"].omit(value, name);

      if (value[name] !== _lodash["default"].reduce(values, function (acc, v) {
        return acc + v;
      }, 0)) return 'invalid sum';
    }
  };
};

exports.validateSum = validateSum;