import _ from 'lodash';

import { default as common_incoming, reduceFields } from '../common_incoming';

export default (data) => {
  if (
    _.has(data.content, `experiences`) &&
    !_.isEmpty(data.content.experiences)
  ) {
    data.content.experiences = _.reduce(
      data.content.experiences,
      (acc, experience_content, experience_key) => {
        let output = experience_content;

        if (_.isUndefined(experience_content['default_completion_event_slug']))
          output['default_completion_event_slug'] = true;

        output[`form`] = reduceFields(experience_content['form']);
        return { ...acc, [experience_key]: output };
      },
      {},
    );
  }

  return common_incoming(data);
};
