//@ts-nocheck
import TransformTree, {
  reduceSequences,
  reduceTiles,
} from '../utils/translateToTree';

import _ from 'lodash';
import { apiGraph } from '../api';
import generatePayload from '../utils/generatePayload';
import { toastr } from 'react-redux-toastr';
import types from '../constants';
import { updateGraph } from '../actions/graph_actions';
import { updateReminder } from '../actions/reminders_actions';
import { updateSequence } from '../actions/sequences_actions';
import { updateTile } from '../actions/tiles_actions';
import { logError } from '@evidation/logger';
import { Middleware } from '../redux/middleware-types';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { DynamicObject } from '../types/utils';

type Error = {
  response: {
    data: {
      errors: Array<any>;
    };
  };
  message: string;
};

const find_resource_id_list = (resource: string): string => {
  switch (resource) {
    case 'sequence':
    case 'sequences':
      return 'tile_ids';
    case 'tile':
    case 'tiles':
      return 'reminder_ids';
    default:
      return '';
  }
};

const find_sequence_from_tile = (
  resources: any,
  resource: string,
  target_uuid: string,
) => {
  const resource_list = find_resource_id_list(resource);
  const output = _.find(resources[resource], (s) =>
    s[resource_list].includes(target_uuid),
  ) as unknown as { id: string; [key: string]: string };
  return output.id;
};

const commonError = (error: Error) => {
  if (!_.isEmpty(_.get(error, 'response.data.errors')))
    error.response.data.errors.forEach((error) => toastr.error(error, ''));
  else toastr.error(error.message, '');
  logError(error);
};

export default ({ getState, dispatch }: Middleware) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (action.type === types.graph.set) {
      // When loading the slate, excluding follow-up tiles from sequences
      const state = getState() as RootState;

      _.forEach(state.tiles, (tile) => {
        if (tile.content.follow_up) {
          _.forEach(tile.content.follow_up, (answer_value) => {
            const follow_up_type = _.keys(answer_value)[0];

            const follow_up_id = answer_value[follow_up_type];

            if (follow_up_type === 'node') {
              tile['follow_up'] = tile['follow_up'] || [];

              _.forEach(state.tiles, (f_tile) => {
                if (f_tile.id === follow_up_id) {
                  f_tile['is_follow_up'] = true;
                  tile['follow_up'].push(f_tile);
                }
              });
            }
          });
        }
      });
    }

    if (action.type === types.slate.modify_resource) {
      const { graph, ...state } = getState() as RootState;
      const { props, data } = action.payload;
      const id = data.id;
      const currentLocale = state.locale?.currentLocale
        ? { translation_locale: state.locale.currentLocale }
        : {};
      let { resource, identifier } = props;
      let payload: DynamicObject = {};
      let sequence_uuid = undefined;
      let uuid = undefined;

      switch (resource) {
        case 'tile':
          sequence_uuid = find_sequence_from_tile(state, 'sequences', id);
          payload = {
            ...currentLocale,
            ...generatePayload(id, reduceTiles, getState(), 'tiles', data)[0],
          };

          apiGraph
            .update_tile(graph.id, sequence_uuid, id, payload)
            .then(async (data) => {
              await dispatch(updateTile(data));
              toastr.success('You have successfully edited the tile.', '', {
                timeOut: 1500,
              });

              if (action.payload?.onResolve) {
                await action.payload.onResolve();
              }
            })
            .catch(commonError);

          break;

        case 'reminder':
        case 'delay':
          uuid = find_sequence_from_tile(state, 'tiles', id);
          sequence_uuid = find_sequence_from_tile(state, 'sequences', uuid);
          payload = {
            ...state.reminders[id],
            ...data,
          };

          apiGraph
            .update_reminder(graph.id, sequence_uuid, uuid, id, payload)
            .then(async (data) => {
              await dispatch(updateReminder(data));
              toastr.success('You have successfully edited the reminder.', '');
            })
            .catch(commonError);
          break;

        case 'sequence':
          sequence_uuid = identifier;
          payload = generatePayload(
            sequence_uuid,
            reduceSequences,
            getState() as RootState,
            'sequences',
            data,
          );

          apiGraph
            .update_sequence(graph.id, sequence_uuid, payload[0])
            .then(async (data) => {
              await dispatch(updateSequence(data));
              toastr.success('You have successfully edited the sequence.', '');
            })
            .catch(commonError);
          break;

        case 'graph':
          payload = {
            ...TransformTree({
              ...(getState() as RootState),
              graph: {
                ...graph,
                ...data,
              },
              insert: {},
            }),
            ...currentLocale,
          };

          apiGraph
            .update(graph.id, payload)
            .then(async (data) => {
              await dispatch(updateGraph(data));
              toastr.success('You have successfully edited the graph.', '');
            })
            .catch((apiError) => {
              let errorMessage = apiError.message;
              if (
                apiError.response &&
                apiError.response.data &&
                apiError.response.data.errors
              ) {
                errorMessage =
                  errorMessage + ': ' + apiError.response.data.errors[0];
              }
              toastr.error(errorMessage, '');
              logError(errorMessage);
            });
          break;

        default:
          toastr.error(
            'There was a problem making your change. Please re-load your browser and try again.',
            '',
          );
          next(action);
          break;
      }
    }

    next(action);
  };
