import { AnyAction } from 'redux';
import types from '../constants';
import { Eventdelays } from '../redux/graph';

const defaultDelaysState = {
  eventDelays: {},
  fileUploads: {},
  unclaimedData: {},
  reviewed: [],
  detail: 0,
};

export default function event_delays(
  state: Eventdelays = defaultDelaysState,
  { type, payload }: AnyAction,
) {
  switch (type) {
    case types.event_delays.retrieve:
      return {
        ...state,
        eventDelays: payload['event_delay'],
        fileUploads: payload['file_upload'],
        unclaimedData: payload['unclaimed_data'],
      };
    case types.event_delays.review:
      return {
        ...state,
        reviewed: payload,
      };
    case types.event_delays.clear:
      return {
        ...state,
        reviewed: [],
      };
    case types.event_delays.detail:
      return {
        ...state,
        detail: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
