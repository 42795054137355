import React from 'react';

export default props => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>icons/tile-types/take_survey</title>
      <desc>Created with Sketch.</desc>
      <g
        id="icons/tile-types/take_survey"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Container"
          fillOpacity="0"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="24"
          height="24"
        />
        <path
          d="M17.0198974,4.3411964 C15.4875581,3.44701219 13.8147385,3 12.0003196,3 C10.1860606,3 8.5127615,3.44701219 6.98074188,4.3411964 C5.44856243,5.23522079 4.23554043,6.4482428 3.34135622,7.98042224 C2.44701219,9.51260168 2,11.1859008 2,13 C2,14.814259 2.44717201,16.4872385 3.3411964,18.0195778 C4.23538061,19.5514376 5.44840261,20.7646194 6.98058206,21.6588036 C8.5127615,22.5529878 10.1859008,23 12.0001598,23 C13.8144188,23 15.487718,22.5529878 17.0197376,21.6588036 C18.5517572,20.7647792 19.7647792,19.5515974 20.6589634,18.0195778 C21.5529878,16.4873983 22,14.8140992 22,13 C22,11.185741 21.5529878,9.51244187 20.6591232,7.98042224 C19.764939,6.44840261 18.551917,5.23538061 17.0198974,4.3411964 Z"
          id="Path-Copy-2"
          fill="#000000"
        />
        <path
          d="M14.5099487,-0.3294018 C13.7437791,-0.776493903 12.9073692,-1 12.0001598,-1 C11.0930303,-1 10.2563808,-0.776493903 9.49037094,-0.3294018 C8.72428122,0.117610395 8.11777021,0.724121398 7.67067811,1.49021112 C7.2235061,2.25630084 7,3.09295041 7,4 C7,4.9071295 7.22358601,5.74361925 7.6705982,6.50978888 C8.1176903,7.27571878 8.72420131,7.8823097 9.49029103,8.3294018 C10.2563808,8.7764939 11.0929504,9 12.0000799,9 C12.9072094,9 13.743859,8.7764939 14.5098688,8.3294018 C15.2758786,7.88238961 15.8823896,7.27579869 16.3294817,6.50978888 C16.7764939,5.74369916 17,4.90704959 17,4 C17,3.0928705 16.7764939,2.25622093 16.3295616,1.49021112 C15.8824695,0.724201307 15.2759585,0.117690304 14.5099487,-0.3294018 Z"
          id="Path-Copy-2"
          fill="#FFFFFF"
        />
        <g
          id="Group-Copy"
          transform="translate(8.000000, 10.000000)"
          fill="#FFFFFF"
        >
          <path
            d="M5.05115037,10.3334769 C5.05115037,10.4306465 5.01969811,10.5105558 4.95730499,10.5729489 C4.89503973,10.635342 4.81513052,10.6665385 4.71796091,10.6665385 L2.71780129,10.6665385 C2.62063169,10.6665385 2.54072247,10.635342 2.4782015,10.5729489 C2.41568053,10.5105558 2.38448397,10.4306465 2.38448397,10.3334769 L2.38448397,8.33331732 C2.38448397,8.23614771 2.41568053,8.1562385 2.4782015,8.09371753 C2.54072247,8.03145227 2.62063169,8.00025571 2.71780129,8.00025571 L4.71796091,8.00025571 C4.81513052,8.00025571 4.89503973,8.03145227 4.95730499,8.09371753 C5.01969811,8.1562385 5.05115037,8.23614771 5.05115037,8.33331732 L5.05115037,10.3334769 L5.05115037,10.3334769 Z"
            id="Path"
          />
          <path
            d="M7.5875331,4.17714265 C7.50071972,4.4341307 7.40355011,4.64432392 7.2958964,4.80746658 C7.1882427,4.97060925 7.03366631,5.13733185 6.83216721,5.30737867 C6.63105169,5.47755334 6.47123324,5.59914322 6.3529676,5.67214828 C6.23508552,5.74489763 6.06478298,5.84398507 5.84269928,5.96889916 C5.61358356,6.10084527 5.42448638,6.27268206 5.27527988,6.48453738 C5.12594553,6.69626485 5.05127835,6.8682295 5.05127835,7.00004775 C5.05127835,7.09734522 5.01982608,7.17725444 4.95743296,7.23964756 C4.8951677,7.30216853 4.81525848,7.33336509 4.71808887,7.33336509 L2.7179291,7.33336509 C2.62075949,7.33336509 2.54085027,7.30216853 2.4783293,7.23964756 C2.41580832,7.17725444 2.38461176,7.09734522 2.38461176,7.00004775 L2.38461176,6.6249219 C2.38461176,6.15965845 2.56680479,5.7238016 2.93144654,5.31760705 C3.2960883,4.91128464 3.69716866,4.61095383 4.13468763,4.41648675 C4.46787711,4.26382817 4.70402484,4.10746181 4.84287511,3.94777122 C4.98198108,3.78808063 5.05140621,3.57609745 5.05140621,3.3123331 C5.05140621,3.08308952 4.92099436,2.87826621 4.66055423,2.69760744 C4.40011409,2.51707653 4.10349107,2.42681107 3.77004587,2.42681107 C3.40885619,2.42681107 3.11031534,2.51017237 2.87416761,2.67689497 C2.64492404,2.84348972 2.34638319,3.16299874 1.97828936,3.63516635 C1.91576838,3.71852765 1.828955,3.76033615 1.71784922,3.76033615 C1.63448792,3.76033615 1.56851487,3.73949583 1.51993007,3.69794303 L0.144980047,2.65631036 C-0.0147105398,2.53126841 -0.0424550213,2.38551399 0.061618748,2.21879139 C0.950592848,0.739639748 2.23872949,5.32907052e-15 3.92615652,5.32907052e-15 C4.53052594,5.32907052e-15 5.12083134,0.142430195 5.69720057,0.427162731 C6.2735698,0.711767412 6.75443152,1.11463774 7.14004146,1.63551801 C7.52513998,2.15627042 7.7180728,2.72228341 7.7180728,3.33342913 C7.71768924,3.63900199 7.67409076,3.92015459 7.5875331,4.17714265 L7.5875331,4.17714265 Z"
            id="Path"
          />
        </g>
        <path
          d="M14.179299,5.74602389 C14.1770367,5.74376175 14.1732664,5.74376175 14.1710041,5.74112271 L13.0877301,4.66074459 L10.8944645,6.87270068 C10.5999957,7.1660226 10.0057508,7.61197163 9.32919068,6.96408381 C8.65263055,6.31619598 9.18584115,5.59425852 9.45305977,5.3270384 L11.645167,3.1162405 L10.5736048,2.03446724 C10.5709656,2.0322051 10.5709656,2.02843499 10.5687034,2.02617285 C10.4914106,1.94398364 10.4420185,1.83539923 10.4420185,1.71362493 C10.4420185,1.46404409 10.6448687,1.26120596 10.8944645,1.26120596 L14.6344096,1 C14.9673327,1 15.237671,1.27032215 15.237671,1.6032253 L14.9442616,5.42023214 C14.9442616,5.66981299 14.7414113,5.87265111 14.4918156,5.87265111 C14.3700841,5.87270121 14.261497,5.82331201 14.179299,5.74602389 Z"
          id="Path-Copy-3"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
