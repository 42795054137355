import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="nonzero">
        <path d="M1.691 14.605l6.849-4.122 1.13.665 1.13-.665 5.253 3.125c.167-.133.366-.266.566-.4l-5.187-3.124L17.65 6.36v6.45c.2-.033.432-.067.665-.067V4.1H1.027v11.968H14.99c0-.233.033-.466.066-.665H1.692v-.798zm0-.765V6.394l6.217 3.723-6.217 3.723zM17.65 4.798v.798L9.67 10.383 1.691 5.596v-.798H17.65z" />
        <path d="M18.314 13.441a2.667 2.667 0 0 0-2.66 2.66 2.667 2.667 0 0 0 2.66 2.66v-.665a2 2 0 0 1-1.995-1.995 2 2 0 0 1 1.995-1.995 2 2 0 0 1 1.995 1.995v1.662c0 .2-.133.333-.333.333s-.332-.133-.332-.333v-1.662c0-.731-.599-1.33-1.33-1.33-.732 0-1.33.599-1.33 1.33 0 .731.598 1.33 1.33 1.33h.665v.332a.98.98 0 0 0 .997.998.98.98 0 0 0 .997-.998v-1.662a2.667 2.667 0 0 0-2.66-2.66zm.665 3.325h-.665a.667.667 0 0 1-.665-.665c0-.366.3-.665.665-.665.366 0 .665.3.665.665v.665z" />
      </g>
    </svg>
  );
}
