/**
 * @description Returns an array of elements that redux forms then uses to build the UI for the settings (tab) -> languages (panel)
 * @param {Object t - params added inside the parent called aka PanelEditWrapper.
 * @param {Object} params - additional params that get injected when this function is curried. It usually the props of the caller component.
 * @returns {[[{input: [{name: string, label: string, interface: string}, {name: string, label: string, interface: string}, {possible_answers: *[], name: string, interface: string}, {addButtonLabel: string, name: string, className: string, interface: string, items: [{name: string, options: *[], interface: string, required: boolean}]}]}]]}
 */
export default (t, params) => {
  const settings_heading_label = 'Languages';
  const paragraph_label = `<strong>Currently showing study in</strong>`;

  // Here we re-shape this into an array of objects that redux forms can parse.
  const possible_answers = Object.keys(t?.content?.languages).reduce(
    (acc, key_name) => {
      acc.push({
        label: t?.content?.languages[key_name]?.label,
        value: key_name,
      });
      return acc;
    },
    [],
  );

  return [
    [
      {
        input: [
          {
            label: settings_heading_label,
            interface: 'settings_heading',
            name: 'displayable_languages_headers_settings',
          },
          {
            label: paragraph_label,
            interface: 'paragraph',
            name: 'displayable_languages_paragraph',
          },
          {
            name: 'primary_locale',
            interface: 'select_horizontal',
            possible_answers: possible_answers,
          },
          {
            name: 'secondary_locales',
            interface: 'array',
            addButtonLabel: 'add a field',
            className: 'external_clients',
            items: [
              {
                name: 'secondary_locale_value',
                interface: 'select_horizontal',
                required: true,
                options: possible_answers,
              },
            ],
          },
        ],
      },
    ],
  ];
};
