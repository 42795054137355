import SequenceForm from './Form';
import { RootState } from '../../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { transformIncoming, transformOutgoing } from './dataTransforms';
import getArmsSelector from '../../../selectors/arms';
import { useCallback, useMemo, useState } from 'react';
import { apiGraph } from '../../../api';
import { toastr } from 'react-redux-toastr';
import { loadGraph } from '../../../actions/combination';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { CloseIcon } from '../../../routes/edit/forms/connect_app/Forms/Connection/components';
import { Sequence } from '../../../redux/graph';

type SequenceProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ graph_id: string }>;

const AddEditSequence: React.FC<SequenceProps> = ({
  match,
  sequence,
  armOptions,
  loadGraph,
  graph_id,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    push,
    location: { state },
  } = useHistory<{
    content: { archipelago_identifier?: string; title?: string };
  }>();

  const routeStateData = useMemo(() => {
    return state?.content || {};
  }, [state]);
  const addSequence = useCallback(
    async (values: Partial<Sequence>) => {
      const {
        params: { graph_id },
      } = match;
      try {
        setLoading(true);
        await apiGraph.add_sequence(graph_id, values);
        await loadGraph(graph_id);
        toastr.success('You have successfully added a sequence.', '');
        push(`/study/${match.params.graph_id}/edit/slate`);
      } catch (err: any) {
        setLoading(false);
        const message =
          err?.response?.data?.errors?.concat(' \n') ||
          'Something went wrong creating this sequence please try again.';
        toastr.error('Error', message);
      }
    },
    [match, loadGraph, push],
  );
  const updateSequence = useCallback(
    async (values: Partial<Sequence>) => {
      const {
        params: { graph_id },
      } = match;
      try {
        setLoading(true);
        await apiGraph.update_sequence(graph_id, sequence.id, values);
        await loadGraph(graph_id);
        toastr.success('Success', 'Sequence updated');
        push(`/study/${match.params.graph_id}/edit/slate`);
      } catch (err) {
        setLoading(false);
        toastr.error(
          'Error',
          'Something went wrong updating this sequence please try again.',
        );
      }
    },
    [match, loadGraph, sequence, push],
  );
  const defaultValues = transformIncoming(sequence);
  return (
    <div style={{ padding: '2rem' }}>
      <Link to={`/study/${graph_id}/edit/slate`} style={{ float: 'right' }}>
        <CloseIcon />
      </Link>
      <h1 style={{ marginTop: '1rem', fontSize: 20 }}>
        {sequence ? 'Update Sequence' : 'Add a sequence'}
      </h1>
      <div
        style={{
          borderBottom: '1px solid #e7e7e7',
          marginTop: 8,
          marginBottom: 8,
        }}
      />
      <SequenceForm
        armOptions={armOptions}
        loading={loading}
        onSubmit={(values) =>
          sequence
            ? updateSequence(transformOutgoing(values, routeStateData))
            : addSequence(transformOutgoing(values, routeStateData))
        }
        defaultValues={defaultValues}
      />
    </div>
  );
};

const connector = connect(
  ({ sequences, graph }: RootState, ownProps: any) => ({
    sequence: ownProps.match.params.identifier
      ? sequences[ownProps.match.params.identifier]
      : undefined,
    armOptions: getArmsSelector(sequences)?.map((arm: any) => ({
      label: arm?.name,
      value: arm?.name,
    })),
    graph_id: graph.id,
  }),
  {
    loadGraph,
  },
);

export default connector(AddEditSequence);
