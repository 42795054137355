import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero" stroke="#000" strokeWidth=".7">
          <path fill="#FFF" d="M1.35 6.993h19.3v12.3H1.35z" />
          <path
            d="M1.664 11.143H20M6 19.005V7.594M11 19.005V7.594M16 19.005V7.594M1.664 15.143H20"
            strokeLinecap="square"
          />
        </g>
        <path
          d="M8.623 1.805c.543 0 .936.223 1.178.67.187.346.281.82.281 1.421 0 .57-.085 1.042-.255 1.416-.246.535-.648.802-1.207.802-.504 0-.879-.218-1.125-.656-.205-.365-.308-.855-.308-1.47 0-.477.062-.886.185-1.228.23-.637.648-.955 1.251-.955zm-.006 3.829c.274 0 .491-.121.654-.363.162-.243.243-.694.243-1.354 0-.477-.059-.869-.176-1.176-.117-.308-.345-.462-.683-.462-.31 0-.537.146-.68.438-.144.292-.216.722-.216 1.29 0 .429.046.772.137 1.032.141.397.381.595.721.595zm1.934-1.576h1.47V4.6h-1.47v-.542zm2.455.873c.035.3.175.508.42.624.124.058.269.088.433.088.312 0 .544-.1.694-.3.15-.198.226-.42.226-.661 0-.293-.09-.52-.268-.68a.93.93 0 0 0-.643-.24.969.969 0 0 0-.468.105c-.13.07-.24.168-.332.293l-.457-.026.319-2.259h2.18v.51h-1.784l-.18 1.166c.098-.074.191-.13.28-.167.155-.065.336-.097.541-.097.385 0 .711.124.979.372.267.248.401.563.401.944 0 .396-.123.746-.368 1.048-.245.303-.636.454-1.173.454-.342 0-.644-.096-.907-.288-.262-.192-.41-.488-.44-.886h.547z"
          fill="#000"
        />
      </g>
    </svg>
  );
}
