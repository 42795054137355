import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.29 6.105a.244.244 0 0 0-.245.244v9.754c0 .135.11.244.244.244h16.329a.244.244 0 0 0 .244-.244V6.35a.245.245 0 0 0-.244-.244H3.29zm5.686 7.066v-2.2h4.955v2.2H8.976zm4.955-4.89v2.201H8.976v-2.2h4.955zm-5.442 0v2.201H3.533v-2.2H8.49zm0 2.69v2.2H3.533v-2.2H8.49zm.488 2.688h4.954v2.2H8.977v-2.2zm5.442-.488v-2.2h4.955v2.2h-4.955zm0-2.689v-2.2h4.955v2.2h-4.955zM3.533 13.66h4.955v2.2H3.533v-2.2zm10.886 2.2v-2.2h4.955v2.2h-4.955z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
