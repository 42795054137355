import { triageV3 } from './triage';

/**
 * @description Request Triage to generate a CSV file of the audit trail using the graph ID
 * @param {string} graphId - Value of `graph.id` returned from the graph reducer
 * @returns {AxiosPromise}
 */
export const generateAuditTrail = (graphId, data) =>
  triageV3({
    method: 'POST',
    url: `graphs/${graphId}/audit_trails`,
    data,
  });

/**
 * @description Request the CSV file
 * @param {string} graphId - Value of `graph.id` returned from the graph reducer
 * @param {string} auditTrailId - Value of `metadata.audit_trail_id` returned from the completed async job (checkAsyncOperations)
 * @returns {AxiosPromise}
 */
export const getAuditTrail = (graphId, auditTrailId) =>
  triageV3({
    method: 'GET',
    url: `graphs/${graphId}/audit_trails/${auditTrailId}`,
  });
