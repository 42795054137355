import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import store from '../../../../reducers';
import { getInputsForExperience } from '../utils';

export const email_enabled_boolean = `email_enabled`;
export const push_enabled_boolean = `push_enabled`;

export const showInterfaceForEmail = (path, state) => {
  return C.shouldShowInterface(path, state, email_enabled_boolean);
};

export const showInterfaceForPush = (path, state) => {
  return C.shouldShowInterface(path, state, push_enabled_boolean);
};

export const CommunicationsTileSettings = ({
  experience,
  displayGroupNames = null,
  id,
  masonryEnabled = false,
}) => {
  const application_state = store.getState();
  const tile_options = C.getFollowUpTileOptions(
    application_state,
    id,
    masonryEnabled,
  );

  return {
    label: 'Settings',
    name: 'content',
    input: getInputsForExperience({
      experience,
      mandatoryInputs: [
        C.experienceTileTitle(masonryEnabled),
        C.experienceTileDescription(masonryEnabled),
        C.optional,
        C.experience_attribute,
        // This is conditional because we don't show display groups when editing
        //   reminders, in which case `displayGroupNames` is null.
        ...(displayGroupNames ? [C.display_group_name(displayGroupNames)] : []),
        C.merge_data,
        C.one_click_contribution_keys,
        ...C.followUp(tile_options),
      ],
      optionalInputs: [
        ...C.dependencyCriteria({
          emitEvent: true,
          availability: true,
          visibility: true,
          cancellation: true,
        }),
      ],
    }),
  };
};

/**
 * Non-SendGrid fields should only show if email is enabled and "Use
 * Sendgrid" is not checked.
 */
const nonSendgridField = (input) => ({
  ...input,
  condition: C.combineEvery(
    showInterfaceForEmail,
    C.negate(C.showInterfaceForSendgridId),
  ),
});

export default ({
  id,
  experience = default_experience_key,
  displayGroupNames = null,
  dataForTile,
}) => {
  const application_state = store.getState();
  const graph_id = application_state.graph?.id;

  const masonryEnabled =
    dataForTile?.communications?.masonryEnabled;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.html_formatter,
        {
          name: 'send_immediately',
          interface: 'checkboxinput',
          label: 'Send Immediately',
        },
      ],
    },
    {
      label: 'Email',
      name: experienceName,
      input: [
        { ...C.enabled, name: `email_enabled` },
        { ...C.use_sendgrid, condition: showInterfaceForEmail },
        {
          ...C.send_email_preview({ graph_id, tile_id: id }),
          condition: showInterfaceForEmail,
        },
        C.sendgrid_template_id,
        // Non-SendGrid fields:
        {
          ...C.subject,
          label: 'Email Subject',
          condition: showInterfaceForEmail,
        },
        nonSendgridField(C.html_formatter),
        nonSendgridField({ ...C.body, title: 'Email Body Paragraphs' }),
        { ...C.email_personalizations, condition: showInterfaceForEmail },
        { ...C.specify_recipients, condition: showInterfaceForEmail },
      ],
    },
    {
      label: 'Push Notification',
      name: experienceName,
      input: [
        { ...C.enabled, name: 'push_enabled' },
        {
          ...C.title,
          label: 'Push Notification Title',
          name: 'push_title',
          maxLength: 40,
          condition: showInterfaceForPush,
        },
        {
          name: 'push_body',
          label: 'Push notification body',
          interface: 'textarea',
          required: true,
          inputStyle: 'sm_minimal',
          maxLength: 140,
          condition: showInterfaceForPush,
        },
        {
          name: 'push_deeplink',
          label: 'Deeplink',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          condition: showInterfaceForPush,
        },
        {
          ...C.merge_data,
          name: 'push_options',
          title: 'Options',
          condition: showInterfaceForPush,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      CommunicationsTileSettings({ experience, displayGroupNames, id }),
    );
  }

  return [schema];
};
