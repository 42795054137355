import { getCountries } from 'react-phone-number-input/input';
import countryNames from 'react-phone-number-input/locale/en.json';
import { validateJson } from './common_validations';
import Papa from 'papaparse';
import { default_experience_key } from '../../../constants';

const PARAMS_NOT_DEFINED = (index, column) =>
  `Row ${index} in the CSV file is missing a value for column ${column}`;

const VALID_PAYLOAD = (index) =>
  `Row ${index} in the CSV file contains invalid JSON on additional payload column`;

/**
 * @description Function returns an array of objects that can be used in a
 * select or selectadvanced field.
 * @returns {array[obj]} - [{value: 'country_code', label: 'country name'}, ...]
 */
export const generateCountryList = () => {
  const countriesCodes = getCountries();

  const countryOptions = countriesCodes.map((country) => ({
    value: country,
    label: countryNames[country],
  }));

  // Adding International phone format as an option
  countryOptions.push({ value: 'ZZ', label: 'International' });

  return countryOptions;
};

/**
 * @description Function returns the array with correct inputs depends of experience
 * @param {string} experience is for the current experience
 * @param {object[]} mandatoryInputs are the mandatory inputs for each tile
 * @param {object[]} optionalInputs are optional inputs for each tile (disqualification criteria, emit event, etc.)
 * @returns {object[]} - [{value: 'country_code', label: 'country name'}, ...]
 */
export const getInputsForExperience = ({
  experience = default_experience_key,
  mandatoryInputs = [],
  optionalInputs = [],
}) => {
  const inputs =
    experience === default_experience_key
      ? [...mandatoryInputs, ...optionalInputs]
      : mandatoryInputs;

  return inputs;
};

export const no_data_field = [
  'paragraph',
  'heading',
  'scrollbox',
  'scrollboxlarge',
  'markdown',
];

export const parseAsInt = ['maxFileSize', 'maxFiles'];
export const parseAsArray = ['imgExtension'];

/** TODO: Currently there is not a mechanism to bundle each form (which is made from individual components)
 * into a and give it a `version`, This new process requires a TDD to discuss paths to version component/forms,
 * for now we hard code it
 * **/

// Sends the version of the form to triage.
export const DEFAULT_INTERFACE_VERSION = 1;

/**
 * Custom error for Safe JSON Parse
 * this is useful in appsignal and other error services
 * to give us more context if this error is thrown
 *
 */
class SafeJSONParseError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SafeJSONParseError';
  }
}
/**
 * This method will call JSON.parse if the argument is a string
 * If the argument is not a string it will just return the argument.
 * @param {string | object} possibleJSON
 */
export const safeJSONParse = (possibleJSON) => {
  if (typeof possibleJSON === 'string') {
    try {
      return JSON.parse(possibleJSON);
    } catch (err) {
      throw new SafeJSONParseError(
        `Unable to parse JSON string ${err.message}`,
      );
    }
  }
  return possibleJSON;
};

export const transformCsvAdditionalPayload = (data) => {
  const dataTransform = Papa.parse(data, { header: true }).data;
  return dataTransform.map(({ label, answer, additional_payload }, index) => {
    if (!label || !answer) {
      const column = (!label && 'label') || (!answer && 'answer');
      throw new Error(PARAMS_NOT_DEFINED(index + 1, column));
    }
    if (additional_payload) {
      if (validateJson(additional_payload)) {
        throw new Error(VALID_PAYLOAD(index + 1));
      } else {
        return {
          label,
          answer,
          additional_payload,
        };
      }
    }
    return { label, answer };
  });
};
