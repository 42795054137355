import _ from 'lodash';
import { selectResource } from './slate_actions';
import types from '../constants';
import { AnyAction, Dispatch } from 'redux';
import { GetState } from '../redux/actions-types';
import { Tile, Tiles } from '../redux/graph';

export const setTiles = (payload: Tiles) => ({
  type: types.tiles.set,
  payload,
});
export const clearTiles = () => ({ type: types.tiles.clear });
export const deleteTile = (payload: Tile) => ({
  type: types.tiles.delete,
  payload,
});
export const updateTile = (payload: Tile) => ({
  type: types.tiles.update,
  payload,
});

export const allTilesWithType =
  (payload: string) => (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    const { tiles } = getState();
    return _.reduce(
      tiles,
      (acc: any, tile: Tile) =>
        tile.content.type === payload ? [...acc, tile.id] : acc,
      [],
    );
  };

export const selectAllTilesWithType =
  (payload: string) => (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    const { tiles, sequences } = getState();
    const identifiers_by_type = _.reduce(
      tiles,
      (acc: any, tile: Tile) =>
        tile.content.type === payload ? [...acc, tile.id] : acc,
      [],
    );

    _.forEach(identifiers_by_type, (uuid) => {
      const { id: sequence_uuid } = _.find(sequences, (s) =>
        s.tile_ids.includes(uuid),
      );
      dispatch(
        selectResource({ [uuid]: { sequence_uuid, uuid, resource: `tile` } }),
      );
    });
  };
