import types from '../constants';

export const getLanguages = () => ({ type: types.locale.getLanguages });
export const clearLocale = () => ({ type: types.locale.clearLocale });

/**
 * @description Used as an action to set the current locale the user is editting
 * in the redux state.
 *
 * @param {object} languages - object with languages that are available
 * @returns {object} type = name of the action to be used in a reducer
 *                   payload = the updated values to apply in redux
 */
export const setLanguages = (languages: any) => ({
  type: types.locale.setLanguages,
  payload: { languages },
});

/**
 * @description Used as an action to set the current locale the user is editting
 * in the redux state.
 *
 * @param {string} locale - locale the user is currently working in
 * @returns {object} type = name of the action to be used in a reducer
 *                   payload = the updated values to apply in redux
 */
export const setLocale = (currentLocale: string) => ({
  type: types.locale.setLocale,
  payload: { currentLocale },
});
