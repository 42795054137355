"use strict";

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.get-own-property-descriptor.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.get-own-property-descriptors.js");

require("core-js/modules/es.object.define-properties.js");

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ArrayMoveDown = exports.ArrayMoveUp = exports.checkboxOptionNormalization = exports.alwaysGetKeyValue = exports.calculateTabularSpan = exports.util_commonInputParamters = exports.prepareEmptyFields = exports.constructFieldClassname = void 0;

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.string.trim.js");

require("core-js/modules/es.string.replace.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _constants = require("../constants");

var _lodash = _interopRequireDefault(require("lodash"));

var _utils = require("../utils");

var _excluded = ["dispatch", "form", "warning", "initial"],
    _excluded2 = ["error", "touched"],
    _excluded3 = ["id", "className", "items", "answer", "displayLabel", "component", "split", "question_description"],
    _excluded4 = ["meta", "input", "config", "ComponentWrapper", "label", "accepts_data"],
    _excluded5 = ["answer"],
    _excluded6 = ["value"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var never_pass_to_inputs = ["fieldsFromRedux", "valuesFromRedux", "accepts_data", "noneLabel", "optional_for_completion", "displayRow", "displayMessage", "remoteSubmit", "nowDate"]; // constructs a string based on field state key/boolean objects

var constructFieldClassname = function constructFieldClassname(_ref, className) {
  var dispatch = _ref.dispatch,
      form = _ref.form,
      warning = _ref.warning,
      initial = _ref.initial,
      args = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return _lodash["default"].reduce(args, function (acc, state, key) {
    return state ? [].concat((0, _toConsumableArray2["default"])(acc), [key]) : acc;
  }, ["".concat(className)]).join(" ");
};

exports.constructFieldClassname = constructFieldClassname;

var prepareEmptyFields = function prepareEmptyFields(fields) {
  var keydObject = _lodash["default"].isArray(fields) ? _lodash["default"].reduce(fields, function (acc, item) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, item.name, item));
  }, {}) : fields;
  return Object.keys(keydObject).reduce(function (acc, key) {
    var component = keydObject[key].component;
    var value = undefined; // default is undefined

    switch (component.toLowerCase()) {
      case "checkboxinput":
        value = keydObject[key]['checked'] || false;
        break;

      case "array":
      case "flatarray":
        value = [];
        break;

      case "numberinput":
        value = 0;
        break;

      case "textareainput":
      case "textinput":
      case "text":
      default:
        value = "";
        break;
    }

    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, key, value));
  }, {});
};

exports.prepareEmptyFields = prepareEmptyFields;

var util_commonInputParamters = function util_commonInputParamters(_ref2) {
  var _ref2$meta = _ref2.meta,
      _ref2$meta$error = _ref2$meta.error,
      error = _ref2$meta$error === void 0 ? "" : _ref2$meta$error,
      _ref2$meta$touched = _ref2$meta.touched,
      touched = _ref2$meta$touched === void 0 ? false : _ref2$meta$touched,
      meta = (0, _objectWithoutProperties2["default"])(_ref2$meta, _excluded2),
      _ref2$input = _ref2.input,
      input = _ref2$input === void 0 ? {} : _ref2$input,
      _ref2$config = _ref2.config,
      _ref2$config$id = _ref2$config.id,
      elemtntId = _ref2$config$id === void 0 ? "" : _ref2$config$id,
      _ref2$config$classNam = _ref2$config.className,
      className = _ref2$config$classNam === void 0 ? "" : _ref2$config$classNam,
      items = _ref2$config.items,
      answer = _ref2$config.answer,
      _ref2$config$displayL = _ref2$config.displayLabel,
      displayLabel = _ref2$config$displayL === void 0 ? true : _ref2$config$displayL,
      component = _ref2$config.component,
      split = _ref2$config.split,
      question_description = _ref2$config.question_description,
      config = (0, _objectWithoutProperties2["default"])(_ref2$config, _excluded3),
      ComponentWrapper = _ref2.ComponentWrapper,
      label = _ref2.label,
      accepts_data = _ref2.accepts_data,
      rest = (0, _objectWithoutProperties2["default"])(_ref2, _excluded4);

  // prioritizing 'name' field over label for testing path
  // this path matches the field data submission key 'name' prepended with t-
  var id = _lodash["default"].trim(elemtntId !== "" ? elemtntId : "t-".concat(config.name ? config.name.replace(/\s/g, "") : (0, _utils.stripSpecialChars)(label)));

  var output = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, input), rest), _lodash["default"].omit(config, ["value"])), {}, {
    label: displayLabel ? label : "",
    error: touched && error ? error : "",
    className: constructFieldClassname(_objectSpread(_objectSpread({}, meta), {}, {
      error: error,
      touched: touched
    }), className),
    id: id
  });

  return _lodash["default"].omit(output, never_pass_to_inputs);
};

exports.util_commonInputParamters = util_commonInputParamters;

var calculateTabularSpan = function calculateTabularSpan() {
  var inputs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

  if (_lodash["default"].isUndefined(inputs) || _lodash["default"].isEmpty(inputs)) {
    return 1;
  } // method receives a list of fields (that display in the tabular format)
  // calculates how many columns in a table each field should take
  // 1 unless is radioinput, radioinput counts the number of options to
  // calculate how many columns it holds


  return _lodash["default"].reduce(inputs, function (acc, input) {
    var span = 1;
    var component = input.component.toLowerCase();

    switch (component) {
      case "radioinput":
        if (_lodash["default"].isArray(input.options)) {
          span = input.options.length;
        }

        break;

      case "range_1to10":
        span = _constants.range_1to10.length;
        break;

      case "range_0to10":
        span = _constants.range_0to10.length;
        break;

      case "range_0to5":
        span = _constants.range_0to5.length;
        break;

      default:
        break;
    }

    return [].concat((0, _toConsumableArray2["default"])(acc), [span]);
  }, []);
};

exports.calculateTabularSpan = calculateTabularSpan;

var alwaysGetKeyValue = function alwaysGetKeyValue(item) {
  // normalzing data so that we always have an apropriate key/value pair
  // our apis don't always provide consistant data, so this is just normalizing data
  var output = {};

  if (_lodash["default"].isString(item)) {
    // covering the case where this is just an array of items
    output = {
      label: item,
      value: _lodash["default"].snakeCase(item)
    };
  } else if (_lodash["default"].has(item, "answer")) {
    // covering the case where we have an answer key
    var answer = item.answer,
        rest = (0, _objectWithoutProperties2["default"])(item, _excluded5);
    var lastKey = Object.keys(rest)[0];
    output = {
      label: item[lastKey],
      value: answer
    };
  } else if (_lodash["default"].has(item, "value")) {
    // covering the case where we have a value key
    var value = item.value,
        _rest = (0, _objectWithoutProperties2["default"])(item, _excluded6);

    var _lastKey = Object.keys(_rest)[0];
    output = {
      label: item[_lastKey],
      value: value
    };
  } else {
    // convering the case where we have a key/value pair
    var key = Object.keys(item)[0];
    output = {
      label: key,
      value: item[key]
    };
  }

  return output;
};

exports.alwaysGetKeyValue = alwaysGetKeyValue;

var checkboxOptionNormalization = function checkboxOptionNormalization(options) {
  // covering the case where options are not provided, or only a string is provided.
  if (_lodash["default"].isString(options)) {
    return [{
      label: options,
      answer: options
    }];
  } else if (_lodash["default"].isEmpty(options)) {
    // if no label is found, this is for an 'acceptance' checkbox, we just need a value if it's checked
    return [{
      label: "",
      answer: "true"
    }];
  }

  return options;
};

exports.checkboxOptionNormalization = checkboxOptionNormalization;

var ArrayMoveUp = function ArrayMoveUp(e, index, fields) {
  e.preventDefault();
  index - 1 >= 0 && fields.move(index, index - 1);
};

exports.ArrayMoveUp = ArrayMoveUp;

var ArrayMoveDown = function ArrayMoveDown(e, index, fields) {
  e.preventDefault();
  index + 1 < fields.length && fields.move(index, index + 1);
};

exports.ArrayMoveDown = ArrayMoveDown;