import { ReminderInfo, ReminderItem } from '..';

import React from 'react';
import _ from 'lodash';

const FollowUpListItem = follow_up => {
  const { title, onClick, selected } = follow_up;

  return (
    <ReminderItem
      id={`t-follow-up-${_.snakeCase(title)}`}
      onClick={onClick}
      selected={selected}
    >
      <ReminderInfo>{title}</ReminderInfo>
    </ReminderItem>
  );
};

FollowUpListItem.defaultProps = {};

export default FollowUpListItem;
