import { Nodes } from '../../../constants';
import _ from 'lodash';
import removeMd from 'remove-markdown';

// const StripHTML = string => string.replace(`<[^>]*>`, ``);

export default (content) => {
  let output = undefined;
  let i = 1;

  // This will address if there is a description at the content level of the
  // tile payload. It will be expanded as the i18n changes are smoothed out and
  // added.
  if (content?.description) {
    return removeMd(content.description);
  }

  if (!_.has(Nodes, content.type)) {
    // short circuit if our Nodes constant does not contain a mapping
    return undefined;
  }

  const displayFields = Nodes[content.type].tile_description;
  const displayFieldsLength = displayFields.length;

  do {
    // defaulting to content
    let targetContent = content;

    if (_.has(content, `experiences`)) {
      /// checking to see if this tile has experiences
      const firstExperienceKey = _.keys(content.experiences)[0];
      targetContent = content.experiences[firstExperienceKey];
    }

    output = _.get(targetContent, displayFields[i - 1], undefined);
    i++;
  } while (!_.isUndefined(output) && i < displayFieldsLength);

  return _.isString(output) ? removeMd(output) : output;
};
