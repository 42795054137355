import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const PortalContainer = styled.div`
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 50px;
`;

const PortalHolder = styled.div`
  z-index: 999990;
  padding: 20px;
  width: 400px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  position: relative;
`;

export const PortalClose = styled.div`
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  line-height: 1.8em;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #111;
  color: #fff;
`;

export class PortalModal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root');
    if (this.modalRoot) this.modalRoot.appendChild(this.el);
    document.addEventListener('keydown', this.handleKeydown, false);
  }

  componentWillUnmount() {
    if (this.modalRoot) this.modalRoot.removeChild(this.el);
    document.removeEventListener('keydown', this.handleKeydown, false);
  }

  handleKeydown = event => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  render() {
    const { onClose = false } = this.props;

    // TODO - SVG icon instead of 'X'
    const PortalContent = (
      <PortalContainer>
        <PortalHolder>
          <PortalClose
            className="portal-close"
            onClick={onClose ? onClose : this.onClose}
          >
            X
          </PortalClose>
          {this.props.children}
        </PortalHolder>
      </PortalContainer>
    );

    return ReactDOM.createPortal(PortalContent, this.el);
  }
}

PortalModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
};

export default PortalModal;
