import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M17.708 14.844l-1.437-2.088a1.674 1.674 0 0 0-2.217-.429l-1.412.958c-.125.02-.748-.284-2.028-1.903a6.401 6.401 0 0 1-.985-1.663c-.182-.512-.117-.702-.1-.718l.031-.028.873-.93h-.001c.587-.639.619-1.627.075-2.305L8.856 3.532a1.446 1.446 0 0 0-1.06-.53 1.446 1.446 0 0 0-1.098.444L4.404 5.945C3.137 7 5.04 10.869 7.472 13.98c2.07 2.644 4.74 5.02 6.293 5.02.244.007.483-.069.68-.216l2.883-1.775h.001a1.51 1.51 0 0 0 .648-.998 1.542 1.542 0 0 0-.269-1.167zm-.342 1.05a.864.864 0 0 1-.379.574l-2.876 1.779-.048.036c-.613.512-3.242-1.025-6.106-4.702C5.095 9.903 4.182 6.95 4.81 6.432l.033-.03 2.302-2.507a.835.835 0 0 1 .596-.256h.037a.83.83 0 0 1 .596.295l1.652 2.214v-.001c.337.426.32 1.042-.04 1.448l-.863.917c-.21.184-.382.582-.077 1.424v.001c.264.67.627 1.293 1.077 1.85 2.05 2.618 2.667 2.193 2.874 2.032l1.391-.946a1.06 1.06 0 0 1 1.38.256l1.437 2.088v.001a.889.889 0 0 1 .162.677z"
          fillRule="nonzero"
        />
        <path d="M5.517 6.115l-.4.556-.03.039a.415.415 0 0 0-.075.193c-.067.5.163 1.373.306 1.846a.344.344 0 0 0 .147.216.22.22 0 0 0 .222.01.337.337 0 0 0 .156-.205.471.471 0 0 0-.006-.29 5.53 5.53 0 0 1-.28-1.355l.367-.507a.452.452 0 0 0-.011-.518c-.113-.14-.29-.132-.396.015z" />
      </g>
    </svg>
  );
}
