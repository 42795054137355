export default () => {
  return {
    content: {
      title: 'Experian Validator',
      description:
        'In this step we will verify your entries and may send you up to two text messages (this may incur additional charges from your phone service provider).',
      action_title: 'Continue',
      success_message: 'Success!',
      fail_message: 'Sorry, your identity could not be verified.',
      prompt_message: 'We sent a passcode to your phone. Please enter it now.',
      incorrect_message:
        'The passcode you entered is invalid. Please try again.',
      expired_message:
        'The passcode you entered is expired. A new code has been sent. Please enter it now.',
      skip_fake: false,
      max_retries: 2,
      success_page_title: 'Congratulations!',
      success_page_confirm_text:
        'We have successfully verified your entries. Press the button below to continue the study.',
      success_page_button_text: 'Return to Dashboard',
      failure_page_title:
        'Unfortunately, we are not able to verify the information you entered',
      failure_page_confirm_text:
        'Please reach out to our support team with additional questions.',
      failure_page_button_text: 'Return to Dashboard',
      intro_page_title: 'Identity Verification',
      intro_page_confirm_text:
        'In this step we will verify some of the information you previously provided. This is required to continue enrolling. We may send you text message(s) during this step.',
      intro_page_button_text: 'Return to Dashboard',
    },
  };
};
