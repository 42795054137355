import {
  ResourceApplicationDisplayProperties,
  matrix_days_range_values,
  matrix_days_range_values_na,
  matrix_range_0to10,
  matrix_range_0to10_na,
  matrix_range_0to5,
  matrix_range_0to5_na,
  single_experience_tile_types,
} from '../../../constants';
import {
  DEFAULT_INTERFACE_VERSION,
  no_data_field,
  parseAsArray,
  parseAsInt,
  safeJSONParse,
} from './utils';
import {
  arrayTranslationFields,
  translationFieldConstants,
} from '../utils/translationFieldConstants';
import EmptyIsFalse from '../../../utils/empty_is_false';
import _ from 'lodash';
import clone from '../../../utils/objectClone';
import uniqid from 'uniqid';

import { displayGroupNameDefaultOption } from './common';

const check_accepts_data = (intf) =>
  !no_data_field.includes(_.isString(intf) ? intf.toLowerCase() : ``);

export const isEmpty = (v) => {
  if (!_.isUndefined(v)) {
    if (_.isBoolean(v)) {
      return false;
    } else if (_.isInteger(v)) {
      return false;
    } else if (_.isString(v)) {
      return _.isEmpty(v.trim());
    } else if (!_.isEmpty(v)) {
      return false;
    }
  }

  return true;
};

const transformToMatrix = (matrixObject, matrixName, possible_answers) => ({
  matrix: matrixName,
  interface: `tabular`,
  inputs: _.reduce(
    matrixObject,
    (acc, { label, name }) => {
      return [
        ...acc,
        [
          {
            label,
            name: `${name}_label`,
            // interface label is required for tabular formats only
            interface: `label`,
            accepts_data: false,
          },
          { name, interface: 'radio', accepts_data: true, possible_answers },
        ],
      ];
    },
    [],
  ),
});

const transform_outgoing_form_options = (config) => {
  return _.reduce(
    config,
    (acc, option) => {
      let value = option.value;

      if (parseAsInt.includes(option.key)) {
        value = parseInt(option.value, 10);
      } else if (parseAsArray.includes(option.key)) {
        value = option.value.split(',');
      }

      return { ...acc, [option.key]: value };
    },
    {},
  );
};

export const transform_outgoing_disqualification_criteria = (dqc) =>
  _.map(dqc, (row) =>
    Object.keys(row).reduce((acc, key) => {
      const value = row[key];
      return isEmpty(value) ? acc : { ...acc, [key]: value };
    }, {}),
  );

const LockedDatatypes = {
  Garmin: ['steps', 'sleep', 'heartrate', 'pulseox', 'stress'],
};

export const transform_outgoing_available_apps = (apps) =>
  _.map(apps, ({ achievement_slug, required_data_types, ...rest }) => {
    return {
      achievement_slug,
      required_data_types:
        LockedDatatypes[achievement_slug] || required_data_types,
      ...rest,
    };
  });

const notAvailableOrEmpty = (v) => v && !_.isEmpty(v);

export const prepareReauthPages = (pages) => {
  if (!_.isArray(pages)) {
    return _.isString(pages) && !_.isEmpty(pages) ? [pages] : [];
  }

  return pages;
};

const appendIndex = (arr = []) =>
  arr.map((item, index) => ({
    ...item,
    index,
  }));

export const PrepareFormField = (
  {
    config = undefined,
    disqualification_criteria = undefined,
    has_dq_criteria = false,
    possible_answers = undefined,
    none_additional_payload = undefined,
    matrix_0to10 = undefined,
    matrix_0to5 = undefined,
    matrix_0to10_na = undefined,
    matrix_0to5_na = undefined,
    matrix_days = undefined,
    matrix_days_na = undefined,
    none_label = undefined,
    id,
    component_id,
    ...rest
  },
  type,
) => {
  let output = {
    ...rest,
    // Written with old 'id' to filter any set ids into the component_id,
    // can be deprecated when no longer needed.
    component_id: component_id || id || uniqid.time(),
    accepts_data: check_accepts_data(rest.interface),
    interface_version: DEFAULT_INTERFACE_VERSION,
  };

  if (
    has_dq_criteria &&
    disqualification_criteria &&
    !_.isEmpty(disqualification_criteria)
  ) {
    output[`disqualification_criteria`] =
      transform_outgoing_disqualification_criteria(disqualification_criteria);
  }

  if (none_label) {
    output['noneLabel'] = none_label;
  }

  if (_.has(output, `required`)) {
    output['required'] = EmptyIsFalse(output.required);
  }

  if (output.interface === `tabular`) {
    output['inputs'] = _.reduce(
      output.inputs,
      (acc, { col }) => {
        return [
          ...acc,
          _.reduce(
            col,
            (c_acc, f, i) => {
              if (i === 0 && f.interface === `paragraph`) {
                // interface label is required for tabular formats only
                return [
                  ...c_acc,
                  { ...PrepareFormField(f, type), interface: `label` },
                ];
              }
              return [...c_acc, PrepareFormField(f, type)];
            },
            [],
          ),
        ];
      },
      [],
    );
  }

  if (output.interface === 'simple_matrix') {
    output.questions = appendIndex(output.questions).map((question) => {
      if (question.question_additional_payload) {
        const question_additional_payload =
          question.question_additional_payload.map((payload) => ({
            ...payload,
            additional_payload: safeJSONParse(payload.additional_payload),
          }));
        return {
          ...question,
          question_additional_payload,
        };
      }
      return question;
    });
    output.answer_labels = appendIndex(output.answer_labels);
  }

  if (_.has(output, `optional_for_completion`)) {
    output['optional_for_completion'] = EmptyIsFalse(
      output.optional_for_completion,
    );
  }

  if (notAvailableOrEmpty(possible_answers)) {
    output[`possible_answers`] = _.reduce(
      possible_answers,
      (acc, { additional_payload, ...answer }) => {
        if (additional_payload) {
          answer['additional_payload'] = safeJSONParse(additional_payload);
        }
        return [...acc, answer];
      },
      [],
    );
  }

  if (none_additional_payload) {
    output['none_additional_payload'] = safeJSONParse(none_additional_payload);
  }

  if (config) {
    output['config'] = transform_outgoing_form_options(config);
  }

  if (notAvailableOrEmpty(matrix_0to10)) {
    output = {
      ...output,
      ...transformToMatrix(matrix_0to10, `matrix_0to10`, matrix_range_0to10),
    };
  }

  if (notAvailableOrEmpty(matrix_0to5)) {
    output = {
      ...output,
      ...transformToMatrix(matrix_0to5, `matrix_0to5`, matrix_range_0to5),
    };
  }

  if (notAvailableOrEmpty(matrix_0to10_na)) {
    output = {
      ...output,
      ...transformToMatrix(
        matrix_0to10_na,
        `matrix_0to10_na`,
        matrix_range_0to10_na,
      ),
    };
  }

  if (notAvailableOrEmpty(matrix_0to5_na)) {
    output = {
      ...output,
      ...transformToMatrix(
        matrix_0to5_na,
        `matrix_0to5_na`,
        matrix_range_0to5_na,
      ),
    };
  }

  if (notAvailableOrEmpty(matrix_days)) {
    output = {
      ...output,
      ...transformToMatrix(
        matrix_days,
        `matrix_days`,
        matrix_days_range_values,
      ),
    };
  }

  if (notAvailableOrEmpty(matrix_days_na)) {
    output = {
      ...output,
      ...transformToMatrix(
        matrix_days_na,
        `matrix_days_na`,
        matrix_days_range_values_na,
      ),
    };
  }

  return output;
};

/**
 * @description This allows the translated fields to be modified back to the expected key
 * value pairs.
 *
 * @param {String} currentLocale
 * @param {Object} content - contains tile fields
 * @returns {Object}
 */
export const modifyTranslatedFields = (
  currentLocale,
  content,
  translationConstants = translationFieldConstants,
) => {
  const fieldKeyMap = translationConstants.reduce(
    (acc, fieldName) => ({
      ...acc,
      [`${fieldName}_${currentLocale}`]: fieldName,
    }),
    {},
  );
  const translationFields = Object.keys(fieldKeyMap);
  const contentKeys = Object.keys(content);
  const localeRegex = new RegExp(
    `(_${_.snakeCase(currentLocale.toLowerCase())})$`,
  );
  const modifiedFields = contentKeys.reduce((acc, contentKey) => {
    if (translationFields.includes(contentKey)) {
      return { ...acc, [fieldKeyMap[contentKey]]: content[contentKey] };
    } else if (translationFieldConstants.includes(contentKey)) {
      // Omit primary locale fields
      return acc;
    } else if (arrayTranslationFields.hasOwnProperty(contentKey)) {
      const filteredValues = content[contentKey].reduce((acc, val) => {
        const modifiedKeyValues = val;
        for (const [key, value] of Object.entries(val)) {
          if (localeRegex.test(key)) {
            modifiedKeyValues[key.replace(localeRegex, '')] = value;
            delete modifiedKeyValues[key];
          }
        }
        return [...acc, modifiedKeyValues];
      }, []);
      return { ...acc, [contentKey]: filteredValues };
    }
    // Returning non-translation content for now, this is because submission
    // of data to Triage currently expects certain fields as required even with
    // a PUT call.
    return { ...acc, [contentKey]: content[contentKey] };
  }, {});

  return modifiedFields;
};

/**
 * @description Filter out the fields that have translation values and pass them along
 * with the translated values, this works in conjuction with the current locale be passed
 * along with the PUT updat to Triage
 *
 * @param {Object} - This should containt the 'content', 'primary_locale', and 'locale' keys
 * @returns {Object}
 */
export const filterTranslationFields = ({
  locale: currentLocale,
  primary_locale: primaryLocale,
  ...content
}) => {
  if (currentLocale && primaryLocale && primaryLocale !== currentLocale) {
    const filteredFields = modifyTranslatedFields(currentLocale, content);

    if (content.experiences) {
      const experiencesList = Object.keys(content.experiences);
      const modifedExperiences = experiencesList.reduce(
        (acc, experience) => ({
          ...acc,
          [experience]: modifyTranslatedFields(
            currentLocale,
            content.experiences[experience],
          ),
        }),
        {},
      );
      return {
        ...filteredFields,
        experiences: modifedExperiences,
      };
    }

    return filteredFields;
  }

  // Removes values from the content part of payload, these values cause problems with publish
  // as they are not whitelisted. This information is also not needed here at the API level
  // because it is available in the graph (a higher level in the JSON blob) already.
  return content;
};

const process = (props) => {
  const tileType = _.get(props, 'content.type');
  if (single_experience_tile_types.includes(tileType)) {
    delete props.content.experiences;
  }

  if (
    _.has(props.content, `experiences`) &&
    !_.isEmpty(props.content.experiences)
  ) {
    props.content.experiences = _.reduce(
      props.content.experiences,
      (acc, experience_content, experience_key) => {
        let output = _.reduce(
          experience_content.form,
          (acc, item) => {
            if (!_.has(item, `page`) && Array.isArray(item)) {
              // Checking for array for paged tiles. This is necessary because
              // paged tiles expect the content as an array of arrays like:
              // [[{}, {}], [{}, {}], [{}, {}]]
              // The first array is the form, the nested arrays are the pages, and
              // the objects are the form components. This is used when adding
              // a component to a paged tile.
              // Added because of changes made in SPP-343.
              const pagedForm = item.map((formComponent) =>
                PrepareFormField(formComponent, tileType),
              );
              return [...acc, pagedForm];
            } else if (!_.has(item, `page`)) {
              // Form fields are prepared for non-paged tiles (Ex: Screener)
              return [...acc, PrepareFormField(item, tileType)];
            } else {
              // Adding a page to a form, this will also work to add form
              // components if they are added at the same time as the page
              // (EX: ICF and Survey tiles).
              return [
                ...acc,
                _.reduce(
                  item.page,
                  (acc_page, field) => {
                    return [...acc_page, PrepareFormField(field, tileType)];
                  },
                  [],
                ),
              ];
            }
          },
          [],
        );

        return {
          ...acc,
          [experience_key]: {
            ...experience_content,
            ...(!_.isEmpty(output) ? { form: output } : {}),
          },
        };
      },
      {},
    );
  }

  if (
    _.has(props.content, 'disqualification_criteria') &&
    !_.isEmpty(props.content.disqualification_criteria)
  ) {
    props.content.disqualification_criteria =
      transform_outgoing_disqualification_criteria(
        props.content.disqualification_criteria,
      );
  }

  if (!_.isEmpty(_.get(props, 'content.available_apps'))) {
    props.content.available_apps = transform_outgoing_available_apps(
      props.content.available_apps,
    );
  }

  if (_.has(props.content, `fire_gtm`)) {
    props.content.fire_gtm = EmptyIsFalse(props.content.fire_gtm);
  }

  // Converts collection to and array of strings which is expected for the
  // visibility__depends_on payload on the API side.
  if (props?.content?.visibility__depends_on) {
    props.content.visibility__depends_on =
      props.content.visibility__depends_on.map((value) => value.visibility_key);
  }

  // If present `reconfirm_in_days` let's send it as a number
  if (props?.content?.experiences?.default?.reconfirm_in_days) {
    props.content.experiences.default.reconfirm_in_days = Number(
      props.content.experiences.default.reconfirm_in_days,
    );
  }

  // If `reconfirm_in_days` is not a number, let's send it as `null` if `reconfirm_in_days` is present in the object.
  if (
    props?.content?.experiences?.default &&
    typeof props.content.experiences.default.reconfirm_in_days !== 'number'
  ) {
    props.content.experiences.default.reconfirm_in_days = null;
  }

  // If `display_group_id` is present in the content object, and has the value of `no_display_group`, let's send it as `null`
  if (
    props?.content?.display_group_id === displayGroupNameDefaultOption.value
  ) {
    props.content.display_group_id = null;
  }

  if (props.content) {
    props.content = filterTranslationFields(props.content);
  }

  // this omit is meant to remove any key/value pairs that we use for
  // application state. These are display properties only.
  return _.omit(props, ResourceApplicationDisplayProperties);
};

export default (p) => process(clone(p));
