import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const SMSMessageTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.display_group_name(displayGroupNames),
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        availability: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const {
    sms_message: { masonryEnabled = false },
  } = dataForTile;

  const currentExperienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: currentExperienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled), 
        C.sms_messages({ masonryEnabled })
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(SMSMessageTileSettings(experience, displayGroupNames));
  }

  return schema;
};
