"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateDecimal = exports.validateMinMax = void 0;

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.repeat.js");

var _lodash = _interopRequireDefault(require("lodash"));

var _i18n = require("../../i18n");

var validateMinMax = function validateMinMax(min, max, decimal) {
  return function (value, allValues, props, name) {
    if (value && validInputs(min) && validInputs(max) && validInputs(decimal)) {
      var val = _lodash["default"].toNumber(value);

      if (min <= val && max >= val) {
        return undefined;
      } else {
        return (0, _i18n.globalTranslate)('validations.number.minMax', {
          min: min.toFixed(decimal),
          max: max.toFixed(decimal)
        });
      }
    }
  };
};

exports.validateMinMax = validateMinMax;

var validateDecimal = function validateDecimal(decimal) {
  return function (value, allValues, props, name) {
    if (value && validInputs(decimal)) {
      var pattern = decimal > 0 ? "^-?\\d+\\.\\d{".concat(decimal, "}$") : "^-?\\d+$";
      var regex = new RegExp(pattern, 'g');

      if (regex.test(value)) {
        return undefined;
      } else {
        return (0, _i18n.globalTranslate)('validations.number.decimal', {
          decimal: decimal,
          zeros: decimal > 0 ? '.' + 'X'.repeat(decimal) : ''
        });
      }
    }
  };
};

exports.validateDecimal = validateDecimal;

var validInputs = function validInputs(v) {
  return !_lodash["default"].isNaN(v) && _lodash["default"].isNumber(v);
};

var validations = function validations(_ref) {
  var minimum = _ref.minimum,
      maximum = _ref.maximum,
      decimal = _ref.decimal;
  return [validateMinMax(minimum, maximum, decimal), validateDecimal(decimal)];
};

exports.validations = validations;