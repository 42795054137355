"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enUS = void 0;
var enUS = {
  common: {
    errorMessage: 'An error has occurred',
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December'
    },
    today: 'Today',
    weekDayLong: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday'
    },
    weekDayShort: {
      Sunday: 'Su',
      Monday: 'Mo',
      Tuesday: 'Tu',
      Wednesday: 'We',
      Thursday: 'Th',
      Friday: 'Fr',
      Saturday: 'Sa'
    },
    dateFormats: {
      year: 'YYYY',
      yearMonth: 'YYYY-MM',
      monthDayYear: 'MM-DD-YYYY',
      yearMonthDay: 'YYYY-MM-DD'
    },
    languages: {
      English: 'English',
      French: 'French',
      German: 'German',
      Italian: 'Italian',
      Spanish: 'Spanish'
    },
    clear: 'Clear',
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    preferNotAnswer: 'I prefer not to answer',
    male: 'Male',
    female: 'Female'
  },
  components: {
    deleteButton: {
      confirmation: 'Are you sure you want to delete this?'
    },
    uploader: {
      removeHeading: 'Remove this Image?',
      removeImage: 'Remove Image',
      cancel: 'Cancel',
      numberOfFilesError: 'Files not uploaded due to number of files. Please upload up to {{MaxFiles}} photos and try again.',
      uploadRules: 'Files must be under {{formattedBytes}} and in {{extensions}} format. PDF files will not be accepted.'
    },
    checkboxGroup: {
      noneAbove: 'None of the above'
    },
    connectedFile: {
      imageError: 'There was a problem with your image, please try again.',
      enterUrl: 'Please enter url to image',
      processing: 'Processing',
      bytes: 'bytes',
      clickSelect: 'Click to select file or drop a file here. Accepted file formats:',
      clickSelectUrl: 'Click to add image URL',
      deleteConfirm: 'Are you sure you want to delete this?',
      preview: 'Preview',
      clear: 'Clear'
    },
    datePicker: {
      month: 'Month',
      day: 'Day',
      year: 'Year'
    },
    fieldHeight: {
      feet: 'Ft',
      inch: 'In'
    },
    insuranceProviderId: {
      idImage: 'Humana Id Card',
      medicareId: 'Medicare Advantage Identification Number',
      reenter: 'Please re-enter',
      notMember: 'I am not currently a Humana Medicare Advantage member.'
    },
    password: {
      password: 'Create Password',
      confirm: 'Confirm',
      validating: 'Validating',
      ok: 'Ok'
    },
    progressBar: {
      estimation: 'On average, it takes about {{time}} to complete.',
      minutes_one: '{{count}} minute',
      minutes_other: '{{count}} minutes',
      percentage: '<span id="t-progressValue">{{progress}}%</span> of the sign-up process has been completed.'
    },
    rangeSlider: {
      maxMinEntryError: 'please ensure Maximum length of entry is greater than Minimum length of entry'
    },
    error: {
      errorOccurred: 'An error occurred',
      somethingWrong: '<p>Hi there, it looks like something went wrong on our end. Please refresh your page to try again.</p><p>If the problem persists, please contact support.</p>',
      defaultError: 'Something went wrong. If the problem persists, please contact us.',
      contributionDoesNotExist: 'This contribution does not exist',
      contributionDoesNotExistMessage: 'It looks like the contribution you selected is no longer available.'
    }
  },
  fields: {
    age: {
      standardLabel: 'What is your current age (in years)?'
    },
    dob: {
      yearOfBirth: 'What is your year of birth?',
      monthYearBirth: 'What is your month and year of birth?',
      dateOfBirth: 'What is your date of birth?'
    },
    emailConfirm: {
      enterEmail: 'Please enter an email address',
      reEnterEmail: 'Please re-enter that same email address'
    },
    english: {
      label: 'Are you able to read, speak, and understand English?'
    },
    ethnicity: {
      label: 'What is your ethnicity?',
      hispOrLat: 'Hispanic or Latino',
      notHispOrLat: 'Not Hispanic or Latino'
    },
    gender: {
      label: 'What is your gender?'
    },
    heightInternational: {
      enterHeight: 'Enter height',
      enterHeightCentimeters: 'Enter height in centimeters',
      imperial: 'Imperial',
      metric: 'Metric'
    },
    weightInternational: {
      imperialWeight: 'Enter weight in pounds',
      stoneWeight: 'Enter weight in stones',
      metricWeight: 'Enter weight in kilograms',
      pounds: 'Pounds',
      stones: 'Stones',
      kilograms: 'Kilograms'
    },
    languageIntl: {
      label: 'Are you able to read, speak, and understand {{language}}?'
    },
    fileUpload: {
      uploadingFiles: 'Uploading files',
      numberFiles_one: '{{count}} file',
      numberFiles_other: '{{count}} files',
      formatFileNumError: 'Number of files selected exceeds the maximum allowed. The first {{number}} of the selected files have been added.',
      fileSizeError: 'Please confirm that each file is less than {{size}} and in JPEG or PNG format.',
      selectDropFile: 'Click to select file or drop up to {{maxFileCount}}  here.',
      formatAccepted: 'Accepted file formats: {{formatAccept}}',
      uploadAFile: 'Please upload at least one file.'
    },
    sex: {
      label: 'What is your sex?'
    },
    countryResident: {
      questionLabel: 'Do you currently live in {{country}}?',
      US: 'the US',
      UK: 'the UK',
      Germany: 'Germany',
      France: 'France',
      Canada: 'Canada',
      Italy: 'Italy',
      Spain: 'Spain'
    },
    usResident: {
      questionLabel: 'Do you currently live in the United States?'
    },
    pregnancy: {
      questionLabel: 'Are you currently pregnant?'
    }
  },
  validations: {
    required: 'This field is required',
    greaterThanZero: 'Enter value greater than 0.',
    invalidEmail: 'Your email address does not appear to be valid',
    alphaNumericError: 'This field can only contain letters and numbers.',
    lengthError: {
      max: 'This field must contain no more than {{max}} characters (current length {{length}})',
      min: 'This field must contain at least {{min}} characters (current length {{length}})'
    },
    textOnly: 'This field can only contain letters',
    phoneError: 'This field must be in the format of 12345678999 or 1 (234) 567-8999',
    dateValidation: 'Please select a day, month and year.',
    timeValidation: 'Please use the format 11:11 AM',
    zipValidation: 'US zipcode must contain 5 digits.',
    invalidJson: 'File has invalid JSON format',
    invalidFileType: '{{fileName}} is an invalid file type. The accepted file format is: {{acceptedTypes}}',
    invalidFileTypes: '{{fileName}} is an invalid file type. The accepted file formats are: {{acceptedTypes}}',
    noEmptyArray: 'Files not uploaded due to number of files. Please upload up to {{maxFiles}} photos and try again.',
    maxFilesUpload: 'You have selected too many files.',
    maxFileSize: 'Files {{fileList}} exceeds the allowed file size.',
    humanaLengthValidation: 'Your Humana ID must be an H plus {{length}} numbers.',
    mustCheck: 'This must be checked to proceed.',
    bpValid: 'Please enter a valid blood pressure measurement',
    pleaseConfirmEmail: 'Please confirm you typed your email address correctly',
    datetime: {
      selectDateTime: 'Please select a date and time',
      validDate: 'Please enter a valid date',
      validTime: 'Please enter a valid time',
      validDateRange: 'Please enter a date in the range  {{startDate}} - {{endDate}}',
      validAfterDate: 'Please enter a date after {{validDate}}',
      validBeforeDate: 'Please enter a date before {{validDate}}',
      validTimeRange: 'Please enter a time in the range {{timeRange}}'
    },
    dob: {
      invalidDate: 'Invalid Date',
      invalidDateFormat: 'Invalid date format, expects {{format}}',
      cannotBeFuture: 'Date cannot be in the future.'
    },
    number: {
      minMax: 'Please respond with a number between {{min}} - {{max}}.',
      decimal: 'Please respond with a number containing {{decimal}} decimal places (Ex.: X{{zeros}})'
    },
    heightInternational: {
      metricErroMsg: 'Enter value 91 to 243 centimeters.',
      imperialErrorMsg: 'Please enter both feet and inches'
    },
    weightInternational: {},
    password: {
      casing: 'At least 1 uppercase and lowercase character',
      symbolAndNumber: 'At least 1 symbol and number',
      length: 'At least 8 characters in length'
    }
  }
};
exports.enUS = enUS;