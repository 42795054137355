import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="nonzero">
        <path d="M20.59 6.168c-3.96.588-6.83.839-9.595.839-2.767 0-5.633-.252-9.587-.84l-.391-.058v5.078c0 .805.423 1.512 1.132 1.892 1.16.622 2.891 1.447 4.525 1.447 1.276 0 2.152-.397 2.855-.716.523-.237.973-.441 1.47-.441.447 0 .904.201 1.434.434.734.323 1.646.724 2.891.724 1.634 0 3.365-.825 4.524-1.447a2.116 2.116 0 0 0 1.133-1.892V6.11l-.39.059zm-.29 5.02c0 .556-.281 1.027-.773 1.29-1.095.587-2.722 1.366-4.202 1.366-1.1 0-1.906-.354-2.617-.666-.574-.253-1.118-.492-1.709-.492-.644 0-1.182.244-1.752.502-.677.307-1.444.656-2.573.656-1.48 0-3.107-.78-4.202-1.366a1.426 1.426 0 0 1-.773-1.29V6.898c3.789.552 6.587.79 9.296.79 2.707 0 5.508-.238 9.304-.79l.001 4.288z" />
        <path d="M6.676 8.702c-1.268-.065-2.411.43-2.878 1.234l-.087.149.07.158c.376.854 1.47 1.474 2.724 1.543.08.004.158.006.236.006 1.169 0 2.206-.487 2.641-1.241l.087-.15-.07-.157C9.024 9.39 7.93 8.77 6.676 8.702zm-.133 2.404c-.9-.05-1.71-.442-2.051-.978.396-.492 1.237-.797 2.146-.746.901.05 1.711.443 2.052.977-.396.493-1.241.797-2.147.747zM15.023 11.79l.073-.002c1.256-.02 2.373-.598 2.782-1.436l.075-.155-.08-.153C17.44 9.227 16.358 8.7 15.116 8.7h-.071c-1.256.022-2.373.6-2.78 1.438l-.076.155.08.153c.43.815 1.51 1.343 2.753 1.343zm.033-2.408l.062-.001c.89 0 1.686.326 2.056.828-.362.522-1.186.883-2.088.898h-.062c-.89 0-1.686-.326-2.055-.828.36-.521 1.184-.883 2.087-.897z" />
      </g>
    </svg>
  );
}
