import { logError } from "@evidation/logger";
import { useCallback, useState } from "react";
import { toastr } from "react-redux-toastr";

const LOAD_GRAPH_403_ERROR_MSG =
  "This content is not available, or is only visible to an audience you're not in.";
const LOAD_GRAPH_GENERIC_ERROR_MSG =
  'Something went wrong loading the graph or version information. Please refresh the page to try again.';

export default function useLoadGraph ({ loadGraph, graph_id, history, authorization }) {
  const [loading, setLoading] = useState(true);
  const load = useCallback(
    async (id) => {
      try {
        await loadGraph(id);
      } catch (err) {
        logError(err);
        if (err.response && err.response.status === 403) {
          if (!(graph_id in Object.keys(authorization))) {
            toastr.error(LOAD_GRAPH_403_ERROR_MSG);
            history.push('/');
          }
        } else {
          toastr.error(LOAD_GRAPH_GENERIC_ERROR_MSG);
        }
      } finally {
        setLoading(false)
      }
    },
    [loadGraph, graph_id, history, authorization],
  );
  return {
    load,
    loading
  }
}