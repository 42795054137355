export default {
  description: (
    <>
      <p>
        This status indicates that the study has fulfilled the enrollment
        targets and no additional participants should be enrolled. This status
        closes the landing page, prevents any participants in the pipeline from
        completing screener questions, registering, enrolling and transitions
        participants’ current status based on the following:
      </p>
      <ul>
        <li>From Screening status {'->'} Screen Failed</li>
        <ul>
          <li>Reason for Screen Failure = TBD</li>
        </ul>
        <li>From Re-Screening status {'->'} Screen Failed</li>
        <ul>
          <li>Reason for Screen Failure = TBD</li>
        </ul>
        <li>From Consented status {'->'} Early Termination</li>
        <ul>
          <li>Reason for Early Termination = TBD</li>
        </ul>
        <li>Other statuses are not altered</li>
      </ul>
      <p>
        This status also removes the PII/data for any participants in status of
        Screening, Re-Screening, Screen Failure. Any production data created
        flows to the Production folder in Data Platform.
      </p>
    </>
  ),
  arrow: (
    <ul>
      <li>
        Schedules will be cancelled and participants will not receive scheduled
        events
      </li>
      <li>
        No new contributions will be accepted via externally available
        endpoints. (process_contributions and external_contributions )
      </li>
      <li>Ordinal events will not be generated</li>
    </ul>
  ),
  rightArrow: (
    <ul>
      <li>No caps are reset.</li>
      <li>Landing page opens up for participants to create accounts.</li>
    </ul>
  ),
};
