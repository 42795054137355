import { object, string, func } from 'prop-types';
import { Panel, Select } from './components';
import React from 'react';
import _ from 'lodash';

export const inputValue = `#INPUT_VALUE#`;
export const inputUnitValue = `#INPUT_UNIT_VALUE#`;
export const inputMax = `#INPUT_MAX#`;
export const inputCurrency = `#CURRENCY#`;
export const inputBucketBetween = `#BUCKET_BETWEEN#`;
export const inputBucketMinThreshold = `#BUCKET_LOW#`;
export const inputBucketMaxThreshold = `#BUCKET_HIGH#`;
export const inputBucketAmount = `#BUCKET_AMOUNT#`;

export const more_than_value = `more_than`;
export const between_value = `between`;
export const amount = `amount`;
export const min_threshold = `min_threshold`;
export const max_threshold = `max_threshold`;

export const CurrencyDisplay = (currency) => {
  let output = null;
  if (_.isEqual(currency, `usd`)) {
    output = <Panel.Copy data-test="usd">$(usd)</Panel.Copy>;
  } else if (_.isEqual(currency, `points`)) {
    output = <Panel.Copy data-test="points">points</Panel.Copy>;
  } else {
    output = (
      <Panel.Copy style={{ color: `red` }} data-test="no_currency">
        currency not selected
      </Panel.Copy>
    );
  }
  return output;
};

class LanguageParser extends React.PureComponent {
  debouncedChange = _.debounce(
    (target, path, payload = undefined) =>
      this.props.modifyReward(target, path, payload),
    800,
    {
      leading: false,
      trailing: true,
    },
  );

  render() {
    const {
      language,
      props,
      targetIndex = undefined,
      modifyReward,
    } = this.props;

    return language.split(` `).map((word) => {
      let output = null;

      switch (word) {
        case inputBucketBetween:
          output = (
            <Select
              data-test={_.snakeCase(inputBucketBetween)}
              value={props.option}
              onChange={(e) =>
                modifyReward(
                  `MODIFY_BUCKET`,
                  `${targetIndex}.option`,
                  e.target.value,
                )
              }
            >
              <option value={between_value}>Between</option>
              <option value={more_than_value}>More Than</option>
            </Select>
          );
          break;

        case inputBucketMinThreshold:
          output = (
            <input
              data-test={_.snakeCase(inputBucketMinThreshold)}
              defaultValue={props.min_threshold || 0}
              onChange={(e) =>
                this.debouncedChange(
                  `MODIFY_BUCKET`,
                  `${targetIndex}.${min_threshold}`,
                  parseInt(e.target.value, 10),
                )
              }
              min="0"
              max={props.max_threshold}
              type="number"
            />
          );
          break;

        case inputBucketMaxThreshold:
          // only displaying field if 'between' is selected
          output = _.isEqual(props.option, between_value) && (
            <React.Fragment>
              <Panel.Copy data-test="hyphen">- </Panel.Copy>
              <input
                data-test={_.snakeCase(inputBucketMaxThreshold)}
                defaultValue={props.max_threshold || 0}
                onChange={(e) =>
                  this.debouncedChange(
                    `MODIFY_BUCKET`,
                    `${targetIndex}.${max_threshold}`,
                    parseInt(e.target.value, 10),
                  )
                }
                min={props.min_threshold}
                type="number"
              />
            </React.Fragment>
          );
          break;

        case inputBucketAmount:
          output = (
            <input
              data-test={_.snakeCase(inputBucketAmount)}
              defaultValue={props.amount || 0}
              onChange={(e) =>
                this.debouncedChange(
                  `MODIFY_BUCKET`,
                  `${targetIndex}.${amount}`,
                  parseInt(e.target.value, 10),
                )
              }
              min="0"
              type="number"
            />
          );
          break;

        case inputValue:
          output = (
            <input
              data-test={_.snakeCase(inputValue)}
              value={props.unitAmountEarned}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);

                modifyReward('MODIFY_AMOUNT_EARNED', '', value);
              }}
              min="0"
              type="number"
            />
          );
          break;

        case inputUnitValue:
          output = (
            <input
              data-test={_.snakeCase(inputUnitValue)}
              defaultValue={props.unit_amount}
              onChange={(e) =>
                this.debouncedChange(
                  `MODIFY_UNIT_AMOUNT_EARNED`,
                  ``,
                  parseInt(e.target.value, 10),
                )
              }
              min="0"
              type="number"
            />
          );
          break;

        case inputMax:
          output = (
            <input
              data-test={_.snakeCase(inputMax)}
              defaultValue={props.max_amount}
              onChange={(e) =>
                this.debouncedChange(
                  `MODIFY_MAX_AMOUNT`,
                  undefined,
                  parseInt(e.target.value, 10),
                )
              }
              min="0"
              type="number"
            />
          );
          break;

        case inputCurrency:
          output = CurrencyDisplay(props.currency);
          break;

        default:
          output = (
            <Panel.Copy data-test={_.snakeCase(word)}>{word}</Panel.Copy>
          );
      }

      return <li key={`${props.slug}_${word}`}>{output}</li>;
    });
  }
}

LanguageParser.defaultProps = {
  language: '',
};

LanguageParser.propTypes = {
  language: string,
  props: object,
  modifyReward: func,
};

export default LanguageParser;
