import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g
        fillRule="nonzero"
        stroke="#000"
        strokeWidth="1.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.417 4v13.487M13.583 4v13.487M18.32 8.583H4h14.32zM18.32 12.75H4h14.32z" />
      </g>
    </svg>
  );
}
