import { triageV2 as triage, triageV3 } from './triage';

//  "create_event": {
//    "method": "POST",
//    "endpoint": "studies/:study_slug/enrollments/:identifier/event",
//    "required_params": [
//      "slug",
//      "status",
//      "notes",
//      "author"
//    ]
//  }
const event_create = data =>
  triage({
    method: 'POST',
    url: `studies/${data.study_slug}/enrollments/${data.identifier}/event`,
    data,
  });

/**
 * @description Axios asychronous call to emit a bulk event to
 * multiple participants.
 *
 * example payload:
 * {
 *   study_slug: 'my_study_slug',
 *   identifiers: ['identifier_01', 'identifier_02'],
 *   slug: 'my_event_slug',
 *   status: 'event_status',
 *   notes: 'Event note',
 *   occurred_at: '2019-05-19T20:40:21UTC+2:00'
 *   authenticated_user_email: 'test@evidation.com'
 * }
 *
 * @returns {AxiosPromise}
 */
const bulk_emit_event = data =>
  triageV3({
    method: 'POST',
    url: `studies/${data.study_slug}/bulk_enrollments/events`,
    data,
  });

//  "node_status_update": {
//  "method": "PATCH",
//  "endpoint": "studies/:study_slug/enrollment_nodes/status_update",
//  "required_params": [
//    "enrollment_identifiers",
//    "slugs",
//    "status",
//    "notes"
//  ],
//  "optional_params": [
//    "force"
//  ]
//  }
const event_patch = data =>
  triage({
    method: 'PATCH',
    url: `studies/${data.study_slug}/enrollment_nodes/status_update`,
    data,
  });

const event_delete = () => console.warn('can not delete events');

export { event_patch, event_create, event_delete, bulk_emit_event };
