import type { Sequence, Tiles } from '../redux/graph';

/**
 * Returns the identifier of the tile that this reminder is associated with.
 */
export const getTileIdForReminder = ({
  reminderId,
  tiles,
}: {
  reminderId: string;
  tiles: Tiles;
}) =>
  Object.values(tiles).find((tile) => tile.reminder_ids.includes(reminderId))
    ?.id ?? undefined;

/**
 * Given the Communications tile for a reminder, returns the identifier of the
 * related Delay tile for the same reminder.
 */
export const getRelatedReminderId = ({
  reminderId,
  tiles,
}: {
  reminderId: string;
  tiles: Tiles;
}) => {
  const tileId = getTileIdForReminder({ reminderId, tiles });
  if (!tileId) {
    return undefined;
  }
  const tile = tiles[tileId];
  const reminderIndex = tile.reminder_ids.indexOf(reminderId);
  if (reminderIndex <= 0) {
    return undefined;
  }
  return tile.reminder_ids[reminderIndex - 1] ?? undefined;
};

export const getSequenceIdForReminder = ({
  reminderId,
  sequences,
  tiles,
}: {
  reminderId: string;
  sequences: Sequence[];
  tiles: Tiles;
}) => {
  const tileWithReminder = Object.values(tiles).find((tile) =>
    tile.reminder_ids.includes(reminderId),
  );
  if (!tileWithReminder) {
    return undefined;
  }
  const sequenceWithTile = Object.values(sequences).find((sequence) =>
    sequence.tile_ids.includes(tileWithReminder.id),
  );
  return sequenceWithTile?.id;
};
