import * as C from '../common';

import _ from 'lodash';

export default data => {
  _.each(data.content.experiences, experience => {
    experience.sms_messages = _.map(
      experience.sms_messages,
      message => message.message_body,
    );
  });
  return C.common_outgoing(data);
};
