import { Nodes } from '../../../../constants';
import _ from 'lodash';

const baseconfig = {
  headingColor: `#1E2834`,
  subheadingColor: `#787878`,
};

export default ({ type = '', tileConfig = {}, configOverride = {} }) => {
  let output = {};
  const t = _.snakeCase(type);

  if (type !== ``) {
    // allowing us to override with custom configuration
    const config = _.merge({}, baseconfig, Nodes, tileConfig);
    output = _.has(config, t) ? config[t] : baseconfig;
  } else {
    // always return the baseconfig as a fallback
    output = baseconfig;
  }

  // always including the override
  return { ...output, ...configOverride };
};
