"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateFutureDate = exports.validateFormat = exports.validateDate = exports["default"] = void 0;

require("core-js/modules/es.date.to-string.js");

var _dateFns = require("date-fns");

var _i18n = require("../../i18n");

/*
  field level validations
*/
var _default = function _default(_ref) {
  var dob_format = _ref.dob_format;
  return [validateDate(dob_format), validateFormat(dob_format), validateFutureFormatDate(dob_format)];
};

exports["default"] = _default;

var validateDate = function validateDate() {
  var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  return function (value) {
    if (value) {
      var parsed = format !== undefined ? (0, _dateFns.parse)(value, transformFormat(format), new Date()) : (0, _dateFns.parseISO)(value);
      return (0, _dateFns.isValid)(parsed) && (0, _dateFns.isDate)(parsed) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDate');
    }
  };
};

exports.validateDate = validateDate;

var transformFormat = function transformFormat(format) {
  switch (format) {
    case 'YYYY':
      return 'yyyy';

    case 'YYYY-MM':
      return 'yyyy-MM';

    case 'MM-DD-YYYY':
      return 'MM-dd-yyyy';

    case 'YYYY-MM-DD':
    default:
      return 'yyyy-MM-dd';
  }
};

var validateFormat = function validateFormat(format) {
  return function (value) {
    if (value) {
      switch (format) {
        case 'YYYY':
          return /^[0-9]{4}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.year')
          });

        case 'YYYY-MM':
          return /^[0-9]{4}-[0-9]{2}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.yearMonth')
          });

        case 'MM-DD-YYYY':
          return /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.monthYearDay')
          });

        case 'YYYY-MM-DD':
        default:
          return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.yearMonthDay')
          });
      }
    }
  };
};

exports.validateFormat = validateFormat;

var validateFutureFormatDate = function validateFutureFormatDate() {
  var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  return function (value) {
    if (value) {
      var parsed = format !== undefined ? (0, _dateFns.parse)(value, transformFormat(format), new Date()) : (0, _dateFns.parseISO)(value);
      return (0, _dateFns.isFuture)(parsed) ? (0, _i18n.globalTranslate)('validations.dob.cannotBeFuture') : undefined;
    }
  };
};

var validateFutureDate = function validateFutureDate(value) {
  if (value) {
    var parsed = (0, _dateFns.parseISO)(value);
    return (0, _dateFns.isFuture)(parsed) ? (0, _i18n.globalTranslate)('validations.dob.cannotBeFuture') : undefined;
  }
};

exports.validateFutureDate = validateFutureDate;