import HorizontalWrapper from './horizontalWrapper';
import React from 'react';
import { Select } from './selectCommon';

const HorizontalSelect = ({
  input,
  options,
  disabled,
  defaultValue,
  omitPlaceholder = false,
  ...rest
}) => (
  <HorizontalWrapper {...rest} fieldType="horizontalSelect">
    <Select
      {...input}
      options={options}
      disabled={disabled}
      defaultValue={defaultValue}
      omitPlaceholder={omitPlaceholder}
    />
  </HorizontalWrapper>
);

HorizontalSelect.defaultProps = {
  disabled: false,
  defaultValue: `- Please Select -`,
};

export default HorizontalSelect;
