import styled from 'styled-components';

export const textStyles = `
  font-size: 14px;
  padding: 10px 15px;
  color: #444;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  background: #fff;
`;

export const SettingsHeading = styled.h3`
  color: #1e2834;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

SettingsHeading.Description = styled.div`
  p {
    color: #757575;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const ErrorDisplay = styled.div`
  color: crimson;
`;

export const Label = styled.label`
  color: #111;
`;
