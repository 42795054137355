import _ from 'lodash';

const objectifyResourceArray = (resourceArray = []) => {
  const result = {};

  _.forEach(resourceArray, item => {
    const uuid = item.reminder_uuid || item.uuid || item.sequence_uuid;
    result[uuid] = item;
  });

  return result;
};

export default objectifyResourceArray;
