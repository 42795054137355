import styled from 'styled-components';

export const AddReminderOuter = styled.li`
  background: #f3f3f3;
  padding: 4px 4px 3px;
  cursor: pointer;
`;

export const AddReminderInner = styled.div`
  background: #fff;
  padding: 5px 0 5px 12px;
  border-radius: 8px;
  font-size: 12px;
`;
