import * as C from '../common';
import _ from 'lodash';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const EvaluateCapTileSettings = ({ experience, masonryEnabled }) => ({
  label: 'Content',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        visibility: true,
      }),
    ],
  }),
});

export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    evaluate_cap: { masonryEnabled = false },
  } = dataForTile;
  const experienceName = masonryEnabled
    ? 'content'
    : `content.experiences.${experience}`;

  return [
    [
      {
        label: 'Content',
        name: experienceName,
        input: [
          C.experienceExternalTitle(masonryEnabled),
          { ...C.description, interface: `rte` },
        ],
      },
      {
        label: 'Settings',
        name: 'content',
        input: [
          {
            // Having problems naming this count_filter, it seems to be protected in this level
            // of the redux-form. I translate it back to count_filter in the outgoing.js
            name: 'count_filters',
            interface: 'array',
            title: 'Status Filters',
            items: [
              {
                name: `enrollment_status`,
                interface: `select`,
                label: `Status Filter`,
                options: [
                  { label: `Pending`, value: `pending` },
                  {
                    label: `Enrollment Completed`,
                    value: `enrollment_completed`,
                  },
                  { label: `Study Completed`, value: `study_completed` },
                ],
              },
            ],
          },
          {
            name: 'caps',
            interface: 'array',
            title: 'Attributes to Cap',
            items: [
              {
                name: 'attribute',
                interface: 'text',
                label: 'Attribute',
              },
              {
                name: 'value',
                interface: 'text',
                label: 'Value',
              },
              {
                name: 'size',
                interface: 'number',
                label:
                  'Cap (maximum number of participants who can pass with this value)',
              },
            ],
          },
          {
            name: `at_capacity_events`,
            interface: `array`,
            title: 'At Capacity Events',
            items: [
              {
                name: `slug`,
                label: `If the bucket is full, emit an event with slug`,
                interface: `textinput`,
                required: true,
                inputStyle: `sm_minimal`,
              },
              {
                name: `status`,
                label: `and status`,
                interface: `textinput`,
                required: true,
                inputStyle: `sm_minimal`,
              },
            ],
            condition: (path, { values }) => _.has(values, path),
          },
          {
            name: `not_at_capacity_events`,
            interface: `array`,
            title: 'Not At Capacity Events',
            items: [
              {
                name: `slug`,
                label: `If the bucket is NOT full, emit an event with slug`,
                interface: `textinput`,
                required: true,
                inputStyle: `sm_minimal`,
              },
              {
                name: `status`,
                label: `and status`,
                interface: `textinput`,
                required: true,
                inputStyle: `sm_minimal`,
              },
            ],
            condition: (path, { values }) => _.has(values, path),
          },
          C.visibility__depends_on,
          C.experience_attribute,
        ],
      },
    ],
  ];
};
