export default {
  description: (
    <p>
      This status indicates that the study is now configured and ready for
      testing and verification. Alterations to the configuration are expected
      and Billboard access can only be done as preview and only be possible
      using a preview token. The study is not available for any purpose without
      a preview token. Any test data created flows to the Staging folder in Data
      Platform.
    </p>
  ),
  arrow: (
    <ul>
      <li>Landing page opens for all participants to create enrollments.</li>
      <li>
        All Enrollments will be marked as FAKE, since data should be test data
      </li>
      <ul>
        <li>
          All enrollments will then be transitioned to completed status to avoid
          bumping up numbers in reports
        </li>
      </ul>
      <li>Cap Counts are Reset to zero</li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
