import * as C from '../common';

export default (data) => {
  /* verify if we're using new components and if is true we transform the payload
   ** to be compatible with previous versions
   */
  if (!data.content.hasOwnProperty('secondary_locales')) {
    for (const experience in data.content.experiences) {
      data.content.experiences[experience].sms_messages =
        data.content.experiences[experience].sms_messages.map((message) => {
          return {
            message_body: message,
          };
        });
    }
  }
  return C.common_incoming(data);
};
