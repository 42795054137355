import types from '../constants';
import { Sequences } from '../redux/graph';

export const setSequences = (payload: Sequences) => ({
  type: types.sequences.set,
  payload,
});
export const clearSequences = () => ({
  type: types.sequences.clear,
});
export const updateSequence = (payload: Sequences) => ({
  type: types.sequences.update,
  payload,
});
export const deleteSequence = (payload: Sequences) => ({
  type: types.sequences.delete,
  payload,
});
export const deleteInSequence = (payload: Sequences) => ({
  type: types.sequences.deleteIn,
  payload,
});
