import _ from 'lodash';
import * as C from '../common';

export default (data) => {
  if (_.has(data.content, 'external_ids')) {
    data.content.external_ids = _.reduce(
      data.content.external_ids,
      (acc, value) => {
        if (value.format === 'sequential_prefix_range') {
          const { options, format, user_data_key, client } = value;
          return [...acc, { format, client, user_data_key, ...options }];
        }
        return [...acc, value];
      },
      [],
    );
  }
  return C.common_incoming(data);
};
