import React, { useCallback, useMemo, useState } from 'react';
import * as C from '../../../common';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { queryStores } from '../../../../../../constants';
import {
  fetchAvailableServices,
  fetchFieldsByService,
} from '../../../../../../api';

const showForConnectAppTypes =
  (types = []) =>
  (path, { values }) => {
    const achievementSlugPath = `${path
      .slice(0, -1)
      .join('.')}.achievement_slug`;
    return _.includes(types, _.get(values, achievementSlugPath));
  };
export default function useConnectionSchema({
  slug,
  initialValues,
  currentConnections = [],
  connId,
}) {
  const [selectedService, setSelectedService] = useState(
    initialValues?.connection?.achievement_slug || null,
  );
  const { data: flow_apps } = useQuery(
    [queryStores.flowApplications, slug],
    () => fetchAvailableServices(slug),
    {
      enabled: !!slug,
    },
  );
  /**
   * Retreive field definitions from triage based on selected service.
   */
  const { data: field_defs, isLoading } = useQuery(
    [queryStores.flowFields, slug, selectedService],
    () => fetchFieldsByService(slug, selectedService),
    {
      enabled: !!selectedService,
    },
  );

  /**
   * Filter the app names uniquely
   * and by already selected connections
   */
  const serviceNames = useMemo(() => {
    const names = _.uniq(flow_apps?.map((app) => app.name));
    return names.filter((name) => {
      const itemNotFound = currentConnections.indexOf(name) < 0;
      // if the index of the item is equal to the connection id
      // we shouldnt remove it from the list since its the current item in use
      if (currentConnections.indexOf(name) === connId) {
        return true;
      }
      return itemNotFound;
    });
  }, [flow_apps, currentConnections, connId]);

  const handleServiceChange = useCallback(
    (name, value) => {
      setSelectedService(value);
    },
    [setSelectedService],
  );

  const serviceFields = useMemo(() => {
    if (field_defs?.fields) {
      return field_defs.fields;
    }
    return [];
  }, [field_defs]);

  const schema = useMemo(() => {
    return [
      {
        name: 'connection',
        input: [
          C.title,
          {
            name: 'achievement_slug',
            label: 'Service Name',
            interface: 'selectadvanced',
            required: true,
            options: serviceNames,
            // Allows you to watch the select
            // for changes without having to override the handler.
            watch: handleServiceChange,
            labelTooltip: {
              title: 'Service Name',
              content: '(vendor integration) to be setup in Flow.',
            },
          },
          {
            name: 'isLoading',
            label: 'requires steps, sleep, heartrate, pulseox, stress',
            condition: () => isLoading,
            interface: 'rendercomponent',
            render: () => <p style={{ fontSize: '9px' }}>Loading....</p>,
          },
          {
            name: 'garmin',
            label: 'requires steps, sleep, heartrate, pulseox, stress',
            condition: showForConnectAppTypes(['Garmin']),
            interface: 'rendercomponent',
            render: () => (
              <p style={{ fontSize: '9px' }}>
                Required data types: steps, sleep, heartrate, pulseox, stress
              </p>
            ),
          },
          {
            name: 'required_data_types',
            interface: 'checkboxgroup',
            label: 'Data Types',
            options: [
              { answer: 'steps', label: 'Steps' },
              { answer: 'sleep', label: 'Sleep' },
              { answer: 'heartrate', label: 'Heart Rate' },
              { answer: 'weight', label: 'Weight' },
              { answer: 'profile', label: 'Profile' },
            ],
            condition: showForConnectAppTypes(['Fitbit']),
            initialValues: ['profile'],
          },
          ...serviceFields,
        ],
      },
    ];
  }, [serviceNames, handleServiceChange, serviceFields, isLoading]);
  return { schema, setSelectedService };
}
