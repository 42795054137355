import React from 'react';

export default function Download({ color = `#111`, ...props }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-7-17-17" transform="translate(-271.000000, -64.000000)" />
        <g id="icons/actions/black/download" fill={color}>
          <g id="Group" transform="translate(3.000000, 3.000000)">
            <path
              d="M8.32458496,0.59375 L8.32458496,13.65625 C8.32458496,13.9845937 8.57321257,14.25 8.87955729,14.25 C9.18590202,14.25 9.43452962,13.9845937 9.43452962,13.65625 L9.43452962,0.59375 C9.43452962,0.26540625 9.18590202,0 8.87955729,0 C8.57321257,0 8.32458496,0.26540625 8.32458496,0.59375"
              id="Fill-556"
            />
            <path
              d="M12.3731081,9.07903125 L8.87955729,12.7947187 L5.38600647,9.07903125 C5.16845732,8.8480625 4.81715983,8.84865625 4.60127559,9.08140625 C4.38483639,9.31415625 4.38594633,9.69 4.60349548,9.92096875 L8.4883018,14.052875 C8.70418604,14.2838438 9.05437358,14.2826563 9.27081278,14.052875 L13.1556191,9.92096875 C13.3731683,9.69 13.3742782,9.31415625 13.157839,9.08140625 C12.9419548,8.84865625 12.5906573,8.8480625 12.3731081,9.07903125"
              id="Fill-557"
            />
            <path
              d="M16.6491699,13.0625 L16.6491699,17.8125 L1.10994466,17.8125 L1.10994466,13.0625 C1.10994466,12.7341563 0.861317057,12.46875 0.554972331,12.46875 C0.248627604,12.46875 0,12.7341563 0,13.0625 L0,17.8125 C0.00221988932,18.4697812 0.495590291,18.997625 1.10994466,19 L16.6491699,19 C17.2629693,18.997625 17.7568947,18.4697812 17.7591146,17.8125 L17.7591146,13.0625 C17.7591146,12.7341563 17.510487,12.46875 17.2041423,12.46875 C16.8977975,12.46875 16.6491699,12.7341563 16.6491699,13.0625"
              id="Fill-558"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
