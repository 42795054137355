import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: {
    Components: () => [],
    Settings,
  },
  incoming,
  outgoing,
};
