export default {
  description: (
    <p>
      This status indicates that the study data cleaning is complete. Unplanned
      data collection or changes will only be allowed after snapshot restore and
      with the appropriate documentation. No CRUD (create, update, delete)
      operations on study data are permitted A signoff step is required before
      Database Lock status is set (eg. CFR11 e-signature or two-person process?)
      {'->'} Person, change, reason Send message to Data Platform to take a
      fully restorable data snapshot (read-only)
    </p>
  ),
  arrow: (
    <ul>
      <li>
        Person, change, reason Send message to Data Platform to take a fully
        restorable data snapshot (read-only)
      </li>
    </ul>
  ),
};
