import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import statusDraft from './status/draft';
import statusQA from './status/qa';
import statusRecruiting from './status/recruiting';
import statusEnrollmentClosed from './status/enrollmentClosed';
import statusDataClose from './status/dataClose';
import statusDataLock from './status/dataLock';
import statusCompleted from './status/completed';

import formatStatus from '../../utils/formatStudyStatus';
import { e2eTestId } from '../../utils/e2eTesting';

const text = {
  title: 'Status Flow Looks Like',
  instruction:
    'Click the steps and arrows below to see the definition and functionality',
};

const statusFlowTestId = 'studyStatusFlow';

const testIds = {
  mainContainer: `${statusFlowTestId}_main_container`,
  title: `${statusFlowTestId}_title`,
  step: `${statusFlowTestId}_container`,
};

const Container = styled.div`
  padding-top: 40px;
  padding-left: 16px;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  margin-bottom: 15px;
`;

const Instruction = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4b4b4b;
  margin-bottom: 40px;
`;

const DataContainer = styled.div`
  display: flex;
  height: 375px;
`;

const Step = styled.div`
  width: 96px;
  min-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  cursor: pointer;

  &:hover,
  &.active {
    background: #162d6d;
    color: #ffffff;
  }
`;

const Arrow = styled.div`
  width: 0;
  height: 26px;
  border-right: 1px solid #cccccc;

  ${({ right }) => {
    if (right) {
      return css`
        width: 22px;
        height: 68px;
        border: solid #cccccc;
        border-width: 1px 1px 1px 0;

        &::after {
          content: '';
          position: fixed;
          top: 297px;

          border: solid #cccccc;
          border-width: 1px 0 0 1px;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
        }
      `;
    }

    return css`
      &::after {
        content: '';
        margin: 0 0 -7px -3px;

        border: solid #cccccc;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
      }
    `;
  }}
`;

const ArrowContainer = styled.div`
  cursor: pointer;

  ${({ right }) => {
    if (right) {
      return css`
        position: absolute;
        margin-left: 96px;
        top: 300px;
        width: 30px;

        &:hover ${Arrow}, &.active ${Arrow} {
          border: solid #162d6d;
          border-width: 2px 2px 2px 0;

          &::after {
            border: solid #162d6d;
            border-width: 2px 0 0 2px;
          }
        }
      `;
    }

    return css`
      width: 25px;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      &:hover ${Arrow}, &.active ${Arrow} {
        border-right: 2px solid #162d6d;

        &::after {
          border: solid #162d6d;
          border-width: 0 2px 2px 0;
        }
      }
    `;
  }}
`;

const MessageContainer = styled.div`
  width: 100%;
  padding-left: 30px;
`;

const Description = styled.div`
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ white }) => (white ? '#ffffff' : '#162d6d')};
  border: 1px solid ${({ white }) => (white ? '#cccccc' : '#162d6d')};

  position: absolute;
  left: 22%;
  top: ${({ top }) => (top ? top : '146px')};

  width: 450px;
  min-height: 70px;

  &::before {
    content: '';
    z-index: 1;

    position: absolute;
    top: 24px;
    left: -7px;

    height: 12px;
    width: 12px;
    background: ${({ white }) => (white ? '#ffffff' : '#162d6d')};
    border: solid ${({ white }) => (white ? '#cccccc' : '#162d6d')};
    border-width: 1px 0 0 1px;
    transform: rotate(-45deg);
  }

  p,
  ul {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: ${({ white }) => (white ? '#162d6d' : '#ffffff')};
  }

  ul {
    list-style: disc;
    padding-left: 20px;

    & ul {
      list-style: circle;
    }
  }
`;

const STATUSES = [
  {
    status: 'draft',
    ...statusDraft,
  },
  {
    status: 'qa',
    ...statusQA,
  },
  {
    status: 'recruiting',
    ...statusRecruiting,
  },
  {
    status: 'enrollment_closed',
    ...statusEnrollmentClosed,
  },
  {
    status: 'data_close',
    ...statusDataClose,
  },
  {
    status: 'data_lock',
    ...statusDataLock,
  },
  {
    status: 'completed',
    ...statusCompleted,
  },
];

const getSearchParams = (search) => new URLSearchParams(search);

const StudyStatusFlow = ({ location }) => {
  const [activeStatus, setActiveStatus] = useState(null);
  const [messageElement, setMessageElement] = useState(null);
  const [refTop, setRefTop] = useState(null);
  const ref = useRef();

  const getSelectedAttribute = (status) => {
    const isStepSelected = activeStatus === status;
    const isArrowSelected = activeStatus === `${status}-arrow`;
    const isRightArrowSelected = activeStatus === `${status}-rightArrow`;

    return { isStepSelected, isArrowSelected, isRightArrowSelected };
  };

  const handleStatusUpdate = (status, element) => {
    if (status !== activeStatus) {
      setActiveStatus(status);
      setMessageElement(element);
    }
  };

  useEffect(() => {
    const status = getSearchParams(location.search).get('status');
    const foundObj = STATUSES.find((obj) => obj.status === status);

    if (status && foundObj) {
      handleStatusUpdate(status, foundObj.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let offsetTopValue = 0;
    if (ref.current && activeStatus && messageElement) {
      const { offsetTop } = ref.current;
      offsetTopValue = offsetTop;

      // when the element is a rightArrow
      if (activeStatus.includes('rightArrow') && ref.current.offsetParent) {
        offsetTopValue = ref.current.offsetParent.offsetTop;
      }

      setRefTop(`${offsetTopValue - 20}px`);
    }
  }, [ref, activeStatus, messageElement]);

  return (
    <Container {...e2eTestId(testIds.mainContainer)}>
      <Title {...e2eTestId(testIds.title)}>{text.title}</Title>
      <Instruction>{text.instruction}</Instruction>
      <DataContainer>
        <div>
          {STATUSES.map(({ status, description, arrow, rightArrow }, i) => {
            const isLastStep = i === STATUSES.length - 1;
            const { isStepSelected, isArrowSelected, isRightArrowSelected } =
              getSelectedAttribute(status);

            return (
              <React.Fragment key={i}>
                <Step
                  className={isStepSelected && 'active'}
                  onClick={() => handleStatusUpdate(status, description)}
                  {...e2eTestId(`${testIds.step}_${status}`)}
                >
                  <div ref={isStepSelected ? ref : undefined}>
                    {formatStatus(status)}
                  </div>
                </Step>
                {rightArrow && (
                  <ArrowContainer
                    className={isRightArrowSelected && 'active'}
                    onClick={() =>
                      handleStatusUpdate(`${status}-rightArrow`, rightArrow)
                    }
                    right
                  >
                    <div ref={isRightArrowSelected ? ref : undefined}>
                      <Arrow right />
                    </div>
                  </ArrowContainer>
                )}
                {!isLastStep && (
                  <ArrowContainer
                    className={isArrowSelected && 'active'}
                    onClick={() => handleStatusUpdate(`${status}-arrow`, arrow)}
                  >
                    <div ref={isArrowSelected ? ref : undefined}>
                      <Arrow />
                    </div>
                  </ArrowContainer>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <MessageContainer>
          {activeStatus && React.isValidElement(messageElement) && (
            <Description
              white={activeStatus.toLowerCase().includes('arrow')}
              top={refTop}
            >
              {messageElement}
            </Description>
          )}
        </MessageContainer>
      </DataContainer>
    </Container>
  );
};

export default StudyStatusFlow;
