import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ContentContainer } from '../../../modal/SimpleModal';
import Button from '../../../inputs/Button';
import CuriousPanda from '../../../fields/CuriousPanda';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../LoadingIndicator';
import styled from 'styled-components';
import uniqueId from 'uniqid';
import { apiGraph } from '../../../../api';
import { logError } from '@evidation/logger';
import { toastr } from 'react-redux-toastr';

const CenterParagraph = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const about_export_study_label = 'You are about to export Study Configuration:';
const export_label = 'Export';
const do_you_proced_label = 'Do you want to proceed?';
const yes_label = 'Yes';
const cancel_label = 'Cancel';
const lock_label = 'Lock';
const invalid_label = 'Invalid selection';
const export_is_complete_label = 'Your language export is complete.';
const export_language_file_title = 'Export Language File';
const exporting_title = 'Exporting';
const export_complete_title = 'Export Complete';

const uuid = uniqueId();

export const exportSchema = (languages) => [
  {
    className: `exportStudy`,
    input: [
      {
        name: `export_language`,
        label: `What language is this going to be translated into?`,
        placeholder: `ex.: English`,
        interface: `selectadvanced`,
        required: true,
        possible_answers: languages,
      },
    ],
  },
];

const ExportLangModal = ({
  graphId,
  slug,
  onClose,
  primary_locale,
  secondary_locales,
  locale: { languages },
}) => {
  const [step, setStep] = React.useState(0);
  const [language, setLanguage] = React.useState(null);
  const [content, setContent] = React.useState([]);
  const all_languages = React.useMemo(
    () =>
      [primary_locale, ...secondary_locales].map((x) => {
        return {
          label: languages[x].label,
          value: x,
        };
      }),
    [languages, primary_locale, secondary_locales],
  );
  all_languages.unshift({ label: 'All', value: 'all' });

  const nextStep = () => setStep(step + 1);

  const exportTranslationsFile = async () => {
    try {
      const params = {
        include_system_translations: false,
        ...(language && language !== 'all' && { locale: language }),
      };
      const res = await apiGraph.exportTranslations(graphId, params);
      if (res && res.data) {
        setContent(res.data);
        nextStep();
      }
    } catch (error) {
      toastr.error(`There was a problem exporting graph ${graphId}`);
      onClose();
      logError(error);
    }
  };

  React.useEffect(() => {
    switch (step) {
      case 2:
        exportTranslationsFile();
        break;
      case 3:
        const url = window.URL.createObjectURL(
          new Blob([JSON.stringify(content, null, 2)], {
            type: 'application/json',
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${slug}_${uuid}_${
            languages[language]
              ? languages[language].label.toLowerCase()
              : 'all'
          }.json`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        break;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const getTitle = () => {
    switch (step) {
      case 0:
      case 1:
        return export_language_file_title;
      case 2:
        return exporting_title;
      case 3:
        return export_complete_title;
      default:
        return invalid_label;
    }
  };

  const getContent = () => {
    switch (step) {
      case 0:
        return (
          <CuriousPanda
            onSubmit={({ export_language }) => {
              setLanguage(export_language);
              nextStep();
            }}
            form={`export_lang_file`}
            schema={exportSchema(all_languages)}
          >
            {(props) => (
              <Button disabled={!props.valid} type="primary">
                {export_label}
              </Button>
            )}
          </CuriousPanda>
        );
      case 1:
        return (
          <>
            <p>{about_export_study_label}</p>
            <p>{`${slug}_${uuid}_${
              languages[language]
                ? languages[language].label.toLowerCase()
                : 'all'
            }.json.`}</p>
            <p>{do_you_proced_label}</p>
            <Button
              onClick={() => {
                nextStep();
              }}
              type="primary"
            >
              {yes_label}
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <CenterParagraph>
              <LoadingIndicator show={step === 2} />
            </CenterParagraph>
            <Button onClick={onClose} type="primary">
              {cancel_label}
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <CenterParagraph>{export_is_complete_label}</CenterParagraph>
            <Button onClick={onClose} type="primary">
              {lock_label}
            </Button>
          </>
        );
      default:
        throw new Error(`Invalid step ${step} in ExportLangModal component.`);
    }
  };

  return (
    <Modal onClose={onClose} center showX modalTitle={getTitle()}>
      <ContentContainer>{getContent()}</ContentContainer>
    </Modal>
  );
};

ExportLangModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default connect(
  ({
    graph: {
      id,
      primary_locale,
      secondary_locales,
      content: { slug },
    },
    locale,
  }) => ({
    graphId: id,
    slug,
    primary_locale,
    secondary_locales: secondary_locales ?? [],
    locale,
  }),
)(ExportLangModal);
