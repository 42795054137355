import React, { PureComponent } from 'react';

import { AddSequenceButton } from '.';
import Bell from '../../../assets/nav_icons/reminder';
import DropdownMenu from 'react-dd-menu';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import _ from 'lodash';
import { connect } from 'react-redux';

const checkExperienceInResource = (id, resource, name) => {
  if (_.has(resource[id].content, `experiences`)) {
    return `${name} - ${resource[id].content.experiences.default.title}`;
  }

  return `${name} - ${resource[id].content.title}`;
};

class ErrorsDropdown extends PureComponent {
  state = { isMenuOpen: false };
  toggle = () => this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
  close = () => this.setState({ isMenuOpen: false });

  getTitleOfError = id => {
    const { tiles, reminders, sequences, graph_id } = this.props;
    let title, link;

    if (_.isEqual(id, graph_id)) {
      title = `Graph`;
      link = `/study/${graph_id}/settings/general`;
    } else if (_.has(tiles, id)) {
      title = checkExperienceInResource(id, tiles, `Tile`);
      link = `/study/${graph_id}/configure/tile/${id}`;
    } else if (_.has(sequences, id)) {
      title = `Sequence - ${sequences[id].content.title}`;
      link = `/study/${graph_id}/edit/slate/sequence/edit/${id}`;
    } else if (_.has(reminders, id)) {
      title = checkExperienceInResource(id, reminders, `Reminder`);
      link = `/study/${graph_id}/configure/reminder/${id}`;
    } else {
      return false;
    }

    return {
      title,
      link,
    };
  };

  render() {
    const { publish_errors } = this.props;

    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      textAlign: `left`,
      toggle: (
        <React.Fragment>
          <Tooltip
            place="left"
            id="t-toggle_errors_dropdown"
            type="dark"
            effect="solid"
          >
            Latest publish errors
          </Tooltip>
          <AddSequenceButton
            warning={!_.isEmpty(publish_errors)}
            data-tip
            data-for="t-toggle_errors_dropdown"
            onClick={this.toggle}
          >
            <Bell />
          </AddSequenceButton>
        </React.Fragment>
      ),
      align: 'right',
    };

    return (
      <DropdownMenu {...menuOptions}>
        {_.map(publish_errors, (arr, err) => {
          const errorData = this.getTitleOfError(err);

          if (!errorData) return null;

          return (
            <li key={err}>
              <Link
                to={{
                  pathname: errorData.link,
                }}
              >
                <strong>{errorData.title}</strong>
                <br />
                {_.map(arr, item => (
                  <small key={`${err}-${_.snakeCase(item)}`}>
                    {' '}
                    - {item}
                    <br />
                  </small>
                ))}
              </Link>
            </li>
          );
        })}
      </DropdownMenu>
    );
  }
}

ErrorsDropdown.defaultProps = {
  publish_errors: {},
};

ErrorsDropdown.propTypes = {
  publish_errors: PropTypes.object.isRequired,
};

export default connect(
  ({ publish_errors, tiles, reminders, sequences, graph }) => ({
    tiles,
    reminders,
    sequences,
    publish_errors,
    graph_id: graph.id,
  }),
  null,
)(ErrorsDropdown);
