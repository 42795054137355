import { AnyAction } from 'redux';
import types from '../constants';
import { Locale } from '../redux/graph';

const defaultState: Locale = {
  currentLocale: null,
  languages: {},
};

const LocaleReducer = (
  state: Locale = defaultState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case types.locale.setLanguages:
    case types.locale.setLocale:
      return {
        ...state,
        ...payload,
      };
    case types.locale.clearLocale:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};

export default LocaleReducer;
