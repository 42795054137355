import React from 'react';

export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.834 226.834"
      style={{
        enableBackground: 'new 0 0 226.834 226.834',
      }}
      xmlSpace="preserve"
      width="24px"
      height="24px"
      {...props}
    >
      <path d="M80.197 44.939v-9.746A3.197 3.197 0 0 1 83.39 32h60.053a3.197 3.197 0 0 1 3.193 3.193v9.746a3.197 3.197 0 0 1-3.193 3.193H83.391a3.196 3.196 0 0 1-3.194-3.193zM131.841 17c-.768-9.5-8.729-17-18.424-17S95.761 7.5 94.993 17h36.848zm60.468 38.334v151.333c0 11.12-9.047 20.167-20.167 20.167H54.692c-11.12 0-20.167-9.047-20.167-20.167V55.334c0-11.12 9.047-20.167 20.167-20.167h10.506l-.001.026v9.746c0 10.032 8.162 18.193 18.193 18.193h60.053c10.032 0 18.193-8.161 18.193-18.193v-9.746l-.001-.026h10.506c11.121 0 20.168 9.047 20.168 20.167zM88.183 143.449a7.5 7.5 0 0 0-10.32 2.449l-7.092 11.504-3.661-2.884a7.5 7.5 0 0 0-9.28 11.785l10.271 8.089a7.499 7.499 0 0 0 11.025-1.957l11.506-18.666a7.5 7.5 0 0 0-2.449-10.32zm0-54a7.5 7.5 0 0 0-10.32 2.449l-7.092 11.504-3.661-2.884a7.5 7.5 0 0 0-9.28 11.785l10.271 8.089a7.499 7.499 0 0 0 11.025-1.957L90.632 99.77a7.5 7.5 0 0 0-2.449-10.321zm77.675 79.051c0-4.143-3.357-7.5-7.5-7.5h-49c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h49c4.143 0 7.5-3.357 7.5-7.5zm0-54c0-4.143-3.357-7.5-7.5-7.5h-49c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h49c4.143 0 7.5-3.357 7.5-7.5z" />
    </svg>
  );
};
