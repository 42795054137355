import types from '../constants';

// create delay/reminder mapping
export const mapReminders = (payload: any) => ({
  type: types.reminders.mapReminders,
  payload,
});
export const mapRemindersClear = () => ({
  type: types.reminders.mapRemindersClear,
});
