import { triageV3 } from './triage';

type WebhookCommonProps = {
  id: number;
  graph_id: number;
  name: string;
  target_http_method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  target_url: string;
  webhook_event_trigger: string;
  on_webhook_success_event?: string;
  on_webhook_failure_event?: string;
  http_headers: string;
  body: Record<string, any>;
  created_at?: string;
  updated_at?: string;
};

type WebhookAuthWithValue = {
  auth_type: 'Basic' | 'Bearer' | 'AWS:ARN';
  auth_value: string;
};

type WebhookAuthWithoutValue = {
  auth_type: 'PPMI' | 'No Auth' | '' | 'Flow' | 'Scribe';
};

export type Webhook = WebhookCommonProps &
  (WebhookAuthWithValue | WebhookAuthWithoutValue);

type WebhookWithoutIdentifers = Omit<Webhook, 'graph_id' | 'id'>;

export const WebhookService = {
  create: ({
    graphId,
    data,
  }: {
    graphId: string | number;
    data: WebhookWithoutIdentifers;
  }) => {
    return triageV3
      .post<Webhook>(`webhook_configs?graph_id=${graphId}`, data)
      .then(({ data }) => data);
  },

  delete: ({
    graphId,
    webhookId,
  }: {
    graphId: string | number;
    webhookId: string | number;
  }) => {
    return triageV3
      .delete(`webhook_configs/${webhookId}?graph_id=${graphId}`)
      .then(({ data }) => data);
  },

  getAll: ({ graphId }: { graphId: string | number }) => {
    return triageV3
      .get<Webhook[]>(`webhook_configs?graph_id=${graphId}`)
      .then(({ data }) => data);
  },

  getEditById: ({
    graphId,
    webhookId,
  }: {
    graphId: string | number;
    webhookId: number;
  }) => {
    return triageV3
      .get<Webhook>(`webhook_configs/${webhookId}/edit?graph_id=${graphId}`)
      .then(({ data }) => data);
  },
  update: ({
    graphId,
    webhookId,
    data,
  }: {
    graphId: string | number;
    webhookId: string | number;
    data: WebhookWithoutIdentifers;
  }) => {
    return triageV3
      .put<Webhook>(`webhook_configs/${webhookId}?graph_id${graphId}`, data)
      .then(({ data }) => data);
  },
};
