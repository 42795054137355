import React from 'react';

class RawText extends React.Component {
  render() {
    const { className, input, disabled, placeholder, label, type } = this.props;

    return (
      <React.Fragment>
        {label && <label htmlFor={input.name}>{label}</label>}
        <input
          {...input}
          className={`${className} rawtext`}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
        />
      </React.Fragment>
    );
  }
}

RawText.defaultProps = {
  type: `text`,
  disabled: false,
  placeholder: ``,
  label: undefined,
};

export default RawText;
