import { Webhook } from '../../api/webhooks';
import { Alert, Center, Grid, NoDataMessage, LoadingState } from './components';
import { WebhookCard } from './WebhookCard';

export const WebhookList: React.FC<{
  data: Webhook[] | undefined;
  loading: boolean;
  error: string | null | unknown;
  editWebhook: (webhook: Webhook) => void;
  removeWebhook: (webhook: Webhook) => void;
}> = ({ data, loading, error, editWebhook, removeWebhook }) => {
  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <Alert rounded>Something went wrong...</Alert>;
  }

  if (data && Array.isArray(data) && data.length > 0) {
    return (
      <Grid>
        {data.map((webhook) => (
          <WebhookCard
            webhook={webhook}
            editWebhook={editWebhook}
            removeWebhook={removeWebhook}
            key={`webhook_card_${webhook.id}`}
          />
        ))}
      </Grid>
    );
  }

  if (data && data.length === 0) {
    return (
      <Center>
        <NoDataMessage>No Webhooks found</NoDataMessage>
      </Center>
    );
  }

  return null;
};
