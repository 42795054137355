export default {
  description: (
    <p>
      This status indicates the study activities are over and no participant
      needs access to the study any longer. Billboard access is no longer
      possible, with or without preview token. No CRUD operations on study data
      are permitted; all data flows/jobs stop for the study.
    </p>
  ),
};
