import * as defaults from '../defaults';

import React, { Component } from 'react';

import Schemas from '../forms';
import _ from 'lodash';
import { apiGraph } from '../../../api';
import { connect } from 'react-redux';
import { loadGraph } from '../../../actions/combination';
import { toastr } from 'react-redux-toastr';
import { logError } from '@evidation/logger';
import { toggle_display_reminder } from '../../../actions/slate_actions';

const commonError = (error) => {
  logError(error);
  _.has(error.response, 'data') &&
    _.forEach(error.response.data.errors, (error) => toastr.error(error));
};

const getDefaultValues = (content, id, props) => {
  const {
    user: { studies },
  } = props;
  const study = studies.find((x) => x.id === id);

  return _.has(defaults, content.content.type) &&
    !study.content.masonry_enabled
    ? defaults[content.content.type](content)
    : {};
};

export const onAdd = async (
  { resource, ...content },
  force_sequence_id = undefined,
  force_uuid = undefined,
  {
    loadGraph,
    graph: { id, masonry_enabled },
    toggleDisplayReminder,
    ...props
  },
) => {
  // if a uuid or sequence_id is explicitly passed, take priority over it
  // vs using the current location state
  let uuid = undefined;
  let sequence_uuid = undefined;

  if (force_sequence_id || force_uuid) {
    if (force_sequence_id) {
      sequence_uuid = force_sequence_id;
    }

    if (force_uuid) {
      uuid = force_uuid;
    }
  } else {
    if (_.has(props.location, `state`)) {
      // TODO: this should be removed
      uuid = props.location.state.uuid;
      sequence_uuid = props.location.state.sequence_uuid;
    }
  }

  const this_type = resource === 'reminder' ? 'reminder' : content.content.type;

  const payload = Schemas[this_type].outgoing(
    _.merge({}, getDefaultValues(content, id, props), content, {
      type: this_type,
    }),
  );

  switch (resource) {
    case 'add_archipelago':
    case 'sequence':
      return apiGraph
        .add_sequence(id, payload)
        .then((data) => {
          loadGraph(id);
          toastr.success('You have successfully added a sequence.');
          return data;
        })
        .catch(commonError);
    case 'tile':
      return apiGraph
        .add_tile(id, sequence_uuid, payload)
        .then((data) => {
          loadGraph(id);
          toastr.success('You have successfully added a tile.');
          return data;
        })
        .catch(commonError);
    case 'reminder':
      if (masonry_enabled) {
        return apiGraph.reminders
          .add({
            graphId: id,
            sequenceId: sequence_uuid,
            tileId: uuid,
            payload: {
              content: {
                ...payload.content,
                offset: 0,
                units: 'minutes',
              },
            },
          })
          .then(async (response) => {
            await loadGraph(id);
            // Fetching the graph above closes the reminder display, so let's
            //   force it to open.
            toggleDisplayReminder(uuid);
            toastr.success('You have successfully added a reminder.');
            return response.data;
          })
          .catch(commonError);
      }
      const relatedDelayPayload = content.delay
        ? content.delay
        : {
            content: {
              offset: 0,
              units: 'minutes',
              title: `${payload.content.title} - delay`,
              type: 'delay',
            },
            type: 'delay',
          };
      return apiGraph
        .add_reminder(id, sequence_uuid, uuid, relatedDelayPayload)
        .then(() =>
          // sending reminder data
          apiGraph
            .add_reminder(id, sequence_uuid, uuid, {
              ...payload,
              type: 'communications',
            })
            .then((data) => {
              loadGraph(id);
              toastr.success(`You have successfully added a reminder.`);
              return data;
            })
            .catch(commonError),
        )
        .catch(commonError);

    default:
      console.warn(`you can not add ${resource} of resource`);
      toastr.error(`There was a problem adding a ${resource} resource.`);
      return loadGraph(id);
  }
};

export default (WrappedComponent) => {
  class OnAdd extends Component {
    constructor(props) {
      super(props);
      this.onAdd = onAdd.bind(this);
    }

    render() {
      return (
        <WrappedComponent
          onAdd={(resource, sequence_uuid, uuid) =>
            this.onAdd(resource, sequence_uuid, uuid, this.props)
          }
          {...this.props}
        />
      );
    }
  }

  return connect((state) => state, {
    loadGraph,
    toggleDisplayReminder: toggle_display_reminder,
  })(OnAdd);
};
