//@ts-nocheck
import { toastr } from 'react-redux-toastr';

export default ({ getState, dispatch }) => next => action => {
  switch (action.type) {
    case 'ATC/ERROR':
      toastr.error(action.payload);
      return next(action);

    default:
      return next(action);
  }
};
