import { default_experience_key } from '../../../constants';

export default () => {
  return {
    content: {
      available_apps: [
        {
          title: '',
          achievement_slug: '',
        },
      ],
      experiences: {
        [default_experience_key]: {
          action_title: 'Connect an App',
        },
      },
    },
  };
};
