"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPhone = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.starts-with.js");

require("core-js/modules/es.array.slice.js");

var _lodash = _interopRequireDefault(require("lodash"));

var formatPhone = function formatPhone(value) {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/[^\d]/g, ''); // Taking into account that changes in Triage will append
  // country code to phone number.

  if (!_lodash["default"].startsWith(onlyNums, '1')) {
    onlyNums = "1".concat(onlyNums);
  }

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length < 8) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }

  if (onlyNums.length < 11) {
    return '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
  }

  return onlyNums.slice(0, 1) + ' (' + onlyNums.slice(1, 4) + ') ' + onlyNums.slice(4, 7) + '-' + onlyNums.slice(7, 11);
};

exports.formatPhone = formatPhone;