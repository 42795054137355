import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { Router } from 'react-router';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ModalContainer, ModalRoute } from 'react-router-modal';
import PropTypes from 'prop-types';
import ReactInterval from 'react-interval';
import { connect } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import Loadable from 'react-loadable';

import Add from '../routes/edit/panels/add';
import Sequence from './Sequences/AddEdit';
import DashboardScrollContainer from '../routes/edit/components/dashboardScrollContainer';
import EditSequence from '../routes/edit/components/edit';
import Export from '../routes/edit/panels/json';
import ExportAuditTrail from '../routes/edit/panels/ExportAuditTrail';
import SelectionMenu from '../routes/edit/components/selectionMenu';
import SlateDashboard from '../routes/edit/dashboard';
import StudyLoader from './studyLoader';
import StudyLayout from './StudyLayout';
import ModalShell from '../components/modalContainer';
import StudyStatusFlow from '../components/studyStatusFlow';

import { assignAuthorization, loginUser } from '../actions/user_actions';
import { keepalive } from '../api';
import { clearAllGraphData } from '../actions/combination';
import { query_params } from '../constants';
import { patchHistoryToPreserveQueryParams } from '../utils/preservedQueryHistory';

const default_study_landing = `overview`;
const KEEPALIVE_INTERVAL = 600000;

let pageBorder = 'none';

const history = patchHistoryToPreserveQueryParams(
  createBrowserHistory(),
  (currentLocation, newLocation) => {
    const newPathname = newLocation.pathname || currentLocation.pathname;
    // When navigating within a study, preserve the current locale.
    const newMatch = matchPath(newPathname, { path: `/study/:graph_id` });
    const currentMatch = matchPath(currentLocation.pathname, {
      path: `/study/:graph_id`,
    });
    if (
      newMatch &&
      (!currentMatch ||
        newMatch.params.graph_id === currentMatch.params.graph_id)
    ) {
      return [query_params.locale];
    }
    return [];
  },
);

const Loading = (props) => {
  if (props.error) {
    console.error(props.error);
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <StudyLoader />;
  }

  return null;
};

const load = (loader, loading = Loading) =>
  Loadable({ loader, loading, delay: 10 });

if (
  window.location.host.includes('localhost') ||
  window.location.host.includes('study-manager-qa')
) {
  pageBorder = '3px solid #00C480';
} else if (window.location.host.includes('study-manager-staging')) {
  pageBorder = '3px solid #F5A623';
}

createGlobalStyle`
  .env-border {
    border-top: ${pageBorder};
  }
`;

const Compensation = load(() => import('../routes/compensation'));
const VisitPortal = load(() => import('../routes/visit_portal'));
// const Edit = load(() => import('../routes/study/routes/edit'));
const Configure = load(() => import('../routes/configure'));
const Overview = load(() => import('../routes/overview'));
const Review = load(() => import('../routes/review'));
const Permissions = load(() => import('../routes/permissions'));
const PermissionsNew = load(() => import('../routes/permissions_new'));
const EventDelays = load(() => import('../routes/event_delays'));
const Settings = load(() => import('../routes/settings'));
const Branding = load(() => import('../routes/branding'));
const Studies = load(() => import('../routes/studies'));
const DeleteUser = load(() => import('../routes/delete_user'));
const Participant = load(() => import('../routes/participant'));
const Participants = load(() => import('../routes/participants'));
const UploadReview = load(() => import('../routes/upload_review'));
const VersionDetail = load(() => import('../routes/study_versions'));
const StudyOutline = load(() => import('../routes/study_outline'));
const AsyncOperations = load(() => import('../routes/async_operations'));

const ParticipantsContainer = load(() =>
  import('../routes/participants/ParticipantsContainer'),
);
const AddEditFilter = load(() =>
  import('../routes/participants/addEditFilter'),
);
const Results = load(() => import('../routes/participants/results'));
const ResultsFullScreen = load(() =>
  import('../routes/participants/resultsFullScreen'),
);
const ICFReview = load(() => import('../routes/icf_review'));
const Schedule = load(() => import('../routes/schedule'));
const About = load(() => import('../routes/version_info'));
const NewConfig = load(() => import('../routes/configureNew'));

const StudyStatusFlowModal = ({ match: { url } }) => (
  <ModalRoute
    className="react-router-modal__modal"
    path={`${url}/study_status_flow`}
    component={(props) => (
      <ModalShell {...props} parentPath={url}>
        <StudyStatusFlow {...props} />
      </ModalShell>
    )}
  />
);

export function AppContainer({ loginUser }) {
  useEffect(() => {
    loginUser({ token: '', username: '' });
  }, [loginUser]);
  let pageIcon = '/favicon-prod.png';
  let pageTitle = 'Study Manager - Production';

  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('study-manager-qa')
  ) {
    pageTitle = 'Study Manager - QA';
    pageIcon = '/favicon-qa.png';
  } else if (window.location.host.includes('study-manager-staging')) {
    pageTitle = 'Study Manager - Staging';
    pageIcon = '/favicon-stage.png';
  }
  return (
    <Router history={history}>
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="shortcut icon" href={pageIcon} />
          <link rel="favicon" href={pageIcon} />
        </Helmet>
        <Route
          path="/studies"
          render={(props) => (
            <>
              <Studies {...props} />
              <StudyStatusFlowModal {...props} />
            </>
          )}
        />
        <Route path="/about" component={About} />
        <Route path="/delete_user" component={DeleteUser} />
        <Route
          path="/study/:graph_id"
          component={(props) => {
            const {
              match: { path },
            } = props;

            return (
              <StudyLayout {...props}>
                <Switch>
                  {/* Redirect Route */}
                  <Route
                    exact
                    path={`/study/${props.match.params.graph_id}`}
                    render={() => (
                      <Redirect
                        to={`/study/${props.match.params.graph_id}/${default_study_landing}`}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={`${path}/edit`}
                    component={(p) => (
                      <Redirect
                        {...p}
                        to={`/study/${props.match.params.graph_id}/edit/slate`}
                      />
                    )}
                  />
                  <Route path={`${path}/outline`} component={StudyOutline} />
                  {/* Slate Route */}
                  <Route
                    path={`${path}/edit/slate`}
                    render={(p) => {
                      return (
                        <React.Fragment>
                          <DashboardScrollContainer>
                            <Route
                              path={`${p.match.path}`}
                              component={SlateDashboard}
                            />

                            {/* Add Sequence && Alternative Route */}
                            <ModalRoute
                              parentPath={`/study/${props.match.params.graph_id}/edit/slate`}
                              path={`${p.match.path}/add`}
                              component={Add}
                            />

                            <ModalRoute
                              parentPath={`/study/${props.match.params.graph_id}/edit/slate`}
                              path={`${p.match.path}/add-new`}
                              component={Sequence}
                            />

                            <ModalRoute
                              parentPath={`/study/${props.match.params.graph_id}/edit/slate`}
                              path={`${p.match.path}/:resource/edit/:identifier`}
                              component={EditSequence}
                            />
                            <ModalRoute
                              parentPath={`/study/${props.match.params.graph_id}/edit/slate`}
                              path={`${p.match.path}/:resource/edit-new/:identifier`}
                              component={Sequence}
                            />
                          </DashboardScrollContainer>
                          <SelectionMenu />
                        </React.Fragment>
                      );
                    }}
                  />
                  {/* Export Route */}
                  <Route path={`${path}/export`} component={Export} />
                  {/* Export Audit Trail Route */}
                  <Route
                    path={`${path}/export_audit_trail`}
                    component={ExportAuditTrail}
                  />
                  <Route
                    path={`${path}/async-operations`}
                    component={AsyncOperations}
                  />
                  <Route
                    path={`/study/:graph_id/versions/:id`}
                    component={VersionDetail}
                  />
                  {/* Edit Tile Route */}
                  <Route
                    path={`${path}/configure/:resource/:identifier/:active_panel?/:path_segment_1?/:path_segment_2?`}
                    component={Configure}
                  />

                  {/* Compensation Route */}
                  <Route
                    exact
                    path={`${path}/edit/compensation/:activeEventSlug?/:activeTab?/:bulk?`}
                    component={Compensation}
                  />

                  {/* Alternate Edit Tile Route for new tile restructure. This will have to change as the feature become
                    more permanent */}
                  <Route
                    path={`${path}/configure_new/:resource/:identifier/:active_panel?/:path_segment_1?/:path_segment_2?`}
                    component={NewConfig}
                  />

                  {/* Compensation Route */}
                  <Route
                    exact
                    path={`${path}/edit/compensation/:activeEventSlug?/:activeTab?/:bulk?`}
                    component={Compensation}
                  />

                  <Route path={`${path}/schedules`} component={Schedule} />

                  <Route exact path={`${path}/branding`} component={Branding} />
                  <Route
                    exact
                    path={`${path}/settings/:active_panel?`}
                    component={Settings}
                  />

                  <Route
                    path={`${path}/visit-portal`}
                    component={VisitPortal}
                  />
                  <Route
                    path={`${path}/overview`}
                    render={(props) => (
                      <>
                        <Overview {...props} />
                        <StudyStatusFlowModal {...props} />
                      </>
                    )}
                  />
                  <Route
                    path={`${path}/review/:active_panel/:tile_id`}
                    component={Review}
                  />
                  <Route
                    path={`${path}/review/:active_panel`}
                    component={Review}
                  />
                  <Route path={`${path}/permissions`} component={Permissions} />
                  <Route
                    path={`${path}/permissions_new`}
                    component={PermissionsNew}
                  />
                  <Route
                    path={`${path}/event_delays`}
                    component={EventDelays}
                  />
                  <Route
                    path={`${path}/participants`}
                    component={(props) => {
                      const {
                        match: { path },
                      } = props;

                      return (
                        <ParticipantsContainer {...props}>
                          <Switch>
                            <Route exact path={path} component={Participants} />
                            <Route
                              path={`${path}/search`}
                              component={(props) => (
                                <Results {...props} participantSearch />
                              )}
                            />
                            <Route
                              path={`${path}/add`}
                              component={AddEditFilter}
                            />
                            <Route
                              exact
                              path={`${path}/edit/:cohort_identifier`}
                              component={AddEditFilter}
                            />
                            <Route
                              exact
                              path={`${path}/results/:cohort_identifier`}
                              component={Results}
                            />
                            <Route
                              exact
                              path={`${path}/results/:cohort_identifier/full_screen`}
                              component={ResultsFullScreen}
                            />
                            <Route
                              path={`${path}/:identifier`}
                              component={Participant}
                            />
                          </Switch>
                        </ParticipantsContainer>
                      );
                    }}
                  />
                  <Route
                    path={`${path}/upload_review`}
                    component={UploadReview}
                  />
                  <Route path={`${path}/icf_review`} component={ICFReview} />
                </Switch>
              </StudyLayout>
            );
          }}
        />

        <ModalContainer />
        <Route exact path="/" component={() => <Redirect to="/studies" />} />
        <ReactInterval
          timeout={KEEPALIVE_INTERVAL}
          enabled={true}
          callback={keepalive}
        />
      </React.Fragment>
    </Router>
  );
}

AppContainer.propTypes = {
  children: PropTypes.element,
};

export default connect(null, {
  assignAuthorization,
  loginUser,
  clearAllGraphData,
})(AppContainer);
