import { AssignExternalIdTileSettings } from '../edit/forms/assign_external_id/settings';
import { CastorEdcTileSettings } from '../edit/forms/castor_edc/settings';
import { CastorEproSurveyTileSettings } from '../edit/forms/castor_epro_survey/settings';
import { CommunicationsTileSettings } from '../edit/forms/communications/settings';
import { ConnectAppTileSettings } from '../edit/forms/connect_app/Forms/Settings';
import { ConnectCamcogTileSettings } from '../edit/forms/camcog/settings';
import { ConnectEHRTileSettings } from '../edit/forms/connect_ehr/settings';
import { ConnectExternalTileSettings } from '../edit/forms/connect_external/settings';
import { DelayTileSettings } from '../edit/forms/delay/settings';
import { DisconnectAppTileSettings } from '../edit/forms/disconnect_app/settings';
import { EducationTileSettings } from '../edit/forms/education/settings';
import { EmailMessageTileSettings } from '../edit/forms/email_message/settings';
import { EventDelayTileSettings } from '../edit/forms/event_delay/settings';
import { EvaluateCapTileSettings } from '../edit/forms/evaluate_cap/settings';
import { ExperianValidatorTileSettings } from '../edit/forms/experian_validator/settings';
import { FollowUpSurveyTileSettings } from '../edit/forms/follow_up_survey/settings';
import { HibbertShippingTileSettings } from '../edit/forms/hibbert_shipping/settings';
import { ICFTileSettings } from '../edit/forms/informed_consent/settings';
import { MilestoneTileSettings } from '../edit/forms/milestone/settings';
import { MtlKitOrderTileSettings } from '../edit/forms/mtl_kit_order/settings';
import { MultiFactorAuthenticateTileSettings } from '../edit/forms/multi_factor_authenticate/settings';
import { QualtricsSurveyTileSettings } from '../edit/forms/qualtrics_survey/settings';
import { RegistrationTileSettings } from '../edit/forms/registration/settings';
import { ReminderTileSettings } from '../edit/forms/reminder/settings';
import { SMSMessageTileSettings } from '../edit/forms/sms_message/settings';
import { TakeSurveyTileSettings } from '../edit/forms/take_survey/settings';
import { TerminalStateTileSettings } from '../edit/forms/terminal_state/settings';
import { VerifyEmailTileSettings } from '../edit/forms/verify_email/settings';

export const pagedEditInterface = [
  'follow_up_survey',
  'informed_consent',
  'survey',
  'visit_portal',
];
export const tabHasPagedEditInterface = ['components'];
export const panelsWithFields = ['components'];
export const edit_field_name = 'other_edit_field';
export const requiresContentTab = [
  'assign_external_id',
  'castor_edc',
  'castor_epro_survey',
  'camcog',
  'communications',
  'connect_camcog',
  'connect_app',
  'connect_ehr',
  'connect_external',
  'delay',
  'disconnect_app',
  'education',
  'email_message',
  'evaluate_cap',
  'event_delay',
  'experian_validator',
  'follow_up_survey',
  'hibbert_shipping',
  'informed_consent',
  'milestone',
  'mtl_kit_order',
  'multi_factor_authenticate',
  'qualtrics_survey',
  'registration',
  'reminder',
  'sms_message',
  'take_survey',
  'terminal_state',
  'verify_email',
];
export const requiresTileSettingsUpdate = [
  ...requiresContentTab,
  'disconnect_app',
  'evaluate_cap',
  'mtl_kit_order',
  'screener',
];

/**
 * @description Builds content level schemas for Tiles in a new tab in the tile editing tool
 */
export const contentTabSchema = ({
  currentTileType,
  displayGroupNames,
  activeExperience,
  dataForTile,
  tileId,
}) => ({
  assign_external_id: () => [
    AssignExternalIdTileSettings({
      experience: 'default',
      masonryEnabled: true,
    }),
  ],
  castor_edc: () => [
    CastorEdcTileSettings(
      'default',
      displayGroupNames,
      true
    ),
  ],
  castor_epro_survey: () => [
    CastorEproSurveyTileSettings({
      experience: 'default',
      displayGroupNames,
      masonryEnabled: true
    }),
  ],
  communications: () => [
    CommunicationsTileSettings({
      experience: 'default',
      displayGroupNames,
      id: tileId,
      masonryEnabled: true,
    }),
  ],
  connect_app: () => [[ConnectAppTileSettings(displayGroupNames, true)]],
  connect_camcog: () => [
    ConnectCamcogTileSettings({
      experience: 'default',
      displayGroupNames,
      masonryEnabled: true,
    }),
  ],
  connect_ehr: () => [
    [ConnectEHRTileSettings('default', displayGroupNames, true)],
  ],
  connect_external: () => [
    [ConnectExternalTileSettings(activeExperience, displayGroupNames, true)],
  ],
  delay: () => [
    [
      DelayTileSettings({
        experience: activeExperience,
        masonryEnabled: true,
      }),
    ],
  ],
  disconnect_app: () => [
    DisconnectAppTileSettings({
      experience: 'default',
      masonryEnabled: true,
    }),
  ],
  education: () => [[EducationTileSettings(activeExperience, true)]],
  email_message: () => [
    [
      EmailMessageTileSettings(
        activeExperience,
        displayGroupNames,
        tileId,
        true,
      ),
    ],
  ],
  evaluate_cap: () => [
    EvaluateCapTileSettings({
      experience: 'default',
      masonryEnabled: true,
    }),
  ],
  event_delay: () => [
    [
      EventDelayTileSettings(
        currentTileType,
        activeExperience,
        displayGroupNames,
        true,
      ),
    ],
  ],
  experian_validator: () => [
    [ExperianValidatorTileSettings(activeExperience, displayGroupNames, true)],
  ],
  follow_up_survey: () => [
    [FollowUpSurveyTileSettings(activeExperience, true)],
  ],
  hibbert_shipping: () => [
    [HibbertShippingTileSettings('default', true)],
  ],
  informed_consent: () => [
    [
      ICFTileSettings(
        activeExperience,
        displayGroupNames,
        dataForTile?.informed_consent.pageList.length,
        true,
      ),
    ],
  ],
  milestone: () => [
    [MilestoneTileSettings(activeExperience, displayGroupNames, true)],
  ],
  mtl_kit_order: () => [
    MtlKitOrderTileSettings({
      experience: 'default',
      masonryEnabled: true,
    }),
  ],
  multi_factor_authenticate: () => [
    MultiFactorAuthenticateTileSettings({
      experience: 'default',
      masonryEnabled: true,
    }),
  ],
  qualtrics_survey: () => [
    [QualtricsSurveyTileSettings(activeExperience, displayGroupNames, true)],
  ],
  registration: () => [[RegistrationTileSettings(activeExperience, true)]],
  reminder: () => [
    ReminderTileSettings({
      experience: 'default',
      displayGroupNames,
      id: tileId,
      masonryEnabled: true,
    }),
  ],
  sms_message: () => [
    [SMSMessageTileSettings(activeExperience, displayGroupNames, true)],
  ],
  take_survey: () => [
    [TakeSurveyTileSettings(activeExperience, displayGroupNames, true)],
  ],
  terminal_state: () => [[TerminalStateTileSettings(activeExperience, true)]],
  verify_email: () => [[VerifyEmailTileSettings(activeExperience, true)]],
});
