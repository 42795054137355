import HorizontalWrapper from './horizontalWrapper';
import React from 'react';
import Toggle from './rawtoggle';

const HorizontalToggle = props => (
  <HorizontalWrapper {...props} fieldType="horizontalToggle" inlineLabel={true}>
    <Toggle {...props} />
  </HorizontalWrapper>
);

export default HorizontalToggle;
