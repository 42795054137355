import React from 'react';

const NavSettings = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_settings</title>
    <g
      transform="translate(3 2)"
      stroke={props.color}
      strokeWidth={1.2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={9.5} cy={9.437} r={3.167} />
      <path d="M16.467 11.02L19 10.703V8.17l-2.533-.317c-.19-.823-.507-1.52-.95-2.216l1.52-2.027-1.774-1.773-2.026 1.583a8.113 8.113 0 0 0-2.154-.887L10.767 0H8.233l-.316 2.533c-.824.19-1.52.507-2.217.95L3.673 1.9 1.9 3.673 3.483 5.7c-.443.697-.76 1.393-.95 2.217L0 8.233v2.534l2.533.316c.19.824.507 1.52.95 2.217l-1.52 2.09 1.774 1.773 2.026-1.52c.634.444 1.394.697 2.154.887l.316 2.533h2.534l.316-2.533a9.54 9.54 0 0 0 2.217-.887l2.09 1.52 1.773-1.773-1.52-2.027c.317-.823.634-1.52.824-2.343z" />
    </g>
  </svg>
);

export default NavSettings;
