"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.globalInit = exports.availableLanguages = exports.curiousPandaNamespace = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.object.keys.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _logger = require("@evidation/logger");

var _testingUtils = require("@evidation/testing-utils");

var _i18next = _interopRequireDefault(require("i18next"));

var _reactI18next = require("react-i18next");

var _i18n = require("../i18n");

var _testing = _interopRequireDefault(require("../i18n/testing"));

var defaultLanguage = 'en_US';
var curiousPandaNamespace = 'curiousPanda';
exports.curiousPandaNamespace = curiousPandaNamespace;
var availableLanguages = _testingUtils.isTestingEnvironment ? (0, _defineProperty2["default"])({}, defaultLanguage, {
  curiousPanda: _testing["default"]
}) : {
  en: {
    curiousPanda: _i18n.enUS
  },
  en_US: {
    curiousPanda: _i18n.enUS
  },
  en_GB: {
    curiousPanda: _i18n.enUS
  },
  es: {
    curiousPanda: _i18n.esUS
  },
  es_US: {
    curiousPanda: _i18n.esUS
  }
}; //This instantiates a global instance of i18next and react-i18next

exports.availableLanguages = availableLanguages;

var globalInit = function globalInit() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  // Wrapped in a try/catch because this errors out during testing
  // during normal usage it is not expected to error like this.
  try {
    _i18next["default"].use(_reactI18next.initReactI18next).init({
      resources: availableLanguages,
      lng: locale,
      defaultNS: curiousPandaNamespace,
      fallbackLng: defaultLanguage,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      },
      saveMissing: true,
      missingKeyHandler: function missingKeyHandler(langs, ns, key, fallbackValue) {
        var error = new Error("Unable to find ".concat(defaultLanguage, " translation for key '").concat(key, "'."));

        if (_testingUtils.isTestingEnvironment) {
          throw error;
        }

        (0, _logger.logError)(error);
      }
    });
  } catch (error) {
    (0, _logger.logError)(error);
  }
};

exports.globalInit = globalInit;

var CuriousPandaLanguageProvider = function CuriousPandaLanguageProvider(_ref2) {
  var children = _ref2.children,
      locale = _ref2.locale;
  var language = _i18next["default"].language; // If there is a global instance of i18next running append a new namespace for the translations.

  if (language && _i18next["default"].isInitialized) {
    // Check to see if language is available in defined languages
    var isAvailable = Object.keys(availableLanguages).includes(language);
    var langResource = isAvailable ? availableLanguages[language].curiousPanda : availableLanguages[defaultLanguage].curiousPanda; // Append language resources to curiousPanda namespace for a specific language.

    _i18next["default"].addResourceBundle(language, curiousPandaNamespace, langResource, false, false);
  } else {
    // This is used to develop when there is no global instance like when using Storybook or
    // when testing using jest.
    globalInit(locale);
  }

  return children;
};

var _default = CuriousPandaLanguageProvider;
exports["default"] = _default;