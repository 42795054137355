import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="nonzero" stroke="#000" fillOpacity="0">
        <path d="M10.91 18.312L6.945 12.5h7.926l-3.963 5.812zM10.91 2.888L6.945 8.7h7.926L10.91 2.888z" />
      </g>
    </svg>
  );
}
