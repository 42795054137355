import types from '../constants';

export const addPublishErrors = (payload: any) => ({
  type: types.publish_errors.add,
  payload,
});
export const removePublishErrors = (payload: any) => ({
  type: types.publish_errors.remove,
  payload,
});
export const clearPublishErrors = () => ({
  type: types.publish_errors.clear,
});
