import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <rect
          stroke="#000"
          fill="none"
          x=".5"
          y=".5"
          width="19"
          height="19"
          rx="2"
        />
        <path
          fill="#000"
          d="M1.818 8.182h11.613v.909H1.818zM1.818 13.636h11.613v.91H1.818zM1.818 5.455h15.39v.909H1.819zM1.818 10.91h15.39v.908H1.819zM1.818 16.364h15.39v.909H1.819zM1.818 2.727h11.613v.91H1.818z"
        />
      </g>
    </svg>
  );
}
