//@ts-nocheck
import _ from 'lodash';
import { memoize } from 'reselectie';

const selectedResourceSelector = (state) => state.slate.selected_resources;

const getSelected = (resources, type) =>
  _.reduce(
    resources,
    (acc, resource) => (resource.resource === type ? [...acc, resource] : acc),
    [],
  );

export const selectedTilesSelector = memoize(
  selectedResourceSelector,
  (resources) => getSelected(resources, `tile`),
);
export const selectedRemindersSelector = memoize(
  selectedResourceSelector,
  (resources) => getSelected(resources, `reminder`),
);
export const selectedSequencesSelector = memoize(
  selectedResourceSelector,
  (resources) => getSelected(resources, `sequence`),
);
