import { Webhook } from '../../api/webhooks';
import { WebhookFormValues } from './WebhookForm';

export const JSON_PARSE_FAIL_MSG =
  'Unable to parse JSON body, please make sure the JSON is correct';

export function transformIncoming(webhook: Webhook) {
  let payload: any = {
    content: {
      http_headers: [],
      body: '',
      on_webhook_success_event_status: '',
      on_webhook_success_event_name: '',
      on_webhook_failure_event_name: '',
      on_webhook_failure_event_status: '',
    },
  };
  payload.content = { ...webhook };
  payload.content.body = JSON.stringify(webhook.body || {});
  const headers =
    typeof webhook.http_headers === 'string'
      ? JSON.parse(webhook.http_headers)
      : webhook.http_headers;
  payload.content.http_headers = Object.keys(headers).map((key: string) => ({
    key,
    value: headers[key],
  }));
  if (webhook.on_webhook_success_event) {
    const index = webhook.on_webhook_success_event.lastIndexOf('_');
    const name = webhook.on_webhook_success_event.slice(0, index);
    const status = webhook.on_webhook_success_event.slice(index + 1);
    payload.content.on_webhook_success_event_name = name;
    payload.content.on_webhook_success_event_status = status;
  }
  if (webhook.on_webhook_failure_event) {
    const index = webhook.on_webhook_failure_event.lastIndexOf('_');
    const name = webhook.on_webhook_failure_event.slice(0, index);
    const status = webhook.on_webhook_failure_event.slice(index + 1);
    payload.content.on_webhook_failure_event_name = name;
    payload.content.on_webhook_failure_event_status = status;
  }
  const result: WebhookFormValues = payload;
  return result;
}

type TransformOutGoingResult = {
  payload: Omit<Webhook, 'id' | 'graph_id'>;
  succeeded: boolean;
  error: string;
};

export function transformOutgoing(
  data: WebhookFormValues,
): TransformOutGoingResult {
  const { content } = data;
  let result = { payload: null, succeeded: false, error: '' };
  let payload: any = { http_headers: {}, ...content };
  // convert the combination of event name and status dropdown
  if (
    content.on_webhook_success_event_name &&
    content.on_webhook_success_event_status
  ) {
    delete payload.on_webhook_success_event_status;
    delete payload?.on_webhook_success_event_name;
    payload.on_webhook_success_event = `${content.on_webhook_success_event_name}_${content.on_webhook_success_event_status}`;
  }
  if (
    content.on_webhook_failure_event_name &&
    content.on_webhook_failure_event_status
  ) {
    delete payload.on_webhook_failure_event_status;
    delete payload?.on_webhook_failure_event_name;
    payload.on_webhook_failure_event = `${content.on_webhook_failure_event_name}_${content.on_webhook_failure_event_status}`;
  }
  // http_headers is required by the api so we set an
  // empty object here to satisfy that requirement
  // will be overwritten below if the user supplies http_headers
  if (Array.isArray(content.http_headers)) {
    let headers: Record<string, any> = {};
    content.http_headers.forEach(({ key, value }: any) => {
      headers[key] = value;
    });
    payload.http_headers = headers;
  }
  if (content?.body) {
    try {
      payload.body = JSON.parse(content.body);
      result.succeeded = true;
    } catch (err) {
      result.error = JSON_PARSE_FAIL_MSG;
    }
  } else {
    result.succeeded = true;
    payload.body = {};
  }
  return { ...result, payload };
}
