import React from 'react';

export default function (props) {
  return (
    <svg
      width="22"
      height="22"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 32 32"
      viewBox="0 0 32 32"
      {...props}
    >
      <circle
        cx="23"
        cy="7"
        r="3"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="3"
        x2="20"
        y1="7"
        y2="7"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="29"
        x2="26"
        y1="7"
        y2="7"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="16"
        r="3"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="3"
        x2="9"
        y1="16"
        y2="16"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="29"
        x2="15"
        y1="16"
        y2="16"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="23"
        cy="25"
        r="3"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="3"
        x2="20"
        y1="25"
        y2="25"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        x1="29"
        x2="26"
        y1="25"
        y2="25"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
}
