import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import format from 'date-fns/format';
import { TextareaAutosize } from '@evidation/form-elements';
import { toastr } from 'react-redux-toastr';

import { Application } from '../../../components/StudyLayout';
import TileWrapper from './tile-wrapper';

import { generateAuditTrail, getAuditTrail } from '../../../api/auditTrails';
import { userHasValidRole } from '../forms/common_validations';
import useWorkerStatus from '../../../hooks/useWorkerStatus';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ErrorPage from '../../../components/ErrorPage';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const text = {
  exportCSV: 'Export CSV',
  download: 'Download',
  errorMsg: 'Something went wrong while fetching worker status',
  taskFailed: (graphId) => `The exporting task on graph ${graphId} has failed`,
  problem: (graphId) => `There was a problem exporting graph ${graphId}`,
  loading: 'Loading',
  validLink: 'The Download Link is valid for 5 minutes.',
};

const ExportContainer = styled.div`
  grid-column-start: content;
  grid-column-end: settings;
`;

const ContentCentered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
`;

const ExportAuditTrail = (props) => {
  const location = useLocation();
  const [userIsValid, setUserIsValid] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [pageState, setPageState] = useState({
    loadingState: 'loading',
  });
  const workerData = useWorkerStatus({
    jobId,
    onError: (error) => {
      if (pageState.loadingState !== 'error') {
        toastr.error(text.errorMsg);
        setPageState({ loadingState: 'error', error: error });
      }
    },
    onJobFinished: () => {
      setJobId(null);
    },
  });

  const {
    graph: {
      id: graphId,
      content: { slug },
    },
    user,
    history,
  } = props;

  useEffect(() => {
    if (user && graphId) {
      // The export audit link should be visible to Owners and Publisher roles only.
      const isValid = userHasValidRole(['owner', 'publisher'], user, graphId);

      if (!isValid) {
        history.replace('/studies');
      }

      setUserIsValid(isValid);
    }
  }, [graphId, history, user]);

  useEffect(() => {
    if (userIsValid && graphId) {
      const {
        state: { exportOptions },
      } = location;
      generateAuditTrail(graphId, exportOptions)
        .then(({ data }) => {
          const { job_id } = data;

          setJobId(job_id);
        })
        .catch((error) => {
          setPageState({ loadingState: 'error', error: error });
        });
    }
  }, [graphId, location, slug, userIsValid]);

  const filePreview = useCallback(
    async (downloadUrl) => {
      try {
        const { data } = await axios.get(downloadUrl);
        if (data) {
          const date = format(new Date(), 'ddMMyyyy_HHmmss');
          setPageState({
            value: data,
            buttonProps: {
              children: text.download,
              download: `${slug}_${date}.csv`,
              href: downloadUrl,
            },
            loadingState: 'loaded',
          });
        } else {
          toastr.error(text.problem(graphId));
          setPageState({ loadingState: 'error' });
        }
      } catch (error) {
        setPageState({ loadingState: 'error', error });
        toastr.error(text.problem(graphId));
      }
    },
    [graphId, slug],
  );

  useEffect(() => {
    if (workerData?.status === 'failed') {
      toastr.error(text.taskFailed(graphId));
      setPageState({ loadingState: 'error' });
    } else if (workerData?.status === 'complete') {
      getAuditTrail(graphId, workerData?.metadata.audit_trail_id)
        .then(({ data }) => {
          const downloadUrl = data?.download_url;
          if (downloadUrl) {
            filePreview(downloadUrl);
          } else {
            toastr.error(text.problem(graphId));
            setPageState({ loadingState: 'error' });
          }
        })
        .catch((error) => {
          setPageState({ loadingState: 'error', error: error });
          toastr.error(text.problem(graphId));
        });
    }
  }, [graphId, workerData, slug, filePreview]);

  return (
    <Application.Content>
      <ExportContainer>
        {pageState.loadingState === 'loading' ? (
          <ContentCentered>
            <LoadingIndicator />
          </ContentCentered>
        ) : pageState.loadingState === 'error' ? (
          <ErrorPage title={text.problem(graphId)} error={pageState.error} />
        ) : (
          <TileWrapper
            {...props}
            buttonProps={pageState.buttonProps}
            heading={text.exportCSV}
          >
            <Label>
              <span role="img" aria-label="warning icon" className="mr10">
                ⚠️️
              </span>
              {text.validLink}
            </Label>
            <TextareaAutosize
              inputStyle="achievement"
              value={pageState.value}
            />
          </TileWrapper>
        )}
      </ExportContainer>
    </Application.Content>
  );
};

export default connect(
  ({ graph, user }) => ({ graph, user }),
  {},
)(ExportAuditTrail);
