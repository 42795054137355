import { default_experience_key } from '../../../../constants';
import * as C from '../common';
import { getInputsForExperience } from '../utils';

export const MtlKitOrderTileSettings = ({
  experience,
  masonryEnabled = false
}) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        cancellation: true,
        completion: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    mtl_kit_order: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
  ? `content.experiences.${experience}.settings`
  : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle,
        C.experienceExternalDescription,
      ],
    },
    {
      label: 'Shipping',
      name: experienceName,
      input: [
        {
          label: 'Panels',
          name: 'panels',
          interface: 'checkbox',
          required: true,
          possible_answers: [
            {
              label: 'Gonorrhea',
              value: 'GC',
            },
            {
              label: 'Chlamydia',
              value: 'CT',
            },
            {
              label: 'Hep B (DBS), Creatinine (DBS), HIV',
              value: 'Hep B (DBS), Creatinine (DBS), HIV',
            },
            {
              label: 'Creatining (DBS), HIV',
              value: 'Creatining (DBS), HIV',
            },
            {
              label: 'Cardiovascular',
              value: 'CARDIOVASCULAR',
            },
            {
              label: 'Fitness DNA',
              value: 'FITNESS DNA',
            },
            {
              label: 'CGx Base',
              value: 'CGX BASE',
            },
            {
              label: 'Flu',
              value: 'FLU',
            },
            {
              label: 'HbA1c',
              value: 'HbA1c',
            },
          ],
        },
        {
          label: 'Shipping Method',
          name: 'shipping_method',
          interface: 'select',
          required: true,
          omitPlaceholder: true,
          options: [
            {
              label: 'Standard',
              value: 'standard',
            },
            {
              label: 'Express',
              value: 'express',
            },
            {
              label: 'USPS First Class',
              value: 'usps first class',
            },
            {
              label: 'FedEx Standard Overnight',
              value: 'fedex standard overnight',
            },
          ],
        },
        {
          label: 'Physician ID',
          name: 'client_physician_id',
          interface: 'textinput',
          required: true,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(MtlKitOrderTileSettings(experience, masonryEnabled));
  }

  return schema;
};
