import { Button } from '@evidation/form-elements';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import onAddHOC from '../hoc/onAddHOC';

const duplicateContent = content =>
  _.merge({}, content, { title: `Duplicate: ${content.title}` });

export const generatePayload = ({ content, delay }) => ({
  resource: `reminder`,
  content: duplicateContent(content),
  delay: delay ? { content: duplicateContent(delay) } : undefined,
});

export const DuplicateReminder = ({
  reminders,
  related_reminders,
  onAdd,
  selected_reminder: { uuid, sequence_uuid, reminder_uuid },
}) => {
  const delay_node = reminders[related_reminders[reminder_uuid]];
  // default delay content will be created when duplicating a reminder
  const delay = _.isUndefined(delay_node) ? '' : delay_node.content;

  return (
    <Button
      id="t-duplicate-reminder"
      onClick={() => {
        onAdd(
          generatePayload({
            content: reminders[reminder_uuid].content,
            delay,
          }),
          sequence_uuid,
          uuid,
        );
      }}
    >
      Duplicate
    </Button>
  );
};

export default connect(
  ({ reminders, related_reminders }) => ({ reminders, related_reminders }),
  null,
)(onAddHOC(DuplicateReminder));
