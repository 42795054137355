import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const MultiFactorAuthenticateTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        visibility: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  dataForTile
}) => {
  const {
    multi_factor_authenticate: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          name: 'mfa_phone_input_label',
          label: 'Phone number input label',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          name: 'mfa_phone_input_description',
          label: 'Phone number input description (optional)',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
        {
          name: 'mfa_token_input_label',
          label: 'MFA token input label',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          name: 'mfa_token_input_description',
          label: 'MFA token input description (optional)',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(MultiFactorAuthenticateTileSettings(experience));
  }

  return schema;
};
