import React from 'react';

export default props => {
  let color = '#000000';

  if (props && props.color) {
    color = props.color;
  }
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g transform="translate(5.000000, 3.000000)">
          <g transform="translate(3.000000, 5.000000)" fill={color}>
            <path d="M4.763 9.578a.287.287 0 0 1-.298.301H2.68a.288.288 0 0 1-.297-.3V7.77c0-.088.027-.16.083-.216a.289.289 0 0 1 .214-.085h1.786a.288.288 0 0 1 .298.301v1.807zm2.265-5.563a2.498 2.498 0 0 1-.26.57 2.174 2.174 0 0 1-.415.452c-.18.153-.322.263-.428.33-.105.065-.257.155-.455.267-.205.12-.374.275-.507.466-.133.192-.2.347-.2.466a.287.287 0 0 1-.298.301H2.68a.288.288 0 0 1-.297-.3v-.34c0-.42.162-.814.488-1.181a3.19 3.19 0 0 1 1.075-.814c.297-.138.508-.28.632-.424.124-.144.186-.336.186-.574 0-.207-.116-.392-.349-.556a1.35 1.35 0 0 0-.795-.244c-.323 0-.59.075-.8.226-.205.15-.471.439-.8.866a.273.273 0 0 1-.233.113.26.26 0 0 1-.176-.057l-1.228-.94c-.143-.114-.168-.246-.075-.396C1.101.909 2.251.24 3.758.24A3.5 3.5 0 0 1 5.34.627c.515.257.944.621 1.288 1.092a2.547 2.547 0 0 1 .4 2.296z" />
          </g>
          <polygon
            stroke={color}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="10.5833 1.28787879 13.9999364 1.28787879 13.9999364 18.2878788 -6.36363636e-05 18.2878788 -6.36363636e-05 1.28787879 3.41657273 1.28787879"
          />
          <path
            d="M10.182 0v1.288c0 .708-.573 1.288-1.273 1.288H5.091c-.7 0-1.273-.58-1.273-1.288V0m0 0h6.364"
            stroke={color}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
