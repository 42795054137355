import React, { useMemo } from 'react';
import CuriousPanda from '../../../../../../components/fields/CuriousPanda';
import { default_experience_key } from '../../../../../../constants';
import { noop } from '../../../../../../utils/noop';
import * as C from '../../../common';

export default function ConnectAppContentForm({
  initialValues,
  onChange,
  experience = default_experience_key,
  masonryEnabled = false,
}) {
  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = useMemo(() => {
    return [
      {
        label: 'Content',
        name: experienceName,
        input: [
          C.experienceExternalTitle(masonryEnabled),
          C.description,
          C.completion_message,
          C.action_title,
          C.tooltip,
        ],
      },
    ];
  }, [experienceName, masonryEnabled]);
  return (
    <CuriousPanda
      schema={schema}
      initialValues={initialValues}
      enableReinitialize
      form="connect_app_content_form"
      onChange={onChange}
      onSubmit={noop}
    >
      {(p) => <div />}
    </CuriousPanda>
  );
}
