import { AnyAction } from 'redux';
import types from '../constants';

export const defaultState = {
  is_publishing: false,
};

export default (state = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.publish.set_publishing:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
