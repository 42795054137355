export default {
  description: (
    <>
      <p>
        This status indicates that the study data collection is complete and no
        further data collection will take place. When any role attempts to make
        changes via Study Manager, they are denied and an error message is
        presented. This status cancels any outstanding contributions for all
        participants, disconnects any apps/devices still connected for any
        participants and transitions participants' current status based on the
        following:
      </p>
      <ul>
        <li>From Enrolled {'->'} Early Termination</li>
        <ul>
          <li>Reason for Early Termination = TBD</li>
        </ul>
        <li>Other statuses are not altered</li>
      </ul>
      <p>
        Billboard is accessible to any participants who have registered for the
        study (have an account). They can log in, see their dashboard with
        compensation so far and are allowed to change any study settings, along
        with downloading their informed consent PDF. Configuration changes are
        no longer permitted for any role. No CRUD operations on study data are
        permitted; all study jobs stop processing.
      </p>
    </>
  ),
  arrow: (
    <ul>
      <li>
        Auth tokens are expired for all enrollments in a study, all participants
        will not be able to log back in
      </li>
      <li>
        Any active connections to Flow for app data (e.g. Fitbit data) will be
        disconnected.
      </li>
      <li>Ordinal events will not be generated</li>
    </ul>
  ),
};
