import _ from 'lodash';

export const createMapping = (reminders = {}, reminder_ids = []) => {
  let output = {};
  let index = 0;

  for (; index < reminder_ids.length; ) {
    const identifier = reminder_ids[index];

    // expects delays before email reminder
    if (_.get(reminders, `${identifier}.content.type`) === `delay`) {
      const nextOne = reminder_ids[index + 1];

      // if there's a consecutive reminder, create mapping of next reminder => delay
      if (nextOne) {
        output[nextOne] = identifier;
        index++;
      }
    } else {
      // if there's no delay following, set delay => false
      output[identifier] = false;
    }

    index++;
  }

  return output;
};

export default (reminders = {}, tiles) =>
  _.reduce(
    tiles,
    (acc, tile) =>
      _.has(tile, `reminder_ids`)
        ? { ...acc, ...createMapping(reminders, tile.reminder_ids) }
        : acc,
    {},
  );
