import Schemas from '../routes/edit/forms';
import types from '../constants';
import { AnyAction, Dispatch } from 'redux';

export const setGraph = (payload: any) => ({ type: types.graph.set, payload });
export const clearGraph = () => ({ type: types.graph.clear });
export const updateGraph = (payload: any) => ({
  type: types.graph.update,
  payload,
});

export const modifyGraphAtPath =
  (payload: any) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: types.graph.modifyAtPath, payload });
    dispatch({ type: types.graph.persist });
  };

export const setGraphPersist =
  (payload: any, success_message = `You have successfully edited the graph.`) =>
  (dispatch: Dispatch<AnyAction>) => {
    const graph = Schemas[`graph`].outgoing(payload);
    dispatch(setGraph(graph));
    dispatch({ type: types.graph.persist, success_message });
  };

export const setCreating = (
  payload: null | {
    job_id: string;
    title: string;
  },
) => ({
  type: types.graph.set_creating,
  payload: {
    creating_job: payload,
  },
});
