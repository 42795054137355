import React from 'react';

export default props => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="icons/essentials/webpage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M19.21875,19.875 C19.21875,20.1849375 19.0021875,20.4375 18.7375,20.4375 L5.2625,20.4375 C4.9978125,20.4375 4.78125,20.1849375 4.78125,19.875 L4.78125,4.125 C4.78125,3.8161875 4.9978125,3.5625 5.2625,3.5625 L14.3916287,3.5625 L13.2641602,9.12145996 C13.0417173,9.49645996 15.0265806,9.30444336 19.21875,8.54541016 L19.21875,19.875 Z"
          id="Stroke-1"
          stroke="#000000"
        />
        <path d="M19.2,8.5 L14.5,3.6" id="Stroke-5-Copy" stroke="#000000" />
      </g>
    </svg>
  );
};
