import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M20.97 18.306a2.543 2.543 0 0 1-2.535 2.535H3.565a2.543 2.543 0 0 1-2.534-2.535V5.636a2.543 2.543 0 0 1 2.534-2.534h3.507V1.515c0-.477.723-.477.723 0v1.587h6.409V1.515c0-.477.723-.477.723 0v1.587h3.507a2.545 2.545 0 0 1 2.535 2.535v12.67zM20.244 7.62H1.754v10.687c0 .996.815 1.81 1.81 1.81h14.87c.495 0 .95-.204 1.278-.532.328-.328.532-.783.532-1.278V7.62h.001zM1.754 6.893h18.491V5.637c0-.996-.815-1.81-1.81-1.81h-3.507v.14c0 .476-.723.476-.723 0v-.14H7.796v.14c0 .476-.723.476-.723 0v-.14H3.566c-.995 0-1.81.812-1.81 1.81v1.256h-.002z"
          fillRule="nonzero"
        />
        <path d="M7.347 11.862h.583c-.075.201-.24.66-.495 1.377-.192.54-.352.979-.48 1.319-.306.8-.52 1.289-.645 1.464-.125.176-.34.264-.645.264-.074 0-.131-.003-.171-.009a1.354 1.354 0 0 1-.148-.032v-.48c.092.025.158.04.199.047a.778.778 0 0 0 .108.008c.098 0 .17-.016.216-.048a.446.446 0 0 0 .115-.119 14.899 14.899 0 0 0 .237-.563l-1.16-3.228h.598l.841 2.555.847-2.555zm2.311-.07c.223 0 .439.052.648.157.209.104.368.24.477.405a1.4 1.4 0 0 1 .211.554c.032.145.047.375.047.692h-2.3c.01.318.085.573.226.766.14.192.358.288.653.288.276 0 .495-.09.66-.272a.918.918 0 0 0 .199-.366h.518c-.013.115-.059.243-.136.385a1.37 1.37 0 0 1-.26.347c-.16.156-.358.262-.594.316-.127.032-.27.047-.43.047-.391 0-.722-.142-.994-.426-.271-.284-.407-.682-.407-1.194 0-.504.137-.913.41-1.227.274-.315.631-.472 1.072-.472zm.841 1.389a1.366 1.366 0 0 0-.15-.548c-.144-.254-.385-.381-.723-.381a.802.802 0 0 0-.61.262.99.99 0 0 0-.26.667h1.743zm1.559.984c0 .152.056.272.167.36a.62.62 0 0 0 .395.132c.186 0 .366-.043.54-.129a.725.725 0 0 0 .439-.7v-.425a.902.902 0 0 1-.25.103 2.122 2.122 0 0 1-.298.058l-.32.041a1.221 1.221 0 0 0-.43.12c-.162.092-.243.239-.243.44zm1.277-1.066c.121-.016.202-.067.243-.153a.464.464 0 0 0 .036-.202c0-.18-.064-.31-.192-.391-.128-.081-.311-.122-.55-.122-.275 0-.47.075-.586.223a.733.733 0 0 0-.126.366h-.492c.01-.386.136-.655.377-.807.241-.151.52-.227.84-.227.368 0 .668.07.899.211.228.14.342.36.342.656v1.808c0 .055.012.099.034.132.022.033.07.05.142.05a.802.802 0 0 0 .173-.018v.39a1.469 1.469 0 0 1-.188.044 1.37 1.37 0 0 1-.175.008c-.182 0-.314-.064-.396-.193a.746.746 0 0 1-.09-.29c-.108.14-.262.263-.464.366a1.432 1.432 0 0 1-.665.155c-.29 0-.528-.088-.713-.265a.88.88 0 0 1-.277-.663c0-.291.091-.517.273-.677.181-.16.42-.259.714-.296l.841-.105zm1.635-1.237h.501v.542c.041-.105.142-.234.302-.385a.782.782 0 0 1 .603-.224l.12.012v.556a.788.788 0 0 0-.172-.014c-.266 0-.47.085-.613.256a.895.895 0 0 0-.213.59V15h-.528v-3.138z" />
      </g>
    </svg>
  );
}
