"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DayPickerWrapper = exports.TimeWrapper = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var TimeWrapper = _styledComponents["default"].div.withConfig({
  displayName: "components__TimeWrapper"
})(["input{height:unset !important;padding:20px !important;border:1px solid #eff2f4 !important;background:#fcfcfc !important;line-height:normal !important;font-family:unset;color:#0c0c0c;::placeholder{color:#ccc;opacity:1;}}padding-left:", ";"], function (p) {
  return p.timeOnly ? "inherit" : "30px";
});

exports.TimeWrapper = TimeWrapper;

var DayPickerWrapper = _styledComponents["default"].div.withConfig({
  displayName: "components__DayPickerWrapper"
})(["input{font-family:unset;color:#0c0c0c;background:#fcfcfc;padding:20px;border:1px solid #eff2f4;border-radius:3px;font-size:1rem;line-height:normal;width:100%;::placeholder{color:#ccc;opacity:1;}:disabled{opacity:0.5;}}.DayPicker-Footer{display:flex;justify-content:center;}.DayPickerInput-OverlayWrapper{z-index:2;}"]);

exports.DayPickerWrapper = DayPickerWrapper;