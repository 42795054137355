import types, { omit_from_sequence_data } from '../constants';

import _ from 'lodash';
import { AnyAction } from 'redux';
import { Sequences } from '../redux/graph';

export const defaultState = {};

export default (
  state: Sequences = defaultState,
  { payload, type }: AnyAction,
) => {
  switch (type) {
    case types.sequences.set:
      return {
        ...payload,
      };

    case types.sequences.update:
      return {
        ...state,
        [payload.id]: {
          ..._.omit(payload, omit_from_sequence_data),
          selected: state[payload.id].selected,
        },
      };

    case types.sequences.delete:
      return {
        ..._.omit(state, payload),
      };

    case types.sequences.deleteIn:
      return {
        ...state,
        [payload.sequence_uuid]: {
          ...state[payload.sequence_uuid],
          tile_ids: _.pull(state[payload.sequence_uuid].tile_ids as any[], payload.uuid),
        },
      };

    case types.sequences.clear:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
};
