import React, { Component } from 'react';

import EvidationLogo from '../assets/EvidationLogo';
import AddSequence from '../routes/edit/components/addSequence';
import BranchTagsList from '../routes/edit/components/branchTags';
import ErrorsDropdown from '../routes/edit/components/errorsDropdown';
import { GLOBAL_HEADER_HEIGHT } from '../constants';
import HeaderDropdown from '../routes/edit/components/headerDropdown';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Toggle from './inputs/Toggle';
import UserAvatar from './UserAvatar';
import { AuthWrap } from './auth';
import { AuthActions } from '../constants';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CurrentLanguage from './currentLang';
import Notifications from './Notifications';

const HeaderText = styled.p`
  font-size: 24px;
  font-weight: 400;
  margin: 0;
`;

const SubheaderText = styled.p`
  font-size: 24px;
  margin: 0 0 0 1rem;
`;

export const StyledGlobalHeader = styled.header`
  align-content: stretch;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-wrap: nowrap;
  grid-area: header;
  justify-content: space-between;
  padding: ${(props) =>
    props.isSlate ? `1rem 0 1rem 1.75rem` : `1.3rem 0 1.3rem 1.75rem`};
  top: 0;
  width: 100%;
  z-index: 99999;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  position: ${(props) => (props.isSlate ? `relative` : `fixed`)};
  height: ${GLOBAL_HEADER_HEIGHT};
`;

const Subheader = styled.p`
  font-size: 24px;
`;

const TextFlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex: 4;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;

const NonactionTextFlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex: 4;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;

const LogoFlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

LogoFlexDiv.LogoSpacer = styled.span`
  margin-right: 1rem;
`;

const PlusFlexDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : `1em`)};
`;

export class GlobalHeader extends Component {
  state = {
    toggleEditValue: this.props.toggleEditValue,
  };

  renderSubheader = () => {
    if (!this.props.subheader) {
      return null;
    }
    return <Subheader>{this.props.subheader}</Subheader>;
  };

  renderToggle = () => {
    if (!this.props.canEdit) {
      return null;
    }

    return (
      <TextFlexDiv className="x-margin">
        <span>Edit Mode </span>
        <Toggle
          toggleFor="edit mode"
          onToggle={this.toggleEdit}
          toggleValue={this.state.toggleEditValue}
        />
      </TextFlexDiv>
    );
  };

  toggleEdit = () => {
    this.setState({ toggleEditValue: !this.state.toggleEditValue });

    this.props.toggleEdit();
  };
  render() {
    const {
      user,
      match,
      isSlate,
      isConfigure,
      disabled = false,
      async_operations,
      graph: { sequence_ids, content: { title } } = { content: { title: `` } },
    } = this.props;

    return (
      <StyledGlobalHeader
        disabled={disabled}
        className="env-border"
        isSlate={isSlate}
      >
        <LogoFlexDiv>
          <LogoFlexDiv.LogoSpacer>
            <NavLink to="/">
              <EvidationLogo width="120" height="23" />
            </NavLink>
          </LogoFlexDiv.LogoSpacer>
          {isSlate || isConfigure ? (
            <TextFlexDiv>
              <HeaderDropdown {...this.props} heading={title} match={match} />
              {!isConfigure && <HeaderDropdown {...this.props} />}
            </TextFlexDiv>
          ) : (
            <NonactionTextFlexDiv>
              {title && <HeaderText>{title}</HeaderText>}
              {_.isEmpty(match.params) && (
                <SubheaderText>{this.props.header}</SubheaderText>
              )}
            </NonactionTextFlexDiv>
          )}
        </LogoFlexDiv>

        {isSlate && (
          <React.Fragment>
            <TextFlexDiv>
              {!isConfigure && <BranchTagsList />}
              {this.renderToggle()}
            </TextFlexDiv>

            <PlusFlexDiv>
              <ErrorsDropdown />
            </PlusFlexDiv>

            {!isConfigure && (
              <PlusFlexDiv>
                <AuthWrap
                  requiredActions={[
                    AuthActions.sequences.create,
                    AuthActions.graphs.publish,
                  ]}
                >
                  <AddSequence target={_.last(sequence_ids)} match={match} />
                </AuthWrap>
              </PlusFlexDiv>
            )}
          </React.Fragment>
        )}

        <PlusFlexDiv>
          <CurrentLanguage />
        </PlusFlexDiv>

        <PlusFlexDiv>
          <Notifications jobs={async_operations.job_ids} />
          <div style={{marginLeft: '1rem'}} />
          <UserAvatar showEmail={true} user={user} />
        </PlusFlexDiv>
      </StyledGlobalHeader>
    );
  }
}

/*
canEdit: (boolean) determines whether edit toggle shows up
header: (string) main header text,
toggleEdit: (function) the function called when the edit toggle is changed
toggleEditValue: (boolean) the value of the edit toggle
user: (object) the current user. has name, email, and image_url fields
*/

GlobalHeader.defaultProps = {
  canEdit: false,
  header: 'Study Manager',
  isSlate: false,
  isConfigure: false,
  toggleEdit: () => {
    return;
  },
  toggleEditValue: false,
  user: {},
  has_publish_errors: false,
};

GlobalHeader.propTypes = {
  canEdit: PropTypes.bool,
  header: PropTypes.string,
  isSlate: PropTypes.bool,
  isConfigure: PropTypes.bool,
  toggleEdit: PropTypes.func,
  toggleEditValue: PropTypes.bool,
  user: PropTypes.object,
  has_publish_errors: PropTypes.bool,
};

export default connect(
  ({ user, graph, async_operations }) => ({
    user,
    graph,
    async_operations,
  }),
  null,
)(GlobalHeader);
