import { useFieldArray } from 'react-hook-form';
import { Input, Select, Button, Flex } from '@evidation/ui';
import { PlusCircle, Trash } from '@evidation/ui/lib/icons';
import { useEffect } from 'react';
import { event_statuses } from '../../../../constants';
import { ErrorMessage } from '../components';
import { capitalize, get } from 'lodash';
import { EventEmission as EventEmissionType } from '../../../../redux/graph';

type EventEmissionProps = {
  control: any;
  setValue: (name: any, value: any) => any;
  register: (...args: any) => any;
  events: EventEmissionType[];
  errors: any;
};

const EventEmission: React.FC<EventEmissionProps> = ({
  control,
  setValue,
  register,
  events,
  errors,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: 'event_emission',
    control,
  });
  useEffect(() => {
    if (fields.length === 0) {
      append({ node_status: '', event_status: '', event_slug: '' });
    }
  }, [fields, append]);
  const getError = (key: string) => get(errors, `${key}.message`, '');

  return (
    <>
      {fields.map((field, fieldIdx) => {
        return (
          <div key={field.id}>
            <Input
              label="When this step becomes"
              required
              error={getError(`event_emission.${fieldIdx}.node_status`)}
              id={`id_event_emission.${fieldIdx}.node_status`}
              {...register(`event_emission.${fieldIdx}.node_status`)}
            />
            <Input
              label="Emit an event with slug"
              required
              error={getError(`event_emission.${fieldIdx}.event_slug`)}
              id={`id_event_emission.${fieldIdx}.event_slug`}
              {...register(`event_emission.${fieldIdx}.event_slug`)}
            />
            <Select
              required
              name={`event_emission.${fieldIdx}.event_status`}
              label="And status"
              error={getError(`event_emission.${fieldIdx}.event_status`)}
              options={event_statuses.map((status) => ({
                label: capitalize(status),
                value: status,
              }))}
              onChange={(option) =>
                setValue(
                  `event_emission.${fieldIdx}.event_status`,
                  option.value,
                )
              }
              value={events?.[fieldIdx]?.event_status}
            />
            {fields.length > 1 && (
              <Button
                variant="ghost"
                type="button"
                onClick={() => remove(fieldIdx)}
                title="Remove Event"
                className="danger"
              >
                <Trash />
              </Button>
            )}
          </div>
        );
      })}
      <Flex items="center">
        <Button
          variant="ghost"
          type="button"
          size="small"
          onClick={() =>
            append({ node_slug: '', event_status: '', event_slug: '' })
          }
        >
          <PlusCircle />
          Emit Another Event{' '}
        </Button>
        <ErrorMessage>{getError('event_emission')}</ErrorMessage>
      </Flex>
    </>
  );
};

export default EventEmission;
