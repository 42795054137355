import * as C from '../common';

import Icon from '@evidation/icon';
import React from 'react';
import { default_experience_key } from '../../../../constants';

const AWS_COGNITO_PROVIDER = 'AWS Cognito';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: 'content',
        input: [
          {
            name: 'internal_study_settings',
            label: 'Internal Study Settings',
            interface: 'settings_heading',
          },
          {
            name: 'title',
            label: 'Internal Title',
            required: true,
            interface: 'text_horizontal',
            description:
              'Internal for the study manager only. This appears in the list of studies, in the Study Manager navigation, and in internal reports. It does not show up anywhere that participants can see it.',
          },
          {
            name: 'description',
            label: 'Internal Description',
            interface: 'textarea_horizontal',
            description:
              'Internal for the study manager only. This is a short description of the study.',
          },
          {
            name: 'slug',
            label: 'Study Slug',
            interface: 'text_horizontal',
            required: true,
            description:
              'This uniquely identifies the study. It is used both for internal identification where uniqueness is important AND it is displayed to the participant in a variety of contexts. Study slugs will appear in the browser location bar, in advertising links, in email addresses, and in some external tracking information. Study slugs should not reveal information you do not want known by the participant during the study.',
            parse: C.parseSlug,
          },
          {
            name: 'preview_token',
            label: 'Preview Token',
            description:
              'Token to give access to the study before it goes Live.',
            interface: 'text_horizontal',
          },
          {
            name: 'external_services',
            label: 'External Services',
            interface: 'settings_heading',
          },
          {
            name: 'keywords',
            label: 'SEO Keywords',
            description:
              'Recommended not to change these. These are the keywords used for finding this study in search engines.',
            interface: 'text_horizontal',
          },
          {
            name: 'gtm_id',
            label: 'Google Tag Manager ID (Google Analytics)',
            interface: 'text_horizontal',
          },
          {
            name: 'gtm_id_cookie_banner',
            label: 'Google Tag Manager ID (Cookie banner)',
            interface: 'text_horizontal',
          },
          {
            name: 'communications',
            label: 'Communications',
            interface: 'settings_heading',
          },
          {
            name: 'contact_email',
            label: 'From Email Address',
            description:
              'This appears as the “Sent From”, and “Reply To” parameters in participant communications',
            interface: 'text_horizontal',
          },
          {
            name: 'contact_modal_heading',
            label: 'Contact Modal Heading',
            interface: 'text_horizontal',
            description: 'This appears as the heading in the contact modal.',
          },
          {
            name: 'contact_modal_message',
            label: 'Contact Modal Message',
            interface: 'textarea_horizontal',
            description: 'This is the message displayed in the contact modal.',
          },
          {
            name: 'contact_modal_phone_number',
            label: 'Contact Modal Phone Number',
            interface: 'toggle_horizontal',
            description:
              '(NOT WORKING YET) This sets wether a contact phone number is displayed in the contact modal.',
          },
          {
            name: 'verify_phone_numbers',
            label: 'Validate Phone Number',
            interface: 'toggle_horizontal',
            description:
              "If this setting is turned on, any phone number collected via the 'tel' or 'tel international' component will go through a verification process with OTP",
          },
          {
            name: 'contact_phone_number',
            label: 'Contact phone number',
            description:
              'This is the study phone contact number for Participants.',
            mask: '111-111-1111',
            interface: 'mask_horizontal',
          },
          {
            name: 'outbound_phone_number',
            label: 'Outbound phone number',
            description:
              'This number is used as the “from” number for all automated participant communications (SMS tiles, bulk SMS, etc)',
            mask: '111-111-1111',
            interface: 'mask_horizontal',
          },
          {
            name: 'contact_preferred_method',
            label: 'Preferred Contact Method',
            description: 'Preferred contact method for contact form',
            interface: 'select_horizontal',
            possible_answers: ['Email', 'Phone'],
          },
          {
            name: 'standalone_contributions',
            label: 'Standalone Contributions',
            interface: 'settings_heading',
          },
          {
            name: 'is_program',
            label: 'It is a program with standalone contributions',
            interface: 'toggle_horizontal',
            description:
              'Appropriate for when the program does not need a landing page or dashboard associated. Participants must reach these contributions from an Achievement offer.',
          },
          {
            name: 'address_settings_heading',
            label: 'Address Settings',
            interface: 'settings_heading',
          },
          {
            name: 'max_identical_addresses',
            label:
              'Set maximum number of allowed participants per single household address. This is an anti-abuse setting.',
            interface: 'number_horizontal',
          },
          {
            name: 'sites_mapped_zip_codes_settings',
            label: 'Sites Mapped to Zip Codes',
            interface: 'settings_heading',
          },
          {
            name: 'site_node',
            label: 'Location screener node uuid',
            interface: 'text_horizontal',
          },
          {
            name: 'site_mapping',
            interface: 'array',
            addButtonLabel: 'add a site',
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            items: [
              {
                name: 'site_id',
                label: 'Site ID',
                interface: 'text_horizontal',
              },
              {
                name: 'site_name',
                label: 'Site Name',
                interface: 'text_horizontal',
              },
              {
                name: 'site_address',
                label: 'Site Address',
                interface: 'text_horizontal',
              },
              {
                name: 'site_prefixes',
                title: 'Site Zip Code Prefixes',
                interface: 'array',
                addButtonLabel: 'add a prefix',
                deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
                items: [
                  {
                    name: 'prefix',
                    label: undefined,
                    interface: 'mask_horizontal',
                    mask: '111',
                  },
                ],
              },
            ],
          },
          {
            label: 'Ghost Inspector Testing Suite IDs',
            interface: 'settings_heading',
            name: 'testing_suite_heading',
          },
          {
            interface: 'flatarray',
            type: 'text_horizontal',
            placeholder: 'suite identifier',
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            addButtonLabel: 'add a suite',
            name: 'testing_suite',
          },
          {
            label: 'Authentication Providers',
            interface: 'settings_heading',
            name: 'auth_heading',
          },
          {
            name: 'authentication_provider',
            label: 'Authentication Provider',
            interface: 'select_horizontal',
            defaultValue: 'Achievement',
            possible_answers: [
              { label: AWS_COGNITO_PROVIDER, value: AWS_COGNITO_PROVIDER },
              { label: 'PPMI', value: 'PPMI' },
              { label: 'PPMI-Online (Auth0)', value: 'PPMI-Online' },
            ],
          },
          {
            name: 'cognito_pool_id',
            label: 'Pool ID',
            interface: 'text_horizontal',
            required: true,
            initialValue: 'default',
            condition: (path, { values }) =>
              values.content?.authentication_provider === AWS_COGNITO_PROVIDER,
          },
          {
            name: 'cognito_pool_domain',
            label: 'Pool Domain',
            interface: 'text_horizontal',
            required: true,
            initialValue: 'default',
            condition: (path, { values }) =>
              values.content?.authentication_provider === AWS_COGNITO_PROVIDER,
          },
          {
            name: 'cognito_client_id',
            label: 'Client ID',
            interface: 'text_horizontal',
            required: true,
            initialValue: 'default',
            condition: (path, { values }) =>
              values.content?.authentication_provider === AWS_COGNITO_PROVIDER,
          },
          {
            label: 'Customer Service Provider',
            interface: 'settings_heading',
            name: 'customer_service_provider_heading',
          },
          {
            name: 'customer_service_provider',
            label: 'Customer Service Provider',
            interface: 'select_horizontal',
            defaultValue: 'Zendesk',
            possible_answers: [
              { label: 'PPMI Support', value: 'Freshdesk' },
              { label: 'Off', value: 'Off' },
            ],
          },
          {
            name: 'zendesk_api_key',
            label: 'Zendesk API Key',
            interface: 'text_horizontal',
            placeholder: 'Example: abcdef01-2345-6789-0abc-def123456789',
            validate: [C.validateUuid],
            parse: C.parseZendeskSnippet,
            condition: (path, { values }) => {
              const provider = values.content?.customer_service_provider;
              return !provider || provider === 'Zendesk';
            },
          },
          {
            label: 'Update Email Settings',
            interface: 'settings_heading',
            name: 'update_email_settings_header',
          },
          {
            name: 'email_update_template',
            label: 'Sendgrid Email Template ID',
            interface: 'text_horizontal',
            description:
              'Sendgrid ID for email template used for requesting update/verify email address.',
          },
          {
            name: 'email_update_link_expiration',
            label: 'Email Update Link Expiration (hours)',
            interface: 'number_horizontal',
            description: 'The time limit for the link to be active.',
          },
          {
            label: 'reCaptcha Settings',
            interface: 'settings_heading',
            name: 'recaptcha_settings_header',
          },
          {
            name: 'recaptcha_version',
            label: 'reCaptcha Support',
            interface: 'select_horizontal',
            defaultValue: 'Off',
            possible_answers: ['Enterprise Checkbox', 'Enterprise Score Based'],
            description:
              'The mode to use for reCaptcha Enterprise. The "checkbox" mode has users select matching image tiles, whereas the "score" mode scores users transparently based on their behavior.',
          },
          {
            label: 'Participant Inactivity Settings',
            interface: 'settings_heading',
            name: 'inactivity_settings_header',
          },
          {
            name: 'participant_default_inactivity_timeout',
            label: 'Default Inactivity Timeout (Hours)',
            interface: 'number_horizontal',
            description:
              'The default amount of inactive time (in hours) after which participants will be logged out out by default. This can be overridden per participant by a user preference.',
          },
        ],
      },
    ],
  ];
};
