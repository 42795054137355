import * as C from '../common';
import _ from 'lodash';
import { branchingStrategies } from './content';

// transforms data pefore sending to triage
export default data => {
  if (!_.get(data, 'content.strategy')) {
    [
      'on_cap_overflow',
      'arm_names',
      'condition',
      'default_arm',
      'arms_by_condition_value',
      'batch_size',
    ].forEach(name => _.unset(data, `content.${name}`));
  }

  if (data.content && data.content.strategy) {
    // cleaning data based on strategy type
    if (data.content.strategy === `conditional`) {
      _.unset(data, `content.batch_size`);
      _.unset(data, `content.on_cap_overflow`);
    } else if (data.content.strategy === `random`) {
      _.unset(data, `content.default_arm`);
      _.unset(data, `content.condition`);
      _.unset(data, `content.arms_by_condition_value`);
    } else if (data.content.strategy === `list`) {
      _.unset(data, `content.batch_size`);
    }

    if (branchingStrategies.includes(data.content.strategy)) {
      _.merge(data, {
        content: { branching: { strategy: data.content.strategy } },
      });
    } else {
      // covering the case where strategy is not one available
      // clearing any selected values
      _.unset(data, `content.branching`);
    }
  }

  if (data.content && data.content.batch_size) {
    _.merge(data, {
      content: {
        branching: { batch_size: parseFloat(data.content.batch_size, 10) },
      },
    });
  }

  if (data.content && data.content.on_cap_overflow) {
    _.merge(data, {
      content: {
        branching: { on_cap_overflow: data.content.on_cap_overflow },
      },
    });
  }
  _.unset(data, `content.on_cap_overflow`);

  if (data.content && data.content.arm_names) {
    _.merge(data, {
      content: {
        branching: { arm_names: data.content.arm_names },
      },
    });
  }
  _.unset(data, `content.arm_names`);

  if (!_.isEmpty(_.get(data, 'content.caps_by_arm'))) {
    _.merge(data, {
      content: {
        branching: {
          caps_by_arm: _.reduce(
            data.content.caps_by_arm,
            (acc, arm) => {
              return { ...acc, [arm.arm_name]: parseFloat(arm.arm_cap, 10) };
            },
            {},
          ),
        },
      },
    });
  }

  //
  // conditional strategy
  if (data.content && data.content.condition) {
    _.merge(data, {
      content: { branching: { condition: data.content.condition } },
    });
  }

  if (data.content && data.content.default_arm) {
    _.merge(data, {
      content: { branching: { default_arm: data.content.default_arm } },
    });
  }

  if (data.content && data.content.arms_by_condition_value) {
    _.merge(data, {
      content: {
        branching: {
          arms_by_condition_value: _.reduce(
            data.content.arms_by_condition_value,
            (acc, arm) => {
              return { ...acc, [arm.condition_value]: arm.arm_name };
            },
            {},
          ),
        },
      },
    });
  }

  _.unset(data, `content.batch_size`);
  _.unset(data, `content.caps_by_arm`);
  _.unset(data, `content.condition`);
  _.unset(data, `content.default_arm`);
  _.unset(data, `content.arms_by_condition_value`);
  _.unset(data, `content.strategy`);

  return C.common_outgoing(data);
};
