import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path
          d="M9.265 16.406h1.02v-4.08a4.08 4.08 0 1 0-8.163 0v4.08h1.021v4.081h6.122v-4.081zM6.204 8.244a3.061 3.061 0 1 0 0-6.122 3.061 3.061 0 0 0 0 6.122z"
          strokeLinejoin="round"
        />
        <path d="M13.856 2.122h6.121M16.917 2.122v18.365M13.856 20.487h6.121" />
      </g>
    </svg>
  );
}
