import React from 'react';

const EvidationLogo = ({
  fill = '#062531',
  width = '26px',
  height = '26px',
  color = '#2A282D',
}) => {
  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('study-manager-qa')
  ) {
    fill = '#00C480';
  } else if (window.location.host.includes('study-manager-staging')) {
    fill = '#F5A623';
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Evidation</title>
      <g clipPath="url(#clip0_573_5204)">
        <path
          d="M35.0265 18.8636V8.4842C35.0265 8.42273 34.9757 8.37378 34.9118 8.37378H23.2305C23.0579 8.37378 22.9196 8.5081 22.9196 8.67316V9.39487C22.9196 9.52009 23 9.63165 23.1217 9.67491C23.6796 9.87184 24.3142 10.0631 24.3142 10.7757C24.3142 10.9476 24.2847 11.1468 24.1948 11.3471L22.0817 16.5539C22.0179 16.7099 21.791 16.7144 21.7212 16.5607L19.1826 10.9806C19.1814 10.9772 19.1791 10.9726 19.1779 10.9692C19.0904 10.7427 19.0314 10.5446 19.0314 10.4034C19.0314 9.78533 19.6329 9.79102 20.3385 9.63165C20.4779 9.59978 20.5748 9.47911 20.5748 9.34137V8.67316C20.5748 8.50697 20.4354 8.37378 20.264 8.37378H13.167C12.9968 8.37378 12.8597 8.50583 12.8597 8.66975V9.36983C12.8597 9.52009 12.9767 9.64645 13.1316 9.66352C13.7367 9.72955 14.7141 9.83655 15.3227 11.2048L19.9615 21.7971C20.0099 21.9075 20.1222 21.9793 20.2463 21.9793H21.6409C21.7673 21.9793 21.882 21.9041 21.9292 21.7914L26.2134 11.3756C26.6803 10.2532 27.2818 10.0358 27.9508 9.87184C28.2769 9.79216 29.5191 9.80012 29.9658 9.91737C30.6903 10.1075 31.2883 10.5332 31.2587 13.9767V18.6644C31.2587 20.0668 30.2152 20.4653 29.2591 20.7203C29.1255 20.7555 29.0333 20.8728 29.0333 21.0071V21.6822C29.0333 21.8472 29.1716 21.9804 29.343 21.9804H36.5877C36.7591 21.9804 36.8973 21.8472 36.8973 21.6822V21.0378C36.8973 20.9035 36.8028 20.7863 36.6681 20.7498C35.9058 20.5495 35.0265 20.2125 35.0265 18.8636Z"
          fill={color}
        />
        <path
          d="M32.6616 5.29099C34.2051 5.61086 35.5749 4.28925 35.2428 2.80371C35.0667 2.01484 34.4143 1.38533 33.5953 1.21458C32.0506 0.89243 30.6773 2.21518 31.0106 3.703C31.189 4.49187 31.8426 5.12024 32.6616 5.29099Z"
          fill={color}
        />
        <path
          d="M51.6775 18.8635V0.297108C51.6775 0.133186 51.5393 0 51.3691 0H50.3338C50.2629 0 50.1955 0.0239052 50.14 0.0660239C49.6081 0.478104 48.8435 0.937995 47.9996 1.3717C47.3189 1.73825 46.5885 2.05699 45.9231 2.32791C45.8144 2.37231 45.7447 2.47362 45.7447 2.58745V3.40251C45.7447 3.54708 45.8593 3.66888 46.0082 3.68368L46.6346 3.74401C47.0801 3.80093 47.9405 4.14357 47.9405 5.83059V8.33495C47.9405 8.47041 47.7963 8.56148 47.6663 8.50684C46.8272 8.15281 45.8179 7.9445 44.6172 7.9445C39.9879 7.9445 37.2578 10.8894 37.2578 15.3767C37.2578 19.8925 40.5516 22.3218 44.4387 22.3218C45.7352 22.3218 46.7965 22.0258 47.6415 21.5363C47.7727 21.46 47.9405 21.5477 47.9405 21.6957C47.9405 21.8516 48.0717 21.978 48.2336 21.978H53.2848C53.4468 21.978 53.5779 21.8516 53.5779 21.6957V21.0241C53.5779 20.8966 53.4893 20.785 53.3605 20.7509C52.5911 20.5528 51.6775 20.2192 51.6775 18.8635ZM47.9394 19.0354C47.9394 19.0729 47.9287 19.1094 47.9074 19.1401C47.1676 20.2238 46.1134 20.8066 45.032 20.8066C43.1623 20.8066 41.2926 18.6916 41.2926 15.2048C41.2926 11.6896 42.6872 9.31506 45.5355 9.31506C46.6736 9.31506 47.4382 9.89562 47.9145 10.73C47.9311 10.7585 47.9394 10.7904 47.9394 10.8222V19.0354Z"
          fill={color}
        />
        <path
          d="M66.7839 19.2073V12.1747C66.7839 9.57355 64.3504 7.94458 60.9975 7.94458C57.4969 7.94458 55.2999 9.63047 55.2999 11.1741C55.2999 12.0597 55.8636 12.6892 56.932 12.6892C57.9413 12.6892 58.4152 12.0312 58.8903 11.0033C59.3051 10.0596 60.0178 9.28782 61.0271 9.28782C62.125 9.28782 63.015 10.1746 63.015 11.717V12.8451C63.015 12.8725 62.9972 12.8964 62.9712 12.9043C62.1747 13.1593 61.1264 13.4678 60.0473 13.7763C58.089 14.3193 53.9053 15.2914 53.9053 18.7213C53.9053 21.2655 56.1898 22.5222 58.979 22.5222C61.3036 22.5222 62.6604 21.755 63.4121 21.1596C63.4393 21.138 63.4771 21.1425 63.4995 21.1676C64.2086 21.9621 65.2038 22.2649 66.4258 22.2649C67.4575 22.2649 68.3309 21.912 68.8273 21.4431C68.8852 21.3884 68.9195 21.3133 68.9195 21.2359V21.0458C68.9195 20.8841 68.78 20.7487 68.6122 20.7589C67.9149 20.8022 66.7839 20.3081 66.7839 19.2073ZM63.015 18.2067C63.015 19.6923 61.6499 20.7498 60.1667 20.7498C58.6539 20.7498 57.7333 19.8642 57.7333 18.2352C57.7333 16.086 60.8404 14.7485 62.9464 14.6369C62.9842 14.6346 63.015 14.6642 63.015 14.7006V18.2067Z"
          fill={color}
        />
        <path
          d="M94.7133 7.60181C90.0249 7.60181 86.9391 10.7755 86.9391 15.3482C86.9391 19.4918 89.7578 22.3786 94.6542 22.3786C98.897 22.3786 102.399 19.3495 102.399 15.1183C102.399 10.7186 99.4903 7.60181 94.7133 7.60181ZM94.5348 20.8931C92.4276 20.8931 90.7364 19.5214 90.7364 15.0056C90.7364 10.4613 92.4571 9.03157 94.6826 9.03157C96.908 9.03157 98.4219 11.0043 98.4219 14.8633C98.4219 18.7211 96.6421 20.8931 94.5348 20.8931Z"
          fill={color}
        />
        <path
          d="M118.156 18.4072V12.2601C118.156 9.45868 116.703 7.97314 113.706 7.97314C112.081 7.97314 110.634 8.46605 109.319 9.29135C109.189 9.37331 109.017 9.28338 109.017 9.13425V8.68233C109.017 8.51158 108.873 8.37384 108.697 8.37384H107.762C107.691 8.37384 107.624 8.39661 107.568 8.43759C107.036 8.82804 106.272 9.31297 105.428 9.74554C104.754 10.0825 104.057 10.4194 103.38 10.671C103.256 10.7177 103.173 10.8304 103.173 10.959V11.724C103.173 11.8822 103.298 12.0154 103.461 12.0302L104.092 12.0894C104.507 12.1463 105.369 12.489 105.369 14.176V18.6075C105.369 20.0066 104.331 20.4061 103.377 20.6611C103.239 20.6987 103.143 20.8193 103.143 20.9571V21.6708C103.143 21.8404 103.286 21.9793 103.464 21.9793H110.602C110.76 21.9793 110.888 21.8564 110.888 21.7038V20.9628C110.888 20.8376 110.802 20.7294 110.677 20.6964C109.912 20.4972 109.02 20.1648 109.02 18.8068V11.3256C109.02 11.2664 109.048 11.2106 109.098 11.1753C109.975 10.5401 111.067 9.97435 112.046 9.97435C113.619 9.97435 114.509 10.8315 114.509 12.4605V18.6929C114.509 20.0339 113.752 20.3936 112.8 20.7158C112.686 20.7545 112.61 20.8592 112.61 20.9753V21.7038C112.61 21.8564 112.738 21.9793 112.896 21.9793H119.679C119.856 21.9793 119.999 21.8416 119.999 21.6708V20.9548C119.999 20.8182 119.906 20.701 119.771 20.66C118.993 20.4255 118.156 19.8893 118.156 18.4072Z"
          fill={color}
        />
        <path
          d="M82.584 5.17624C84.1274 5.49612 85.4972 4.1745 85.1651 2.68896C84.989 1.90009 84.3366 1.27059 83.5176 1.09984C81.9729 0.777684 80.5996 2.10044 80.9329 3.58825C81.1114 4.37826 81.7649 5.00663 82.584 5.17624Z"
          fill={color}
        />
        <path
          d="M86.5822 20.7523C85.8223 20.5519 84.9489 20.2127 84.9489 18.8695V8.85431C84.9489 8.71657 84.8342 8.60502 84.6912 8.60502L73.9872 8.57314C73.8797 8.57314 73.7922 8.4889 73.7922 8.38532V3.27985C73.7922 3.11023 73.6492 2.97363 73.4743 2.97363H72.8231C72.6919 2.97363 72.5749 3.05104 72.5265 3.16829C71.9911 4.48308 71.4782 5.67037 70.8849 6.4877C70.2041 7.41659 69.4147 8.40581 68.3368 9.03076C68.2435 9.08426 68.1855 9.17988 68.1855 9.28347V9.59424C68.1855 9.75588 68.3215 9.88679 68.4893 9.88679H69.8602C69.9678 9.88679 70.0552 9.97103 70.0552 10.0746V18.0635C70.0552 21.3214 72.1625 22.4655 74.8323 22.4655C75.8049 22.4655 76.8024 22.2287 77.3531 21.9703C77.4453 21.927 77.5032 21.8348 77.5032 21.7358V20.9777C77.5032 20.7898 77.3035 20.6612 77.125 20.7363C76.8958 20.8342 76.5767 20.9208 76.1677 20.9208C74.8323 20.9208 73.7934 20.2912 73.7934 18.3196V10.0735C73.7934 9.96989 73.8809 9.88565 73.9884 9.88565L79.3788 9.95168C80.1931 9.95168 81.2095 10.1805 81.18 13.8676V18.6691C81.18 20.0852 80.1163 20.4768 79.1531 20.7329C79.0349 20.7637 78.9545 20.8672 78.9545 20.9845V21.7221C78.9545 21.8667 79.0763 21.984 79.2264 21.984H86.4983C86.6744 21.984 86.8174 21.8462 86.8174 21.6766V21.0494C86.8186 20.9105 86.7216 20.7887 86.5822 20.7523Z"
          fill={color}
        />
        <path
          d="M7.78602 20.8978C5.49796 20.8978 3.98283 19.2654 3.98283 15.3153C3.98283 14.8372 4.12229 14.72 4.22629 14.72H13.4908C13.7874 14.72 13.817 14.5765 13.817 14.1474C13.817 11.9151 11.7665 7.90356 7.30973 7.90356C2.64498 7.90356 0 11.6533 0 15.4587C0 19.1789 2.3176 22.5586 7.54728 22.5586C11.0113 22.5586 12.6552 20.4868 13.7532 18.0052C13.8217 17.8515 13.7437 17.6728 13.5818 17.6136L12.5252 17.2255C12.3704 17.1685 12.1967 17.2403 12.134 17.3871C11.2867 19.3997 9.83652 20.8978 7.78602 20.8978ZM7.162 9.4483C9.4796 9.4483 10.0906 12.2452 10.1332 13.1775C10.1379 13.2845 10.0493 13.3744 9.93698 13.3744H4.46384C4.33738 13.3744 4.27356 13.2629 4.27356 13.1411C4.26883 11.6282 5.22377 9.4483 7.162 9.4483Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_573_5204">
          <rect width="120" height="22.5564" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EvidationLogo;
