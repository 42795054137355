//@ts-nocheck
import _ from 'lodash';

export const alwaysGetKeyValue = (item) => {
  // normalzing data so that we always have an apropriate key/value pair
  // our apis don't always provide consistant data, so this is just normalizing data
  let output = {};
  if (_.isString(item)) {
    // covering the case where this is just an array of items
    output = { label: item, value: _.snakeCase(item) };
  } else if (_.has(item, `answer`)) {
    // covering the case where we have an answer key
    const { answer, ...rest } = item;
    const lastKey = Object.keys(rest)[0];
    output = { label: item[lastKey], value: answer };
  } else if (_.has(item, `value`)) {
    // covering the case where we have a value key
    const { value, ...rest } = item;
    const lastKey = Object.keys(rest)[0];
    output = { label: item[lastKey], value };
  } else {
    // convering the case where we have a key/value pair
    const key = Object.keys(item)[0];
    output = { label: key, value: item[key] };
  }

  return output;
};
