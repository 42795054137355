import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M18.916 21H3.084C1.934 21 1 19.956 1 18.674V3.326C1 2.044 1.934 1 3.084 1h15.832C20.065 1 21 2.043 21 3.326v15.348C21 19.956 20.065 21 18.916 21zM3.084 1.93c-.69 0-1.25.627-1.25 1.396v15.348c0 .77.56 1.395 1.25 1.395h15.832c.69 0 1.25-.626 1.25-1.395V3.326c0-.77-.561-1.395-1.25-1.395H3.084z"
          fillRule="nonzero"
        />
        <path d="M18.166 5.727H4.14c-.228 0-.413-.244-.413-.545 0-.301.185-.546.413-.546h14.026c.227 0 .412.245.412.546 0 .3-.184.545-.412.545zM15.034 8.455H5.958c-.228 0-.413-.245-.413-.546 0-.3.185-.545.413-.545h9.075c.228 0 .413.244.413.545 0 .301-.184.546-.412.546zM10.815 17.12a.395.395 0 0 1-.313-.163L9.28 15.355a.502.502 0 0 1 .046-.652.382.382 0 0 1 .581.05l.869 1.139 2.275-3.779c.127-.212.383-.268.573-.124a.5.5 0 0 1 .11.643l-2.578 4.282a.405.405 0 0 1-.318.204l-.024.001z" />
      </g>
    </svg>
  );
}
