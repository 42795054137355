import { AnyAction } from 'redux';
import types from '../constants';

const defaultState: any = [];
export default (state: any = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.pe_user_data_events.set:
      return [...payload];

    case types.pe_user_data_events.clear:
      return defaultState;

    default:
      return state;
  }
};
