import _ from 'lodash';

import { default as common_incoming, reduceFields } from '../common_incoming';

export default (data) => {
  if (data.content?.experiences && !_.isEmpty(data.content.experiences)) {
    data.content.experiences = Object.entries(data.content.experiences).reduce(
      (acc, [experience_key, experience_content]) => {
        let output = experience_content;

        if (experience_content['default_completion_event_slug'] === undefined) {
          output['default_completion_event_slug'] = true;
        }

        output['form'] = reduceFields(experience_content['form']);
        return { ...acc, [experience_key]: output };
      },
      {},
    );
  }

  return common_incoming(data);
};
