export default () => {
  return {
    content: {
      title: 'Verify Email',
      subject: 'Please confirm your email address',
      body: [
        {
          labels: 'body',
          section:
            'Thank you for your interest in {{company_name}} Studies! <br><br>Please confirm your email address to continue enrolling in the study.<br><br>',
        },
        {
          labels: 'button',
          section: 'Confirm my email',
        },
        {
          labels: 'signature',
          section: '<br><br> All the best, <br><br>The {{company_name}} Team',
        },
      ],
      merge_data: [{ key: 'needs_auth_token', value: 'true' }],
      html_formatter: 'mustache',
    },
  };
};
