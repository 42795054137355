import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path d="M6.582 5.693a.518.518 0 0 0-.725 0l-4.55 4.549a.518.518 0 0 0 0 .725l4.55 4.55c.11.11.22.153.352.153a.438.438 0 0 0 .352-.154.518.518 0 0 0 0-.725l-4.176-4.197 4.198-4.199c.197-.176.197-.505 0-.703h-.001zM20.846 10.242l-4.549-4.55a.518.518 0 0 0-.725 0 .518.518 0 0 0 0 .726l4.198 4.198-4.198 4.175a.518.518 0 0 0 0 .726c.11.11.22.153.352.153a.438.438 0 0 0 .352-.153l4.549-4.55a.5.5 0 0 0 .021-.725zM13.33 2.132a.51.51 0 0 0-.637.33L7.66 18.44a.51.51 0 0 0 .33.637c.043.022.11.022.153.022.22 0 .417-.132.483-.352l5.033-16a.477.477 0 0 0-.329-.615z" />
      </g>
    </svg>
  );
}
