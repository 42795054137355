import * as C from '../common';

import _ from 'lodash';

export default (data) => {
  let processed = C.common_incoming(data);

  processed.content.tracked_values = _.map(
    processed.content.tracked_values,
    (cap, value) => ({
      tracked_value: value,
      cap: cap,
    }),
  );
  // Testing for array to protect legacy studies with count_filter already
  // set.
  processed.content.count_filters = !_.isUndefined(
    processed.content.count_filter,
  )
    ? _.isArray(processed.content.count_filter)
      ? processed.content.count_filter
      : [processed.content.count_filter]
    : [];
  return processed;
};
