import { default_experience_key } from '../../../../constants';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          {
            label: `Event Delay Configuration`,
            interface: `settings_heading`,
            name: `event_delay_configuration`,
          },
          {
            name: `event_delay_config`,
            title: `Event Delay Config`,
            interface: 'array',
            items: [
              {
                name: `node_slug`,
                label: `Node Slug`,
                interface: `text_horizontal`,
              },
              {
                name: `sheet_title`,
                label: `Sheet Title`,
                interface: `text_horizontal`,
              },
              {
                name: `user_data_key`,
                label: `User Data Key`,
                interface: `text_horizontal`,
              },
            ],
          },
        ],
      },
    ],
  ];
};
