"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.array.concat.js");

var _lodash = _interopRequireDefault(require("lodash"));

var _constants = require("./constants");

var _excluded = ["name"];

var iterateOverInputs = function iterateOverInputs(arrayOfInputs, index) {
  return _lodash["default"].reduce(arrayOfInputs, function (acc, _ref) {
    var _ref$required = _ref.required,
        required = _ref$required === void 0 ? false : _ref$required,
        intf = _ref["interface"],
        name = _ref.name;

    if (_lodash["default"].includes(_constants.allowedNoName, intf) || !required) {
      return acc;
    }

    return [].concat((0, _toConsumableArray2["default"])(acc), [name]);
  }, []);
};

var iterateOverSinglePage = function iterateOverSinglePage(page, index) {
  return _lodash["default"].reduce(page, function (acc, _ref2) {
    var _ref2$name = _ref2.name,
        name = _ref2$name === void 0 ? "" : _ref2$name,
        item = (0, _objectWithoutProperties2["default"])(_ref2, _excluded);

    if (!_lodash["default"].has(item, "input")) {
      return acc;
    }

    return [].concat((0, _toConsumableArray2["default"])(acc), (0, _toConsumableArray2["default"])(iterateOverInputs(item.input, index)));
  }, []);
};

var _default = function _default(pages) {
  return _lodash["default"].reduce(pages, function (acc, page, index) {
    return [].concat((0, _toConsumableArray2["default"])(acc), [iterateOverSinglePage(page, index)]);
  }, []);
};

exports["default"] = _default;