import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { checkAsyncOperations } from '../../api/operations';
import { queryStores } from '../../constants';

/**
 * @description This useWorkerStatus hook fetch from Triage the current status, and metadata, of the async job, using the job id as a parameter
 * @param {object} props
 * @returns {import('src/redux/graph').AsyncOperation | null} - The `data` property returned by `checkAsyncOperations(jobId)` if the worker status has been fetched, or null otherwise
 */
const useWorkerStatus = ({
  jobId,
  onJobFinished = (data) => {},
  onError = () => {},
}) => {
  const [finishedOrFailed, setFinishedOrFailed] = useState(false);
  const [workerState, setWorkerState] = useState(null);
  const { data, error } = useQuery(
    [queryStores.workerStatus, jobId],
    () => checkAsyncOperations(jobId).then(({ data }) => data),
    {
      enabled: !!jobId && !finishedOrFailed,
      refetchInterval: jobId ? 2000 : false,
    },
  );

  if (error) {
    onError(error);
  }

  useEffect(() => {
    if (data?.status === 'complete' || data?.status === 'failed') {
      setWorkerState(data);
      onJobFinished(data);
      setFinishedOrFailed(true);
    } else if (data?.status === 'working') {
      setWorkerState(data);
    }
  }, [data, onJobFinished]);

  return workerState;
};

export default useWorkerStatus;
