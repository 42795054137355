import HorizontalWrapper from './horizontalWrapper';
import React, { useState } from 'react';
import { Select } from './selectCommon';
import styled from 'styled-components';

const DEFAULT_FREQUENCY_OPTIONS = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  & > * {
    width: 50%;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

const HorizontalFrequency = ({
  input: { onChange, name, value, ...restInput },
  frequencyOptions,
  disabled = false,
  defaultValue = '- Please Select -',
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value?.quantity);
  const [selectValue, setSelectValue] = useState(value?.unit);
  const onInputChange = event => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    onChange({
      quantity: value,
      unit: selectValue,
    });
    setInputValue(value);
  };
  const onSelectChange = event => {
    const { value } = event.target;
    onChange({
      quantity: inputValue,
      unit: value,
    });
    setSelectValue(value);
  };
  return (
    <HorizontalWrapper {...rest} fieldType="horizontalFrequency">
      <Container>
        <input
          type="number"
          onChange={onInputChange}
          value={inputValue ?? ''}
          data-testid="input"
        />
        <Select
          options={frequencyOptions || DEFAULT_FREQUENCY_OPTIONS}
          disabled={disabled}
          value={selectValue}
          defaultValue={defaultValue}
          onChange={onSelectChange}
          data-testid="select"
        />
      </Container>
    </HorizontalWrapper>
  );
};

export default HorizontalFrequency;
