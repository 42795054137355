// eslint-disable-next-line
import React, { useEffect } from 'react';
/** useOnClickOutside 
 * @param {React.Ref} ref
 * @param {() => void} handler
 * @description This hook takes a target ref e.g. The component you want to watch for an outside click on and a handler
 * and a handler the handler is a function to be called when your component is clicked outside of
 * @example 
    const WatchClickOutside = () => {
      const ref = useRef();
      useOnClickOutside(ref, () => console.log("You clicked outside of the div"))
      return (
        <div ref={ref}>
          Im being watched for outside clicks.
        </div>
      )
    }
 */
export default function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, handler],
  );
}
