import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const DelayTileSettings = ({ experience, masonryEnabled }) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  dataForTile,
  isReminder = false,
}) => {
  const {
    delay: { masonryEnabled = false },
  } = dataForTile;

  const schema = [
    {
      label: isReminder ? 'Delay Configuration' : 'Content',
      name: masonryEnabled
        ? `content.experiences.${default_experience_key}.settings`
        : 'content',
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        C.offset,
        C.units,
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      DelayTileSettings({
        experience,
        masonryEnabled,
      }),
    );
  }

  return schema;
};
