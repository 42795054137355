"use strict";

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.get-own-property-descriptor.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.get-own-property-descriptors.js");

require("core-js/modules/es.object.define-properties.js");

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.inputType = void 0;

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.concat.js");

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _lodash = _interopRequireDefault(require("lodash"));

var _interfaceTransformer = _interopRequireDefault(require("./interfaceTransformer"));

var _excluded = ["component", "labelStyle", "inputStyle"],
    _excluded2 = ["interface", "label", "possible_answers"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var inputType = function inputType(_ref) {
  var _ref$component = _ref.component,
      component = _ref$component === void 0 ? "TextInput" : _ref$component,
      _ref$labelStyle = _ref.labelStyle,
      labelStyle = _ref$labelStyle === void 0 ? "dark" : _ref$labelStyle,
      _ref$inputStyle = _ref.inputStyle,
      inputStyle = _ref$inputStyle === void 0 ? "muted" : _ref$inputStyle,
      rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return _objectSpread({
    component: component,
    labelStyle: labelStyle,
    inputStyle: inputStyle
  }, rest);
};

exports.inputType = inputType;

var Transformer = function Transformer(fields) {
  return _lodash["default"].reduce(fields, function (acc, field) {
    if (!field) {
      return acc;
    }

    var intf = field["interface"],
        label = field.label,
        _field$possible_answe = field.possible_answers,
        options = _field$possible_answe === void 0 ? undefined : _field$possible_answe,
        rest = (0, _objectWithoutProperties2["default"])(field, _excluded2);
    var inputs = [];
    var items = {};

    if (!_lodash["default"].isEmpty(rest.inputs)) {
      inputs = rest.inputs.map(function (input) {
        return Transformer(input);
      });
    }

    if (!_lodash["default"].isEmpty(rest.items)) {
      items = Object.keys(rest.items).reduce(function (acc, key) {
        return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, key, inputType(rest.items[key])));
      }, {});
    }

    var component = _lodash["default"].isFunction(intf) ? intf : (0, _interfaceTransformer["default"])(intf);
    return [].concat((0, _toConsumableArray2["default"])(acc), [_objectSpread({}, inputType(_objectSpread(_objectSpread({
      label: label,
      options: options,
      inputStyle: "muted"
    }, rest), {}, {
      items: items,
      inputs: inputs,
      // must stay as final element otherwise "rest" will override
      component: component
    })))]);
  }, []);
};

var _default = Transformer;
exports["default"] = _default;