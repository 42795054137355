import { AnyAction } from 'redux';
import types from '../constants';
import { Participant } from '../redux/graph';

const defaultState: Participant = {
  cohorts: [], // { data: [], columns: [] }
  participant: {
    internal_identifier: '',
    email: '',
    name: '',
    arm: '',
    testing_status: '',
    enrollment_nodes: [],
  },
  error: '',
};

export default function participant(
  state: Participant = defaultState,
  { type, payload }: AnyAction,
) {
  switch (type) {
    case types.participant.current_event_set:
      return {
        ...state,
        ...payload,
      };
    case types.participant.current_enrollment_node_set:
      return {
        ...state,
        current_event: undefined,
        ...payload.current_enrollment_node,
      };
    case types.participant.set_detail:
      return {
        ...defaultState,
        current_enrollment_node: state.current_enrollment_node,
        ...payload,
      };
    case types.participant.clear:
      return defaultState;
    default:
      return state;
  }
}
