import React, { Component } from 'react';

import ConfirmModal from '../modal/ConfirmModal';
import PortalModal from '../PortalModal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BUTTON_TYPE_VALUES = [
  'default',
  'destructive',
  'floating',
  'outline',
  'primary',
];

function getBackgroundColor(props) {
  if (props.state === 'active') {
    return getBackgroundColorActive(props);
  }

  if (props.state === 'hover') {
    return getBackgroundColorHover(props);
  }

  if (props.buttonType === 'destructive') {
    return '#d52c00';
  }

  if (props.buttonType === 'primary') {
    return '#326BFE';
  }

  return '#ffffff';
}

function getBackgroundColorActive(props) {
  if (props.buttonType === 'destructive') {
    return '#af2400';
  }

  if (props.buttonType === 'primary') {
    return '#436dd7';
  }

  if (props.buttonType === 'outline') {
    return '#d9e3ff';
  }

  if (props.buttonType === 'floating') {
    return 'linear-gradient(180deg, #FAFBFC 0%, #EBEFF2 100%)';
  }

  return '#ffffff';
}

function getBackgroundColorHover(props) {
  if (props.buttonType === 'destructive') {
    return '#ee471c';
  }

  if (props.buttonType === 'primary') {
    return '#386AEB';
  }

  if (props.buttonType === 'outline') {
    return '#d9e3ff';
  }

  return 'linear-gradient(180deg, #FAFBFC 0%, #EBEFF2 100%)';
}

function getBorder(props) {
  if (props.state === 'focus') {
    return getBorderFocus(props);
  }
  if (props.buttonType === 'outline') {
    return '2px solid #326BFE';
  }

  return '1px solid #D8D8D8';
}

function getBorderFocus(props) {
  if (props.buttonType === 'outline') {
    return '2px solid #505050';
  }

  if (props.buttonType === 'destructive' || props.buttonType === 'primary') {
    return '2px solid #ffffff';
  }

  if (props.buttonType === 'floating') {
    return '2px solid #1DE7A7';
  }

  return '1px solid #D8E3FF';
}

function getBorderRadius(props) {
  if (props.buttonType === 'floating') {
    return '100px';
  }

  return '2px';
}

function getBoxShadow(props) {
  if (props.buttonType === 'floating') {
    return '0 0 2px 0 rgba(0,0,0,0.5)';
  }

  return 'none';
}

function getColor(props) {
  if (props.buttonType === 'primary' || props.buttonType === 'destructive') {
    return '#ffffff';
  }

  if (props.buttonType === 'default' || props.buttonType === 'outline') {
    return '#326BFE';
  }

  return '#1E2834;';
}

const StyledButton = styled.button`
  background-color: ${(props) => getBackgroundColor(props)};
  border: ${(props) => getBorder(props)};
  border-radius: ${(props) => getBorderRadius(props)};
  box-shadow: ${(props) => getBoxShadow(props)};
  color: ${(props) => getColor(props)};
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;

  &:disabled {
    background-color: #f5f7f9 !important;
    color: #cccccc !important;
    cursor: default !important;
  }

  &:focus {
    border: ${(props) => getBorderFocus(props)};
  }

  &:hover {
    background-color: ${(props) => getBackgroundColorHover(props)};
  }

  &:active {
    background-color: ${(props) => getBackgroundColorActive(props)};
  }
`;

class Button extends Component {
  constructor(props) {
    super(props);

    const promptConfirm = props.promptConfirm ?? props.type === 'destructive';

    this.state = {
      promptConfirm,
      showConfirm: false,
    };
  }

  componentDidUpdate() {
    const { promptConfirm } = this.props;
    if (this.state.promptConfirm !== promptConfirm) {
      this.setState({ promptConfirm });
    }
  }

  onClick = () => {
    if (this.state.promptConfirm) {
      return this.setState({ showConfirm: true });
    }

    this.props.onClick();
  };

  renderConfirmComponent() {
    const {
      confirmMessage,
      confirmValue,
      promptMessage,
      promptButtonType,
      disabled,
      promptComponent,
      promptTitle,
      onClick: propsOnClick,
    } = this.props;
    const Confirm = promptComponent ? promptComponent : ConfirmModal;
    // TODO - props for hard-coded values

    const closeConfirm = () => this.setState({ showConfirm: false });

    return (
      <PortalModal onClose={closeConfirm}>
        <Confirm
          cancelAction={closeConfirm}
          cancelMessage="Cancel"
          confirmAction={() => {
            closeConfirm();
            propsOnClick();
          }}
          confirmMessage={confirmMessage || 'Confirm'}
          confirmValue={confirmValue || 'Confirm'}
          heading={promptTitle}
          message={promptMessage}
          buttonType={promptButtonType}
          disabled={disabled}
        />
      </PortalModal>
    );
  }

  render() {
    const { children, state, value, type, title, innerType } = this.props;
    let { disabled } = this.props;

    if (state === 'disabled') {
      disabled = true;
    }

    return (
      <React.Fragment>
        <StyledButton
          {...this.props}
          buttonType={type}
          type={innerType}
          disabled={disabled}
          onClick={this.onClick}
          title={title || value || children}
        />
        {this.state.showConfirm && this.renderConfirmComponent()}
      </React.Fragment>
    );
  }
}

/*
children : (string) value or components to go inside button. may include text and icons
confirmationValue: (string) value to be typed in for a double confirm
disabled: (boolean) determines whether button is disabled
onClick: (function) function called when button is clicked
promptComponent: (node) component to be used as confirmation prompt
promptMessage: (string) Message to display on a confirmation prompt
type: (string) type of button [default, destructive, floating, outline, primary]
innerType: (string) button "type" prop
value: (string) string-only value of button, preferentially sets title
*/

Button.defaultProps = {
  children: '',
  confirmValue: 'Confirm',
  disabled: false,
  onClick: () => {},
  promptMessage: 'Are you sure?',
  promptTitle: 'Please Confirm',
  type: 'default',
  innerType: `submit`,
  value: '',
  promptButtonType: 'primary',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  confirmValue: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  promptComponent: PropTypes.func,
  promptConfirm: PropTypes.bool,
  promptMessage: PropTypes.string,
  promptTitle: PropTypes.string,
  promptButtonType: PropTypes.string,
  type: PropTypes.oneOf(BUTTON_TYPE_VALUES),
  innerType: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Button;
