import HorizontalWrapper from './horizontalWrapper';
import React from 'react';

const HorizontalNumberInput = ({ input, ...rest }) => (
  <HorizontalWrapper {...rest} fieldType="horizontalNumberInput">
    <input {...input} type={'number'} />
  </HorizontalWrapper>
);

export default HorizontalNumberInput;
