import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path d="M7 6.888l1.86-2.25H7.138v-.472h2.431v.43L7.721 6.833h1.904v.472H7v-.416zm3.2-2.707h.536v3.123h-.537V4.18zM10.2 3h.536v.598h-.537V3zm2.622 3.95c.246 0 .45-.104.613-.31.163-.206.245-.514.245-.924 0-.25-.036-.465-.108-.645-.137-.345-.387-.518-.75-.518-.366 0-.616.182-.75.548a2.171 2.171 0 0 0-.109.744c0 .242.036.448.109.618.136.324.386.486.75.486zm-1.366-2.77h.513v.417a1.28 1.28 0 0 1 .346-.331c.178-.118.386-.176.627-.176.355 0 .657.136.905.409.248.272.372.661.372 1.167 0 .684-.179 1.172-.536 1.465-.227.185-.49.278-.791.278-.236 0-.435-.052-.595-.155a1.37 1.37 0 0 1-.313-.302v1.603h-.528V4.18z" />
        <path
          d="M2.665 15.505l6.848-4.122 1.13.665 1.13-.665 6.85 4.243c.166-.133.365-.266.564-.399l-6.781-4.243 6.216-3.723v9.64c.2-.034.433 0 .665 0V5H2v11.968h17.287c0-.233-.033-.465 0-.665H2.665v-.798zm0-.764V7.294l6.216 3.723-6.216 3.724zm15.957-9.043v.798l-7.979 4.787-7.978-4.787v-.798h3.293V5h9.261v.698h3.403z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
