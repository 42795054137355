import HorizontalWrapper from './horizontalWrapper';
import React from 'react';

const SettingsTextInput = ({
  input,
  disabled,
  placeholder,
  fullWidth,
  ...rest
}) => {
  return (
    <HorizontalWrapper
      {...rest}
      fieldType="settingsTextInput"
      fullWidth={fullWidth}
    >
      <input
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        type={'text'}
      />
    </HorizontalWrapper>
  );
};

SettingsTextInput.defaultProps = {
  fullWidth: true,
  disabled: false,
  placeholder: ``,
};

export default SettingsTextInput;
