import React from 'react';

const EventDelay = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>icons/tile-types/event_delay</title>
    <defs>
      <path id="a" d="M9 12.666l1.955 1.612 4.127-5.088" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={props.color}
        d="M16.556 1L23 7.444v9.112L16.556 23H7.444L1 16.556V7.444L7.444 1z"
      />
      <rect fill="#FFF" x={6} y={6} width={12} height={12} rx={2} />
      <g strokeLinecap="round" strokeLinejoin="round">
        <use stroke="#FFF" strokeWidth={5} xlinkHref="#a" />
        <use stroke={props.color} strokeWidth={2} xlinkHref="#a" />
      </g>
    </g>
  </svg>
);

export default EventDelay;
