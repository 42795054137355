import { Draggable, Droppable } from 'react-beautiful-dnd';
import React, { Component } from 'react';

import FollowUpList from './components/tile/FollowUpList';
import ReminderList from './components/tile/ReminderList';
import Tile from './components/tile';
import TileContainer from './components/tile/container';
import _ from 'lodash';
import equal from 'fast-deep-equal';
import getTileConfig from './components/tile/config';

export default class TileList extends Component {
  shouldComponentUpdate(nextProps) {
    const allPropsEqual = _.every(
      [
        'tiles',
        'reminders',
        'selectedResources',
        'sequence_uuid',
        'filterTypes',
        'isDragDisabled',
      ],
      (prop) => equal(this.props[prop], nextProps[prop]),
    );
    if (allPropsEqual) {
      return false;
    }
    return true;
  }

  render() {
    const {
      tiles,
      toggleDisplayReminder,
      isDragDisabled,
      filterTypes,
      reminders,
      selectResource,
      selectedResources,
      sequence_uuid,
      masonryEnabled = false,
    } = this.props;

    return _.map(tiles, (tile, index) => {
      const uuid = tile.id;
      const { icon, ...config } = getTileConfig({
        ...tile,
        ...tile.content,
      });

      return (
        <TileContainer key={uuid}>
          <Draggable
            draggableId={uuid}
            isDragDisabled={isDragDisabled}
            type="SLATE/TILE"
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Tile
                  {...tile}
                  masonryEnabled={masonryEnabled}
                  isDragging={snapshot.isDragging}
                  selected={_.keys(selectedResources).includes(uuid)}
                  filtered={filterTypes.includes(tile.content.type)}
                  tileConfig={config}
                  icon={icon}
                  reminders={_.pick(reminders, tile.reminder_ids)}
                  toggleReminders={() => toggleDisplayReminder(uuid)}
                  onTileClick={(e) =>
                    selectResource({
                      [uuid]: {
                        sequence_uuid,
                        resource: `tile`,
                        uuid,
                      },
                    })
                  }
                >
                  {provided.placeholder}
                </Tile>
              </div>
            )}
          </Draggable>
          {tile.displayReminders && (
            <Droppable droppableId={uuid} type="SLATE/REMINDER">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <ReminderList
                    uuid={uuid}
                    sequence_uuid={sequence_uuid}
                    reminder_ids={tile.reminder_ids}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          {tile.follow_up && (
            <FollowUpList
              dashboard={true}
              follow_up={tile.follow_up}
              follow_up_content={tile.content.follow_up}
              sequence_uuid={sequence_uuid}
              uuid={uuid}
            />
          )}
        </TileContainer>
      );
    });
  }
}
