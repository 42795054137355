"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.textOnlyRegex = exports.alphanumericRegex = exports.dateFormatString = exports.range_0to5 = exports.range_0to10 = exports.range_1to10 = exports.ascendingOptionListOfInt = exports.defaultMaxFileSize = exports.defaultMaxFiles = exports.defaultMinFiles = exports.allowedNoName = exports.HTML_regex = exports.regexUnderscores = exports.HumanaIDLength = exports.zipRegex = exports.timeRegex = exports.emailRegex = exports.phoneRegex = exports.safe_name_regex = exports.default_select_value = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

var _lodash = _interopRequireDefault(require("lodash"));

/*eslint no-useless-escape: 0*/
var default_select_value = "---Choose One---";
exports.default_select_value = default_select_value;
var safe_name_regex = /[^a-zA-Z0-9._]+/g;
exports.safe_name_regex = safe_name_regex;
var phoneRegex = /^\+?([1]{1})[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
exports.phoneRegex = phoneRegex;
var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.emailRegex = emailRegex;
var timeRegex = /(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/;
exports.timeRegex = timeRegex;
var zipRegex = /^\d{5}$/;
exports.zipRegex = zipRegex;
var HumanaIDLength = 9;
exports.HumanaIDLength = HumanaIDLength;
var regexUnderscores = /[_]/g;
exports.regexUnderscores = regexUnderscores;
var HTML_regex = /<[a-z][\s\S]*>/i; // fields allowed to render without a `name` field

exports.HTML_regex = HTML_regex;
var allowedNoName = ["scrollbox", "scrollboxsmall", "scrollboxlarge", "paragraph", "label", "heading", "markdown"]; // if no maxFiles is supplied, this is what is used

exports.allowedNoName = allowedNoName;
var defaultMinFiles = 1;
exports.defaultMinFiles = defaultMinFiles;
var defaultMaxFiles = 2;
exports.defaultMaxFiles = defaultMaxFiles;
var defaultMaxFileSize = 10485760;
exports.defaultMaxFileSize = defaultMaxFileSize;

var fillRange = function fillRange(start, end) {
  return _lodash["default"].times(end - start + 1, function (index) {
    return start + index;
  });
};

var ascendingOptionListOfInt = function ascendingOptionListOfInt() {
  var max = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
  var start = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var arr = fillRange(start, max);
  return _lodash["default"].reduce(arr, function (acc, unknown, count) {
    var num = start + count;
    return [].concat((0, _toConsumableArray2["default"])(acc), [{
      label: num.toString(),
      value: num
    }]);
  }, []);
};

exports.ascendingOptionListOfInt = ascendingOptionListOfInt;
var range_1to10 = ascendingOptionListOfInt(10, 1);
exports.range_1to10 = range_1to10;
var range_0to10 = ascendingOptionListOfInt(10);
exports.range_0to10 = range_0to10;
var range_0to5 = ascendingOptionListOfInt(5);
exports.range_0to5 = range_0to5;
var dateFormatString = "yyyy-MM-dd'T'HH:mm:ssxxx";
exports.dateFormatString = dateFormatString;
var alphanumericRegex = /^[a-z0-9]+$/i;
exports.alphanumericRegex = alphanumericRegex;
var textOnlyRegex = /^[^0-9!@#$%^&*()_=\.,\{\}\[\]\;\:<>\/\\\|\"\?]*$/i;
exports.textOnlyRegex = textOnlyRegex;