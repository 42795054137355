import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4 4)" fillRule="nonzero" fill="none">
        <circle stroke="#000" fill="none" cx="7.5" cy="7.5" r="7" />
        <circle
          fill="#000"
          transform="matrix(1 0 0 -1 0 15)"
          cx="7.5"
          cy="7.5"
          r="2.5"
        />
      </g>
    </svg>
  );
}
