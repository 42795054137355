//@ts-nocheck
import {
  Checkbox,
  CheckboxGroup as ReactCheckboxGroup,
} from 'react-checkbox-group';

import { MarkdownHtml as Markdown } from '@evidation/form-elements';
import React from 'react';
import _ from 'lodash';
import { alwaysGetKeyValue } from './utils';
import styled from 'styled-components';

const GroupListHolder = styled.ul`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  li {
    width: 50%;
    display: flex;
    align-items: center;

    input {
      width: 20px;
    }

    label {
      p {
        font-size: 14px !important;
        margin-bottom: 0;
      }
    }
  }
`;

class CheckboxGroup extends React.PureComponent {
  render() {
    const {
      input: { value = [], onChange, ...input },
      options = [],
    } = this.props;

    const Inputs = _.map(options, (item, i) => {
      const { label, value } = alwaysGetKeyValue(item);
      const unique_id = `t-${
        input.name ? input.name.replace(/\s/g, ``) : _.snakeCase(label)
      }_${_.snakeCase(`${value}`)}`;

      return (
        <li key={unique_id}>
          <Checkbox id={unique_id} value={value} />
          <label className="label" htmlFor={unique_id}>
            <Markdown>{label}</Markdown>
          </label>
        </li>
      );
    });

    return (
      <ReactCheckboxGroup
        checkboxDepth={3}
        className="checkboxgroup"
        value={value}
        onChange={onChange}
      >
        <GroupListHolder>{Inputs}</GroupListHolder>
      </ReactCheckboxGroup>
    );
  }
}

CheckboxGroup.defaultProps = {
  ComponentWrapper: false,
};

export default CheckboxGroup;
