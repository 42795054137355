import PropTypes from 'prop-types';
import React from 'react';
import Toggle from '../inputs/Toggle';

const RawToggle = ({ input, name, className, ...rest }) => {
  return (
    <Toggle
      className={className}
      name={input.name}
      onToggle={input.onChange}
      toggleFor={name}
      toggleValue={input.value}
    />
  );
};

RawToggle.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
};

RawToggle.defaultProps = {
  name: ``,
};

export default RawToggle;
