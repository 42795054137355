import React, { Component } from 'react';

import DropdownMenu from 'react-dd-menu';
import DuplicateUi from './duplicate_dropdown';
import styled from 'styled-components';

const DropdownButton = styled.button`
  font-size: 12px;
  line-height: 1.2em;
  padding: 2px 1px;
  color: #637283;
  background: none;
  border: 0;
`;

class SequenceDropdown extends Component {
  state = {
    duplicate: false,
    isMenuOpen: false,
  };

  toggleDropdown = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen, duplicate: false });
  };

  closeDropdown = () => {
    if (!this.state.duplicate) {
      this.setState({ isMenuOpen: false });
    }
  };

  render() {
    const { duplicate } = this.state;

    let menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.closeDropdown,
      toggle: (
        <DropdownButton
          onClick={() => this.toggleDropdown(true)}
          data-test="sequenceDropdown"
        >
          •••
        </DropdownButton>
      ),
      // this propery doesn't seem to work
      closeOnInsideClick: false,
    };

    return (
      <DropdownMenu {...menuOptions}>
        {duplicate ? (
          <DuplicateUi {...this.props} toggleDropdown={this.toggleDropdown} />
        ) : (
          <ul>
            <li ref="preventclose">
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
                data-test="duplicate_sequence"
                id="t-duplicate_sequence"
                onClick={() => this.setState({ duplicate: true })}
              >
                Duplicate
              </button>
            </li>
          </ul>
        )}
      </DropdownMenu>
    );
  }
}

export default SequenceDropdown;
