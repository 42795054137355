import _ from 'lodash';

export const incomingEmailData = body =>
  _.reduce(
    body,
    (acc, value) => {
      if (value.labels === 'button')
        return [
          ...acc,
          { labels: value.labels, section_button: value.section },
        ];
      return [...acc, value];
    },
    [],
  );

export const outgoingEmailData = body =>
  _.reduce(
    body,
    (acc, value) => {
      if (value.labels === 'button')
        return [
          ...acc,
          { labels: value.labels, section: value.section_button },
        ];
      return [...acc, value];
    },
    [],
  );
