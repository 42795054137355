import _ from 'lodash';
import * as C from '../common';

export default data => {
  const outgoing = C.common_outgoing(data);

  if (!_.has(outgoing, 'content.experiences.default.reconfirm_in_days'))
    outgoing.content.experiences.default.reconfirm_in_days = null;

  return outgoing;
};
