import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const ConnectCamcogTileSettings = ({
  experience,
  displayGroupNames,
  masonryEnabled = false,
}) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.experience_attribute,
      C.display_group_name(displayGroupNames),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  displayGroupNames,
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    connect_camcog: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        C.completion_message,
        C.action_title,
        C.tooltip,
      ]
    },
    {
      label: 'Cambridge Cognition Settings',
      name: experienceName,
      input: [
        {
          label: `Visit ID`,
          name: `visit_identifier`,
          interface: `textinput`,
          required: true,
        },
        { ...C.assessment_platform, required: true },
        C.platform_error_message,
        //Group Definition ID, Site ID, Locale, Stimuli and study slug are interchanged with Bridge services team outside the API contract
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(ConnectCamcogTileSettings(experience, displayGroupNames));
  }

  return schema;
};
