import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import store from '../../../../reducers';
import { getInputsForExperience } from '../utils';

const hideIfUsingSendgrid = (input) => ({
  ...input,
  condition: C.negate(C.showInterfaceForSendgridId),
});

export const VerifyEmailTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        availability: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  arms,
  id,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  dataForTile,
}) => {
  const application_state = store.getState();
  const graph_id = application_state.graph?.id;
  const masonryEnabled = dataForTile?.verify_email?.masonryEnabled;
  
  const schema = [
    [
      {
        label: 'Content',
        name: masonryEnabled ? 'content.experiences.default.settings' : 'content',
        input: [
          C.experienceExternalTitle(masonryEnabled),
          C.use_sendgrid,
          C.send_email_preview({
            graph_id,
            tile_id: id,
          }),
          C.sendgrid_template_id,
          C.subject,
          // The below inputs should not be visible if using SendGrid.
          ...[
            C.html_formatter,
            C.body,
            C.email_personalizations,
            {
              name: 'recipient',
              label:
                'Choose who this email should be sent to (default is participant)',
              interface: 'selectadvanced',
              possible_answers: [
                { value: 'crc_email', label: 'CRC' },
                { value: 'caretaker_email', label: 'Caretaker' },
              ],
            },
            {
              name: 'email_debounce_amount',
              label: 'Disable emails for...',
              interface: 'mask',
              mask: '11',
            },
            C.units,
            {
              name: 'auth_token_expiration_amount',
              label: 'Auth Token expires after...',
              interface: 'numberinput',
              validate: [C.validateNumber],
            },
            {
              name: `auth_token_expiration_units`,
              interface: `select`,
              inputStyle: `muted`,
              label: `Units`,
              options: [`minutes`, `hours`],
            },
          ].map(hideIfUsingSendgrid),
          C.merge_data,
        ],
      },
    ],
  ];

  // This section will be in the settings tab for new component studies
  if (!masonryEnabled) {
    schema[0].push(VerifyEmailTileSettings(
      experience,
      masonryEnabled
    ));
  }

  return schema;
};
