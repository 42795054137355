import _ from 'lodash';
import { archipelagoGroupSelector } from '../selectors/sequences';

export const reduceTiles = ({ acc, key, ...rest }) => {
  let obj = [];
  let output = [];

  if (rest.insert && rest.insert.target === key) {
    obj = _.isArray(rest.insert.content)
      ? rest.insert.content
      : [rest.insert.content];
  }

  if (!_.has(rest.tiles, key) && !_.has(rest.reminders, key)) {
    output = acc;
  } else {
    // has tiles
    if (_.has(rest.tiles, key)) {
      const { tile_ids, reminder_ids, ...tile } = rest.tiles[key];
      const reminders = reminder_ids.reduce(
        (acc, key) => reduceTiles({ acc, key, ...rest }),
        [],
      );

      output = [
        ...acc,
        {
          ...tile,
          reminders,
        },
      ];
    }

    // has reminder
    if (_.has(rest.reminders, key)) {
      output = [...acc, rest.reminders[key]];
    }
  }

  return rest.append ? [...output, ...obj] : [...obj, ...output];
};

let collected_archipelagos = [];

export const reduceSequences = ({ acc, key, ...rest }) => {
  let obj = [];
  let output = [];

  if (rest.insert && rest.insert.target === key) {
    obj = _.isArray(rest.insert.content)
      ? rest.insert.content
      : [rest.insert.content];
  }

  if (!rest.sequences[key]) {
    output = acc;
  } else {
    const { sequence_ids, tile_ids = [], ...sequence } = rest.sequences[key];

    if (
      _.has(sequence.content, `archipelago_identifier`) &&
      !_.isEmpty(sequence.content.archipelago_identifier) &&
      !_.includes(
        collected_archipelagos,
        sequence.content.archipelago_identifier,
      )
    ) {
      // pushing the tracked archipelago onto our tracking variable
      collected_archipelagos.push(sequence.content.archipelago_identifier);

      // identifying the first archipelago as "is_root"
      sequence.content[`is_root`] = true;
    } else {
      if (_.has(sequence.content, `is_root`)) {
        _.unset(sequence, `content.is_root`);
      }
      if (_.has(sequence.content, `visibility__depends_on`)) {
        _.unset(sequence, `content.visibility__depends_on`);
      }
    }

    const tiles = tile_ids.reduce(
      (acc, key) => reduceTiles({ acc, key, ...rest }),
      [],
    );

    output = [
      ...acc,
      {
        ...sequence,
        tiles,
      },
    ];
  }

  return rest.append ? [...output, ...obj] : [...obj, ...output];
};

//
// This method prepares the {sequences, graph, tiles} object for sending to our api
// It also handles inserting new nodes into the graph at that time, since we require
// all tiles to contain an ID we can not render them unless they have been sent to
// the api for processing.
// This method requires: graph, sequences, and tiles
///
// It will also take an object for inserting:
// insert: {
//   target: `c5e244f3-1ccc-44a3-be54-0cce4736b128`,
//   content: { title: `New Tile` },
// },
//
// it will also take an append argument to determine if the inserted tile should apppear
// before or after the target.
//
export default props => {
  const {
    graph: { sequence_ids: all_sequence_identifiers, ...graph },
    append = true,
    ...rest
  } = props;

  const archipelagos = archipelagoGroupSelector(props);
  const archipelago_ids = _.reduce(
    archipelagos,
    (acc, i) => [...acc, ..._.keys(i)],
    [],
  );
  const sequences = all_sequence_identifiers.filter(
    i => !_.includes(archipelago_ids, i),
  );

  const all_sequences_and_archipelagos = [...sequences, ...archipelago_ids];

  return {
    ...graph,
    sequences: _.reduce(
      all_sequences_and_archipelagos,
      (acc, key, index) =>
        reduceSequences({ acc, key, append, index, ...rest }),
      [],
    ),
  };
};
