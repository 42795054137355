import React from 'react';

const NavParticipantDetail = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_participant_detail</title>
    <g
      stroke={props.color}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 11a9 9 0 1 0-18 0 9 9 0 0 0 18 0z" strokeWidth={1.4} />
      <path d="M18 18l4 4" strokeWidth={2} />
      <path
        d="M8.504 7.09L8.5 9.59M13.5 9.59v-2.5M13.5 7.09c-.18-.948-1.403-1.667-2.5-1.667v.002c-1.098 0-2.32.719-2.5 1.665M13.495 9.59s.12 1.409-.83 2.5M8.505 9.59s-.12 1.409.828 2.5M16 13.743l-3.335-1.666M9.333 12.09L6 13.743M6 13.743v.834c0 .458.375.833.833.833h8.334a.836.836 0 0 0 .833-.833v-.834"
        strokeWidth={1.4}
      />
    </g>
  </svg>
);

export default NavParticipantDetail;
