//@ts-nocheck
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format, fromUnixTime } from 'date-fns';
import LoadingIndicator from '../LoadingIndicator';

const texts = {
  title: 'Critical Application Error',
  back: 'Back to Studies',
  breadcrumbs: 'Breadcrumbs',
  errorDetails: 'Error Details',
  refresh: 'You can try clicking here to refresh the page',
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div`
  background: #fff;
  padding: 2rem;
  min-height: 500px;
  min-width: 700px;
  border-radius: 6px;
  box-shadow: 1px 1px 1px #e7e7e7;
  border: 2px solid #f30;
  max-height: 900px;
  overflow-y: auto;
  margin: 1em;
`;

const Code = styled.code`
  display: flex;
  width: 100%;
  background-color: #e7e7e7;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  color: #222;
`;

const Pre = styled.pre`
  background-color: #e7e7e7;
  white-space: pre-wrap;
  max-height: 300px;
  overflow-y: auto;
  margin-left: 1rem;
  padding: 1em;
  border-radius: 6px;
`;

const BreadcrumbContainer = styled.div`
  margin-left: 1rem;
`;

const Label = styled.span`
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
  margin-right: 2px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
`;

const ButtonLink = styled.button`
  background: #ddf4ff;
  color: #0969da;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
  margin-right: 2px;
  border: none;
  &:hover {
    cursor: pointer;
  }
  margin: 3px 0 3px 0;
`;

const Breadcrumb = ({ breadcrumb }) => {
  return (
    <BreadcrumbContainer>
      <details>
        <summary>
          <Label>
            {format(fromUnixTime(breadcrumb.timestamp), 'HH:mm:ss')}
          </Label>
          <Label>{breadcrumb.category}</Label>
          {breadcrumb.action}
        </summary>
        <Pre>{JSON.stringify(breadcrumb.metadata, null, 2)}</Pre>
      </details>
    </BreadcrumbContainer>
  );
};

const ErrorPage = ({
  title = texts.title,
  error,
  appSignalInstance,
  displayRedirectLink = false,
  details,
}) => {
  const [loading, setLoading] = useState(true);
  const [sortedBreadcrumbs, setSortedBreadcrumbs] = useState([]);
  const sortBreadcrumbs = (breadcrumbs) =>
    breadcrumbs.sort(
      (breadcrumbA, breadcrumbB) =>
        breadcrumbB.timestamp - breadcrumbA.timestamp,
    );

  useEffect(() => {
    if (appSignalInstance) {
      const intervalId = setInterval(() => {
        const breadcrumbs = sortBreadcrumbs([
          ...appSignalInstance._breadcrumbs,
        ]);
        setSortedBreadcrumbs(breadcrumbs);
        setLoading(false);
      }, 500);
      setTimeout(() => {
        clearInterval(intervalId);
      }, 5000);
    }
  }, [appSignalInstance]);
  return (
    <Wrapper>
      <Panel>
        <TitleContainer>
          <h1>{title}</h1>
          <div>
            <ButtonLink onClick={() => window.location.reload()}>
              {texts.refresh}
            </ButtonLink>
          </div>
        </TitleContainer>
        <hr />
        <Code>{error?.toString()}</Code>
        {details && (
          <details>
            <summary>{texts.errorDetails}</summary>
            <Pre>{details.componentStack}</Pre>
          </details>
        )}
        {appSignalInstance && (
          <div>
            {loading === true ? (
              <LoadingIndicator />
            ) : (
              <details>
                <summary>{texts.breadcrumbs}</summary>
                {sortedBreadcrumbs.map((breadcrumb, index) => (
                  <Breadcrumb
                    breadcrumb={breadcrumb}
                    key={`breadcrumb-${index}`}
                  />
                ))}
              </details>
            )}
          </div>
        )}

        {displayRedirectLink && <a href="/studies">{texts.back}</a>}
      </Panel>
    </Wrapper>
  );
};

export default ErrorPage;
