/**
 * Returns an object with an attribute called 'data-e2e-test-id' and its value to identify the e2e tests
 * e.g:
 *  <Component {...e2eTestId('test-field')} />
 * result:
 *  <Component data-e2e-test-id="test-field"/>
 * @param {string} testId The test identifier for the html element
 * @returns {object} An object with a key called 'data-e2e-test-id' and a value
 */
export const e2eTestId = (testId) => ({ 'data-e2e-test-id': testId });
