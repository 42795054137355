import React from 'react';
import styled from 'styled-components';

const Faded = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(29, 41, 52, 1) 90%);
  z-index: 1;
`;

Faded.Container = styled.div`
  position: relative;
  height: 150px;
  overflow: hidden;
`;

Faded.Button = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  left: 7px;
  top: -3px;
  color: #84898f;
  font-size: 20px;
  padding: 3px;
`;

Faded.Button.defaultProps = {
  children: `❖`,
};

class AlternativeRouteContainer extends React.PureComponent {
  state = { open: true };

  toggle = () => this.setState(state => ({ open: !state.open }));

  render() {
    return (
      <React.Fragment>
        <Faded.Button onClick={this.toggle} />
        {this.state.open ? (
          this.props.children
        ) : (
          <Faded.Container>
            <Faded />
            {this.props.children}
          </Faded.Container>
        )}
      </React.Fragment>
    );
  }
}

export default AlternativeRouteContainer;
