import React from 'react';

const NavContent = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_content</title>
    <g fill={props.color} fillRule="evenodd">
      <path d="M19.719 21.667H3.312V5.261h10.5a.657.657 0 0 0 0-1.313H2.656a.661.661 0 0 0-.464.193.66.66 0 0 0-.192.463v17.719c0 .173.07.342.192.464a.661.661 0 0 0 .464.193h17.719a.662.662 0 0 0 .656-.657V11.167a.657.657 0 0 0-1.312 0v10.5z" />
      <path d="M21.224 5.453l-9.078 9.078-2.546.849.849-2.546 9.077-9.078a.656.656 0 1 0-.927-.928L9.41 12.016a.654.654 0 0 0-.159.256L7.94 16.21a.655.655 0 0 0 .83.83l3.937-1.313a.638.638 0 0 0 .257-.158l9.187-9.189a.655.655 0 1 0-.927-.927" />
      <path d="M19.526 3.756c.313-.31.614-.445.87-.443.226.002.495.086.858.443.34.347.418.601.422.82 0 .13-.032.264-.106.413a1.79 1.79 0 0 1-.346.464.655.655 0 1 0 .927.927c.24-.238.445-.505.595-.808.15-.301.242-.64.242-.996.003-.616-.285-1.233-.806-1.748-.53-.536-1.159-.83-1.787-.828-.715.002-1.327.358-1.796.828a.656.656 0 1 0 .927.928" />
    </g>
  </svg>
);

export default NavContent;
