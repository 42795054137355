import _ from 'lodash';

export function pairReminders(reminderList, relatedReminders) {
  let sorted_tiles = [];

  _.each(reminderList, reminder_id => {
    const obj = {};
    if (relatedReminders[reminder_id]) {
      obj[reminder_id] = relatedReminders[reminder_id];
      sorted_tiles.push(obj);
    }
  });

  return _.uniq(sorted_tiles);
}
