import React from 'react';

const NavSequence = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_sequence</title>
    <g fill={props.color} fillRule="evenodd">
      <path d="M10.125 6.124h11.25a.626.626 0 0 0 0-1.25h-11.25a.626.626 0 0 0 0 1.25M10.125 12.374h11.25a.626.626 0 0 0 0-1.25h-11.25a.626.626 0 0 0 0 1.25M10.125 18.624h11.25a.626.626 0 0 0 0-1.25h-11.25a.626.626 0 0 0 0 1.25M5.854 3.28l-1.71 2.582-1.15-.844a.626.626 0 0 0-.737 1.013l1.678 1.225c.139.1.31.137.479.106a.6.6 0 0 0 .41-.269l2.072-3.125a.62.62 0 0 0-.176-.862.622.622 0 0 0-.866.175M6.375 11.75H5.75A1.253 1.253 0 0 1 4.5 13c-.69 0-1.249-.563-1.25-1.25.001-.688.56-1.25 1.25-1.25s1.249.562 1.25 1.25H7a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0h-.625M6.375 18H5.75a1.253 1.253 0 0 1-1.25 1.25c-.69 0-1.249-.563-1.25-1.25.001-.688.56-1.25 1.25-1.25s1.249.562 1.25 1.25H7a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0h-.625" />
    </g>
  </svg>
);

export default NavSequence;
