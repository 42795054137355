import React, { PureComponent } from 'react';
import { bool, object } from 'prop-types';
import {
  selectResource,
  toggle_display_reminder,
} from '../../actions/slate_actions';

import GetArms from '../../selectors/arms';
import Sequence from './components/sequence';
import SequenceDropdown from './components/sequenceDropdown';
import SequenceFooter from './components/addTileUi';
import TileList from './TileList';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateGraph } from '../../actions/graph_actions';
import { withRouter } from 'react-router-dom';
import { checkIsAuthorized } from '../../components/auth';
import { AuthActions } from '../../constants';

class DashboardSequence extends PureComponent {
  renderDropdown = () => (
    <SequenceDropdown
      url={this.props.url}
      sequence_uuid={this.props.sequence_uuid}
    />
  );

  render() {
    const {
      sequence_uuid,
      sequence,

      // from redux
      reminders,
      tiles,
      slate: { filter_arms, filter_types, selected_resources },
      arms,
      toggle_display_reminder,
      isDraggingOver,
      sequenceIsDragging,
      sequenceDragHandleProps,
      selectResource,
      show_branch_arms,
      user: { authorization },
      graph: { id, masonry_enabled: masonryEnabled = false },
    } = this.props;

    if (
      // preventitive measure
      !sequence ||
      // filtering out arms
      (!_.isEmpty(filter_arms) &&
        !_.isEmpty(sequence.branch_arms) &&
        !_.intersection(filter_arms, sequence.branch_arms).length > 0)
    ) {
      return null;
    }

    const has_tiles = !_.isEmpty(sequence.tile_ids);
    const canDragTile = !checkIsAuthorized(authorization, id, [
      AuthActions.graphs.publish,
      AuthActions.tiles.update,
      AuthActions.sequences.update,
    ]);

    return (
      <Sequence
        {...sequence}
        selected={
          _.keys(selected_resources).includes(sequence_uuid) ||
          sequenceIsDragging
        }
        show_branch_arms={show_branch_arms}
        sequenceDragHandleProps={sequenceDragHandleProps}
        isDraggingOver={isDraggingOver}
        arms={arms}
        dropdown={this.renderDropdown()}
        key={sequence_uuid}
        onClick={() =>
          selectResource({
            [sequence_uuid]: { sequence_uuid, resource: `sequence` },
          })
        }
      >
        {(provided) => (
          <React.Fragment>
            <TileList
              tiles={sequence.tile_ids
                .map((id) => tiles[id])
                .filter((tile) => tile && !tile.is_follow_up)}
              reminders={reminders}
              filterTypes={filter_types}
              toggleDisplayReminder={toggle_display_reminder}
              isDragDisabled={canDragTile}
              selectResource={selectResource}
              selectedResources={selected_resources}
              sequence_uuid={sequence_uuid}
              masonryEnabled={masonryEnabled}
            />
            {provided.placeholder}
            <SequenceFooter
              has_tiles={has_tiles}
              sequence_uuid={sequence_uuid}
            />
          </React.Fragment>
        )}
      </Sequence>
    );
  }
}

DashboardSequence.propTypes = {
  graph: object.isRequired,
  show_branch_arms: bool,
};

DashboardSequence.defaultProps = {
  show_branch_arms: true,
};

export default withRouter(
  connect(
    ({ sequences, graph, ...state }, props) => ({
      ...state,
      sequence: sequences[props.sequence_uuid],
      graph,
      arms: GetArms(sequences),
    }),
    {
      updateGraph,
      selectResource,
      toggle_display_reminder,
    },
  )(DashboardSequence),
);
