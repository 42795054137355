import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.97 18.306a2.543 2.543 0 0 1-2.535 2.535H3.565a2.543 2.543 0 0 1-2.534-2.535V5.636a2.543 2.543 0 0 1 2.534-2.534h3.507V1.515c0-.477.723-.477.723 0v1.587h6.409V1.515c0-.477.723-.477.723 0v1.587h3.507a2.545 2.545 0 0 1 2.535 2.535v12.67zm-4.049-4.261a.725.725 0 0 1 0 1.45.725.725 0 0 1 0-1.45zm0-3.767a.725.725 0 0 1 0 1.45.725.725 0 0 1 0-1.45zm-3.826 3.767c.397 0 .723.326.723.726a.725.725 0 1 1-.723-.726zm0-3.767c.397 0 .723.326.723.724a.728.728 0 0 1-.723.726.73.73 0 0 1-.726-.726c0-.398.328-.724.726-.724zm-3.828 3.767a.725.725 0 1 1-.004 1.45.725.725 0 0 1 .004-1.45zm0-3.767a.725.725 0 1 1-.003 1.45.725.725 0 0 1 .003-1.45zm-3.828 3.767a.725.725 0 0 1 0 1.45.725.725 0 0 1 0-1.45zm0-3.767a.725.725 0 0 1 0 1.45.725.725 0 0 1 0-1.45zM1.754 7.619v10.687c0 .996.815 1.81 1.81 1.81h14.87c.495 0 .95-.204 1.278-.532.328-.328.532-.783.532-1.278V7.62H1.754zm0-.726h18.491V5.637c0-.996-.815-1.81-1.81-1.81h-3.507v.14c0 .476-.723.476-.723 0v-.14H7.796v.14c0 .476-.723.476-.723 0v-.14H3.566c-.995 0-1.81.812-1.81 1.81v1.256h-.002z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
