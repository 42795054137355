import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="nonzero" stroke="#000">
        <g fill="none" fillOpacity="0">
          <path d="M16.91 18.312L12.945 12.5h7.926l-3.963 5.812zM16.91 2.888L12.945 8.7h7.926L16.91 2.888z" />
        </g>
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M4.889 7v7.193M7.111 7v7.193M9.637 9.444H2h7.637zM9.637 11.667H2h7.637z" />
        </g>
      </g>
    </svg>
  );
}
