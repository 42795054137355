import { triageV2 } from './triage';

export const visit_portal_get = (study_slug, identifier, data = {}) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/enrollments/${identifier}/visit_portal`,
    data,
  });

export const process_visit_portal_contribution = (
  study_slug,
  identifier,
  data = {},
) =>
  triageV2({
    method: 'PUT',
    url: `studies/${study_slug}/enrollments/${identifier}/process_visit_portal_contribution`,
    data,
  });

export const cra_search = (study_slug, identifier) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/enrollments/cra_search`,
    params: {
      identifier,
    },
  });
