import axios from 'axios';
import { userconst } from '../../../reducers/user';
import { triageApi } from '../../../api/triage';
import { toastr } from 'react-redux-toastr';

export default ({
  graph_id,
  tile_id,
  server_url = triageApi.url,
}) => {
  const url = `${server_url}api/${triageApi.version_3}/graphs/${graph_id}/tiles/${tile_id}/email_preview`;
  axios({
    method: 'POST',
    url: url,
    headers: {
      Authentication: localStorage.getItem(userconst.token),
    },
  }).then(() => {
    toastr.info('Preview email submitted to Sendgrid');
  }).catch((error) => {
    toastr.error(`There was an error: ${error}`);
  })
};
