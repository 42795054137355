import styled, { css, keyframes } from 'styled-components';

import { ContentFormattingStyles } from '@evidation/form-elements';

const NavigationHeight = `62px`;
const TileListHeight = `220px`;
const PanelNavigationHeight = `45px`;
const ConfigureHeaderHeight = `52px`;

export const HeaderFooterImg = styled.div`
  position: relative;
  overflow-y: hidden;
  height: ${(props) => (props.height ? `${props.height}px` : `auto`)};

  img {
    position: absolute;
    top: ${(props) => (props.placement === `top` ? 0 : `auto`)};
    bottom: ${(props) => (props.placement === `bottom` ? 0 : `auto`)};
    max-width: 100%;
    height: auto;
  }
`;

export const EducationContainer = styled.div`
  zoom: 0.75;
  background: #fff;
  border: 1px solid gray;
  overflow: auto;
`;

export const PanelNavigation = styled.ul`
  display: flex;
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #e9e9e9;
`;

PanelNavigation.defaultProps = {
  'data-test': 'panelNavigation',
};

const ActivePanelLinkStyle = css`
  font-weight: 600;
  border-bottom: 3px solid #1de7a7;
`;

PanelNavigation.PanelLink = styled.li`
  color: #565656;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 26px 5px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.66px;
  line-height: 19px;
  border-bottom: 3px solid transparent;

  ${(p) => p.active && ActivePanelLinkStyle}
`;

const buttonReset = css`
  background: unset;
  border: unset;
`;

PanelNavigation.PanelButton = styled.button`
  ${buttonReset}
  color: #565656;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 26px 5px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.66px;
  line-height: 19px;
  border-bottom: 3px solid transparent;

  ${(p) => p.active && ActivePanelLinkStyle}
`;

PanelNavigation.ColorPanelLink = styled.li`
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 26px 0px;
  color: ${(props) => (props.active ? `#1DE7A7` : `#0d0d0d`)};
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.66px;
  border-bottom: 3px solid
    ${(props) => (props.active ? `#1DE7A7` : `transparent`)};

  div {
    margin-right: 0.5rem;
  }
`;

PanelNavigation.Alt = styled(PanelNavigation.PanelLink)`
  flex: 1;
`;

export const PreviewHolder = styled.div`
  grid-area: content;
  position: relative;
  background: #f7f7f7;
  height: calc(100vh - ${NavigationHeight});
  padding: 0;
  padding-bottom: 15px;
  display: grid;
  grid-template-rows: ${ConfigureHeaderHeight} auto;
  grid-template-columns: auto;
  grid-template-areas:
    'preview-header'
    'preview-content';
  ${({ displaySideBar }) => (displaySideBar ? '' : 'grid-column: 1 / 3')}
`;

export const PreviewContent = styled.div`
  margin: 34px;
  margin-top: 31px;
`;

export const ResizerContainer = styled.div`
  grid-area: resizer;
  grid-template-rows: auto;
  grid-template-columns: 8px;
  background: #d8d8d8;
  position: relative;
  overflow: hidden;
  cursor: col-resize;
  z-index: -1;
  user-select: none;
  ::after,
  ::before {
    content: '';
    border-left: 1px solid #333;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: 0;
    display: inline-block;
    height: 20px;
    margin: 0 2px;
    pointer-events: none;
  }
  ::before {
    left: 0;
  }

  > div {
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  grid-area: settings;
  border-left: 1px solid #d2d2d2;
  display: grid;
  overflow: hidden;
  grid-template-columns: 345px;
  max-width: 800px;
  min-width: 345px;
  grid-template-rows: ${({ displayPreviewNavigation, displaySettings }) => {
    if (!displayPreviewNavigation && displaySettings)
      return `auto ${PanelNavigationHeight} auto`;
    else if (displayPreviewNavigation && !displaySettings)
      return `auto ${PanelNavigationHeight}`;
    else return `${TileListHeight} ${PanelNavigationHeight} auto`;
  }};

  .tui-editor-contents {
    ${css`
      ${ContentFormattingStyles};
    `};
  }

  > div {
    width: inherit;
  }
`;

export const SideBarStub = styled.div`
  grid-area: settings;
  grid-column: 3;
  background: #ffffff;
  border-left: 1px solid #d2d2d2;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
`;

export const TileSectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: #f4f4f4;
  overflow: ${(p) => (p.displaySettings ? `visible` : ` hidden`)};
`;

export const ListOfResources = styled.div`
  overflow: auto;
  margin-bottom: 10px;
  padding: 10px 22px;

  & > :first-child {
    border-radius: 6px 6px 0 0 !important;
    overflow: hidden;
  }
  & > :last-child {
    border-radius: 0 0 6px 6px !important;
    border: 0;
    overflow: hidden;
  }
`;

export const EditContainer = styled.div`
  background: #fff;
  overflow: auto;
  height: calc(
    100vh -
      ${(props) =>
        props.displayPreviewNavigation
          ? `${TileListHeight} - ${PanelNavigationHeight} - ${NavigationHeight}`
          : `${PanelNavigationHeight} - ${NavigationHeight}`}
  );

  fieldset {
    border: 3px solid #e6e6e6;
    padding: 0 10px;
    margin-bottom: 20px;

    > label {
      background-color: #e6e6e6;
      margin: -2px -12px 20px -12px;
      padding: 5px 10px;
    }
  }
`;

const marchingAnts = keyframes`
0% {
  background-position: 0 0,   0 100%,   0 0,   100% 0;
}
100% {
  background-position: 40px 0, -40px 100%, 0 -40px, 100% 40px;
}
`;

const marchingAntsAnimation = css`
  animation: ${marchingAnts} 4s linear infinite;
`;

const AllPreviewEditStyles = css`
  ${css`
    ${ContentFormattingStyles};
  `};

  margin: auto;

  p {
    -webkit-margin-before: 16px;
    -webkit-margin-after: 16px;
  }

  .radiocheckboxlabelholder label p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }

  ol,
  ul {
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
    li {
      margin-bottom: 3px;
    }
  }

  .hiddenInput {
    position: relative;

    &:before {
      content: 'Hidden Input Field';
      position: absolute;
      top: 0;
      left: 5px;
      color: #1de7a7;
      font-size: 10px;
    }

    width: 100%;
    display: block;
    height: 25px;
    background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    border-radius: 3px;
    ${marchingAntsAnimation}
    animation-play-state: running;
    background-image: linear-gradient(to right, #1de7a7 50%, transparent 50%),
      linear-gradient(to right, #1de7a7 50%, transparent 50%),
      linear-gradient(to bottom, #1de7a7 50%, transparent 50%),
      linear-gradient(to bottom, #1de7a7 50%, transparent 50%);
    background-color: transparent;
    color: #1de7a7;
  }
`;

export const PreviewFontStyles = styled.div`
  ${AllPreviewEditStyles};
`;

export const EditFormInnerContainer = styled.div`
  padding: 30px 20px 20px;

  h1 {
    font-weight: 400;
    font-size: 24px;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
  }

  label {
    p {
      color: #000;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  form div:empty {
    // empty div's should not display
    // TODO: remove this, and move the behavior to Curious-panda
    display: none;
  }

  .fr-element.fr-view {
    ${css`
      ${AllPreviewEditStyles};
    `};

    ul,
    ol {
      padding-left: 25px;
    }
  }

  & li.additionalPayloads {
    li.row {
      background-color: #f4f4f4;
    }

    button.add_row {
      width: 100%;
    }
  }
`;

export const ContributionHolder = styled(PreviewFontStyles)`
  list-style: none;
  max-width: 500px;
  min-width: 400px;
  width: auto;
`;

export const EnrollmentHolder = styled(ContributionHolder)`
  max-width: 850px;
  background: #fff;
  padding: 20px;
  border-radius: 3px;
`;

export const EmailHolder = styled(EnrollmentHolder)`
  background: #fff;
  max-width: 800px;

  p {
    display: block;
  }

  #t-preview_items {
    list-style-type: none;
    padding-left: 0;
  }
`;

export const CommunicationsHolder = styled(EnrollmentHolder)`
  background: #fff;
  max-width: 800px;

  hr {
    border-top: 1px dashed rgba(0, 0, 0, 0.3);
    border-bottom: none;
    margin: 20px 0;
  }
`;

export const PushHolder = styled(EnrollmentHolder)`
  background: #eee;
  max-width: 500px;
  border-radius: 8px 12px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 14px;
    color: rgba(80, 80, 80, 1);

    h4 {
      text-transform: uppercase;
      font-weight: 400;
      height: 20px;
      font-family: 'Karla', Helvetica, Arial, sans-serif;
      color: rgba(80, 80, 80, 1);

      svg {
        margin-right: 8px;
      }

      span {
        display: inline-block;
        margin-top: -8px;
        vertical-align: middle;
      }
    }

    label {
      display: inline-block;
      margin-top: -2px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Karla', Helvetica, Arial, sans-serif;
  }

  p {
    margin: 0;
  }
`;

export const SurveyHolder = styled(EnrollmentHolder)`
  background: #fff;
  max-width: 1000px;
  min-width: 700px;
`;

export const TextHolder = styled(PreviewFontStyles)`
  & > p {
    width: 100%;
    text-align: center;
  }
`;

export const ACui = styled.div`
  width: 250px;
  padding: 8px;
  background: #fff;
  border-radius: 3px;
`;

export const PageNumberNavigation = styled.li`
  display: flex;
  border: none;
  border-bottom: ${(props) =>
    props.isDragging ? `none` : `2px solid #d2d2d2`};
  padding: 15px 30px;
  width: 100%;
  cursor: pointer;
  justify-content: flex-end;
  border-radius: ${(props) => (props.isDragging ? `3px` : `none`)};
  background: ${(props) =>
    props.isDragging ? `rgba(0, 187, 213, 0.5)` : `#FFF`};

  &:hover {
    background: rgba(0, 187, 213, 0.04);
  }

  &:first-child {
    margin-right: auto;
  }

  button {
    background: transparent;
    border: none;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    color: #1e2834;
  }
`;

PageNumberNavigation.Lower = styled.div`
  padding: 30px 50px;
  color: #326bfe;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;

  button {
    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
`;

export const ComponentListItem = styled(PageNumberNavigation)`
  padding-left: 10px;
`;

export const PageButton = styled.button`
  font-weight: normal;
  order: -1;
  cursor: pointer;
  margin-right: auto;
  display: flex;
`;

export const CheveronIcon = styled.button`
  cursor: pointer;
  border: none;
  width: 20px;
  height: auto;
  text-align: center;
  font-size: 22px;
  font-weight: normal;
  margin-left: 5px;
`;

export const IconContainer = styled.div`
  height: auto;
  text-align: center;
  margin-left: 5px;

  button {
    padding: 0.5rem;
  }
`;

export const GrayText = styled.span`
  color: gray;
`;

export const TileSectionHeader = styled.div`
  color: #0d0d0d;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d2d2d2;
  padding: 10px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  height: 52px;

  & > *:not(:first-child):not(:last-child) {
    padding: 0 5px;
  }

  & > :last-child {
    padding-left: 5px;
  }
`;

export const ToggleButton = styled.button`
  border: unset;
  background: unset;
  font-family: unset;
  color: unset;
  font-size: unset;
  cursor: pointer;
  padding: 0;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const ToggleSideBarButton = styled(ToggleButton)`
  flex: 0 auto;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #d8d8d8;
  padding: 0;
`;

export const TileCount = styled.div`
  flex: 1 auto;
  color: #1e2834;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 22px;
`;

export const PreviewHeader = styled.div`
  display: flex;
  grid-area: preview-header;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d2d2d2;

  color: #1e2834;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 22px;

  > :first-child {
    padding: 15px;
  }

  > * {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  > :nth-child(2) {
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }
`;

export const PreviewHeaderCenter = styled.div`
  flex: 1 auto;
  > :not(:first-child):not(:last-child) {
    padding: 15px;
    justify-self: flex-end;
  }
`;

export const HeaderDropDownTitle = styled.div`
  opacity: 0.75;
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
`;

export const ResourceTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1 auto;
  > * {
    display: flex;
  }
  > :first-child {
    padding-right: 10px;
    padding-bottom: 2px;
    justify-self: flex-start;
  }
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SettingsControls = styled.div`
  display: flex;
  background-color: #fafafa;
  border-bottom: 1px solid #e9e9e9;
`;

export const SettingsNavigation = styled(PanelNavigation)`
  border-bottom: unset;
`;

export const SettingsCollapseButtons = styled.div`
  display: flex;
  padding: 0 15px;

  > *:not(:last-child) {
    padding: 0 10px;
  }
`;

export const FlexCenterAlign = styled.div`
  display: flex;
  align-items: center;
`;

export const OverflowEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
