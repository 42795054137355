"use strict";

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.get-own-property-descriptor.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.get-own-property-descriptors.js");

require("core-js/modules/es.object.define-properties.js");

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BillboardNavigation", {
  enumerable: true,
  get: function get() {
    return _BillboardNavigation["default"];
  }
});
Object.defineProperty(exports, "CuriousPandaFinalForm", {
  enumerable: true,
  get: function get() {
    return _Form["default"];
  }
});
Object.defineProperty(exports, "GetFieldDictionary", {
  enumerable: true,
  get: function get() {
    return _generateFieldDictionary["default"];
  }
});
Object.defineProperty(exports, "constructFieldClassname", {
  enumerable: true,
  get: function get() {
    return _utils.constructFieldClassname;
  }
});
Object.defineProperty(exports, "emailValidation", {
  enumerable: true,
  get: function get() {
    return _validations.emailValidation;
  }
});
Object.defineProperty(exports, "requiredValidation", {
  enumerable: true,
  get: function get() {
    return _validations2.required;
  }
});
Object.defineProperty(exports, "ComponentDictionary", {
  enumerable: true,
  get: function get() {
    return _fields["default"];
  }
});
Object.defineProperty(exports, "SubmissionError", {
  enumerable: true,
  get: function get() {
    return _SubmissionError["default"];
  }
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _App = require("./App");

var _BillboardNavigation = _interopRequireDefault(require("./BillboardNavigation"));

var _FormOld = _interopRequireDefault(require("./FormOld"));

var _Form = _interopRequireDefault(require("./Form"));

var _generateFieldDictionary = _interopRequireDefault(require("./generateFieldDictionary"));

var _utils = require("./components/utils");

var _validations = require("./fields/emailConfirm/validations");

var _validations2 = require("./validations");

var _fields = _interopRequireDefault(require("./fields"));

var _SubmissionError = _interopRequireDefault(require("./SubmissionError"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_FormOld["default"].defaultProps = _App.defaultProps;
_FormOld["default"].propTypes = _App.propTypes;
_Form["default"].defaultProps = _App.defaultProps;
_Form["default"].propTypes = _objectSpread(_objectSpread({}, _App.propTypes), {}, {
  form: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].func])
});
var _default = _FormOld["default"];
exports["default"] = _default;