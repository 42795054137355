import React from 'react';

export default function(props) {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect id="path-1" x="2" y="2" width="18" height="18" rx="2" />
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="18"
          height="18"
          fill="white"
        >
          <use xlinkHref="#path-1" />
        </mask>
      </defs>
      <g
        id="icons/comp/hidden"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0"
        strokeDasharray="2,2"
      >
        <use
          id="Rectangle"
          stroke="#000000"
          mask="url(#mask-2)"
          strokeWidth="2.8"
          fill="#D8D8D8"
          fillRule="nonzero"
          xlinkHref="#path-1"
        />
      </g>
    </svg>
  );
}
