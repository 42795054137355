import PropTypes from 'prop-types';
import _ from 'lodash';

const getSequenceTypes = (sequence_ids = {}, sequences = []) => {
  // array of sequence ids
  let regular_sequences = [];

  // object keyd on arm name, value array of sequence ids
  let armed_sequences = {};

  _.forEach(sequence_ids, id => {
    const arm = _.has(sequences[id], `branch_arms`)
      ? sequences[id].branch_arms[0]
      : undefined;

    if (!arm) {
      regular_sequences = [...regular_sequences, id];
    } else {
      if (!_.has(armed_sequences, arm)) {
        // adding key to armed_sequences object if it doesn't exist
        armed_sequences[arm] = [];
      }

      armed_sequences[arm].push(id);
    }
  });

  return {
    regular_sequences,
    armed_sequences,
  };
};

getSequenceTypes.propTypes = {
  sequence_ids: PropTypes.array.isRequired,
  sequences: PropTypes.object.isRequired,
};

export default getSequenceTypes;
