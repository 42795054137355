//@ts-nocheck
import React from 'react';

// TODO - svgs will live in /icon/svg and be imported
const default_icon_svg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <title>default-svg</title>
    <rect x="10" y="10" width="30" height="30" />
  </svg>
);

const iconList = [
  {
    altText: 'default icon found',
    name: 'default-icon',
    svg: default_icon_svg,
  },
];

export default iconList;
