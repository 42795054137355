import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M18.895 10.123v7.018a1.76 1.76 0 0 1-1.754 1.754H4.859a1.76 1.76 0 0 1-1.754-1.754V4.859c0-.964.79-1.754 1.754-1.754h7.018v.878H4.86a.882.882 0 0 0-.876.876v12.282c0 .48.396.876.876.876h12.282c.48 0 .876-.396.876-.876v-7.018h.878zm-8.458 3.559l5.076-7.198a.439.439 0 0 1 .685.549l-5.653 7.918-4.292-3.816a.44.44 0 0 1 .582-.656l3.602 3.203z"
          fillRule="nonzero"
        />
        <path d="M20.566 3.37l-1.554.084 1.204.952c.462.364.602.91.14 1.232-.462.322-.91.042-1.12-.518l-.518-1.386L18.2 5.12c-.21.56-.658.84-1.12.518-.462-.322-.322-.868.14-1.232l1.204-.952-1.554-.084c-.602-.028-1.008-.378-.826-.924.154-.476.7-.546 1.19-.224l1.288.84-.406-1.498c-.154-.56.028-1.064.602-1.064s.756.504.602 1.064l-.406 1.498 1.288-.84c.49-.322 1.036-.252 1.19.224.182.546-.224.896-.826.924z" />
      </g>
    </svg>
  );
}
