import { default_experience_key } from '../constants';
import types from '../constants';
import { Configure } from '../redux/graph';

export const defaultState: Configure = {
  active_experience: default_experience_key,
  experiences: [default_experience_key],
  list_of_resources: [],
  preview_state: `available`,
};

export default (
  state: Configure = defaultState,
  { payload, type }: { payload: unknown; type: string },
) => {
  switch (type) {
    case types.configure.setPreviewState:
      return {
        ...state,
        preview_state: payload as string,
      };

    case types.configure.selectExperience:
      return {
        ...state,
        active_experience: payload as string,
      };

    case types.configure.setExperiences:
      return {
        ...state,
        experiences: payload as string,
      };

    case types.configure.prepare:
      return {
        ...state,
        ...(payload as Configure),
      };

    default:
      return state;
  }
};
