import _ from 'lodash';
import clone from '../../../utils/objectClone';
import { parseAsArray } from './utils';
import { single_experience_tile_types } from '../../../constants';

const transform_incoming_form_options = (config) => {
  return Object.keys(config).reduce((acc, key) => {
    let value = config[key];

    if (parseAsArray.includes(key)) {
      value = config[key].toString();
    }

    return [...acc, { key, value }];
  }, []);
};

export const commonFieldTransform = ({
  disqualification_criteria = undefined,
  config = undefined,
  possible_answers = undefined,
  none_additional_payload = undefined,
  noneLabel = undefined,
  matrix = undefined,
  ...rest
}) => {
  let output = clone(rest);

  if (config) {
    output['config'] = transform_incoming_form_options(config);
  }

  if (possible_answers && !_.isEmpty(possible_answers)) {
    output[`possible_answers`] = _.reduce(
      possible_answers,
      (acc, { additional_payload, ...answer }) => {
        if (additional_payload) {
          answer['additional_payload'] = JSON.stringify(additional_payload);
        }
        return [...acc, answer];
      },
      [],
    );
  }

  if (rest.interface === `tabular`) {
    output['inputs'] = _.reduce(
      output.inputs,
      (acc, col, i) => {
        return [...acc, { col: reduceFields(col) }];
      },
      [],
    );
  }

  if (rest.interface === 'simple_matrix') {
    output.questions = rest.questions.map((question) => {
      if (question.question_additional_payload) {
        // Turn the additional payload from an object into a string for the text
        // field.
        const question_additional_payload =
          question.question_additional_payload.map((payload) => {
            // We need to support strings for backwards compatibility, but these
            // should be objects moving forward.
            if (typeof payload.additional_payload === 'string') {
              return payload;
            }
            return {
              ...payload,
              additional_payload: JSON.stringify(payload.additional_payload),
            };
          });
        return {
          ...question,
          question_additional_payload,
        };
      }
      return question;
    });
  }

  if (!_.isUndefined(disqualification_criteria)) {
    output[`has_dq_criteria`] = !_.isEmpty(disqualification_criteria);
    output[`disqualification_criteria`] = disqualification_criteria;
  } else {
    output[`has_dq_criteria`] = false;
  }

  if (noneLabel) {
    output['none_label'] = noneLabel;
  }

  if (none_additional_payload) {
    output['none_additional_payload'] = JSON.stringify(none_additional_payload);
  }

  if (matrix) {
    output['interface'] = matrix;
    output[matrix] = _.reduce(
      output.inputs,
      (acc, item) => {
        return [...acc, { label: item.col[0].label, name: item.col[1].name }];
      },
      [],
    );
    _.unset(output, `inputs`);
  }

  return output;
};

export const reduceFields = (fields) =>
  _.reduce(
    fields,
    (acc_page, field, i) => {
      if (i === 0 && field.interface === `label`) {
        return [
          ...acc_page,
          { ...commonFieldTransform(field), interface: `paragraph` },
        ];
      }

      return [...acc_page, commonFieldTransform(field)];
    },
    [],
  );

export default (props) => {
  const tileType = _.get(props, 'content.type');
  if (
    _.includes(single_experience_tile_types, tileType) &&
    _.has(props, 'content.experiences')
  ) {
    _.merge(props.content, _.get(props, 'content.experiences.default'));
    delete props.content.experiences;
  }
  if (
    props &&
    _.has(props.content, `experiences`) &&
    !_.isEmpty(props.content.experiences)
  ) {
    props.content.experiences = _.reduce(
      props.content.experiences,
      (acc, experience_content, experience_key) => {
        let output = experience_content;

        if (_.has(experience_content, `form`)) {
          if (experience_content.form[0] && experience_content.form[0][0]) {
            // Multi Page Forms
            output[`form`] = _.reduce(
              experience_content.form,
              (acc, item) => [
                ...acc,
                {
                  page: reduceFields(item),
                },
              ],
              [],
            );
          } else {
            // Single page forms
            output[`form`] = reduceFields(experience_content['form']);
          }
        }

        return {
          ...acc,
          [experience_key]: output,
        };
      },
      {},
    );
  }

  // Convert reauth pages to string values, this is to manage old studies
  // where the values are stored as integers
  if (
    props &&
    _.has(props.content, 'reauth_pages') &&
    !_.isEmpty(props.content.reauth_pages)
  ) {
    props.content.reauth_pages = _.map(props.content.reauth_pages, (value) =>
      _.toString(value),
    );
  }

  // If visibility__depends_on is set then convert it from array of strings
  // to a collection so that form array can be properly populated. This
  // change will also prevent any legacy usage of visibility__depends_on
  // does not break.
  if (
    props?.content?.visibility__depends_on &&
    props.content.visibility__depends_on.every((i) => typeof i === 'string') &&
    !_.isEmpty(props.content.visibility__depends_on)
  ) {
    props.content.visibility__depends_on =
      props.content.visibility__depends_on.map((value) => ({
        visibility_key: value,
      }));
  }
  return props;
};
