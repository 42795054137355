import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 3)" fillRule="nonzero" fill="none">
        <rect
          stroke="#000"
          strokeWidth=".7"
          fill="none"
          x="3.35"
          y="5.35"
          width="3.3"
          height="5.3"
          rx="1"
        />
        <rect
          stroke="#000"
          strokeWidth=".7"
          fill="none"
          x="10.35"
          y="5.35"
          width="3.3"
          height="5.3"
          rx="1"
        />
        <rect
          stroke="#000"
          strokeWidth=".7"
          fill="none"
          x="15.35"
          y="5.35"
          width="3.3"
          height="5.3"
          rx="1"
        />
        <path fill="#000" d="M8 6h1v1H8z" />
        <rect
          stroke="#000"
          strokeWidth=".7"
          fill="none"
          x=".35"
          y=".35"
          width="21.3"
          height="15.3"
          rx="3"
        />
        <path fill="#000" d="M8 9h1v1H8z" />
      </g>
    </svg>
  );
}
