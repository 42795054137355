import { AnyAction } from 'redux';
import { setLocalstorage } from '../actions/user_actions';
import types from '../constants';
import { userconst } from './contants';

const getInitialState = () => {
  const storageToken = localStorage.getItem(userconst.token);
  const storageUsername = localStorage.getItem(userconst.username);
  const storageAuthorization = JSON.parse(
    localStorage.getItem(userconst.authorization) || '{}',
  );

  return {
    token: storageToken || '',
    username: storageUsername || '',
    authorization: storageAuthorization,
    authenticated: !!(storageToken && storageUsername && storageAuthorization),
    studies: [],
  };
};

export default function user(
  state = getInitialState(),
  { type, payload }: AnyAction,
) {
  switch (type) {
    case types.user.logged_in:
      return {
        ...state,
        ...payload,
        authenticated: true,
      };

    case types.user.assign_authorization:
      const userAuthorization = {
        ...state,
        authorization: {
          ...state.authorization,
          [payload.study_slug]: payload.actions,
        },
      };
      // TODO: This shouldn't be here
      setLocalstorage(userAuthorization);
      return userAuthorization;

    case types.user.log_out:
      return {
        username: '',
        token: '',
        authorization: {},
        authenticated: false,
        studies: [],
      };

    case types.user.set_studies:
      return {
        ...state,
        studies: payload,
      };

    default:
      return state;
  }
}

export { userconst };
