import axios from 'axios';

//  This is used throughout the app thus we export it to the outside world via index.js
export const triageApi = {
  url: window.env.REACT_APP_TRIAGE_ENDPOINT || window.location.origin + '/',
  version_2: 'v2',
  version_3: 'v3',
};

export const triage = {
  v2: axios.create({
    baseURL: `${triageApi.url}api/${triageApi.version_2}/`,
  }),
  v3: axios.create({
    baseURL: `${triageApi.url}api/${triageApi.version_3}/`,
  }),
};

export const { v2: triageV2, v3: triageV3 } = triage;

export const keepalive = () =>
  triageV2({
    method: 'PATCH',
    url: 'access_tokens/keepalive',
  });

// Get platform versions
// api/v3/versions
// expected payload:
// {"app_revision": "std-3.36","database_schema_ver": "20200904055838",
// "release_branch_name": "release-3.36","release_date": "Tue Sep 15 21:50:59 PDT 2020",
// "release_tag_name": "tags/env/3.36", "revision": "e208ee57664899b4d84036a059f1aff4fd5decb3"}
export const get_platform_version = () =>
  triageV3({
    method: 'GET',
    url: '/versions',
  });
