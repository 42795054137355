import _ from 'lodash';

import * as constants from '../../../../constants';
import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

const hideIfUsingSendgrid = (input) => ({
  ...input,
  condition: C.negate(C.showInterfaceForSendgridId),
});

export const ICFTileSettings = (
  experience,
  displayGroupNames,
  numberOfPages,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      {
        name: 'optional',
        label: 'Optional',
        interface: 'checkbox',
        condition: (path, { values }) => {
          const toPath = C.getToPath(path, 'layout');
          return _.at(values, toPath)[0] === 'dashboard';
        },
      },
      C.experience_attribute,
      C.requires_reauth,
      {
        name: `reauth_pages`,
        interface: `checkboxgroup`,
        label: `Require re-authentication for pages`,
        possible_answers: Array.apply(null, {
          length: numberOfPages,
        }).map((v, i) => ({
          // need to convert 'answer' to string, there is an issue where
          // integer 0 is not stored as a valid value.
          answer: `${i}`,
          label: i + 1,
        })),
      },
      {
        name: 'layout',
        label: 'Layout',
        interface: 'select',
        options: [
          {
            label: 'Pre-Dashboard',
            value: 'informed_consent',
          },
          {
            label: 'Dashboard',
            value: 'dashboard',
          },
        ],
        omitPlaceholder: true,
        defaultValue: 'informed_consent',
      },
      {
        ...C.display_group_name(displayGroupNames),
        // Only show the display group if it's a dashboard tile.
        condition: (path, { values }) => {
          const toPath = C.getToPath(path, 'layout');
          return _.at(values, toPath)[0] === 'dashboard';
        },
      },
      {
        name: 'signed_event_emission',
        title: 'Signed Event Emission',
        interface: 'array',
        items: [
          {
            name: 'slug',
            label: 'Slug',
            interface: 'textinput',
            required: true,
            inputStyle: 'sm_minimal',
          },
          {
            name: 'status',
            label: 'Status',
            interface: 'select',
            required: true,
            options: constants.event_statuses,
          },
        ],
      },
      {
        name: 'declined_event_emission',
        title: 'Declined Event Emission',
        interface: 'array',
        items: [
          {
            name: 'slug',
            label: 'Slug',
            interface: 'textinput',
            required: true,
            inputStyle: 'sm_minimal',
          },
          {
            name: 'status',
            label: 'Status',
            interface: 'select',
            required: true,
            options: constants.event_statuses,
          },
        ],
      },
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        availability: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default (
  {
    experience = default_experience_key,
    id: tile_id,
    active_sequence: sequence_id,
    content: { experiences },
    study_slug,
    displayGroupNames,
    dataForTile,
  },
  graph_id,
) => {
  const {
    pageList = [],
    pageLayout,
    masonryEnabled = false,
  } = dataForTile.informed_consent;

  //TODO: This is currently used to fixed the informed consent tile, this will allow
  // reauth to work with the current changes, but we will be making changes in SPP-1524
  // to address this and make it match with the current architectural changes made
  // for new components. SPP-1524 & SPP-1523
  const numberOfPages = !masonryEnabled
    ? experiences?.[experience].form.length
    : pageList.length;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.use_sendgrid,
        C.send_email_preview({
          graph_id,
          tile_id,
        }),
        {
          ...C.sendgrid_template_id,
          label: 'SendGrid Signed ICF Template ID',
        },
        C.sendgrid_declined_template_id,
        C.experienceExternalTitle(masonryEnabled),
        {
          ...C.action_title,
          condition: (path, { values }) => {
            const toPath = C.getToPath(path.slice(0, 2), 'layout');
            const layoutType = !masonryEnabled
              ? _.at(values, toPath)[0]
              : pageLayout;
            return layoutType === 'dashboard';
          },
        },
        ...[
          C.description,
          C.completion_message,
          C.pdfPreviewConfiguration({
            graph_id,
            tile_id,
            sequence_id,
            experience,
          }),
        ].map(hideIfUsingSendgrid),
        C.email_personalizations,
      ],
    },
    {
      label: 'Page Setup',
      name: experienceName,
      input: C.pdfConfiguration({ graph_id, study_slug }),
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      ICFTileSettings(experience, displayGroupNames, numberOfPages),
    );
  }

  return [schema];
};
