export const PATH_TO_COMPENSATION_ORIGIN = `content.compensation_origin`;
export const PATH_TO_COMPENSATION_CONFIG = `content.compensation_config`;
export const BLACKLIST_COMPENSATION_STUDY_SLUGS = [
  `activitypatterns`,
  `asthmacontrol`,
  `asthmamonitoring`,
  `bdapp`,
  `bdbeta`,
  `humanaglucose`,
  `migrainehealth`,
  `uxinterviews`,
  `rapilot`,
];
