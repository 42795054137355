/**
 * Note: This is only used with new components!
 */

import { default_experience_key } from '../../../../constants';
import CommunicationsTileContent, {
  CommunicationsTileSettings,
} from '../communications/settings';
import DelayTileContent from '../delay/settings';

export const ReminderTileSettings = ({
  experience,
  id,
  displayGroupNames,
  masonryEnabled,
}) =>
  CommunicationsTileSettings({
    experience,
    id,
    displayGroupNames,
    masonryEnabled,
  });

export default ({ id, experience = default_experience_key }) => {
  // Since this is only run for new components, we can fake the fetched data.
  const dataForTile = {
    communications: { masonryEnabled: true },
    delay: { masonryEnabled: true },
  };
  return [
    ...CommunicationsTileContent({
      experience,
      id,
      dataForTile,
    })[0],
    DelayTileContent({
      experience,
      dataForTile,
      isReminder: true,
    })[0],
  ];
};
