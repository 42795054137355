import React from 'react';

const Close = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/actions/close</title>
    <g fill={props.color} fillRule="nonzero">
      <path d="M17.127 5.46L5.813 16.772a.75.75 0 1 0 1.06 1.06L18.187 6.52a.75.75 0 0 0-1.06-1.06z" />
      <path d="M5.813 6.52l11.314 11.314a.75.75 0 1 0 1.06-1.061L6.873 5.459a.75.75 0 0 0-1.06 1.06z" />
    </g>
  </svg>
);

export default Close;
