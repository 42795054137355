import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.664 2.282a1.89 1.89 0 0 0-1.752 1.4l-2.79 10.475a.277.277 0 0 0 0 .158l.818 2.615-.246.748a.286.286 0 0 0-.008.231c.03.074.092.132.168.158a.285.285 0 0 0 .368-.205l.255-.774 2.2-1.804a.28.28 0 0 0 .089-.141l2.482-9.305 1.057.282c.099.026.15.115.123.22l-1.11 4.322a.283.283 0 0 0 .203.344.283.283 0 0 0 .343-.203l1.11-4.322a.747.747 0 0 0-.52-.907l-1.065-.29.167-.617a1.898 1.898 0 0 0-1.329-2.324 1.83 1.83 0 0 0-.563-.061zm.15.563a1.32 1.32 0 0 1 1.197 1.673l-.168.625-2.553-.687.168-.625a1.313 1.313 0 0 1 1.355-.986zM16.15 5.002l2.552.686-.21.784-2.554-.678.212-.792zm-.353 1.338l2.553.678-2.06 7.702-2.552-.678 2.06-7.702zm-1.971 8.3l2.068.564-1.505 1.241-.563-1.804zm-9.727 1.726c-.767 0-1.6.418-2.694 1.171a.28.28 0 0 0-.076.393.283.283 0 0 0 .18.119.284.284 0 0 0 .213-.045c1.063-.731 1.83-1.074 2.377-1.074.89 0 1.32.473 1.866 1.038.546.566 1.205 1.215 2.359 1.215 1.132 0 2.422-.521 4.05-1.153a.281.281 0 0 0 .016-.535.28.28 0 0 0-.228.016c-1.627.631-2.885 1.109-3.838 1.109-.953 0-1.413-.487-1.954-1.048-.542-.561-1.168-1.206-2.271-1.206z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
