import { Button, Flex } from '@evidation/ui';
import { useMemo, useState } from 'react';
//@ts-expect-error
import DropdownMenu from 'react-dd-menu';
import WorkerNotification from './WorkerNotification';
import styled from 'styled-components';

type NotificationProps = {
  jobs: string[];
};

const NotificationDropdownWrapper = styled.div`
  .dd-menu-items {
    min-width: 600px;
  }
`;

const Notification: React.FC<NotificationProps> = ({ jobs }) => {
  const [open, setOpen] = useState(false);
  const hasNotifications = jobs.length > 0;
  /**
   * when calling react-dd-menu from a function component
   * The options must be memoized to stop infinite re-renders
   */
  const menuOptions = useMemo(() => {
    return {
      isOpen: open,
      close: () => setOpen(false),
      toggle: (
        <Button
          size="small"
          onClick={() => setOpen(!open)}
          variant="transparent"
          style={{
            padding: 0,
            margin: 0,
            width: 36,
            height: 36,
            position: 'relative',
          }}
        >
          {hasNotifications && (
            <Flex
              justify="center"
              items="center"
              style={{
                top: 0,
                right: 5,
                position: 'absolute',
                fontSize: 10,
                width: 16,
                height: 16,
                color: '#FFF',
                background: '#9E47B2',
                borderRadius: 99999,
                border: '2px solid #fff',
              }}
            >
              {jobs.length}
            </Flex>
          )}
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9375 14H12.4688V7.35938C12.4688 4.60352 10.4316 2.32422 7.78125 1.94531V1.1875C7.78125 0.755859 7.43164 0.40625 7 0.40625C6.56836 0.40625 6.21875 0.755859 6.21875 1.1875V1.94531C3.56836 2.32422 1.53125 4.60352 1.53125 7.35938V14H1.0625C0.716797 14 0.4375 14.2793 0.4375 14.625V15.25C0.4375 15.3359 0.507812 15.4062 0.59375 15.4062H4.8125C4.8125 16.6133 5.79297 17.5938 7 17.5938C8.20703 17.5938 9.1875 16.6133 9.1875 15.4062H13.4062C13.4922 15.4062 13.5625 15.3359 13.5625 15.25V14.625C13.5625 14.2793 13.2832 14 12.9375 14ZM7 16.3438C6.48242 16.3438 6.0625 15.9238 6.0625 15.4062H7.9375C7.9375 15.9238 7.51758 16.3438 7 16.3438Z"
              fill={'#555'}
            />
          </svg>
        </Button>
      ),
      align: 'right',
    };
  }, [jobs, hasNotifications, open]);
  return (
    <NotificationDropdownWrapper>
      <DropdownMenu {...menuOptions}>
        {jobs.map((jobId) => (
          <WorkerNotification jobId={jobId} key={jobId} />
        ))}
      </DropdownMenu>
    </NotificationDropdownWrapper>
  );
};

export default Notification;
