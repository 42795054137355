import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DroppableBranchWrapper, OuterDashboardContainer } from './components';
import { array, string, bool } from 'prop-types';

import DashboardSequence from './DashboardSequence';
import React from 'react';
import _ from 'lodash';

export const getItemStyle = (draggableStyle, isDragging) => ({
  style: {
    ...draggableStyle,
    userSelect: 'none',
    // position initial fixes drag
    position: `initial`,
  },
});

class InnerList extends React.Component {
  render() {
    const { list_of_sequences, url, isSeqDragDisabled } = this.props;
    return _.map(list_of_sequences, (sequence_uuid, index) => {
      return (
        <Draggable
          draggableId={sequence_uuid}
          key={sequence_uuid}
          isDragDisabled={isSeqDragDisabled}
          index={index}
        >
          {(provided, snapshot) => {
            const sequenceIsDragging = snapshot.isDragging;
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DashboardSequence
                  {...snapshot}
                  sequenceIsDragging={sequenceIsDragging}
                  sequence_uuid={sequence_uuid}
                  url={url}
                />
                {provided.placeholder}
              </div>
            );
          }}
        </Draggable>
      );
    });
  }
}

const NestedSequence = (props) => {
  const {
    display,
    type,
    direction,
    droppableId,
    list_of_sequences,
    url,
    isSeqDragDisabled,
  } = props;

  const ListOfSequences = (
    <Droppable
      droppableId={droppableId}
      direction={direction}
      type={`SLATE/${type}`}
    >
      {(provided, snapshot) => (
        <DroppableBranchWrapper ref={provided.innerRef}>
          <InnerList
            isSeqDragDisabled={isSeqDragDisabled}
            list_of_sequences={list_of_sequences}
            url={url}
          />
          {provided.placeholder}
        </DroppableBranchWrapper>
      )}
    </Droppable>
  );

  if (display === `arms`) {
    // arms must be wrapped in an `outerdashboardcontainer
    return <OuterDashboardContainer>{ListOfSequences}</OuterDashboardContainer>;
  }

  return ListOfSequences;
};

NestedSequence.defaultProps = {
  display: undefined,
  type: `SEQUENCE`,
  direction: `horizontal`,
  droppableId: `main_branch`,
  list_of_sequences: [],
  isSeqDragDisabled: false,
};

NestedSequence.propTypes = {
  display: string,
  type: string,
  direction: string,
  droppableId: string,
  list_of_sequences: array.isRequired,
  isSeqDragDisabled: bool,
};

export default NestedSequence;
