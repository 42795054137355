import _ from 'lodash';
import { Sequences } from '../redux/graph';
export default (sequences: Sequences) => {
  const SequencesWithBranches = _.filter(
    sequences,
    ({
      content: {
        branching: {
          batch_size,
          strategy,
          caps_by_arm,
          arms_by_condition_value,
          arm_names,
        } = {
          batch_size: undefined,
          strategy: undefined,
          caps_by_arm: undefined,
          arms_by_condition_value: undefined,
          arm_names: undefined,
        },
      } = {},
    }) =>
      (strategy && caps_by_arm) ||
      (strategy && arms_by_condition_value) ||
      (strategy && arm_names),
  );

  return _.uniqWith(
    _.flatten(
      _.map(
        SequencesWithBranches,
        ({
          content: {
            branching: {
              batch_size,
              strategy,
              caps_by_arm,
              arms_by_condition_value,
              arm_names,
            } = {
              batch_size: undefined,
              strategy: undefined,
              caps_by_arm: undefined,
              arms_by_condition_value: undefined,
              arm_names: undefined,
            },
          },
        }) => {
          if (caps_by_arm) {
            return _.map(caps_by_arm, (cap, name) => ({
              name,
              cap,
              batch_size,
              strategy,
            }));
          } else if (arms_by_condition_value) {
            return _.map(arms_by_condition_value, (arm) => ({
              name: arm,
              strategy,
            }));
          } else if (arm_names) {
            return arm_names.map((name: string) => ({
              strategy: 'list',
              name,
            }));
          }
        },
      ),
    ),
    _.isEqual,
  );
};
