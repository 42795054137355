import React from 'react';
import styled from 'styled-components';

export const Item = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #e7e7e7;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  background-color: transparent;
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  display: block;
  width: 100%;
  & :focus-within {
    background-color: #d6f0ff;
  }
  & .item-heading {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .item-body-wrapper {
    margin-left: 1rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    > * + * {
      margin-left: 0.5rem;
    }
  }

  & .item-body-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * + * {
      margin-top: 0.5rem;
    }
    & .version-comments {
      text-overflow: ellipsis;
    }
  }
`;

export const Container = styled.div`
  height: calc(100vh - 125px);
  overflow-y: scroll;
  ${Item} {
    &:last-child {
      border-bottom: 1px solid #e7e7e7;
    }
  }
`;

export const Heading = styled.h3`
  color: #666666;
  font-weight: 300;
  font-size: 0.7rem;
  padding: 1rem 0rem 0.2rem 1rem;
`;

export const Dot = styled.div`
  border-radius: 50%;
  height: 0.8rem;
  width: 0.8rem;
  background-color: #20b1a8;
`;

export const MenuItemWrapper = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  width: 100%;

  text-align: left;

  & a {
    color: #222;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 0rem 0.5rem 1rem;
  }

  & a:focus,
  :hover {
    background-color: #f8f8f8;
  }
`;

export const MenuButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  cursor: pointer;
  width: 100%;
  &:hover,
  :focus {
    background-color: #f8f8f8;
  }
`;

export const NoVersionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 150px);
  color: #787878;
`;

const VerticalEllipsis = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  border: 0;
  background-color: transparent;
`;

export const CustomToggle = React.forwardRef((props, ref) => {
  return (
    <VerticalEllipsis ref={ref} {...props}>
      &#8942;
    </VerticalEllipsis>
  );
});
