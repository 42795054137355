import _ from 'lodash';
import { userconst } from '../reducers/contants';
import { kickOut } from './kickout';
import { triage } from './triage';

_.forEach(triage, (value) => {
  value.interceptors.request.use(
    (config) => {
      if (!config.headers.Authentication) {
        config.headers['Authentication'] = localStorage.getItem(
          userconst.token,
        );
      }
      if (!config.headers['User-Email']) {
        config.headers['User-Email'] = localStorage.getItem(userconst.username);
      }
      // User-Email will only be used in integration tests to pass role-specific emails
      return config;
    },
    (error) => Promise.reject(error),
  );

  value.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response?.status === 401) {
        // covering the case where the bearer token is invalid
        kickOut();
      }
      return Promise.reject(error);
    },
  );
});
