import React, { Component } from 'react';

import Button from '../../../components/inputs/Button';
import CuriousPanda from '../../../components/fields/CuriousPanda';
import CustomNavigationPrompt from '../../../components/CustomNavigationPrompt';
import OnAddHOC from '../hoc/onAddHOC';
import Schemas from '../forms';
import TileWrapper from './tile-wrapper';
import _ from 'lodash';
import { connect } from 'react-redux';
import updateGraph from '../hoc/updateGraphHOC';

const resource_name_dictionary = {
  add_archipelago: 'Alternative Route',
  sequence: 'Sequence',
};

// TODO: this could be refactored into the 'edit' component
class Add extends Component {
  state = { contentHasChanged: false };

  UNSAFE_componentWillMount() {
    const { history, location, parentPath } = this.props;
    // if we don't have a target or a type by default
    // close the modal by navigating back to the dashboard

    if (!location.state.resource) {
      history.push(parentPath);
    }
  }

  render() {
    const {
      slate,
      data,
      location: { state: { resource } = {} },
      onAdd,
      history: { push },
    } = this.props;
    const { contentHasChanged } = this.state;

    const schema = Schemas[resource].form['Content']({}, slate);

    return (
      <React.Fragment>
        <CustomNavigationPrompt when={contentHasChanged} />

        <TileWrapper
          heading={`Add ${resource_name_dictionary[resource]}`}
          {...this.props}
        >
          {schema ? (
            <CuriousPanda
              form={`add_${resource}`}
              initialValues={data}
              schema={schema}
              onChange={() => this.setState({ contentHasChanged: true })}
              onSubmit={d =>
                this.setState({ contentHasChanged: false }, () =>
                  onAdd(d).then(() => push && push(this.props.parentPath)),
                )
              }
            >
              {props => (
                <Button disabled={!props.valid} type="primary">
                  Submit
                </Button>
              )}
            </CuriousPanda>
          ) : (
            <button onClick={() => this.onAdd({ resource })}>Add</button>
          )}
        </TileWrapper>
      </React.Fragment>
    );
  }
}
export default connect(
  ({ slate }, { location: { state } = {} }) => {
    return {
      slate,
      data: {
        ..._.merge({}, state, {
          title: `New ${state.resource}`,
          content: {
            type: state.resource,
          },
        }),
      },
    };
  },
  {},
)(OnAddHOC(updateGraph(Add)));
