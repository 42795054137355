import Modal from '../modal/SimpleModal';
import { Header, Container, Button } from './components';
import { useWebhooks } from './useWebhooks';
import { WebhookList } from './WebhookList';
import { WebhookForm } from './WebhookForm';
import { transformIncoming } from './dataTransforms';
import ConfirmModal from '../modal/ConfirmModal';

export const Webhooks: React.FC<{ graph_id: string }> = ({ graph_id }) => {
  const {
    data,
    fetchError,
    serviceError,
    loading,
    createWebhook,
    deleteWebhook,
    updateWebhook,
    editWebhook,
    removeWebhook,
    modalState,
    webhookDetails,
    setModalState,
    setServiceState,
  } = useWebhooks({ graphId: graph_id });

  const onCloseModal = () => {
    setModalState(null);
    setServiceState({ type: 'loaded' });
  };

  return (
    <Container>
      <Header>
        <h1>Webhooks</h1>
        <div className="spacer" />
        <Button onClick={() => setModalState({ shownModal: 'create' })}>
          Create Webhook
        </Button>
      </Header>
      <hr />
      <WebhookList
        data={data}
        loading={loading}
        error={fetchError}
        editWebhook={editWebhook}
        removeWebhook={removeWebhook}
      />
      {modalState?.shownModal === 'create' && (
        <Modal showX onClose={onCloseModal} modalTitle="Create Webhook">
          <WebhookForm onSubmit={createWebhook} error={serviceError} />
        </Modal>
      )}
      {modalState?.shownModal === 'edit' && webhookDetails && (
        <Modal
          showX
          modalTitle={`Edit Webhook: ${modalState.selectedWebhook.name}`}
          onClose={onCloseModal}
          holderClassName="webhooks-modal"
        >
          <div className="noMaxWidth">
            <WebhookForm
              initialValues={transformIncoming(webhookDetails)}
              error={serviceError}
              onSubmit={async (values) => {
                await updateWebhook(values);
              }}
            />
          </div>
        </Modal>
      )}
      {modalState?.shownModal === 'confirm' && (
        <Modal
          showX
          modalTitle={`Are you sure you want to delete ${modalState.selectedWebhook.name}`}
          onClose={() => setModalState(null)}
          holderClassName="webhooks-modal"
        >
          <div style={{ padding: '1rem' }}>
            {/* @ts-expect-error */}
            <ConfirmModal
              cancelAction={() => setModalState(null)}
              confirmAction={() => {
                deleteWebhook(modalState.selectedWebhook);
                setModalState(null);
              }}
            />
          </div>
        </Modal>
      )}
    </Container>
  );
};
