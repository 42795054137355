import * as C from '../common';

import { default_experience_key } from '../../../../constants';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          C.archipelago_identifier,
          C.sequence_in_archipelago,
          C.archipelago_type,
        ],
      },
    ],
  ];
};
