import { ReminderChevron, ReminderListContainer } from '..';
import { array, bool, func, object, oneOfType } from 'prop-types';

import AddReminder from '../addReminder';
import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import ReminderItem from './reminderListItem';
import _ from 'lodash';
import { connect } from 'react-redux';
import { selectResource } from '../../../../actions/slate_actions';
import { checkIsAuthorized } from '../../../../components/auth';
import { AuthActions } from '../../../../constants';

const determineSelected = (selected, reminder_uuid, selected_resources) => {
  if (selected) {
    return selected === reminder_uuid;
  } else {
    return _.keys(selected_resources).includes(reminder_uuid);
  }
};

const getItemStyle = (draggableStyle, isDragging) => ({
  userSelect: 'none',
  position: `initial`,
  background: isDragging ? `#FFF` : `transparent`,
  ...draggableStyle,
});

export class ReminderList extends React.PureComponent {
  render() {
    const {
      reminders,
      reminder_ids,
      sequence_uuid,
      related_reminders,
      allowAdd,
      selected_resources,
      selectResource,
      onClick,
      selected,
      uuid,
      configure: { active_experience },
      authorization,
      graphId,
    } = this.props;

    return (
      <ReminderListContainer id="t-reminders">
        {_.map(
          _.filter(reminder_ids, (id) => reminders[id]),
          (reminder_uuid, idx) => {
            if (_.has(related_reminders, reminder_uuid)) {
              // delay will be a reminder object or undefined if none exists
              const delay = reminders[related_reminders[reminder_uuid]];

              const offset = _.isUndefined(delay)
                ? '0min'
                : delay.content.offset;
              const units = _.isUndefined(delay) ? '' : delay.content.units;

              const reminder = reminders[reminder_uuid];

              const title = _.has(reminder, `content.experiences`)
                ? reminder.content.experiences[active_experience].title
                : reminder.content.title;

              const isDraggable =
                window.location.pathname.includes('slate') &&
                checkIsAuthorized(authorization, graphId, [
                  AuthActions.graphs.publish,
                  AuthActions.tiles.update,
                  AuthActions.sequences.update,
                ]);

              if (isDraggable) {
                return (
                  <Draggable
                    draggableId={reminder_uuid}
                    key={reminder_uuid}
                    type="SLATE/REMINDER"
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        style={getItemStyle(
                          provided.draggableProps.style,
                          snapshot.isDragging,
                        )}
                        {...provided.dragHandleProps}
                      >
                        <ReminderItem
                          onClick={(e) =>
                            _.isFunction(onClick)
                              ? onClick({ reminder_uuid })
                              : selectResource({
                                  [reminder_uuid]: {
                                    sequence_uuid,
                                    resource: `reminder`,
                                    reminder_uuid,
                                    uuid,
                                  },
                                })
                          }
                          selected={determineSelected(
                            selected,
                            reminder_uuid,
                            selected_resources,
                          )}
                          offset={offset}
                          reminder_uuid={reminder_uuid}
                          units={units}
                          idx={idx}
                          title={title}
                        />
                      </li>
                    )}
                  </Draggable>
                );
              }

              return (
                <li key={reminder_uuid}>
                  <ReminderItem
                    onClick={(e) =>
                      _.isFunction(onClick)
                        ? onClick({ reminder_uuid })
                        : selectResource({
                            [reminder_uuid]: {
                              sequence_uuid,
                              resource: `reminder`,
                              reminder_uuid,
                              uuid,
                            },
                          })
                    }
                    ref={
                      determineSelected(
                        selected,
                        reminder_uuid,
                        selected_resources,
                      )
                        ? this.props.selectedRef
                        : null
                    }
                    selected={determineSelected(
                      selected,
                      reminder_uuid,
                      selected_resources,
                    )}
                    offset={offset}
                    reminder_uuid={reminder_uuid}
                    units={units}
                    idx={idx}
                    title={title}
                  />
                </li>
              );
            } else {
              return null;
            }
          },
        )}
        {allowAdd && <AddReminder {...this.props} />}

        <ReminderChevron />
      </ReminderListContainer>
    );
  }
}

ReminderList.defaultProps = {
  onClick: false,
  reminder_ids: [],
  reminderMapping: {},
  allowAdd: true,
  selectedRef: {},
  configure: {},
};

ReminderList.propTypes = {
  onClick: oneOfType([func, bool]),
  reminder_ids: array,
  reminderMapping: object,
  allowAdd: bool.isRequired,
  selectedRef: object,
};

export default connect(
  ({
    configure,
    reminders,
    related_reminders,
    slate: { selected_resources },
    user: { authorization },
    graph: { id },
  }) => ({
    reminders,
    related_reminders,
    selected_resources,
    configure,
    authorization,
    graphId: id,
  }),
  { selectResource },
)(ReminderList);
