export default () => {
  return {
    content: {
      experiences: {
        default: {
          faq: ``,
          title: '',
          hero_title: '',
          hero_content: ``,
          hero_button: `Check Your Eligibility`,
          how_you_help_heading: '',
        },
      },
      type: 'education',
    },
  };
};
