"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateColorValue = validateColorValue;
exports["default"] = void 0;

var _validateColor = _interopRequireDefault(require("validate-color"));

var _default = function _default(props) {
  return [validateColorValue];
};

exports["default"] = _default;

function validateColorValue(value) {
  if (!(0, _validateColor["default"])(value)) {
    return 'invalid color';
  }
}