import { merge } from 'lodash';

import { Graph, Tile, TileCallback } from 'src/redux/graph';

const CASTOR_FUNCTION_ID = 'castor.push';

/**
 * Represents a component within a Screener tile. Takes the form of
 *   'TILE_ID:COMPONENT_NAME'.
 */
export type ComponentString = `${string}:${string}`;
export const componentString = (
  tileId: string,
  componentName: string,
): ComponentString => `${tileId}:${componentName}`;

const getCastorCallback = (tile: Tile): TileCallback | undefined =>
  tile.content.callbacks?.after_commit?.find(
    (callback) => callback.function_id === CASTOR_FUNCTION_ID,
  );

/**
 * Returns a copy of the given tile with Castor integration enabled for the
 *   given attributes.
 */
export const tileWithCastor = (tile: Tile, attributes: string[]): Tile => {
  let castorCallback = getCastorCallback(tile) || {
    function_id: CASTOR_FUNCTION_ID,
    attributes: [],
  };
  castorCallback = {
    ...castorCallback,
    attributes: [
      ...castorCallback.attributes,
      ...attributes.map((attr) => ({ source: attr })),
    ],
  };
  const otherCallbacks =
    tile.content.callbacks?.after_commit?.filter(
      (callback) => callback.function_id !== CASTOR_FUNCTION_ID,
    ) ?? [];
  return {
    ...tile,
    content: {
      ...tile.content,
      callbacks: {
        ...tile.content.callbacks,
        after_commit: [...otherCallbacks, castorCallback],
      },
    },
  };
};

/**
 * Returns a copy of the given tile with Castor integration disabled for the
 *   given attributes.
 */
export const tileWithoutCastor = (tile: Tile, attributes: string[]): Tile => ({
  ...tile,
  content: {
    ...tile.content,
    callbacks: {
      ...tile.content.callbacks,
      after_commit: tile.content.callbacks?.after_commit
        // Remove the passed in attributes from the Castor callbacks.
        ?.map((callback) =>
          callback.function_id === CASTOR_FUNCTION_ID
            ? {
                function_id: CASTOR_FUNCTION_ID,
                attributes: callback.attributes.filter(
                  (attribute) => !attributes.includes(attribute.source),
                ),
              }
            : callback,
        )
        // Remove Castor callbacks without any attributes.
        .filter(
          (callback) =>
            callback.function_id !== CASTOR_FUNCTION_ID ||
            callback.attributes.length > 0,
        ),
    },
  },
});

/**
 * Given a set of `ComponentString`s, returns the component names grouped by
 *   tile ID.
 */
export const groupComponentsByTileId = (
  components: Set<ComponentString> | ComponentString[],
) =>
  Array.from(components).reduce<Record<string, string[]>>(
    (additions, componentString) => {
      const [tileId, componentName] = componentString.split(':');
      if (!additions.hasOwnProperty(tileId)) {
        additions[tileId] = [];
      }
      additions[tileId].push(componentName);
      return additions;
    },
    {},
  );

/**
 * For the given tile, returns an array of component strings for all components
 *   that are to be pushed to Castor.
 */
export const getCastorEnabledComponentsForTile = (
  tile: Tile,
): ComponentString[] =>
  getCastorCallback(tile)?.attributes?.map((attr) =>
    componentString(tile.id, attr.source),
  ) ?? [];

export const isGraphEnabled = (graph: Graph) =>
  !!graph.content.supported_features?.castor_data_pushes;

export const graphWithCastorIntegration = (graph: Graph, enabled: boolean) =>
  merge({}, graph, {
    content: { supported_features: { castor_data_pushes: enabled } },
  });
