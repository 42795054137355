import React, { useEffect, useState } from 'react';

import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { loginUser } from '../actions/user_actions';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { useQuery } from 'react-query';
import LoadingIndicator from './LoadingIndicator';
import { getCognitoAuthConfig as realGetCognitoAuthConfig } from '../api/user';
import EvidationLogo from '../assets/EvidationLogo';

const text = {
  logInWithGoogle: 'Log In with Google',
  logInWithOkta: 'Log In with Okta',
  errorFetchingConfig: 'Error fetching Cognito configuration for Okta login.',
  problemLoggingIn: 'There was a problem logging you in.',
};

const LogoContainer = styled.div`
  text-align: center;
`;

const LoginContainer = styled.div`
  border-radius: 3px;
  padding: 32px;
  background: #093b4f;
  width: 255px;
`;

const PageContainer = styled.div`
  height: 100vh;
  background: #061e26;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.p`
  color: #fff;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  button {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 0.75em 1em;
    position: relative;
    border-radius: 3;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(-180deg, #0d5875 0%, #0c4e69 100%);
    border: 1px solid #083245;
    &:active {
      background: linear-gradient(0deg, #0c526e 0%, #0c4e69 100%);
    }
    &:hover {
      cursor: pointer;
      background: linear-gradient(-180deg, #0c526e 0%, #0b4861 100%);
    }
    &:disabled {
      color: #3e83a0;
      background: linear-gradient(-180deg, #0c526e 0%, #0b4861 100%);
      cursor: default !important;
      &:hover {
        cursor: default !important;
      }
    }

    // Space between
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

/**
 * Checks to see if we were redirected here from a Cognito login.
 */
const isThirdPartyLoginRedirect = () => {
  const queryParams = new URLSearchParams(window.location);
  // These two query params are part of the OAuth standard.
  return queryParams.has('code') && queryParams.has('state');
};

const defaultConfigureAmplify = Amplify.configure.bind(Amplify);
const defaultFederatedSignIn = Auth.federatedSignIn.bind(Auth);
// Defining `hub` as an object makes it easier to mock only the methods we care
// about, especially with TypeScript.
const defaultHub = {
  listen: Hub.listen.bind(Hub),
  remove: Hub.remove.bind(Hub),
};

export function LoginForm({
  loginUser,
  // These props are here to make mocking easier:
  getCognitoAuthConfig = realGetCognitoAuthConfig,
  configureAmplify = defaultConfigureAmplify,
  federatedSignIn = defaultFederatedSignIn,
  hub = defaultHub,
  showErrorMessage = toastr.error,
}) {
  const [loading, setLoading] = useState(true);
  const [oktaLoginSupported, setOktaLoginSupported] = useState(false);

  const {
    data: cognitoAuthConfigResponse,
    error,
    isLoading,
  } = useQuery(['getCognitoAuthConfig'], () => getCognitoAuthConfig(), {
    retry: false,
    // The auth config is not bound to change, so we don't need to refetch.
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        // A 404 means that no Okta config was returned, in which case we just
        // ignore the error and don't show the button.
        if (error?.response?.status !== 404) {
          console.error(error);
          showErrorMessage(text.errorFetchingConfig);
        }
      } else if (cognitoAuthConfigResponse) {
        const redirectUrl = `${window.location.origin}/`;
        const clientId =
          cognitoAuthConfigResponse.data.study_manager_okta_client_id;
        configureAmplify({
          Auth: {
            region: cognitoAuthConfigResponse.data.region,
            userPoolId:
              cognitoAuthConfigResponse.data.study_manager_okta_pool_id,
            clientId,
            userPoolWebClientId: clientId,
            oauth: {
              domain:
                cognitoAuthConfigResponse.data.study_manager_okta_pool_domain,
              scope: cognitoAuthConfigResponse.data.scope,
              redirectSignIn: redirectUrl,
              redirectSignOut: redirectUrl,
              responseType: cognitoAuthConfigResponse.data.responseType,
            },
          },
        });
        setOktaLoginSupported(true);
      }
      if (!isThirdPartyLoginRedirect()) {
        setLoading(false);
      }

      const listener = ({ payload: { event, data, message } }) => {
        if (event === 'signIn') {
          setLoading(false);
          loginUser({
            token: data.signInUserSession.idToken.jwtToken,
            username: data.signInUserSession.idToken.payload.email,
          });
        } else if (event === 'signIn_failure') {
          showErrorMessage(text.problemLoggingIn);
          setLoading(false);
          throw new Error(
            `Received Cognito event 'signIn_failure' with message: "${message}".`,
          );
        }
      };
      hub.listen('auth', listener);
      return () => {
        hub.remove('auth', listener);
      };
    }
  }, [
    isLoading,
    cognitoAuthConfigResponse,
    error,
    loginUser,
    getCognitoAuthConfig,
    configureAmplify,
    hub,
    showErrorMessage,
  ]);

  const logInWithOkta = async () => {
    try {
      setLoading(true);
      await federatedSignIn({ provider: 'Okta' });
    } catch (error) {
      showErrorMessage(error.toString());
      setLoading(false);
    }
  };

  // We have two google_auth_client_id's : one is setup for all production and stage environments, as well as http://localhost:8001
  // A second is setup for heroku and local development under http://localhost:8001
  // This is the default clientID, we need for production
  const defaultGoogleClientId = `27946411914-f7q63vnm2a2u6hefotmam4gdosudj5tk.apps.googleusercontent.com`;
  // If the config-xx.js file specifies a different clientId, we will use that instead (see config_heroku.js)
  const googleLoginClientID =
    window.env.GOOGLE_OAUTH_CLIENT_ID || defaultGoogleClientId;

  return (
    <PageContainer id="t-loginPanel">
      <LogoContainer>
        <EvidationLogo color="#FFF" width='100%'  height='40'/>
      </LogoContainer>
      <LoginContainer>
        {loading ? (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        ) : (
          <>
            <Message>Sign into Study Manager</Message>
            <Buttons>
              <GoogleLogin
                disabled={false}
                style={{}}
                clientId={googleLoginClientID}
                onRequest={() => {
                  setLoading(true);
                }}
                onSuccess={({
                  tokenId: token,
                  profileObj: { email: username },
                }) => {
                  setLoading(false);
                  loginUser({ token, username });
                }}
                onFailure={(error) => {
                  toastr.error(error.reason);
                  setLoading(false);
                }}
              >
                <div id="t-login_button">{text.logInWithGoogle}</div>
              </GoogleLogin>
              {oktaLoginSupported && (
                <button onClick={logInWithOkta}>{text.logInWithOkta}</button>
              )}
            </Buttons>
          </>
        )}
      </LoginContainer>
    </PageContainer>
  );
}

export default connect(null, {
  loginUser,
})(LoginForm);
