import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const TerminalStateTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
      }),
    ],
  }),
});

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    terminal_state: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [
          C.experienceExternalTitle(masonryEnabled),
          C.experienceExternalDescription(masonryEnabled),
        ],
      }),
    },
  ];

  if (!masonryEnabled) {
    schema.push(TerminalStateTileSettings(experience));
  }

  return [schema];
};
