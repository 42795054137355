import _ from 'lodash';
import { AnyAction } from 'redux';
import types from '../constants';
import { Graph } from '../redux/graph';

export const defaultState: Graph = {
  content: {
    slug: '',
    title: '',
  },
  sequence_ids: [],
  creating_job: null,
};

export default (
  state: Graph = defaultState,
  { payload, type }: AnyAction,
): Graph => {
  switch (type) {
    case types.graph.set:
    case types.graph.update:
      return {
        ...payload,
      };

    case types.graph.clear:
      return {
        ...defaultState,
      };

    case types.graph.modifyAtPath:
      return {
        ..._.set(state, payload.path, payload.data),
      };

    case types.graph.set_creating:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
