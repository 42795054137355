import React from 'react';

const NavRecurring = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 19 22" {...props}>
    <title>icons/nav/nav_recurring</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Menus/menu_recurringjobs"
        transform="translate(-31.000000, -284.000000)"
        fill={props.color}
      >
        <g id="recurring_icon" transform="translate(30.000000, 284.000000)">
          <path
            d="M9.80283092,15.3196106 L7.00568194,13.0651725 C6.74985862,12.8458954 6.36551454,12.8757414 6.14684652,13.1315648 C5.92756939,13.3873881 5.95741544,13.771123 6.21262966,13.9897911 L9.47635164,16.6444099 C9.59999957,16.751003 9.7601937,16.8021676 9.92221513,16.7893765 C10.0842366,16.7759762 10.2340759,16.6998383 10.3388417,16.5749722 L15.0844919,10.0020125 C15.3013326,9.74497097 15.2684411,9.36001779 15.0107904,9.14317707 C14.7537489,8.92633635 14.3694048,8.95983702 14.1525641,9.21626944 L9.80283092,15.3196106 Z"
            id="Fill-49"
          />
          <path
            d="M12.0072316,6.14135848 L10.4684955,3.45590649 L13.0431816,1.76528641 C13.3345619,1.57450577 13.4250758,1.16653888 13.2452879,0.857593074 C13.0655001,0.54666685 12.6836059,0.449626181 12.3922256,0.641727097 L9.29243489,2.67694058 C9.00291444,2.86772122 8.91116064,3.27304755 9.08846867,3.58331363 L10.9483431,6.82856484 C11.126891,7.13883093 11.5081653,7.23851216 11.8007855,7.04773153 C12.0927858,6.85827117 12.1851595,6.45162456 12.0072316,6.14135848"
            id="Fill-61"
            transform="translate(11.167906, 3.843902) rotate(-12.000000) translate(-11.167906, -3.843902) "
          />
          <path
            d="M5.78499248,4.79486136 C5.45920451,4.53030677 5.18314523,4.50922579 4.95681464,4.73161843 C3.20106931,6.39649485 2.10612892,8.75135964 2.10581377,11.3623925 C2.10642287,16.408812 6.19594135,20.4983305 11.2423609,20.4989396 C16.2887804,20.4983305 20.3782989,16.408812 20.378908,11.3623925 C20.3813444,10.3585905 20.2065318,9.60025711 19.9165987,8.73472155 C19.8106147,8.4149424 19.4658624,8.24195711 19.1466923,8.34794106 C18.8275223,8.453925 18.654537,8.79867738 18.7605209,9.11784743 C19.0309627,9.93952756 19.1576562,10.491375 19.1607017,11.3623925 C19.1607017,13.5515092 18.2756748,15.5274398 16.841846,16.9618777 C15.406799,18.3957065 13.4308685,19.2807333 11.2423609,19.2807333 C9.05385329,19.2807333 7.0779227,18.3957065 5.6428757,16.9618777 C4.20904691,15.5274398 3.32402005,13.5515092 3.32402005,11.3623925 C3.32402005,9.17388491 4.20904691,7.19795433 5.6428757,5.76412554 C5.99566758,5.38364685 6.04303984,5.06055879 5.78499248,4.79486136 Z"
            id="Fill-50"
            transform="translate(11.242373, 12.539103) rotate(-70.000000) translate(-11.242373, -12.539103) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default NavRecurring;
