import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../../LoadingIndicator';

const text = {
  PREVIEW_MSG:
    'You are previewing an older version. Editing the Slate is disabled during preview.',
  PREVIEW_BTN_TXT: 'Cancel Preview',
  PREVIEW_CONFIRM_MESSAGE_TXT:
    'This will exit the preview and load the current graph.',
  PREVIEW_CONFIRM_MESSAGE_TITLE: 'Confirm Exit Preview',
};

export const PreviewPane = styled.div`
  width: calc(100vw - 481px);
  height: 100vh;
  position: absolute;
  top: 62px;
  left: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.loading ? 0.8 : 0)};
  background-color: #222;
  transition: opacity 0.5s ease-in-out;
`;

const PreviewBanner = styled.div`
  width: calc(100vw - 481px);
  position: absolute;
  top: 62px;
  left: 81px;
  height: 50px;
  background-color: #ebefff;
  opacity: 1;
  color: #5e5e5e;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  box-shadow: 1px 1px 1px #e7e7e7;
  font-weight: 600;
  z-index: 11;
  text-align: center;
  .loader-fixed {
    width: 50px;
  }
`;

export default function VersionPreview({ loading }) {
  return (
    <>
      <PreviewBanner role="alert">
        <span>{text.PREVIEW_MSG}</span>
        <span className="loader-fixed">
          {loading && (
            <LoadingIndicator width={36} height={18}></LoadingIndicator>
          )}
        </span>
      </PreviewBanner>
      <PreviewPane loading={loading} role="banner" />
    </>
  );
}
