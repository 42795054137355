import * as C from '../common';
import _ from 'lodash';
import { incomingEmailData } from '../email';

export default (data) => {
  const follow_ups = [];

  if (data.content.body) {
    data.content.body = incomingEmailData(data.content.body);
  }

  // TODO: Abstract this out so it can be used with more than just email
  if (data.content.follow_up) {
    _.forEach(data.content.follow_up, (value, answer) => {
      const obj = {};
      obj['answer_value'] = answer;
      obj['resource_type'] =
        typeof value === 'string' ? value : Object.keys(value)[0];
      if (obj['resource_type'] === 'web') {
        obj['resource_value_web'] = value[obj['resource_type']];
      } else {
        obj['resource_value_node'] = value[obj['resource_type']];
      }
      follow_ups.push(obj);
    });
  }
  data.content['follow_up'] = follow_ups;
  return C.common_incoming(data);
};
