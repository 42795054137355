import { default_experience_key } from '../../../../constants';
import * as C from '../common';
import { getInputsForExperience } from '../utils';

/**
 * @description The tile level settings part of the schema for the connect_ehr
 * @param {String} experience
 * @param {Array} displayGroupNames
 * @param {Boolean} masonryEnabled - flag for new components
 * @returns {Object}
 */
export const ConnectEHRTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => {
  const label = masonryEnabled ? 'Settings' : 'More Settings';
  return {
    label,
    name: 'content',
    input: getInputsForExperience({
      experience,
      mandatoryInputs: [
        masonryEnabled ? C.experienceTileTitle(true) : C.title,
        C.experienceTileDescription(masonryEnabled),
        C.optional,
        C.display_group_name(displayGroupNames),
      ],
      optionalInputs: [
        ...C.dependencyCriteria({
          emitEvent: true,
          visibility: true,
          cancellation: true,
        }),
      ],
    }),
  };
};

export default ({
  displayGroupNames,
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    connect_ehr: { masonryEnabled = false },
  } = dataForTile;

  const setFieldName = (name) =>
    masonryEnabled
      ? `content.experiences.${experience}.settings.${name}`
      : name;

  const schema = [
    {
      label: 'Settings',
      name: setFieldName('ehr_settings'),
      input: [
        {
          label: 'Electronic Health Record Type',
          name: 'record_type',
          interface: 'select',
          disabled: true,
          possible_answers: ['EPIC MyChart'],
          initialValue: 'EPIC MyChart',
        },
      ],
    },
    {
      label: 'Contribution Tile',
      name: setFieldName('contribution_tile'),
      input: [
        {
          name: 'title',
          label: 'Title',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        C.description,
        {
          label: 'Button Label',
          name: 'button_label',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
      ],
    },
    {
      label: 'Introduction Page',
      name: setFieldName('intro'),
      input: [
        {
          label: 'Intro Headline',
          name: 'intro_headline',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Description',
          name: 'intro_desc',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Intro Tip',
          name: 'intro_tip',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Intro Button Label',
          name: 'intro_button_label',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          required: true,
        },
      ],
    },
    {
      label: 'Find and Connect Provider',
      name: setFieldName('find'),
      input: [
        {
          label: 'Title',
          name: 'find_title',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Description',
          name: 'find_desc',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Search Headline',
          name: 'find_search',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
      ],
    },
    {
      label: 'Connected List Page',
      name: setFieldName('connected'),
      input: [
        {
          label: 'Title',
          name: 'connected_title',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Description',
          name: 'connected_desc',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Headline',
          name: 'connected_headline',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Headline Description',
          name: 'connected_headline_desc',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'List Label',
          name: 'connected_list_label',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Add More Button Label',
          name: 'connected_add_more',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Done Button Label',
          name: 'connected_done_label',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
      ],
    },
    {
      label: 'Confirmation Modal',
      name: setFieldName('confirm'),
      input: [
        {
          label: 'Title',
          name: 'confirm_title',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Body',
          name: 'confirm_body',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Go Back Button Label',
          name: 'confirm_back',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Submit Button Label',
          name: 'confirm_submit',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      ConnectEHRTileSettings(
        experience,
        displayGroupNames,
      ),
    );
  }

  return schema;
};
