"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.validateWeight = void 0;

require("core-js/modules/es.function.name.js");

var _i18n = require("../../i18n");

var validateWeight = function validateWeight(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value && value[name] < 1) {
      return (0, _i18n.globalTranslate)('validations.greaterThanZero');
    }

    return undefined;
  };
};

exports.validateWeight = validateWeight;

var WeightValidations = function WeightValidations(props) {
  return [validateWeight(props)];
};

var _default = WeightValidations;
exports["default"] = _default;