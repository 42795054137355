import Schemas from '../forms';
import _ from 'lodash';
import no_type from '../forms/no_type';
import {
  translationFieldConstants,
  graphTranslationFields,
  arrayTranslationFields,
} from './translationFieldConstants';

/**
 * @description Takes array of fields for a schema and disables the schema
 * for the primary locale or fields that don't require translation, then
 * duplicate the fields that can be translated. The name of the field is modified
 * to include the locale.
 *
 * @example
 *  const locale = 'es'
 *  const fields = [{label: 'title', name: 'title', interface: 'textinput'}]
 *  createTranslationFields(locale, fields)
 *  // returns [{label: 'title', name: 'title', interface: 'textinput'}, {label: 'title (es)', name: 'title_es', interface: 'textinput'}]
 *
 * @param {String} locale - Current locale that user is working in
 * @param {Array} fields - Array of objects that make of the fields in a form
 * @param {String} type - current type for schema
 * @param {Boolean} includeTitle - should title be filtered from array
 * @returns {Object} - returns the schema object with the modified form fields
 * @author Corey Quin
 */
export const createTranslationFields = (
  locale,
  fields,
  type,
  includeTitle = false,
) => {
  const modifiedFields = includeTitle
    ? translationFieldConstants
    : translationFieldConstants.filter((item) => item !== 'title');
  const translationFieldList =
    type === 'graph' ? graphTranslationFields : modifiedFields;

  return fields.reduce((acc, field) => {
    if (
      field.interface === 'array' &&
      arrayTranslationFields.hasOwnProperty(field.name)
    ) {
      const newField = {
        ...field,
        disabled: true,
        items: getTranslatedItems(locale, field),
      };
      acc.push(newField);
      return acc;
    } else {
      // Disable the field that shows the configurations for the primary locale
      const disablePrimary = {
        ...field,
        disabled: true,
      };
      acc.push(disablePrimary);
    }

    // If the field matches a certain type it can have a translated string
    // this is how we determine if a field should be duplicated with and updated
    // with the locale information
    if (translationFieldList.includes(field.name)) {
      const translationName = `${field.name}_${locale}`;
      const isLabel = !!field.label;
      const translationFieldText = `${
        isLabel ? field.label : field.title
      } (${locale})`;
      const key = isLabel ? 'label' : 'title';
      const translationField = {
        ...field,
        name: translationName,
        showValidationWithoutTouch: true,
        [key]: translationFieldText,
      };
      acc.push(translationField);
    }
    return acc;
  }, []);
};

/**
 * @description Take array of sub-items for a field and disables the items
 * for the primary locale or fields that don't require translation, then
 * duplicate the fields that can be translated. The name of the field is modified
 * to include the locale.
 *
 * @example
 *  const locale = 'es'
 *  const field = {..., items: [{label: 'title', name: 'title', interface: 'textinput'}] }
 *  getTranslatedItems(locale, fields)
 *   // returns: [{{label: 'title',label: 'title (es)', name: 'title',name_es: 'title', interface: 'textinput'}}]
 *
 * @param {String} locale - Current locale that user is working in
 * @param {Array} fields - Array of objects that make of the fields in a form
 * @returns {Array} - returns the items/schema array with the modified form fields
 * @author Enrique Moya
 */
export const getTranslatedItems = (locale, field) => {
  const nonTranslatedFields = arrayTranslationFields[field.name] ?? [];
  const items = field.items.reduce((acc, item) => {
    const modifiedItem = {
      ...item,
      disabled: true,
    };
    if (nonTranslatedFields.includes(item.name)) {
      return [...acc, modifiedItem];
    } else {
      const isLabel = !!item.label;
      const translationFieldText = `${
        isLabel ? item.label : item.title
      } (${locale})`;
      const key = isLabel ? 'label' : 'title';

      const translatedItem = {
        ...item,
        disabled: false,
        showValidationWithoutTouch: true,
        name: `${item.name}_${locale}`,
        [key]: translationFieldText,
      };
      return [...acc, modifiedItem, translatedItem];
    }
  }, []);
  return items;
};

/**
 * @description Takes a tile's form schema and reconfigures it to allow for translations
 * for secondary locales.
 *
 * @param {Object}
 * @returns {Array} - modified form schema with disabled primary locale fields and enabled secondary locale
 * @author Corey Quin
 */
export const buildTranslationSchema = ({ currentLocale, schema, tileType }) => {
  let modifiedSchema = [];
  const transformFields = (fields) => ({
    ...fields,
    input: createTranslationFields(
      currentLocale,
      fields.input,
      tileType,
      fields.name?.includes('experiences'),
    ),
  });

  modifiedSchema = schema.map((fieldList) => {
    if (Array.isArray(fieldList)) {
      return fieldList.map((fields) => transformFields(fields));
    } else {
      return transformFields(fieldList);
    }
  });
  const result = modifiedSchema;
  return result;
};

// If you need to pass dynamic data into the component tile when editing, this is the place!!
const getSchema = (t, graph_id) => {
  const type = t.content.type;
  const form = _.has(Schemas, type) ? Schemas[type]['form'] : no_type['form'];
  return _.isFunction(form) ? form(t, graph_id) : form;
};

export default getSchema;
