import { useState, useCallback, useMemo } from 'react';
import { Webhook, WebhookService } from '../../api/webhooks';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { transformOutgoing } from './dataTransforms';
import { WebhookFormValues } from './WebhookForm';

export type CreateModalState = {
  shownModal: 'create';
};

export type EditModalState = {
  shownModal: 'edit';
  selectedWebhook: Webhook;
};

export type ConfirmModalState = {
  shownModal: 'confirm';
  selectedWebhook: Webhook;
};

export type ServiceStateLoading = {
  type: 'loading';
};

export type ServiceStateLoaded = {
  type: 'loaded';
};

export type ServiceStateError = {
  type: 'error';
  errorMessage: string;
};

export type ModalState =
  | null
  | CreateModalState
  | EditModalState
  | ConfirmModalState;

export type ServiceState =
  | ServiceStateLoading
  | ServiceStateLoaded
  | ServiceStateError;

export const useWebhooks = ({ graphId }: { graphId: string | number }) => {
  const [modalState, setModalState] = useState<ModalState>(null);
  const [serviceState, setServiceState] = useState<ServiceState>({
    type: 'loaded',
  });
  const { data, isLoading, error, refetch } = useQuery(
    [`webhooks_${graphId}`],
    () => WebhookService.getAll({ graphId }),
    {
      enabled: graphId ? true : false,
    },
  );

  const canGetWebhookDetails = useMemo(() => {
    return graphId && modalState?.shownModal === 'edit' ? true : false;
  }, [modalState, graphId]);

  const selectedWebhookId = useMemo(() => {
    if (canGetWebhookDetails) {
      const ms = modalState as EditModalState;
      return ms.selectedWebhook.id;
    }
    // Although this is an invalid id. it will never
    // fire with this value because of the enabled constraint on the useQuery hook
    return -1;
  }, [modalState, canGetWebhookDetails]);

  const {
    data: webhookDetails,
    refetch: refetchWebhookDetails,
    error: webhookDetailsError,
  } = useQuery(
    [`webhooks_${selectedWebhookId}_${graphId}`],
    () =>
      WebhookService.getEditById({
        graphId,
        webhookId: selectedWebhookId,
      }),
    {
      enabled: canGetWebhookDetails,
    },
  );

  const editWebhook = (webhook: Webhook) =>
    setModalState({ shownModal: 'edit', selectedWebhook: webhook });

  const removeWebhook = (webhook: Webhook) =>
    setModalState({ shownModal: 'confirm', selectedWebhook: webhook });

  const serviceError = useMemo(() => {
    if (serviceState.type === 'error') {
      return serviceState.errorMessage;
    }
    return null;
  }, [serviceState]);

  const loading = useMemo(() => {
    if (serviceState.type === 'loading' || isLoading) {
      return true;
    }
    return false;
  }, [isLoading, serviceState]);

  /**
   * Check that the webhook name is not duplicated
   */
  const isValidName = useCallback(
    (name: string) => {
      return !data?.some(
        (x) => x.name === name && x.name !== webhookDetails?.name,
      );
    },
    [data, webhookDetails?.name],
  );

  /**
   * Webhook CRUD callbacks
   * Create
   */
  const createWebhook = useCallback(
    async (data: WebhookFormValues) => {
      setServiceState({ type: 'loading' });
      try {
        const { payload, succeeded, error } = transformOutgoing(data);
        if (!succeeded) {
          setServiceState({ type: 'error', errorMessage: error });
          return;
        }
        if (isValidName(payload?.name)) {
          await WebhookService.create({ graphId, data: payload });
          await refetch();
          setModalState(null);
          setServiceState({ type: 'loaded' });
        } else {
          setServiceState({
            type: 'error',
            errorMessage: 'The Webhook Name should be unique.',
          });
        }
      } catch (err: any) {
        setServiceState({
          type: 'error',
          errorMessage: _.get(err, 'response.data.message', err.message),
        });
      }
    },
    [graphId, isValidName, refetch],
  );
  /**
   * Update
   */
  const updateWebhook = useCallback(
    async (data: WebhookFormValues) => {
      setServiceState({ type: 'loading' });
      try {
        const { payload, succeeded, error } = transformOutgoing(data);
        if (!succeeded) {
          setServiceState({ type: 'error', errorMessage: error });
          return;
        }

        if (isValidName(payload?.name)) {
          if (data.content.id) {
            await WebhookService.update({
              graphId,
              webhookId: data.content.id,
              data: payload,
            });
            await refetch();
            await refetchWebhookDetails();
            setModalState(null);
            setServiceState({ type: 'loaded' });
          }
        } else {
          setServiceState({
            type: 'error',
            errorMessage: 'The Webhook Name should be unique.',
          });
        }
      } catch (err) {
        setServiceState({
          type: 'error',
          errorMessage: _.get(
            err,
            'response.data.message',
            'Something went wrong updating your webhook. please try again later',
          ),
        });
      }
    },
    [graphId, isValidName, refetch, refetchWebhookDetails],
  );
  /**
   * Delete
   */
  const deleteWebhook = useCallback(
    async (data: Webhook) => {
      setServiceState({ type: 'loading' });
      try {
        await WebhookService.delete({ graphId, webhookId: data.id });
        await refetch();
        setServiceState({ type: 'loaded' });
      } catch (err) {
        setServiceState({
          type: 'error',
          errorMessage: _.get(
            err,
            'response.data.message',
            'Something went wrong creating your webhook. please try again later',
          ),
        });
      }
    },
    [graphId, refetch],
  );

  return {
    data,
    serviceState,
    fetchError: error || webhookDetailsError,
    serviceError,
    loading,
    createWebhook,
    updateWebhook,
    deleteWebhook,
    editWebhook,
    removeWebhook,
    modalState,
    setModalState,
    webhookDetails,
    setServiceState,
  };
};
