"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.midBreakpoint = void 0;
// Grid variables
var breakpoints = {
  'lower-breakpoint': 550,
  'mid-breakpoint': 775,
  'authed-header-breakpoint': 775,
  'high-breakpoint': 825
};
var midBreakpoint = "@media (max-width: ".concat(breakpoints['mid-breakpoint'], "px)");
exports.midBreakpoint = midBreakpoint;