import styled from 'styled-components';
import {
  SEQUENCE_WIDTH,
  SEQUENCE_LEFT_MARGIN,
  SEQUENCE_PADDING,
} from '../../../../constants';

export const SequenceContainer = styled.div`
  width: ${SEQUENCE_WIDTH}px;
  min-width: 250px;
  padding: ${SEQUENCE_PADDING}px;
  display: block;
  margin: 0 0 0 ${SEQUENCE_LEFT_MARGIN}px;
  background: ${props =>
    props.selected ? `rgba(51, 65, 85, 0.5)` : `transparent`};
  border: 1px solid ${props => (props.selected ? `#334151` : `transparent`)};
  border-radius: 10px;
`;

SequenceContainer.defaultProps = {
  width: 250,
};

export const SequenceInner = styled.div`
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

export const BranchArmsContainer = styled.div`
  margin: 15px 0 0 0;
  background: #334151;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex: 1 auto;
`;

export const BranchArmsList = styled.ul`
  list-style: none;
  width: 100%;

  & li {
    padding: 4px 10px 4px 5px;
    border-bottom: 1px solid #1e2834;
    font-size: 12px;
    color: #e1e1e1;
    display: flex;
    align-items: center;
    & svg {
      flex: 1;
    }
  }

  & li:last-child {
    border: 0;
  }
`;

export const BranchArmsDescription = styled.div`
  flex: 5;
`;
