import * as C from '../common';
import _ from 'lodash';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export default (
  {
    experience = default_experience_key,
    id: tile_id, // tile_id
    active_sequence: sequence_id,
    content: { experiences },
    dataForTile,
  },
  graph_id,
) => {
  const { pageList = [], masonryEnabled = false } =
    dataForTile.visit_portal;

  //TODO: This is currently used to fixed the informed consent tile, this will allow
  // reauth to work with the current changes, but we will be making changes in SPP-1524
  // to address this and make it match with the current architectural changes made
  // for new components.
  const numberOfPages = !masonryEnabled
    ? experiences?.[experience].form.length
    : pageList.length;

  return [
    [
      {
        label: 'Content',
        name: `content.experiences.${experience}`,
        input: [
          C.title,
          C.description,
          C.completion_message,
          C.pdfPreviewConfiguration({
            graph_id,
            tile_id,
            sequence_id,
            experience,
          }),
        ],
      },
      {
        label: 'Page Setup',
        name: `content.experiences.${experience}`,
        input: C.pdfConfiguration({ graph_id }),
      },
      {
        label: 'Settings',
        name: `content`,
        input: getInputsForExperience({
          experience,
          mandatoryInputs: [
            C.on_dashboard,
            C.optional,
            C.experience_attribute,
            {
              name: `reauth`,
              interface: `checkbox`,
              label: `Require re-authentication before submitting? (deprecated for page settings)`,
              condition: (path, { values }) => _.get(values, path),
            },
            {
              name: `reauth_pages`,
              interface: `checkboxgroup`,
              label: `Require re-authentication for pages`,
              possible_answers: Array.apply(null, {
                length: numberOfPages,
              }).map((v, i) => ({
                // need to convert 'answer' to string, there is an issue where
                // integer 0 is not stored as a valid value.
                answer: `${i}`,
                label: i + 1,
              })),
            },
          ],
          optionalInputs: [
            C.review_banner,
            C.review_before_submit,
            ...C.dependencyCriteria({
              emitEvent: true,
              visibility: true,
              cancellation: true,
            }),
          ],
        }),
      },
    ],
  ];
};
