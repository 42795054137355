import { MarkdownHtml as Markdown } from '@evidation/form-elements';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BREAKPOINT = 950;

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  margin-bottom: 30px;

  p {
    margin-top: 0;
  }

  span {
    flex: ${(props) => (!props.fullWidth ? `50%` : `100%`)};
    space-between: 10px;

    @media (max-width: ${BREAKPOINT}px) {
      flex: 100%;
    }
  }
`;

Wrap.Label = styled.label`
  p {
    margin-bottom: 10px;
    color: #1e2834;
    font-family: Avenir;
    font-size: 16px !important;
    font-weight: 200;
    line-height: 1em;
    text-transform: none !important;
  }
`;

Wrap.Error = styled.span`
  p {
    margin-top: 3px;
    color: red;
    font-size: 11px;
  }
`;

Wrap.Description = styled.span`
  p {
    color: #757575;
    font-family: Avenir;
    font-size: 14px;
    line-height: 19px;
    padding-left: ${(props) => (!props.fullWidth ? `30px` : `0`)};

    @media (max-width: ${BREAKPOINT}px) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
`;

Wrap.ChildrenContainer = styled.span`
  ${(props) =>
    props.inlineLabel &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `};
`;

Wrap.Outer = styled.div`
  &.horizontalToggle {
    label {
      p {
        margin-bottom: 0;
      }
    }
  }
`;

const HorizontalWrapper = ({
  children,
  label,
  description,
  input,
  meta,
  inlineLabel,
  fullWidth,
  className,
  fieldType,
  ...props
}) => {
  const FieldLabel = label && (
    <Wrap.Label>
      <Markdown>{label}</Markdown>
    </Wrap.Label>
  );

  return (
    <Wrap.Outer className={`${className} ${fieldType}`}>
      {!inlineLabel && FieldLabel}
      <Wrap fullWidth={fullWidth}>
        <Wrap.ChildrenContainer inlineLabel={inlineLabel}>
          {inlineLabel && FieldLabel}
          {children}
          {meta.touched && meta.error && (
            <Wrap.Error>
              <Markdown>{meta.error}</Markdown>
            </Wrap.Error>
          )}
        </Wrap.ChildrenContainer>
        <Wrap.Description>
          <Markdown>{description}</Markdown>
        </Wrap.Description>
      </Wrap>
    </Wrap.Outer>
  );
};

HorizontalWrapper.propTypes = {
  className: PropTypes.string,
  fieldType: PropTypes.string,
  fullWidth: PropTypes.bool.isRequired,
  inlineLabel: PropTypes.bool.isRequired,
};

HorizontalWrapper.defaultProps = {
  label: undefined,
  className: ``,
  fieldType: ``,
  fullWidth: false,
  inlineLabel: false,
};
HorizontalWrapper.handlesErrorDisplay = true;

export default HorizontalWrapper;
