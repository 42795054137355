import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M1.691 14.605l6.849-4.122 1.13.665 1.13-.665 3.954 2.34c.167-.132.366-.265.566-.398l-3.888-2.341L17.65 6.36v4.45c.2-.033.432-.067.665-.067V4.1H1.027v11.968H13.99c0-.233.033-.466.066-.665H1.692v-.798zm0-.765V6.394l6.217 3.723-6.217 3.723zM17.65 4.798v.798L9.67 10.383 1.691 5.596v-.798H17.65z"
          fillRule="nonzero"
        />
        <path d="M16.633 16.21a.395.395 0 0 1-.313-.162l-1.22-1.602a.502.502 0 0 1 .045-.653.382.382 0 0 1 .581.05l.87 1.14 2.274-3.779c.128-.212.384-.268.573-.124a.5.5 0 0 1 .11.643l-2.577 4.282a.405.405 0 0 1-.32.203c-.008.002-.015.002-.023.002z" />
      </g>
    </svg>
  );
}
