import Schemas from '../forms';
import clone from '../../../utils/objectClone';
import getResourceType from '../../../utils/getResourceType';
import getType from './getType';

export default ({ resource, identifier }, state) => {
  const valuesFromState = state[getResourceType(resource)][identifier];

  const type = getType(valuesFromState, resource);

  // transforming incoming data to what our form expects
  // cloneDeep seems to be the only way to copy this object from the store
  // without it, some how it mutates redux state
  // *** DO NOT REMOVE cloneDeep ***
  const initialValues = Schemas[type]?.incoming?.(clone(valuesFromState));

  return {
    initialValues,
    type,
  };
};
