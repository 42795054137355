import { useState } from "react";

export default function useExportImportLang () {
  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const toggleExportModal = () => setExportModal(!exportModal);
  const toggleImportModal = () => setImportModal(!importModal);
  
  return {
    exportModal,
    toggleExportModal,
    importModal,
    toggleImportModal
  }
}