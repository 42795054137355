import * as C from '../common';

import _ from 'lodash';

export default (data) => {
  const processed = C.common_outgoing(data);

  processed.content.count_filter = (
    processed.content.count_filters || []
  ).filter(
    (filter) => _.toLower(filter.enrollment_status) !== '---choose one---',
  );

  processed.content = _.omit(processed.content, ['count_filters']);

  // SPP-3606 $JRA
  // If the tile got saved incorrectly and the caps were put in content.experience.default
  // we need to remove those completely as the ones in content.caps are the correct and
  // used version by the backend
  if (
    processed.content?.experiences?.default?.caps ||
    processed.content.experiences?.default?.count_filters
  ) {
    processed.content.experiences.default = _.omit(
      processed.content.experiences.default,
      ['caps', 'count_filters'],
    );
  }

  processed.content.version = 2.0;

  return processed;
};
