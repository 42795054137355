const OutlineIcon = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 20 20`}
    fill={props.color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5938 12.4688H0.40625C0.320312 12.4688 0.25 12.5391 0.25 12.625V13.7188C0.25 13.8047 0.320312 13.875 0.40625 13.875H17.5938C17.6797 13.875 17.75 13.8047 17.75 13.7188V12.625C17.75 12.5391 17.6797 12.4688 17.5938 12.4688ZM17.5938 0.125H0.40625C0.320312 0.125 0.25 0.195312 0.25 0.28125V1.375C0.25 1.46094 0.320312 1.53125 0.40625 1.53125H17.5938C17.6797 1.53125 17.75 1.46094 17.75 1.375V0.28125C17.75 0.195312 17.6797 0.125 17.5938 0.125ZM15.5625 9.89062C15.7344 9.89062 15.875 9.75 15.875 9.57812V4.42188C15.875 4.25 15.7344 4.10938 15.5625 4.10938H2.4375C2.26562 4.10938 2.125 4.25 2.125 4.42188V9.57812C2.125 9.75 2.26562 9.89062 2.4375 9.89062H15.5625ZM3.53125 5.51562H14.4688V8.48438H3.53125V5.51562Z"
      fill={props.color}
    />
  </svg>
);

export default OutlineIcon;
