import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path d="M2 12h11.808v1H2zM2 16h17.54v1H2zM2 8h17.54v1H2zM2 4h11.808v1H2z" />
      </g>
    </svg>
  );
}
