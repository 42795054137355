import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const RegistrationTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  dataForTile
}) => {
  const {
    registration: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        {
          name: `reconfirm_in_days`,
          label: `Confirm Every X Days`,
          interface: `number`,
          decimal: 0,
          required: false,
          inputStyle: `sm_minimal`,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(RegistrationTileSettings(experience));
  }

  return schema;
};
