import React from 'react';

export default function Add({ color = `#111`, ...props }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-7-17-17" transform="translate(-67.000000, -64.000000)" />
        <g id="icons/actions/black/add" fillRule="nonzero" fill={color}>
          <g id="Group" transform="translate(3.000000, 3.000000)">
            <g id="Group-2">
              <path
                d="M8,0.75 L8,16.75 C8,17.1642136 8.33578644,17.5 8.75,17.5 C9.16421356,17.5 9.5,17.1642136 9.5,16.75 L9.5,0.75 C9.5,0.335786438 9.16421356,0 8.75,0 C8.33578644,0 8,0.335786438 8,0.75 Z"
                id="Line"
              />
              <path
                d="M0.75,9.5 L16.75,9.5 C17.1642136,9.5 17.5,9.16421356 17.5,8.75 C17.5,8.33578644 17.1642136,8 16.75,8 L0.75,8 C0.335786438,8 0,8.33578644 0,8.75 C0,9.16421356 0.335786438,9.5 0.75,9.5 Z"
                id="Line-Copy"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
