import styled from 'styled-components';

const selected_border_color = `#00BBD5`;
const selected_background_color = `#BEDFE4`;

const active_border_color = `yellow`;
const active_background_color = `orange`;

const filtered_background_color = `pink`;
const filtered_border_color = `red`;

const dragging_background_color = '#fff';

// this method determines the border color
// active will always take priority
const determine_border_color = props => {
  if (props.selected) {
    return props.selected_border_color || selected_border_color;
  } else if (props.filtered) {
    return props.filtered_border_color || filtered_border_color;
  } else if (props.active) {
    return props.active_border_color || active_border_color;
  }

  return `transparent`;
};

const determine_background_color = props => {
  if (props.selected) {
    return props.selected_background_color || selected_background_color;
  } else if (props.filtered) {
    return props.filtered_background_color || filtered_background_color;
  } else if (props.active) {
    return props.active_background_color || active_background_color;
  } else if (props.isDragging) {
    return props.dragging_background_color || dragging_background_color;
  }

  return `transparent`;
};

export const TileComponent = styled.div`
  border-right: 4px solid ${determine_border_color};
  background: ${determine_background_color};
  border-radius: ${props => (props.isDragging ? `3px` : `inherit`)};
  box-shadow: ${props => (props.isDragging ? `2px 2px 4px #888888` : `none`)};

  &:focus {
    outline: 1px solid ${active_background_color};
    outline-offset: 1;
  }
`;

export const FollowUpContainer = styled.ul`
  // using visibility attribute for svg's due to opacity causing scroll jumps
  color: rgb(0, 0, 0, ${props => (props.showIcon ? 1 : 0)});
  svg {
    visibility: ${props => (props.showIcon ? 'visible' : 'hidden')};
  }

  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1rem;
  margin-top: 3px;

  li {
    &:first-child {
      font-weight: 500;
      font-size: 12px;
    }
    &:last-child {
      font-size: 7px;
      line-height: 12px;
      padding: 3px 3px 0 0;
    }
  }
`;

export const HeadingContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ReminderContainer = styled.ul`
  // using visibility attribute for svg's due to opacity causing scroll jumps
  color: rgb(0, 0, 0, ${props => (props.showBell ? 1 : 0)});
  svg {
    visibility: ${props => (props.showBell ? 'visible' : 'hidden')};
  }

  ${TileComponent}:hover & {
    color: rgb(0, 0, 0, 1);
    svg {
      visibility: visible;
    }
  }
  //

  margin-top: 3px;
  display: flex;
  cursor: pointer;

  li {
    &:first-child {
      font-weight: 500;
      font-size: 12px;
    }
    &:last-child {
      font-size: 7px;
      line-height: 12px;
      padding: 3px 3px 0 0;
    }
  }
`;

export const TileContent = styled.div`
  flex: 1;
  padding: 0 0 0 10px;
`;

export const IconContainer = styled.div`
  text-align: center;
  display: block;
  width: 40px;
  line-height: 1;
  margin-left: 8px;
`;

export const SequenceIconContainer = styled(IconContainer)`
  background: transparent;
  padding: 0;
`;

const TileContainer = styled.div`
  margin: 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  background: #fff;

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  .tile-dd {
    font-size: 12px;
    display: flex;
    padding-right: 5px;
    justify-content: flex-end;

    // I know this isn't ideal, trying to get closer to the design - Mike C

    .dd-menu-items {
      > ul {
        border-radius: 4px;

        .dropdown {
          width: 290px;

          div {
            padding: 12px;
          }

          input {
            width: 266px;
          }
        }
      }
    }
  }
`;

export const UnconfiguredContainer = styled.ul`
  margin-top: 3px;
  display: flex;
  cursor: pointer;
`;

TileContainer.defaultProps = {
  selected: false,
  active: false,
  background: `#FFF`,
  border: `transparent`,
};

export default TileContainer;
