import React from 'react';

const NavEditor = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_editor</title>
    <g
      stroke={props.color}
      strokeWidth={1.4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.438 19.875c0 .31-.254.563-.563.563H4.125a.564.564 0 0 1-.563-.563V4.125c0-.309.254-.563.563-.563h15.75c.31 0 .563.254.563.563v15.75z" />
      <path d="M5.813 18.188h6.75v-6.75h-6.75zM18.188 9H5.813M14.813 18.188h3.374v-6.75h-3.375zM3.563 6h16.875" />
    </g>
  </svg>
);

export default NavEditor;
