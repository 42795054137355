import {
  SEQUENCE_LEFT_MARGIN,
  SEQUENCE_PADDING,
  SEQUENCE_WIDTH,
  GLOBAL_HEADER_HEIGHT
} from '../../../constants';

import Title from './title';
import iEmptyDashboard from './EmptyDashboard';
import styled from 'styled-components';

export const leftColumn = `81px`;
export const EmptyDashboard = iEmptyDashboard;

export const DashboardSpacer = styled.div`
  padding-top: 25px;
`;

export const Islands = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

Islands.Container = styled.div`
  position: relative;
`;

Islands.Heading = styled(Title)`
  margin: 0 0 0 ${SEQUENCE_LEFT_MARGIN + SEQUENCE_PADDING}px;
  cursor: normal;

  &:hover {
    text-decoration: none;
  }

  span {
    position: relative;
    cursor: pointer;
    padding-left: 10px;
    font-size: 16px;

    &:hover {
      color: #fff;
    }
  }
`;

export const Logo = styled.div`
  background-color: rgba(0, 187, 213, 0.9);
  padding: 17px 0 0 28px;
`;

export const ReminderListContainer = styled.ul`
  background: #f3f3f3;
  border-top: 1px solid #e5e5e5;
  list-style: none;
  position: relative;
  ul li {
    z-index: 2;
    &:nth-child(odd) {
      background-color: #f3f3f3;
    }
  }
`;

export const ReminderChevron = styled.div`
  position: absolute;
  top: -10px;
  right: 12px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f3f3f3;
  border-radius: 0;
`;

export const ReminderDelay = styled.div`
  font-weight: 500;
  flex: 1;
`;

export const ReminderItem = styled.div`
  cursor: pointer;
  font-size: 75%;
  padding: 3px 3px 3px 5px;
  margin: 2px 2px 2px 5px;
  display: flex;
  flex-direction: row;
  color: #111;
  text-decoration: none;
  border-radius: 3px;
  background: ${props => (props.selected ? `#BEDFE4` : `#fff`)};
  color: #111;
  text-decoration: none;
  border-right: 4px solid
    ${props => (props.selected ? `#00BBD5` : `transparent`)};
`;

export const ReminderInfo = styled.div`
  flex: 7;
  margin-left: 3px;
  &:hover {
    text-decoration: underline;
  }
`;

export const DashboardContainer = styled.div`
  grid-column-start: content;
  grid-column-end: settings;
  overflow: ${props => (props.allowScroll ? `scroll` : `none`)};
  max-height: calc(100vh - ${GLOBAL_HEADER_HEIGHT});
  height: calc(100vh - ${GLOBAL_HEADER_HEIGHT});
  position: relative;
  background: ${props => (props.background ? props.background : `transparent`)};
`;

export const SelectionMenuContainer = styled.div`
  position: fixed;
  background: #fff;
  bottom: 0;
  right: 0;
  left: ${leftColumn};
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0 29px 0 0;
  z-index: 5;

  & > div {
    display: flex;
    align-items: center;
    margin-left: 40px;

    & > * {
      margin-left: 10px;
    }
  }
`;

export const TitleSubhead = styled.div`
  padding: 0 20px 0 10px;
  background-color: rgba(0, 187, 213, 0.9);
  color: #fff;
  display: flex;
  align-items: center;

  h1 {
    padding-right: 40px;
    min-width: 200px;
    max-width: 400px;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 20px;
    }
  }
`;

export const TagList = styled.ul`
  flex: 1;
  display: flex;
  overflow: h-scroll;

  & > li {
    flex: 1;
    width: auto;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50px;
    text-align: center;
    padding: 2px 5px;
    margin: 0 5px;
    border-radius: 5px;

    &:hover {
      background: gray;
    }

    &.active {
      color: #111;
      background: #00bbd5e6;
    }
  }
`;

export const OptionsList = styled.ul`
  display: flex;
  width: auto;
  & > li {
    flex: 1;
  }
`;

export const AddSequenceButton = styled.div`
  cursor: pointer;
  z-index: 9999;
  text-align: center;
  display: block;
  height: 35px;
  width: 35px;
  border: ${props => (props.warning ? `1px solid red` : `1px solid #ebebeb`)};
  border-radius: 50%;
  background: #fff;
  font-size: 30px;
  line-height: 27px;
  color: #326bfe;
  text-decoration: none;

  &:hover {
    border-color: #326bfe;
  }
`;

export const HeadingModal = styled.h1`
  font-size: 30px;
  line-height: 36px;
  color: #fff;
  text-decoration: none;

  &:hover {
    background: #fff;
    color: #111;
  }
`;

export const DropdownArrow = styled.div`
  font-size: 8px;
  line-height: 1em;
  padding: 0 10px 0 0;
`;

export const StudyNameHolder = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const StudyNameHeading = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  span {
    padding-left: 3px;
    font-size: 10px;
    line-height: 1em;
  }
  div {
    white-space: nowrap;
  }
`;

export const StudyNameSubHeading = styled(StudyNameHeading)`
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    padding-left: 3px;
    font-size: 8px;
  }
  div {
    white-space: nowrap;
  }
`;

export const SequenceAction = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 12px 25px;
  color: #00bbd5;
`;

export const AddTileAction = styled(SequenceAction)`
  padding: 2px 0 0 0;
  background: #f3f3f3;
  ${props =>
    props.has_tiles
      ? `border-radius: 5px !important;`
      : `border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;`};
`;

const calculateDashboardWidth = ({ width }) => {
  const numberOfSequences = width / SEQUENCE_WIDTH;
  const paddingBetweenSequences = SEQUENCE_PADDING * numberOfSequences;
  const additionalPaddingForScrolling = 100;

  return width + paddingBetweenSequences + additionalPaddingForScrolling;
};

export const OuterDashboardContainer = styled.div`
  width: ${calculateDashboardWidth}px;
  display: flex;
  flex-direction: row;
  background: transparent;
  height: ${props => (props.height ? props.height : `auto`)};
  padding: 0 0 15px 0;

  ${props => props.archipelago && `border-top: 1px solid #2d343c;`};
`;

// This is needed to fix a bug with react-beautiful-dnd where you can't drop
// items that are larger than the viewport, which means you can't drop sequences
// that are longer than the browser window.
// See: https://github.com/atlassian/react-beautiful-dnd/issues/1903#issuecomment-878513239
export const DroppableBranchWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
`;
