import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 5)" fill="#000" fillRule="nonzero">
        <rect x="3" y="2" width="1" height="6" rx=".5" />
        <path d="M21.407.055H.351a.364.364 0 0 0-.248.098A.328.328 0 0 0 0 .389V9.72c0 .088.038.174.103.236a.364.364 0 0 0 .248.098h21.057a.364.364 0 0 0 .248-.098.328.328 0 0 0 .103-.236V.39a.328.328 0 0 0-.104-.236.364.364 0 0 0-.248-.098zM.702.72h20.354v8.666H.702V.721z" />
      </g>
    </svg>
  );
}
