import React from 'react';
import CuriousPanda from '../../../../../../components/fields/CuriousPanda';
import { noop } from '../../../../../../utils/noop';

const schema = [
  {
    label: 'Connections Common Settings',
    name: 'content',
    input: [
      {
        label: 'Start of Collection (days)',
        name: 'collection_start',
        required: true,
        interface: 'number',
        decimal: 0,
        labelTooltip: {
          title: 'Start of collection (Days)',
          content:
            'Date of data collection start relative to the day that a participant completes this tile. This means this can be negative (if your data collection start date is retrospective), positive (if your data collection start date is in the future) or 0 (if your data collection start date is the same day as your device connection date).',
        },
      },
      {
        label: 'Length of Collection (days)',
        name: 'collection_length',
        required: true,
        interface: 'number',
        decimal: 0,
        labelTooltip: {
          title: 'Length of Collection (Days)',
          content:
            'Number of days that data should be collected from data collection start.',
        },
      },
    ],
  },
];

export default function ConnectAppCommonSettingsForm({
  initialValues,
  onChange,
}) {
  return (
    <CuriousPanda
      schema={schema}
      initialValues={initialValues}
      enableReinitialize
      form="connect_app_common_settings_form"
      onChange={onChange}
      onSubmit={noop}
    >
      {(p) => <div />}
    </CuriousPanda>
  );
}
