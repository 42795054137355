import Close from './closeModal';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeading from '../sectionHeading';
import styled from 'styled-components';
import { e2eTestId } from '../../utils/e2eTesting';

const ContentHolder = styled.div`
  padding: 0 24px 24px 24px;
`;

const ModalShell = ({
  contentLabel = ``,
  closeModal,
  fixedHeight,
  showX,
  children,
  heading,
  parentPath,
  history: { push },
}) => (
  <div>
    {heading && (
      <SectionHeading style={{ marginBottom: 24 }}>{heading}</SectionHeading>
    )}
    {showX && (
      <Close
        onClick={() => push(parentPath)}
        {...e2eTestId('closeModal_button')}
      />
    )}
    <ContentHolder {...e2eTestId('modalContainer_content_holder')}>
      {children}
    </ContentHolder>
  </div>
);

ModalShell.defaultProps = {
  showX: true,
  modalStyle: `dark`,
};

ModalShell.propTypes = {
  parentPath: PropTypes.string.isRequired,
  heading: PropTypes.string,
  showX: PropTypes.bool,
  history: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default ModalShell;
