import {
  BranchArmsContainer,
  BranchArmsDescription,
  BranchArmsList,
  SequenceContainer,
  SequenceInner,
} from './container';
import { array, func, node, oneOfType, string } from 'prop-types';

import BranchArmsIcon from '../../../../assets/tile_icons/branch_arms';
import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import SequenceHeading from './sequence_heading';
import { SequenceIconContainer } from '../tile/container';
import VisOff from '../../../../assets/icons/VisOff';
import VisOn from '../../../../assets/icons/VisOn';
import _ from 'lodash';
import { armsFilter } from '../../../../actions/slate_actions';
import { connect } from 'react-redux';
import { branchMapping } from '../../forms/sequence/content';

const Sequence = props => {
  const {
    children,
    content: {
      branching: {
        condition,
        strategy,
        arms_by_condition_value = {},
        caps_by_arm = {},
        arm_names = [],
      } = {},
    },
    selected,
    id,
    isDraggingOver,
    sequenceDragHandleProps,
    armsFilter,
    filter_arms,
  } = props;

  const cap_keys = _.keys(caps_by_arm);
  const arms_by_condition_keys = _.keys(arms_by_condition_value);

  return (
    <SequenceContainer
      selected={selected || isDraggingOver}
      id={`t-sequence_${_.snakeCase(props.content.title)}`}
    >
      <SequenceHeading
        sequenceDragHandleProps={sequenceDragHandleProps}
        {...props}
        type="sequence"
      />

      <SequenceInner>
        <Droppable droppableId={id} type="SLATE/TILE">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {children(provided)}
              </div>
            );
          }}
        </Droppable>
      </SequenceInner>

      {cap_keys.length > 1 && (
        <BranchArmsContainer>
          <SequenceIconContainer>
            <BranchArmsIcon />
          </SequenceIconContainer>
          <BranchArmsList>
            {cap_keys.map((key, i) => {
              return (
                <li key={key}>
                  {branchMapping[strategy]} Hat {i + 1} - ({caps_by_arm[key]}{' '}
                  caps) - {key}
                </li>
              );
            })}
          </BranchArmsList>
        </BranchArmsContainer>
      )}

      {arm_names.length > 1 && (
        <BranchArmsContainer>
          <SequenceIconContainer>
            <BranchArmsIcon />
          </SequenceIconContainer>
          <BranchArmsList>
            {arm_names.map((key, i) => {
              return (
                <li key={key}>
                  {branchMapping[strategy]} {i + 1} {key}
                </li>
              );
            })}
          </BranchArmsList>
        </BranchArmsContainer>
      )}

      {arms_by_condition_keys.length > 1 && (
        <BranchArmsContainer>
          <SequenceIconContainer>
            <BranchArmsIcon />
          </SequenceIconContainer>
          <BranchArmsList>
            {arms_by_condition_keys.map(key => {
              return (
                <li key={key}>
                  <BranchArmsDescription>
                    {condition}: {arms_by_condition_value[key]}
                  </BranchArmsDescription>
                  <SequenceIconContainer>
                    {!_.isEmpty(filter_arms) &&
                    !filter_arms.includes(arms_by_condition_value[key]) ? (
                      <VisOff
                        color={'#e1e1e1'}
                        onClick={() => armsFilter(arms_by_condition_value[key])}
                      />
                    ) : (
                      <VisOn
                        color={'#e1e1e1'}
                        onClick={() => armsFilter(arms_by_condition_value[key])}
                      />
                    )}
                  </SequenceIconContainer>
                </li>
              );
            })}
          </BranchArmsList>
        </BranchArmsContainer>
      )}
    </SequenceContainer>
  );
};

Sequence.propTypes = {
  onAddTile: func,
  type: string,
  children: oneOfType([func, node]),
  tiles: array,
};

Sequence.defaultProps = {
  type: ``,
  onAddTile: () => console.warn(`no onAddTile method`),
};

export default connect(
  ({ slate: { filter_arms } }) => ({
    filter_arms,
  }),
  {
    armsFilter,
  },
)(Sequence);
