import HorizontalWrapper from './horizontalWrapper';
import React from 'react';

const HorizontalTextInput = props => (
  <HorizontalWrapper {...props} fieldType="horizontalTextInput">
    <input
      {...props.input}
      className={`${props.className} text`}
      disabled={props.disabled}
      placeholder={props.placeholder}
      type={props.type}
    />
  </HorizontalWrapper>
);

HorizontalTextInput.defaultProps = {
  disabled: false,
  placeholder: ``,
  className: ``,
  type: `text`,
};
HorizontalTextInput.handlesErrorDisplay = HorizontalWrapper.handlesErrorDisplay;

export default HorizontalTextInput;
