import React from 'react';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { EditFormInnerContainer } from '../../configure/components';

const Panel = styled.div`
  padding: 35px 50px;
`;

Panel.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

Panel.Heading = styled.h1`
  font-family: Avenir, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 0 0 15px;
  border-bottom: 1px solid #d2d2d2;
`;

// Styles taken from `form-elements/Button`
Panel.Button = styled.a`
  justify-self: flex-end;
  text-decoration: none;

  font-size: 16px;
  padding: 13px 16px;
  position: relative;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;

  background: linear-gradient(-180deg, #0d5875 0%, #0c4e69 100%);
  border: 1px solid #083245;

  &:active {
    background: linear-gradient(0deg, #0c526e 0%, #0c4e69 100%);
  }

  &:hover {
    cursor: pointer;
    background: linear-gradient(-180deg, #0c526e 0%, #0b4861 100%);
  }
`;

Panel.InnerContainer = styled(EditFormInnerContainer)`
  padding: 0;

  input:not(input[type='checkbox']):not(input[type='radio']) {
    height: 40px;
    font-size: 16px;
    width: 100%;
  }
`;

const TileWrapper = props => {
  const { children, heading, buttonProps } = props;

  return (
    <Panel>
      <Panel.Header>
        {heading !== `` && <Panel.Heading>{heading}</Panel.Heading>}

        {buttonProps && <Panel.Button {...buttonProps} />}
      </Panel.Header>

      <Panel.InnerContainer>{children}</Panel.InnerContainer>
    </Panel>
  );
};

TileWrapper.defaultProps = {
  heading: ``,
};

TileWrapper.propTypes = {
  heading: string,
  match: object.isRequired,
  buttonProps: object,
};

export default connect(({ tiles }) => ({ tiles }), {})(TileWrapper);
