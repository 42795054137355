import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.295 2.709s-1.011.835-2.686.835c-1.674 0-3.109-1.48-3.109-1.48s-1.435 1.48-3.11 1.48c-1.674 0-2.685-.835-2.685-.835S4.158 4.834 3.416 7.216a9.891 9.891 0 0 0-.287 2.343c0 3.375 1.88 8.12 8.37 9.891 6.493-1.77 8.372-6.516 8.372-9.891 0-.843-.113-1.625-.288-2.343-.741-2.382-2.288-4.507-2.288-4.507zm-11.404.873c.529.278 1.39.605 2.5.605 1.328 0 2.493-.766 3.109-1.263.616.497 1.78 1.263 3.11 1.263 1.11 0 1.97-.328 2.499-.604.461.715 1.29 2.118 1.794 3.634L4.095 7.216C4.591 5.709 5.426 4.3 5.891 3.582zm5.609 15.2c-7.167-2.038-7.727-7.567-7.727-9.222 0-.566.07-1.128.179-1.7h15.097c.107.56.177 1.126.177 1.7.001 1.655-.559 7.184-7.726 9.223z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
