import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const ConnectExternalTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      {
        label: 'Open in new tab/window',
        name: 'open_in_new_window',
        interface: 'checkbox',
      },
      C.experience_attribute,
      C.external_url,
      C.display_group_name(displayGroupNames),
    ],
    optionalInputs: [
      C.transmittable_attributes,
      C.user_data,
      ...C.dependencyCriteria({
        emitEvent: true,
        availability: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const {
    connect_external: { masonryEnabled = false },
  } = dataForTile;

  // external_title is an experience attribute for both legacy and new, but for legacy we want to
  //  also show title here
  const external_title = masonryEnabled
    ? {}
    : C.external_title;

  const experienceInputs = [
    C.experienceExternalTitle(masonryEnabled),
    external_title,
    C.description,
    C.completion_message,
    C.action_title,
  ];

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: experienceInputs,
    },
  ];

  if (!masonryEnabled) {
    schema.push(ConnectExternalTileSettings(experience, displayGroupNames));
  }

  return [schema];
};
