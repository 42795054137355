import { DropdownArrow, StudyNameHeading, StudyNameSubHeading } from '.';
import Dropdown from '../../../components/dropdown';
import { Link } from 'react-router-dom';
import { Nodes, NON_EDITABLE_STATUSES } from '../../../constants';
import PublishHOC from '../hoc/PublishHOC';
import React from 'react';
import _ from 'lodash';
import availableTileTypes from '../../../selectors/availableTileTypes';
import { connect } from 'react-redux';
import { selectAllTilesWithType } from '../../../actions/tiles_actions';
import { triageApi } from '../../../api/triage';
import styled from 'styled-components';
import { userHasValidRole } from '../forms/common_validations';

const text = {
  selectByType: 'Select By Type',
  exportLangFile: 'Export Language File',
  importLangFile: 'Import Language File',
  publishStudy: 'Publish Study',
  revertPublish: 'Revert to Last Publish',
  exportConfig: 'Export Study Configuration',
  visitStudy: 'Visit Study',
  versionHistory: 'See Version History',
  exportAudit: 'Export Audit Trail',
};

const NavButton = styled.button`
  cursor: pointer;
`;

export const HeaderDropdown = (props) => {
  const {
    graph,
    tiles,
    onPublish,
    onRestore,
    heading = '',
    match: { url },
    user: { studies },
    user,
    selectAllTilesWithType,
    toggleVersionHistory,
    toggleExportAuditTrail,
  } = props;
  const {
    secondary_locales,
    content,
    status,
    id: graphId,
    i18n_enabled,
  } = graph;
  const available_tile_types = availableTileTypes(tiles);
  const studyStatus = graph.status;
  const SubHeading = (
    <StudyNameSubHeading>
      <div data-test="selectByType">{text.selectByType}</div> <span>▼</span>
    </StudyNameSubHeading>
  );
  const Heading = (
    <StudyNameHeading>
      <div data-test="studyNameHeading">{heading}</div> <span>▼</span>
    </StudyNameHeading>
  );

  const TileTypes = available_tile_types.map((type) => (
    <li key={type}>
      <button
        data-test={`tileTypes_${_.snakeCase(type)}`}
        alt={Nodes[type].description || ``}
        onClick={() => selectAllTilesWithType(type)}
        style={{ fontWeight: 'inherit', cursor: 'pointer' }}
      >
        {Nodes[type].title}
      </button>
    </li>
  ));

  const StudiesList = (
    <React.Fragment>
      <hr />

      {i18n_enabled &&
        Array.isArray(secondary_locales) &&
        secondary_locales.length > 0 && (
          <>
            <li>
              <NavButton onClick={() => props.openImportLangFile()}>
                {text.importLangFile}
              </NavButton>
            </li>
            <li>
              <NavButton onClick={() => props.openExportLangFile()}>
                {text.exportLangFile}
              </NavButton>
            </li>
            <hr />
          </>
        )}

      {studies.map(({ content: { title }, id: study_id }) => (
        <li key={title}>
          <Link to={`/study/${study_id}/edit/slate`}>{title}</Link>
        </li>
      ))}
    </React.Fragment>
  );

  const canPublish = _.includes(
    props.user.authorization[graphId]?.actions,
    'graphs/publish',
  );

  const currentStatus = status?.toLowerCase();

  // Setting Billboard landing page URL
  let visitStudyUrl = window.env.REACT_APP_BILLBOARD_URL || triageApi.url;
  // Appending graph slug if is not undefined
  if (content?.slug) {
    visitStudyUrl += content.slug;
  }
  // Appending preview token to Billboard landing page URL if study status is Draft or QA
  if (['draft', 'qa'].includes(currentStatus) && content?.preview_token) {
    visitStudyUrl = `${visitStudyUrl}/?preview_token=${content.preview_token}`;
  }
  // Setting Visit Study URL to 'null' if study status is undefined or Unpublished
  else if (!currentStatus || currentStatus === 'unpublished') {
    visitStudyUrl = null;
  }

  // The export audit link should be visible to Owners and Publisher roles only.
  const userIsValid = userHasValidRole(['owner', 'publisher'], user, graphId);

  const StudyLinks = (
    <React.Fragment>
      {canPublish && !NON_EDITABLE_STATUSES.includes(studyStatus) && (
        <React.Fragment>
          <li>
            <NavButton onClick={() => onPublish(graphId)}>
              <b>{text.publishStudy}</b>
            </NavButton>
          </li>
          <li>
            <NavButton onClick={() => onRestore(graphId)}>
              <b>{text.revertPublish}</b>
            </NavButton>
          </li>
          <li>
            <NavButton onClick={toggleVersionHistory}>
              <b>{text.versionHistory}</b>
            </NavButton>
          </li>
        </React.Fragment>
      )}
      {visitStudyUrl && (
        <li>
          <a href={visitStudyUrl} target="_blank" rel="noopener noreferrer">
            <b>{text.visitStudy}</b>
          </a>
        </li>
      )}
      <li>
        <Link to={`${url}/export`}>{text.exportConfig}</Link>
      </li>
      {userIsValid && (
        <li>
          <NavButton onClick={toggleExportAuditTrail}>
            {text.exportAudit}
          </NavButton>
        </li>
      )}
    </React.Fragment>
  );

  const HeadingList = (
    <React.Fragment>
      {StudyLinks}
      {studies.length > 1 && StudiesList}
    </React.Fragment>
  );

  return (
    <Dropdown
      toggle={<DropdownArrow>{heading ? Heading : SubHeading}</DropdownArrow>}
    >
      {heading ? HeadingList : TileTypes}
    </Dropdown>
  );
};

export default PublishHOC(
  connect(
    ({ graph, user, tiles, locale }) => ({ graph, user, tiles, locale }),
    {
      selectAllTilesWithType,
    },
  )(HeaderDropdown),
);
