import React from 'react';

export default (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.3992 20.0625L12.6492 3.1875C12.5039 2.93672 12.2531 2.8125 12 2.8125C11.7469 2.8125 11.4937 2.93672 11.3508 3.1875L1.60076 20.0625C1.31248 20.5641 1.67342 21.1875 2.24998 21.1875H21.75C22.3265 21.1875 22.6875 20.5641 22.3992 20.0625ZM4.03592 19.4086L12 5.62266L19.964 19.4086H4.03592V19.4086Z"
        fill="#FAA438"
      />
      <path
        opacity="0.1"
        d="M4.03577 19.4082H19.9639L11.9998 5.62225L4.03577 19.4082ZM13.1248 16.8746C13.1188 17.169 12.9977 17.4493 12.7873 17.6554C12.577 17.8614 12.2943 17.9769 11.9998 17.9769C11.7054 17.9769 11.4227 17.8614 11.2123 17.6554C11.002 17.4493 10.8808 17.169 10.8748 16.8746C10.8808 16.5802 11.002 16.2999 11.2123 16.0938C11.4227 15.8877 11.7054 15.7723 11.9998 15.7723C12.2943 15.7723 12.577 15.8877 12.7873 16.0938C12.9977 16.2999 13.1188 16.5802 13.1248 16.8746V16.8746ZM12.7498 9.7496V14.0621C12.7498 14.1652 12.6655 14.2496 12.5623 14.2496H11.4373C11.3342 14.2496 11.2498 14.1652 11.2498 14.0621V9.7496C11.2498 9.64647 11.3342 9.5621 11.4373 9.5621H12.5623C12.6655 9.5621 12.7498 9.64647 12.7498 9.7496Z"
        fill="#FAA438"
      />
      <path
        d="M10.875 16.875C10.875 17.1734 10.9935 17.4595 11.2045 17.6705C11.4155 17.8815 11.7016 18 12 18C12.2984 18 12.5845 17.8815 12.7955 17.6705C13.0065 17.4595 13.125 17.1734 13.125 16.875C13.125 16.5766 13.0065 16.2905 12.7955 16.0795C12.5845 15.8685 12.2984 15.75 12 15.75C11.7016 15.75 11.4155 15.8685 11.2045 16.0795C10.9935 16.2905 10.875 16.5766 10.875 16.875V16.875ZM11.25 9.75V14.0625C11.25 14.1656 11.3344 14.25 11.4375 14.25H12.5625C12.6656 14.25 12.75 14.1656 12.75 14.0625V9.75C12.75 9.64687 12.6656 9.5625 12.5625 9.5625H11.4375C11.3344 9.5625 11.25 9.64687 11.25 9.75Z"
        fill="#FAA438"
      />
    </svg>
  );
};
