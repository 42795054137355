//@ts-nocheck
import { array, object, string } from 'prop-types';
import { checkIsAuthorized } from './sharedAuthMethods';

import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

// AuthLink is a component meant to display a link based on user capabilities
const AuthLinkComponent = ({
  required,
  authorization,
  graph: { id },
  children,
  ...etc
}) => {
  const isAuthorized = checkIsAuthorized(authorization, id, required);
  return isAuthorized ? (
    <Link {...etc}>{children}</Link>
  ) : (
    <div {...etc}>{children}</div>
  );
};

AuthLinkComponent.defaultProps = {
  required: [],
};

AuthLinkComponent.propTypes = {
  graph: object.isRequired,
  authorization: object.isRequired,
  required: array,
  to: string.isRequired,
};

export default connect(
  ({ graph, user: { authorization } }) => ({ graph, authorization }),
  {},
)(AuthLinkComponent);
