import { connect } from 'react-redux';
import { loadGraph } from '../../../../actions/combination';
//@ts-expect-error: No declared types for this library
import { Button } from '@evidation/form-elements';
import { apiGraph } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { logError } from '@evidation/logger';
import { DuplicateSequenceProps } from './types';

export const text = {
  duplicate: 'Duplicate',
  error: 'There was a problem duplicating the sequence.',
  success: 'Sequence successfully duplicated.',
};

const DuplicateSequence: React.FC<DuplicateSequenceProps> = ({
  selectedSequence: { sequence_uuid },
  graphId,
  toggleLoader,
  loadGraph,
}) => {
  const handleClick = async () => {
    try {
      toggleLoader.showLoader();
      await apiGraph.duplicateSequence(graphId, sequence_uuid);
      toastr.success(text.success, '');
      await loadGraph(graphId);
    } catch (error: any) {
      toastr.error(text.error, '');
      logError(error);
    } finally {
      toggleLoader.hideLoader();
    }
  };

  return (
    <Button id="duplicate-seq" onClick={handleClick}>
      {text.duplicate}
    </Button>
  );
};

export default connect(null, { loadGraph: loadGraph as any })(
  DuplicateSequence,
);
