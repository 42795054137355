import _ from 'lodash';

export const generateSourceSeqOrder = (sourceSeq, draggableId) => {
  const currentOrder = sourceSeq.tile_ids;
  const sourceIndex = _.indexOf(currentOrder, draggableId);
  return currentOrder
    .slice(0, sourceIndex)
    .concat(currentOrder.slice(sourceIndex + 1, currentOrder.length));
};

export const generateTargetSeqOrder = (targetSeq, draggableId, newIndex) => {
  const currentOrder = targetSeq.tile_ids;
  return currentOrder
    .slice(0, newIndex)
    .concat([draggableId])
    .concat(currentOrder.slice(newIndex, currentOrder.length));
};
