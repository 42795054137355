import { addPublishErrors, clearPublishErrors } from './publish_errors_actions';
import { clearEventSelectors, setEventSelectors } from './event_actions';
import { clearGraph, setGraph } from './graph_actions';
import { clearReminders, setReminders } from './reminders_actions';
import {
  clearResourceOrder,
  generateResourceOrder,
  selectClearType,
  typesCollect,
  resetSlateCoordinates,
  selectClearAll,
} from './slate_actions';
import { clearSequences, setSequences } from './sequences_actions';
import { clearTiles, setTiles } from './tiles_actions';
import { mapReminders, mapRemindersClear } from './related_reminders_actions';

import { apiGraph } from '../api';
import { clearPECohorts } from './pe_cohorts_actions';
import { clearPEUserDataEvents } from './pe_user_data_events_actions';
import { getLanguages, clearLocale } from './locale_actions';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../reducers';
import { ComposedDispatch } from '../redux/actions-types';

export const setGraphSequencesTiles =
  (data: RootState) => (dispatch: Dispatch<AnyAction>) => {
    const { sequences = {}, reminders = {}, tiles = {}, ...graph } = data;
    dispatch(clearPublishErrors());
    dispatch(generateResourceOrder(data));
    dispatch(setTiles(tiles));
    dispatch(setReminders(reminders));
    dispatch(typesCollect(tiles));
    dispatch(mapReminders({ reminders, tiles }));
    dispatch(setSequences(sequences));
    // setting graphs should be last since that kicks off rendering of available elements
    dispatch(setGraph(graph));
    return Promise.resolve();
  };

export const clearAllResourceData = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(clearLocale());
  dispatch(clearPublishErrors());
  dispatch(clearResourceOrder());
  dispatch(clearTiles());
  dispatch(clearReminders());
  dispatch(clearSequences());
  dispatch(clearEventSelectors());
  dispatch(clearPEUserDataEvents());
  dispatch(clearPECohorts());
  dispatch(mapRemindersClear());
  return Promise.resolve();
};

export const clearAllGraphData = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(clearPublishErrors());
  dispatch(clearResourceOrder());
  dispatch(clearTiles());
  dispatch(clearReminders());
  dispatch(clearSequences());
  dispatch(mapRemindersClear());
  dispatch(clearEventSelectors());
  dispatch(clearPEUserDataEvents());
  dispatch(clearPECohorts());
  dispatch(clearGraph());
  dispatch(resetSlateCoordinates());

  [`tile`, `sequence`, `reminder`].forEach((type: string) =>
    dispatch(selectClearType(type)),
  );

  return Promise.resolve();
};

type LoadGraphOptions = {
  resetLocales: boolean;
  shouldClearTiles: boolean;
};
const defaultLoadGraphOptions: LoadGraphOptions = {
  resetLocales: true,
  shouldClearTiles: true,
};

export const loadGraph =
  (payload: string, options: LoadGraphOptions = defaultLoadGraphOptions) =>
  (dispatch: ComposedDispatch) => {
    return apiGraph.get(payload).then((data) => {
      const {
        sequences = {},
        reminders = {},
        tiles = {},
        publish_errors = {},
        ...graph
      } = data;

      const {
        resetLocales = defaultLoadGraphOptions.resetLocales,
        shouldClearTiles = defaultLoadGraphOptions.shouldClearTiles,
      } = options;

      // Clear the current locale before entering study, this prevents any problems when
      // switching between multi and single language studies
      if (resetLocales) {
        dispatch(clearLocale());
        dispatch(getLanguages());
      }
      dispatch(clearPublishErrors());
      dispatch(generateResourceOrder(data));
      if (shouldClearTiles) {
        dispatch(clearTiles());
      }
      dispatch(setTiles(tiles));
      dispatch(setReminders(reminders));
      dispatch(typesCollect(tiles));
      dispatch(mapReminders({ reminders, tiles }));
      dispatch(setSequences(sequences));
      dispatch(addPublishErrors(publish_errors));
      // setting graphs should be last since that kicks off rendering of available elements
      dispatch(setGraph(graph));
      dispatch(setEventSelectors(graph, sequences, tiles, reminders)); // advanced event select

      return data;
    });
  };

/**
 *  loadGraphPreview
 *  This function works exactly the same as loadGraph above with two key differences
 *  it expects that you provide the entire graph before hand
 *  it also clears any selected tiles so the edit tiles bottom bar will disappear.
 * @param {object} payload - The Graph object
 * @returns {object} payload
 */
export const loadGraphPreview =
  (payload: any) => (dispatch: ComposedDispatch) => {
    const { sequences, reminders, tiles, publish_errors, ...graph } = payload;
    dispatch(selectClearAll());
    dispatch(clearPublishErrors());
    dispatch(generateResourceOrder(payload.id));
    dispatch(clearTiles());
    dispatch(setTiles(tiles));
    dispatch(setReminders(reminders));
    dispatch(typesCollect(tiles));
    dispatch(mapReminders({ reminders, tiles }));
    dispatch(setSequences(sequences));
    dispatch(addPublishErrors(publish_errors));
    // setting graphs should be last since that kicks off rendering of available elements
    dispatch(setGraph(graph));
    dispatch(setEventSelectors(graph, sequences, tiles, reminders)); // advanced event select

    return payload;
  };
