import { Button, color, Flex } from '@evidation/ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import useWorkerStatus from 'src/hooks/useWorkerStatus';
import actions from 'src/constants';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import useGraph from 'src/hooks/useGraph';
import { AsyncOperation } from 'src/redux/graph';

type WorkerNotificationProps = {
  jobId: string;
};

const Dot = ({ background, title }: { background: string; title: string }) => (
  <div
    title={title}
    style={{
      borderRadius: 9999,
      background,
      width: 12,
      height: 12,
    }}
  />
);

const getTitle = (op: AsyncOperation) => {
  return op?.metadata?.exception_message ||
    op?.metadata?.message || 'Processing'
}

const renderWorkerDot = (status: string) => {
  switch (status) {
    case 'failed':
      return <Dot background={color.status.error} title="failed" />;
    case 'complete':
      return <Dot background={color.status.success} title="complete" />;
    case 'working':
      return <Dot background={color.status.warning} title="working" />
    default:
      return (
        <Dot background={color.status.informational_subdued} title="pending" />
      );
  }
};

const WorkerNotification: React.FC<WorkerNotificationProps> = ({ jobId }) => {
  const dispatch = useDispatch();
  const { id } = useGraph();
  const workerStatus = useWorkerStatus({ jobId });
  if (!workerStatus) {
    return null;
  }
  return (
    <Flex
      items="center"
      style={{
        minWidth: 150,
        padding: '.5rem',
        borderBottom: '1px solid #e7e7e7',
      }}
      fullWidth
    >
      <Flex style={{ width: 50 }} items="center" justify="center">
        {renderWorkerDot(workerStatus?.status)}
      </Flex>

      <div style={{ maxWidth: 350, marginRight: 'auto' }}>
        <div style={{ fontSize: 14, fontWeight: 800 }}>
          <Link to={`/study/${id}/async-operations/${jobId}`}>
            {workerStatus?.metadata?.exception_class}
          </Link>
        </div>
        <p>
          {getTitle(workerStatus)}
        </p>
        <p>
          <small>
            <strong>
              {workerStatus?.update_time
                ? formatDistanceToNow(fromUnixTime(parseInt(workerStatus.update_time, 10)), {
                    addSuffix: true,
                  })
                : ''}
            </strong>
          </small>
        </p>
      </div>
      <Button
        variant="transparent"
        title="Clear Notification"
        style={{
          alignSelf: 'flex-start',
          textDecoration: 'underline',
        }}
        size="small"
        onClick={() =>
          dispatch({
            type: actions.async_operations.remove_job,
            payload: jobId,
          })
        }
      >
        Clear
      </Button>
    </Flex>
  );
};

export default WorkerNotification;
