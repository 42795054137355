import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import Modal from '../../../../components/modal/SimpleModal';
import Button from '../../../../components/inputs/Button';
import { queryStores } from '../../../../constants';
import { e2eTestId } from '../../../../utils/e2eTesting';

const texts = {
  title: 'Friendly reminder',
  paragraphs: [
    'There are unconfigured tiles in this build.',
    'Are you sure you want to publish?',
  ],
  confirm: 'Ok',
  cancel: 'Cancel',
};

const friendlyTestId = texts.title.replace(' ', '_').toLowerCase();

const testIds = {
  mainContainer: `${friendlyTestId}_main_container`,
  title: `${friendlyTestId}_title`,
  paragraph: `${friendlyTestId}_p`,
  buttonOk: `${friendlyTestId}_button_ok`,
  buttonCancel: `${friendlyTestId}_button_cancel`,
};

const Container = styled.div`
  min-width: 590px;
  min-height: 276px;
  padding: 50px 40px 16px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 18px;
  color: #000000;
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
  color: #333333;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 16px 0;
`;

const StyledButton = styled(Button)`
  color: #162d6d;
  border: 2px solid #162d6d;
  margin-right: 8px;

  ${({ type }) => {
    if (type === 'primary') {
      return css`
        margin-right: 0;
        margin-left: 8px;

        color: #ffffff;
        background-color: #162d6d;
      `;
    }
  }};
`;

const FriendlyReminderModal = ({ onClose, onConfirm }) => {
  const queryClient = useQueryClient();
  const handleOnConfirm = () => {
    onConfirm(null, () =>
      queryClient.invalidateQueries(queryStores.studyVersions),
    );
  };
  return (
    <Modal onClose={onClose} center>
      <Container {...e2eTestId(testIds.mainContainer)}>
        <Title {...e2eTestId(testIds.title)}>{texts.title}</Title>
        {texts.paragraphs.map((val, i) => (
          <Paragraph key={i} {...e2eTestId(`${testIds.paragraph}_${i}`)}>
            {val}
          </Paragraph>
        ))}
        <ButtonContainer>
          <StyledButton
            type="outline"
            onClick={handleOnConfirm}
            {...e2eTestId(testIds.buttonOk)}
          >
            {texts.confirm}
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={onClose}
            {...e2eTestId(testIds.buttonCancel)}
          >
            {texts.cancel}
          </StyledButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

FriendlyReminderModal.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
};

FriendlyReminderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FriendlyReminderModal;
