import React from 'react';

import settings_heading from './settings_heading';
import text_horizontal from './horizontal_text_input';
import settings_textinput from './settings_textinput';
import number_horizontal from './horizontal_number_input';
import textarea_horizontal from './horizontal_textarea';
import toggle_horizontal from './horizontal_toggle';
import mask_horizontal from './horizontal_mask_input';
import buttongroup_horizontal from './horizontal_buttongroup';
import select_horizontal from './horizontal_select';
import rich_text_editor from './RichTextEditor';
import checkbox_list_horizontal from './horizontal_checkboxlist';
import frequency_horizontal from './horizontal_frequency';
import rawtext from './rawtext';
import rawtoggle from './rawtoggle';

const rendercomponent = ({ render: Component, ...props }) => (
  <Component {...props} />
);

export default {
  field: {
    checkbox_list_horizontal,
    buttongroup_horizontal,
    textarea_horizontal,
    settings_textinput,
    number_horizontal,
    toggle_horizontal,
    select_horizontal,
    settings_heading,
    text_horizontal,
    mask_horizontal,
    frequency_horizontal,
    rawtoggle,
    rawtext,
    rte: rich_text_editor,
  },
  raw: {
    rendercomponent,
  },
};
