import React from 'react';

export default props => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/disqualified-">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <path
            d="M10.3443206,18.6915348 L1.14503568,3.51844485 L1.14503568,3.51844485 C0.858706499,3.04618005 1.0094371,2.43121812 1.4817019,2.14488893 C1.63802464,2.0501121 1.81733689,2 2.00014675,2 L22.1994446,2 L22.1994446,2 C22.7517294,2 23.1994446,2.44771525 23.1994446,3 C23.1994446,3.18280986 23.1493325,3.36212211 23.0545557,3.51844485 L13.8552708,18.6915348 L13.8552708,21 L13.8552708,21 C13.8552708,21.5522847 13.4075555,22 12.8552708,22 L11.3443206,22 L11.3443206,22 C10.7920359,22 10.3443206,21.5522847 10.3443206,21 L10.3443206,18.6915348 Z"
            id="Combined-Shape-Copy"
            fill="#000000"
          />
          <path
            d="M15.8114662,7.02022957 C15.9371829,6.89459546 16,6.74193348 16,6.5623263 C16,6.38271911 15.9371003,6.23013979 15.8114662,6.10442302 L14.8956596,5.18853382 C14.7699429,5.06281706 14.6173635,5 14.437839,5 C14.2581492,5 14.1055698,5.06281706 13.9798531,5.18853382 L12.0000413,7.16834558 L10.0202296,5.18853382 C9.89459546,5.06281706 9.74193348,5 9.5623263,5 C9.38280176,5 9.23013979,5.06281706 9.10442302,5.18853382 L8.18853382,6.10442302 C8.06289971,6.23005713 8,6.38271911 8,6.5623263 C8,6.74193348 8.06281706,6.89451281 8.18853382,7.02022957 L10.1683456,9.00004133 L8.18853382,10.9798531 C8.06289971,11.1055698 8,11.2581492 8,11.4377564 C8,11.6173635 8.06281706,11.7699429 8.18853382,11.8956596 L9.10442302,12.8114662 C9.23013979,12.9371829 9.38280176,13 9.5623263,13 C9.74193348,13 9.89451281,12.9371003 10.0202296,12.8114662 L12.0000413,10.8316544 L13.9799357,12.8114662 C14.1056525,12.9371829 14.2581492,13 14.4379217,13 C14.6174462,13 14.7701082,12.9371003 14.8957423,12.8114662 L15.8114662,11.8956596 C15.9371829,11.7700255 16,11.6173635 16,11.4377564 C16,11.2581492 15.9371829,11.1055698 15.8114662,10.9798531 L13.8316544,9.00004133 L15.8114662,7.02022957 Z"
            id="Shape-Copy"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};
