import React from 'react';

const NavComp = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_comp</title>
    <g fill={props.color} fillRule="evenodd">
      <path d="M21.688 6.594v13.125a.678.678 0 0 1-.19.466.675.675 0 0 1-.467.19H3.97a.675.675 0 0 1-.466-.19.678.678 0 0 1-.19-.466V7.906c0-.177.072-.341.19-.466a.675.675 0 0 1 .466-.19H20.4c.25 0 .69 0 1.168-.131.237-.066.493-.17.729-.341.236-.178.453-.434.577-.755a.665.665 0 0 0-.38-.853.661.661 0 0 0-.847.38.376.376 0 0 1-.131.17c-.099.08-.289.145-.505.178-.21.04-.44.04-.61.04H3.968C2.879 5.938 2 6.816 2 7.905V19.72c0 1.09.88 1.968 1.969 1.968H21.03c1.09 0 1.969-.879 1.969-1.968V6.594a.658.658 0 0 0-.656-.657.658.658 0 0 0-.657.657" />
      <path d="M5.938 6.154V3.746c0-.164.052-.263.111-.329a.397.397 0 0 1 .282-.104l.112.013.04.006 14.463 1.956.085-.65-.138.637c.224.052.368.13.473.21a.8.8 0 0 1 .282.36l.04.133v.025l.223-.025-.224.012v.013l.224-.025-.224.012.657-.04h-.657v.644c0 .36.296.656.657.656.36 0 .656-.295.656-.656v-.69c-.007-.059-.033-.413-.282-.84a2.072 2.072 0 0 0-.565-.63 2.432 2.432 0 0 0-.984-.439l-.052-.007L6.659 2.033l-.091.65.13-.65A2.388 2.388 0 0 0 6.332 2c-.466 0-.905.184-1.22.499a1.796 1.796 0 0 0-.486 1.247v2.408c0 .361.295.656.656.656.361 0 .657-.295.657-.656M6.541 13.813h-.656 1.313a1.312 1.312 0 1 0-2.625-.001 1.312 1.312 0 0 0 2.624 0h-.656" />
    </g>
  </svg>
);

export default NavComp;
