import * as C from '../common';

export default data => {
  if (data.content.offset) {
    // this transform is for delay offset
    // cascading the transforms can cause some data to be transformed more than once
    // causing some unexpected results and :sad:
    data.content.offset = parseFloat(data.content.offset, 10);
  }

  return C.common_outgoing(data);
};
