import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M13.84 1.246H5.474A2.335 2.335 0 0 0 3.142 3.58v14.878a2.335 2.335 0 0 0 2.333 2.333H16.32a2.336 2.336 0 0 0 2.333-2.333l-.001-11.833-4.812-5.378zm.175 1.141l3.616 4.041h-3.616v-4.04zm4.005 16.07c0 .939-.763 1.702-1.702 1.702H5.474a1.704 1.704 0 0 1-1.702-1.702L3.773 3.58c0-.939.764-1.702 1.702-1.702h7.909v5.182h4.638l-.002 11.398z"
          fillRule="nonzero"
        />
        <path d="M8.028 12.2l.48.41 2.02-2.364v6.015h.631v-6.015l2.022 2.364.48-.41-2.816-3.294z" />
      </g>
    </svg>
  );
}
