import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const EventDelayTileSettings = (
  type,
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.experience_attribute,
      C.display_group_name(displayGroupNames),
    ],
    optionalInputs: [
      C.on_dashboard,
      C.generate_billboard_display_options(type),
      C.user_data_key,
      ...C.disqualification_criteria(masonryEnabled),
      {
        name: 'file_upload_slug',
        label: 'File Upload UUID',
        interface: 'textinput',
        inputStyle: 'sm_minimal',
      },
      {
        name: 'additional_columns',
        label:
          'Additional attributes to display when reviewing this event delay',
        interface: 'flatarray',
      },
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
        completion: true,
      }),
    ],
  }),
});

export default ({
  content: { type },
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const {
    event_delay: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.completion_message,
        C.description_rte,
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(EventDelayTileSettings(type, experience, displayGroupNames));
  }

  return schema;
};
