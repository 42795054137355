import { default_experience_key } from '../../../../constants';
import * as C from '../common';
import { getInputsForExperience } from '../utils';

export const HibbertShippingTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        cancellation: true,
        completion: true,
      }),
    ],
  }),
});

export default ({
  externalIds = [],
  dataForTile,
  experience = default_experience_key,
}) => {
  const {
    hibbert_shipping: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
  ? `content.experiences.${experience}.settings`
  : `content`;

  const schema = [
    {
      label: 'Shipping',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          label: 'External Id',
          name: 'external_id',
          interface: 'select',
          required: true,
          options: externalIds,
        },
        {
          //TODO: add validation when kit types are known
          label: 'Kit Type',
          name: 'kit_type',
          interface: 'textinput',
          required: true,
        },
        {
          label: 'Shipping Method',
          name: 'shipping_method',
          interface: 'select',
          description:
            'Above is a list of Shipping methods supported by Hibbert service.',
          required: false,
          // This dropdown is populated with shipping options provided by Hibbert through the "hibbert_shipping_methods" endpoint
          options: dataForTile?.hibbert_shipping?.shippingNames ?? [],
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(HibbertShippingTileSettings(experience));
  }

  return schema;
};
