//@ts-nocheck
import { checkIsAuthorized } from './sharedAuthMethods';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// AuthWrap is a component to hide/show elements based on auth capabilities
export const AuthWrap = ({
  requiredActions,
  authorization,
  id,
  children,
  elseDisplay = null,
}) => {
  let isAuthorized =
    !requiredActions || checkIsAuthorized(authorization, id, requiredActions);
  return isAuthorized ? children : elseDisplay;
};

AuthWrap.propTypes = {
  elseDisplay: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  authorization: PropTypes.object.isRequired,
  requiredActions: PropTypes.arrayOf(PropTypes.string),
};

export default connect(({ graph, user: { authorization } }) => {
  return { authorization, id: graph.id };
})(AuthWrap);
