import { logError } from '@evidation/logger';
import { useCallback, useState } from 'react';
import { userGetRoleInfo } from '../../../../api';

export default function useCra({
  authorization,
  username,
}) {
  const [roleId, setRoleId] = useState(null);
  const [craModal, setCraModal] = useState(false);
  const toggleCraModal = () => {
    if (craModal) {
      setRoleId(null);
    }
    setCraModal(!craModal);
  };
  const checkCraUser = useCallback(
    async (graphId) => {
      const isCra =
        authorization?.[graphId] &&
        authorization?.[graphId]?.roles?.includes('cra');
      if (isCra) {
        try {
          const {
            data: { data },
          } = await userGetRoleInfo(graphId, username);
          const craRole = data.find((role) => role.name === 'cra');
          if (craRole && !craRole.pin_set) {
            setCraModal(true);
            setRoleId(craRole.id);
          }
        } catch (err) {
          logError(err);
        }
      } else {
        setCraModal(false);
      }
    },
    [authorization, username],
  );
  return {
    checkCraUser,
    craModal,
    roleId,
    toggleCraModal,
  };
}
