import { Middleware } from 'redux';
import {
  getStudies,
  loggedIn,
  setLocalstorage,
  setStudies,
} from '../actions/user_actions';
import { apiGraph, userAuthorizedActions } from '../api';
import { toastr } from 'react-redux-toastr';
import types from '../constants';
import { logError } from '@evidation/logger';

const middleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case types.user.get_studies:
        apiGraph.get().then((payload) => {
          dispatch(setStudies(payload));
        });
        break;

      case types.user.log_in:
        const user = getState().user;
        const { payload } = action;

        const token = payload.token || user.token;
        const username = payload.username || user.username;

        userAuthorizedActions({ token, userEmail: username })
          .then(({ data: authorization }) => {
            const payload = { token, username, authorization };
            setLocalstorage(payload);
            dispatch(loggedIn(payload));
            dispatch(getStudies());
          })
          .catch((error) => {
            logError(error);
            console.error(error);
            toastr.error('There was a problem logging you in', '');
            dispatch({ type: types.user.log_out });
          });
        break;

      default:
        return next(action);
    }
  };
export default middleware;
