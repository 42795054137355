import { default_experience_key } from '../../../../constants';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          {
            name: `invite_email_template_id`,
            label: `Email Template ID`,
            interface: `text_horizontal`,
            description: `The ID of the SendGrid email template for this invite.`,
          },
          {
            name: `invite_reminder_frequency`,
            label: `Reminder Frequency`,
            description: `The frequency at which reminders should be sent.`,
            interface: `frequency_horizontal`,
          },
          {
            name: `invite_max_reminder_count`,
            label: `Maximum Number of Reminders`,
            description: `The maximum number of reminders that should be sent.`,
            interface: `number_horizontal`,
          },
          {
            name: `invite_link_expiration`,
            label: `Invitation Link Expiration (Days)`,
            description: `The number of days after which the invitation link expires.`,
            interface: `number_horizontal`,
          },
        ],
      },
    ],
  ];
};
