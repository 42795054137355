import React from 'react';

export default function VisOff({ color = `#111`, ...props }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-7-17-17" transform="translate(-514.000000, -64.000000)" />
        <g id="icons/actions/black/vis-off" fill={color}>
          <g id="Group" transform="translate(2.000000, 3.000000)">
            <path
              d="M1.27272727,8.90925 C1.27145455,8.82588636 1.30136364,8.63943182 1.38409091,8.40906818 C1.66918182,7.58625 2.555,6.23970455 4.01927273,5.15088636 C5.48609091,4.05443182 7.52054545,3.18325 10.1818182,3.18197727 C11.4335455,3.18325 12.3486364,3.36015909 13.097,3.59434091 C13.846,3.83106818 14.4327273,4.12952273 15.0315455,4.39934091 C15.3522727,4.54315909 15.7283636,4.39934091 15.8721818,4.07861364 C16.016,3.75788636 15.8728182,3.38052273 15.5520909,3.23797727 C14.9971818,2.98979545 14.3404545,2.65252273 13.4794545,2.38143182 C12.6178182,2.10906818 11.5544545,1.90861364 10.1818182,1.90925 C6.81418182,1.90734091 4.26745455,3.18834091 2.58490909,4.67743182 C1.74172727,5.42261364 1.10918182,6.21806818 0.679,6.94734091 C0.463909091,7.31197727 0.298454545,7.66070455 0.183909091,7.98588636 C0.07,8.31170455 0.00190909091,8.61079545 0,8.90925 C0,9.26115909 0.285090909,9.54561364 0.636363636,9.54561364 C0.988272727,9.54561364 1.27272727,9.26115909 1.27272727,8.90925"
              id="Fill-567"
            />
            <path
              d="M16.786,5.57406818 C17.5719091,6.24734091 18.1694545,6.96261364 18.5601818,7.58179545 C18.7555455,7.88979545 18.8987273,8.17615909 18.9884545,8.41097727 C19.0794545,8.64325 19.1119091,8.83034091 19.11,8.90925 C19.11,9.26115909 19.3944545,9.54561364 19.7463636,9.54561364 C20.0976364,9.54561364 20.3827273,9.26115909 20.3827273,8.90925 C20.3808182,8.59615909 20.3025455,8.28752273 20.1765455,7.95470455 C19.7940909,6.96834091 18.935,5.74588636 17.6151818,4.60806818 C17.3485455,4.37961364 16.9463636,4.41015909 16.7179091,4.67679545 C16.4888182,4.94343182 16.5193636,5.34434091 16.786,5.57406818"
              id="Fill-568"
            />
            <path
              d="M0.0190909091,8.90925 C0.0241818182,9.26943182 0.136181818,9.61497727 0.326454545,10.03625 C0.901727273,11.2714318 2.29154545,13.0475227 4.78227273,14.5455227 C5.08390909,14.7275227 5.47463636,14.6288864 5.65536364,14.3285227 C5.83609091,14.02625 5.73872727,13.6361591 5.43709091,13.4547955 C3.91490909,12.5416136 2.86045455,11.5183409 2.19481818,10.6477955 C1.862,10.2131591 1.62718182,9.81670455 1.48145455,9.50106818 C1.33318182,9.19115909 1.28609091,8.94234091 1.29181818,8.90925 C1.29181818,8.55797727 1.00672727,8.27288636 0.655454545,8.27288636 C0.303545455,8.27288636 0.0190909091,8.55797727 0.0190909091,8.90925"
              id="Fill-569"
            />
            <path
              d="M7.51990909,15.61525 C8.34463636,15.8029773 9.22981818,15.90925 10.2009091,15.90925 C13.5685455,15.9111591 16.1152727,14.6301591 17.7978182,13.1410682 C18.641,12.3958864 19.2735455,11.6004318 19.7037273,10.8711591 C19.9188182,10.5065227 20.0842727,10.1584318 20.1988182,9.83325 C20.3127273,9.50679545 20.3808182,9.20770455 20.3827273,8.90925 C20.3827273,8.55797727 20.0976364,8.27288636 19.7463636,8.27288636 C19.3944545,8.27288636 19.11,8.55797727 19.11,8.90925 C19.1112727,8.99261364 19.0813636,9.17906818 18.9986364,9.40943182 C18.7135455,10.2316136 17.8277273,11.5787955 16.3634545,12.66825 C14.896,13.7640682 12.8621818,14.63525 10.2009091,14.6365227 C9.32209091,14.6365227 8.53681818,14.5410682 7.80309091,14.3743409 C7.46009091,14.2960682 7.119,14.5111591 7.04072727,14.8528864 C6.96309091,15.1958864 7.17754545,15.5369773 7.51990909,15.61525"
              id="Fill-570"
            />
            <path
              d="M12.7463636,8.90925 C12.7457273,9.61370455 12.4619091,10.2468864 11.9999091,10.7088864 C11.5372727,11.1715227 10.9047273,11.4547045 10.2009091,11.4547045 C9.849,11.4547045 9.56454545,11.7391591 9.56454545,12.0910682 C9.56454545,12.4429773 9.849,12.7274318 10.2009091,12.7274318 C12.3091818,12.7267955 14.0184545,11.0175227 14.0190909,8.90925 C14.0190909,8.55797727 13.734,8.27288636 13.3827273,8.27288636 C13.0308182,8.27288636 12.7463636,8.55797727 12.7463636,8.90925"
              id="Fill-571"
            />
            <path
              d="M2.37809091,17.6318864 L18.9235455,1.08643182 C19.1717273,0.837613636 19.1717273,0.435431818 18.9235455,0.186613636 C18.6747273,-0.0622045455 18.2719091,-0.0622045455 18.0237273,0.186613636 L1.47763636,16.7320682 C1.22945455,16.9808864 1.22945455,17.3830682 1.47763636,17.6318864 C1.72645455,17.8807045 2.12927273,17.8807045 2.37809091,17.6318864"
              id="Fill-572"
            />
            <path
              d="M8.16454545,10.43525 C7.84445455,10.0095227 7.65609091,9.48325 7.65609091,8.90797727 C7.65609091,8.20352273 7.93990909,7.57034091 8.40127273,7.10834091 C8.86390909,6.64570455 9.49645455,6.36315909 10.2015455,6.36252273 C10.7755455,6.36315909 11.3005455,6.55152273 11.7269091,6.87034091 C12.0081818,7.08161364 12.4071818,7.02434091 12.6178182,6.74306818 C12.8284545,6.46179545 12.7711818,6.06279545 12.4905455,5.85215909 C11.8541818,5.37488636 11.0580909,5.08979545 10.2015455,5.08979545 C8.092,5.09043182 6.38336364,6.80034091 6.38336364,8.90797727 C6.38272727,9.76579545 6.66781818,10.5625227 7.147,11.2001591 C7.35827273,11.4807955 7.75727273,11.5374318 8.03790909,11.3267955 C8.31918182,11.1155227 8.37581818,10.7165227 8.16454545,10.43525"
              id="Fill-573"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
