import stripSpecialCharacters from './stripSpecialCharacters';
import isJson from 'is-json';

export const validateJson = (v) =>
  v && !isJson(v, true) ? 'The JSON is not valid' : undefined;

export const enforceLowercase_parse = (value) => {
  if (!value) {
    return value;
  }

  return stripSpecialCharacters(value);
};

export const enforceLowercaseWithHyphens_parse = (value) => {
  if (!value) {
    return value;
  }

  return stripSpecialCharacters(value, { allowHyphens: true });
};

export const enforceNoWhiteSpace_parse = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/\s+/g, '');
};

export const MaxValidationMessage = (max, min) =>
  min > max ? 'Please enter a value greater or equal to min' : undefined;

export const validateMaskCustomMax = (max, { mask_custom_min = 0 }) =>
  MaxValidationMessage(max, mask_custom_min);

export const ValidateMaxNumber = (max, { min = 0 }) =>
  MaxValidationMessage(max, min);

const trimAndLowerCase = (value = '') =>
  value.replace(/\s+/g, ' ').trim().toLowerCase();

export const validateDisplayGroupName = (value) => {
  const notAllowedNames = [
    'my activities',
    'completed activities',
    'future contributions',
  ];

  if (!value) {
    return 'Display group name is a required field';
  }

  const parsedValue = trimAndLowerCase(value);

  if (notAllowedNames.some((name) => parsedValue === name)) {
    return 'Display group name not allowed';
  }
};

export const sliderPositiveSteps = (steps) =>
  parseInt(steps) <= 0 ? 'please add a number higher than 0' : undefined;

/**
 * userHasValidRole checks if the user has any of the allowed roles.
 * @param {string[]} allowedRoles - List of roles to compare with the user.
 * @param {object} user - User object (coming from the redux store) containing the "authorization" property.
 * @param {string} graphId - Graph id value.
 * @returns {boolean}
 */
export const userHasValidRole = (allowedRoles, user, graphId) =>
  allowedRoles.some((role) => {
    const { roles = [] } = user?.authorization[graphId] || {};

    return roles.includes(role);
  });
