import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg)
  }
`;

const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(50, 58, 68, 0.8);

  &[data-active] {
    display: flex;
  }

  &::after {
    content: '';
    width: 6rem;
    height: 6rem;
    margin: auto;
    border: 1rem solid white;
    border-radius: 100%;
    border-right-color: transparent;
    animation: ${rotate} 1s infinite linear;
    filter: drop-shadow(0 0 0.5rem rgb(50, 58, 68));
  }
`;

const Loader = ({ show = false, children }) => (
  <div aria-live="polite" aria-busy={show}>
    <Overlay data-active={show ? true : null} />

    {children}
  </div>
);

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default Loader;
