import _ from 'lodash';
export default (value = false) => {
  if (_.isBoolean(value)) {
    return value;
  } else if (
    _.isString(value) &&
    !_.isEmpty(value) &&
    value.toLowerCase() === `true`
  ) {
    return true;
  }

  return false;
};
