import HorizontalWrapper from './horizontalWrapper';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

const BORDER_RADIUS = `4px`;
const BLUE = `#326bfe`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  button {
    padding: 15px 5px;
    flex: 1;
    border-color: #d6d6d6;
    border: 1px solid #d6d6d6;
    background: #fff;
    color: ${BLUE};
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background: ${BLUE};
      border-color: ${BLUE};
      color: #fff;
      cursor: auto;
    }

    &:first-child {
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
    }

    &:last-child {
      border-right: 1px solid #d6d6d6;

      &:disabled {
        border-color: ${BLUE};
      }

      border-top-right-radius: ${BORDER_RADIUS};
      border-bottom-right-radius: ${BORDER_RADIUS};
    }

    @media screen and (max-width: 550px) {
      min-width: 100%;
      border-radius: 0;
      border: 1px solid #d6d6d6;
      border-bottom: 0;

      &:first-child,
      &:last-child {
        border-radius: 0;
      }

      &:first-child {
        border-top-left-radius: ${BORDER_RADIUS};
        border-top-right-radius: ${BORDER_RADIUS};
      }
      &:last-child {
        border-bottom: 1px solid #d6d6d6;
        border-bottom-left-radius: ${BORDER_RADIUS};
        border-bottom-right-radius: ${BORDER_RADIUS};
      }
    }
  }
`;

const HorizontalButtonGroup = props => {
  const {
    input: { onChange, onBlur, value = `` },
    options = [],
  } = props;

  return (
    <HorizontalWrapper {...props} fieldType="horizontalButtonGroup">
      <ButtonContainer className={`buttoncontainer`}>
        {_.map(options, option => (
          <button
            key={option.label}
            disabled={_.isEqual(value, option.value)}
            id={`t-${option.value}`}
            type="button"
            onClick={() => {
              onChange(option.value);
              onBlur();
            }}
          >
            {option.label}
          </button>
        ))}
      </ButtonContainer>
    </HorizontalWrapper>
  );
};

export default HorizontalButtonGroup;
