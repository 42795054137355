import React from 'react';
import CuriousPanda from '../../../../../../components/fields/CuriousPanda';
import useConnectionSchema from './useConnectionSchema';
import { noop } from '../../../../../../utils/noop';
import {
  ConnectionContainer,
  Panel,
  Content,
  FloatRight,
  TransparentBtn,
  CloseIcon,
} from './components';

export default function CreateConnectionForm({
  initialValues,
  onChange,
  slug,
  connId,
  onRemove,
  validate,
  currentConnections,
}) {
  const { schema } = useConnectionSchema({
    slug,
    initialValues,
    currentConnections,
    connId,
  });
  return (
    <ConnectionContainer>
      <Panel>Connection Configuration</Panel>
      <Content>
        <FloatRight>
          <TransparentBtn
            onClick={() => onRemove(connId)}
            title="Remove Connection"
            aria-label="Remove Connection"
          >
            <CloseIcon />
          </TransparentBtn>
        </FloatRight>
        <CuriousPanda
          schema={schema}
          initialValues={initialValues}
          enableReinitialize
          keepDirtyOnReinitialize
          form={`connect_app_settings_form_${connId}`}
          onChange={onChange}
          onSubmit={noop}
        >
          {(p) => <div />}
        </CuriousPanda>
      </Content>
    </ConnectionContainer>
  );
}
