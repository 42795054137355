export const getSearchParamsFromUrl = () =>
  getParamsFromQueryString(window.location.search);

// helpers

export const getParamsFromQueryString = (str) => {
  return str
    .slice(1) // rm '?'
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
};

export const downloadFileUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.id = 'downloadFileUrl';
  a.download = '';
  a.click();
  a.remove();
};
