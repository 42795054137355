import React from 'react';

export default props => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/informed-consent">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <rect
            id="Rectangle-5"
            fill="#000000"
            x={1}
            y={20}
            width={22}
            height={3}
          />
          <g
            id="Group-3"
            transform="translate(5.242641, 16.242641) rotate(45.000000) translate(-5.242641, -16.242641) translate(1.242641, 12.242641)"
            fill="#000000"
          >
            <rect
              id="Rectangle-3"
              x="2.83012864"
              y={0}
              width="1.88675243"
              height="7.54700972"
            />
            <rect
              id="Rectangle-3-Copy"
              x={0}
              y="2.83012864"
              width="7.54700972"
              height="1.88675243"
            />
          </g>
          <path
            d="M10,18.0102517 C12.257716,16.2806795 18.4585452,4.27964977 13.7136321,4.00241171 C10.2543365,3.80029033 10.7984555,16.3684768 10.9636489,16.9494097 C11.2025368,17.7895031 12.5305252,17.790702 13.1345277,17.2918737 C14.0881054,16.5043416 14.0534536,14.6131368 14.9171583,13.6843207 C14.9560779,13.642467 17.0480546,16.4898132 17.7503687,16.553585 C19.3055529,16.6947995 20.796952,15.6714803 22.3516123,15.5219454 C22.6105618,15.4970384 22.7838708,15.8730816 23,16.0486497"
            id="Path-2-Copy"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
