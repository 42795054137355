import _ from 'lodash';
import fillRange from './fillRange';

export default (max = 100, start = 0) => {
  const arr = fillRange(start, max);
  return _.reduce(
    arr,
    (acc, unknown, count) => {
      const num = start + count;
      return [...acc, { label: num, value: num }];
    },
    [],
  );
};
