// const cloneObj = obj => {
//   var clone = {};
//   for (var i in obj) {
//     if (obj[i] != null && typeof obj[i] === 'object')
//       clone[i] = cloneObj(obj[i]);
//     else clone[i] = obj[i];
//   }
//   return clone;
// };

// export default cloneObj;
export default (obj = {}) => JSON.parse(JSON.stringify(obj));
