import { triageV3 } from './triage';

// Retrieve list of files from s3 bucket
// GET api/v3/studies/:study_slug/files
// sample response:
// [
//     {
//         "id": 942,
//         "s3_bucket": "bucket_38b890f856cd1151f7fd5bfc8f9ad13c",
//         "s3_key": "object_1ecd9c109104ebbd9c33a922c3bab71e",
//         "uploaded_at": "2020-01-30T23:44:27.273Z",
//         "reviewed_at": null,
//         "enrollment_identifier": "a7104dd64a53b937b1baf48602771f2a",
//     }
// ]
export const file_list = (slug) =>
  triageV3({
    method: 'GET',
    url: `studies/${slug}/files`,
  });

// Retrieve download url for image
// GET api/v3/studies/:study_slug/files/:file_id/download_url
// sample response:
// {
//     "download_url"=>"https://s3.us-stubbed-1.amazonaws.com/test_bucket_336/object_key_158",
//     "expires_at"=>"2020-01-30T13:00:00.000-08:00"
// }
export const file_url = (slug, fileId) =>
  triageV3({
    method: 'GET',
    url: `studies/${slug}/files/${fileId}/download_url`,
  });

// Set review status for files
// PATCH /api/v3/studies/:study_slug/files/review_update
// payload: { "ids": [1,2] }
// response: 200 OK, no body
export const file_reviewed = (slug, data) =>
  triageV3({
    method: 'PATCH',
    url: `studies/${slug}/files/review_update`,
    data,
  });

// Get list of icf that have been submitted
// GET /api/v3/studies/:study_slug/informed_consents
// response:
//
export const icf_list = (slug) =>
  triageV3({
    method: 'GET',
    url: `studies/${slug}/informed_consents`,
  });

// Get the corret s3 url for the selected icf form
// GET /api/v3/studies/:study_slug/enrollments/:enrollment_identifier/informed_consent_url?node_identifier=:node_identifier
// response:
//
export const icf_url = (slug, enrollmentId, nodeId, submissionKey) =>
  triageV3({
    method: 'GET',
    url: `studies/${slug}/enrollments/${enrollmentId}/informed_consent_url?node_identifier=${nodeId}&submission_key=${submissionKey}`,
  });

// Resend icf to participate
// POST
//
export const resend_icf = (slug, data) =>
  triageV3({
    method: 'POST',
    url: `studies/${slug}/informed_consents/resend_icf`,
    data,
  });
