import { ReminderDelay, ReminderInfo, ReminderItem } from '..';
import React from 'react';
import _ from 'lodash';

const offsetType = (units) => {
  switch (units) {
    case 'days':
      return 'd';
    case 'hours':
      return 'hr';
    case 'minutes':
      return 'min';
    default:
      return '';
  }
};

const reminderListItem = ({
  offset,
  units,
  title,
  onClick,
  reminder_uuid,
  selected,
  innerRef,
}) => {
  return (
    <ReminderItem
      id={`t-reminder-${_.snakeCase(title)}`}
      onClick={onClick}
      selected={selected}
      ref={innerRef}
    >
      <ReminderDelay>
        {offset}
        {offsetType(units)}
      </ReminderDelay>
      <div>•</div>
      <ReminderInfo>{title}</ReminderInfo>
    </ReminderItem>
  );
};

reminderListItem.defaultProps = {};

export default reminderListItem;
