import { useState } from 'react';

export default function useExportAuditTrail() {
  const [exportAuditTrailModal, setExportAuditTrailModal] = useState(false);
  const toggleExportAuditTrailModal = () =>
    setExportAuditTrailModal(!exportAuditTrailModal);

  return {
    exportAuditTrailModal,
    toggleExportAuditTrailModal,
  };
}
