import _ from 'lodash';
import * as C from '../common';

export default (data) => {
  if (_.has(data.content, 'external_ids'))
    data.content.external_ids = _.reduce(
      data.content.external_ids,
      (acc, value) => {
        const {
          format,
          client,
          user_data_key,
          range_start,
          range_end,
          prefix,
        } = value;
        if (value.format === 'sequential_prefix_range') {
          return [
            ...acc,
            {
              format,
              client,
              user_data_key,
              options: {
                prefix,
                range_start: _.toNumber(range_start),
                range_end: _.toNumber(range_end),
              },
            },
          ];
        }

        return [...acc, { format, client, user_data_key }];
      },
      [],
    );

  return C.common_outgoing(data);
};
