import React from 'react';

const Status = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    {...props}
  >
    <g
      id="icons/nav/study_status"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle id="Oval" fill={props.color} cx="12" cy="12" r="6" />
      <circle
        id="Oval"
        stroke={props.color}
        strokeWidth="1.2"
        fillRule="nonzero"
        cx="12"
        cy="12"
        r="8.4"
      />
    </g>
  </svg>
);

Status.defaultProps = {
  color: '#000000',
  size: 24,
};

export default Status;
