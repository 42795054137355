import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@evidation/form-elements';
import onAddHOC from '../hoc/onAddHOC';
import { single_experience_tile_types } from '../../../constants';
import Schemas from '../forms';
import _ from 'lodash';

const duplicateTitle = title => `*Duplicate of: ${title}`;

const duplicateContent = content => {
  if (single_experience_tile_types.includes(content.type)) {
    return { ...content, title: duplicateTitle(content.title) };
  } else {
    const newContent = _.merge({}, content, {
      experiences: {
        default: { title: duplicateTitle(content.experiences.default.title) },
      },
    });
    return newContent;
  }
};

const generatePayload = ({ content }) => {
  const payload = {
    resource: `tile`,
    content: duplicateContent(content),
  };
  //necessary for outgoing transformation to behave as expected for multi-page forms
  return Schemas[content.type].incoming(payload);
};

const DuplicateTile = ({
  tiles,
  onAdd,
  selected_tile: { uuid, sequence_uuid },
}) => (
  <Button
    id="t-duplicate-tile"
    onClick={() => {
      onAdd(generatePayload(tiles[uuid]), sequence_uuid, uuid);
    }}
  >
    Duplicate
  </Button>
);

export default connect(({ tiles }) => ({ tiles }), null)(
  onAddHOC(DuplicateTile),
);
