import _ from 'lodash';
import { AnyAction } from 'redux';
import types from '../constants';
import { Reminders } from '../redux/graph';

export const defaultState = {};

export default (
  state: Reminders = defaultState,
  { payload, type }: AnyAction,
) => {
  switch (type) {
    case types.reminders.set:
      return {
        ...payload,
      };

    case types.reminders.update:
      return {
        ...state,
        [payload.id]: { ...payload, selected: state[payload.id].selected },
      };

    case types.reminders.delete:
      return {
        ..._.omit(state, payload),
      };

    case types.reminders.clear:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
};
