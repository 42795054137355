import { triageV2, triageV3 } from './triage';
import qs from "qs";

// Enrollment Search
// enrollments/search?search_term=XYZ
export const enrollment_search = (
  term,
  study_slug,
  filter_steps = '',
  { page = 1, per_page = 10, include_fakes = false },
) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/enrollments/search?search_term=${term}&page=${page}&per_page=${per_page}&include_fakes=${include_fakes}&current_step=${filter_steps}`,
  });

// Single Enrollment
// enrollments/:identifier/report:
export const enrollment_query = (
  identifier,
  study_slug,
  limit,
  offset,
  filters = null,
) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/enrollments/${identifier}/report${
      limit ? `?limit=${limit}` : ''
    }${limit && parseInt(offset, 10) ? `&offset=${offset}` : ''}${
      filters ? `${limit || offset ? '&' : '?'}${qs.stringify(filters)}` : ''
    }`,
  });

// get total events for a given enrollment
export const event_totals_query = (identifier, study_slug) =>
  triageV2({
    method: 'GET',
    url: `studies/${study_slug}/enrollments/${identifier}/total`,
  });
//default behavior is to perform this operation asynchronously
//to override that behavior, add {synchronous: true} to the `data` argument
export const process_contributions = (study_slug, data) =>
  triageV2({
    method: 'PUT',
    url: `studies/${study_slug}/enrollments/process_contributions`,
    data,
  });

export const update_data = (study_slug, identifier, data) =>
  triageV2({
    method: 'PUT',
    url: `studies/${study_slug}/enrollments/${identifier}/update_data`,
    data,
  });

// update enrollment status
// payload expects: { identifiers: ['id1', 'id2'], status: 'some other string' }
export const enrollment_status = (study_slug, data) =>
  triageV2({
    method: 'PATCH',
    url: `studies/${study_slug}/enrollments/status_update`,
    data,
  });

// mark enrollments as fake
// api/v3/studies/:study_slug/bulk_enrollments/bulk_update_fake
// expected payload:
// { enrollment_identifiers: ["e1", "e2", "e3"] }
export const enrollment_status_fake = (study_slug, data) =>
  triageV3({
    method: 'PATCH',
    url: `/studies/${study_slug}/bulk_enrollments/bulk_update_fake`,
    data,
  });

export const enrollment_bulk_update = (study_slug, data) => 
  triageV3({
    method: 'POST',
    url: `studies/${study_slug}/bulk_enrollments/bulk_update`,
    data
  })
