export default {
  description: (
    <p>
      This status indicates that the study is now actively recruiting
      participants (ie, the study is launched). Alterations to the configuration
      should be rare and only for cause. Billboard access is live and does not
      require a preview token. Transitioning into this status marks all prior
      records as test entries and transitions test participants’ current status
      to study_completed. Any production data created flows to the Production
      folder in Data Platform.
    </p>
  ),
  arrow: (
    <ul>
      <li>
        All enrollments in screening and screening complete aasm_status will be
        marked as disqualified
      </li>
      <li>
        Landing page closes and no new enrollments will be allowed to be created
      </li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
