import React from 'react';

/**
 * A simple, inline loading indicator component. Any props given are
 * passed down to the inline SVG element that is rendered. To control
 * the size, pass `width` and `height` and the dots will be scaled
 * automatically; to change the color, use the `fill` prop.
 */
export default function LoadingIndicator(props) {
  const largeRadius = 15;
  const smallRadius = 9;
  const midRadius = (largeRadius + smallRadius) / 2;
  const spacing = 5;
  const height = largeRadius * 2;
  const width = largeRadius * 6 + spacing * 2;
  const duration = '1.25s';
  const keyTimes = '0 ; 0.15 ; 0.3 ; 0.45 ; 0.60 ; 0.75 ; 0.9 ; 1';
  const lowOpacity = 0.6;
  const highOpacity = 1;
  const midOpacity = (highOpacity + lowOpacity) / 2;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="#00C480"
      {...props}
    >
      <circle cx={largeRadius} cy={largeRadius}>
        <animate
          attributeName="r"
          from={largeRadius}
          to={largeRadius}
          dur={duration}
          keyTimes={keyTimes}
          values={[
            smallRadius,
            midRadius,
            largeRadius,
            midRadius,
            smallRadius,
            smallRadius,
            smallRadius,
            smallRadius,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          dur={duration}
          keyTimes={keyTimes}
          values={[
            lowOpacity,
            midOpacity,
            highOpacity,
            midOpacity,
            lowOpacity,
            lowOpacity,
            lowOpacity,
            lowOpacity,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={largeRadius * 3 + spacing} cy={largeRadius}>
        <animate
          attributeName="r"
          dur={duration}
          keyTimes={keyTimes}
          values={[
            smallRadius,
            smallRadius,
            midRadius,
            largeRadius,
            midRadius,
            smallRadius,
            smallRadius,
            smallRadius,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          dur={duration}
          keyTimes={keyTimes}
          values={[
            lowOpacity,
            lowOpacity,
            midOpacity,
            highOpacity,
            midOpacity,
            lowOpacity,
            lowOpacity,
            lowOpacity,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={largeRadius * 5 + spacing * 2} cy={largeRadius}>
        <animate
          attributeName="r"
          from={largeRadius}
          to={largeRadius}
          dur={duration}
          keyTimes={keyTimes}
          values={[
            smallRadius,
            smallRadius,
            smallRadius,
            midRadius,
            largeRadius,
            midRadius,
            smallRadius,
            smallRadius,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          dur={duration}
          keyTimes={keyTimes}
          values={[
            lowOpacity,
            lowOpacity,
            lowOpacity,
            midOpacity,
            highOpacity,
            midOpacity,
            lowOpacity,
            lowOpacity,
          ].join(' ; ')}
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
}
