import Arms from './arms';
import Content from './content';
import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: {
    Content,
    Arms,
    Settings,
  },
  incoming,
  outgoing,
};
