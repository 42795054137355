//@ts-nocheck
import { getLocales } from '../api';
import { setLanguages } from '../actions/locale_actions';
import types from '../constants';
import { toastr } from 'react-redux-toastr';
import { Middleware } from '../redux/middleware-types';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';

const LocalMiddleware =
  ({ dispatch }: Middleware) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    switch (action?.type) {
      case types.locale.getLanguages:
        getLocales()
          .then((response) => {
            dispatch(setLanguages(response?.data));
          })
          .catch((error) => {
            /* eslint-disable no-console */
            console.error(error);
            const errorMessage =
              'Retrieving locales failed, please try refreshing the page';
            toastr.error(errorMessage, '');
          });
        break;
      default:
        next(action);
    }
  };

export default LocalMiddleware;
