import styled from 'styled-components';

export default styled.div`
  text-align: left;
  color: ${props => props.headingColor};
  font-famiy: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1em;
  font-weight: ${props => props.fontWeight || 400};
  margin: 0 0 5px 0;
  flex: auto;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SequenceTitleHolder = styled.div`
  flex: auto;
`;

export const BranchArms = styled.ul`
  min-height: 25px;
  list-style: none;
`;

BranchArms.Li = styled.li`
  font-size: 10px;
  background: ${props => (props.background ? props.background : `green`)};
  border-radius: 10px;
  padding: 4px 7px;
  margin: 0 5px 3px 0;
  line-height: 1em;
  display: inline-block;
  color: #1d2934;
  font-weight: 900;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  color: #787878;
  font-size: 12px;
  margin: 0;
  line-height: 1em;
`;

export const IconsHolder = styled.div`
  max-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto; // right aligns this element within the outer flexbox

  & > div {
    align-self: flex-end;
    font-size: 12px;
    flex: 1;
    padding: 0 3px;
  }
`;
