import { triageV3 } from './triage';

/**
 * @typedef {{
 *   id: number,
 *   brand_name: string,
 *   branding_json: Object,
 *   default: boolean,
 *   shared: boolean,
 *   graph_id: number | null,
 *   created_at: string,
 *   updated_at: string,
 * }} Brand
 */

/**
 * Fetches all of the brands that are relevant to the study with the
 * slug, which includes shared brands and study-specific brands.
 * @param {string} studySlug
 * @returns {Promise<AxiosResponse<Brand[]>>}
 */
export const fetchBrands = (studySlug) =>
  triageV3({
    method: 'GET',
    url: `studies/${studySlug}/brands`,
  });

export const queryBrands = (studySlug) =>
  fetchBrands(studySlug).then(({ data }) => data);

/**
 * Creates a new brand tied to the study given by `studySlug` with the
 * given brand name and JSON.
 * @param {string} studySlug
 * @param {string} brandName
 * @param {string} sendgridTemplateId
 * @param {Object} brandJson
 * @returns {Promise<AxiosResponse<Brand>>} - A response with the brand
 *   that was created.
 */
export const createBrand = ({
  studySlug,
  brandName,
  sendgridTemplateId,
  brandJson,
}) =>
  triageV3({
    method: 'POST',
    url: `studies/${studySlug}/brands`,
    data: {
      brand_name: brandName,
      sendgrid_template_id: sendgridTemplateId,
      branding_json: brandJson,
    },
  });

/**
 * Updates the name and JSON of the brand with ID `brandId`.
 * @param {string} studySlug
 * @param {string} brandId
 * @param {string} brandName - The new name for the brand.
 * @param {Object} brandJson - The updated branding JSON. Note that this
 *   replaces the JSON entirely; it does not update individual fields.
 * @returns {Promise<AxiosResponse<Brand>>} - A response with the brand
 *   that was updated.
 */
export const updateBrand = ({ studySlug, brandId, brandName, brandJson }) =>
  triageV3({
    method: 'PATCH',
    url: `studies/${studySlug}/brands/${brandId}`,
    data: {
      brand_name: brandName,
      branding_json: brandJson,
    },
  });

/**
 * Deletes the given brand.
 * @param {string} studySlug
 * @param {string} brandId
 * @returns {Promise<AxiosResponse<null>>} - An empty response
 */
export const deleteBrand = ({ studySlug, brandId }) =>
  triageV3({
    method: 'DELETE',
    url: `studies/${studySlug}/brands/${brandId}`,
  });
