import * as C from '../common';
import _ from 'lodash';

export default (data) => {
  if (_.has(data.content, 'collection_length'))
    data.content.collection_length = _.toNumber(data.content.collection_length);

  if (_.has(data.content, 'collection_start'))
    data.content.collection_start = _.toNumber(data.content.collection_start);

  return C.common_outgoing(data);
};
