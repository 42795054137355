import { ReminderChevron, ReminderListContainer } from '..';
import { array, bool, func, object, oneOfType } from 'prop-types';

import React from 'react';
import FollowUpItem from './FollowUpListItem';
import _ from 'lodash';
import { connect } from 'react-redux';
import { selectResource } from '../../../../actions/slate_actions';

export const determineSelected = (
  dashboard,
  selected,
  uuid,
  selected_resources,
  pathname = window.location.pathname,
) => {
  if (selected) {
    return selected === uuid;
  } else {
    if (dashboard) {
      return _.keys(selected_resources).includes(uuid);
    }

    return pathname.includes(uuid);
  }
};

const FollowUpList = props => {
  const {
    dashboard,
    follow_up,
    follow_up_content,
    sequence_uuid,
    selected_resources,
    selectResource,
    onClick,
    selected,
    uuid,
  } = props;
  return (
    <ReminderListContainer id="t-reminders">
      {_.map(follow_up_content, (value, answer) => {
        const resource_type = Object.keys(value)[0];

        if (resource_type === 'node') {
          const node_id = value[resource_type];
          const follow_up_tile = _.filter(
            follow_up,
            fup => fup.id === node_id,
          )[0];
          const title = `Page ● Completed, Answered ${answer}`;

          return follow_up_tile ? (
            <FollowUpItem
              key={follow_up_tile.id}
              onClick={e =>
                _.isFunction(onClick)
                  ? onClick({ uuid: follow_up_tile.id })
                  : selectResource({
                      [follow_up_tile.id]: {
                        sequence_uuid,
                        resource: `tile`,
                        uuid,
                      },
                    })
              }
              selected={determineSelected(
                dashboard,
                selected,
                follow_up_tile.id,
                selected_resources,
              )}
              follow_up={value}
              title={title}
            />
          ) : null;
        } else if (resource_type === 'web') {
          const web_url = value['web'];
          const title = `Web resource, Answered ${answer}`;
          return (
            <FollowUpItem
              onClick={e => window.open(web_url)}
              title={title}
              key={`${answer}_web`}
            />
          );
        }
      })}
      <ReminderChevron />
    </ReminderListContainer>
  );
};

FollowUpList.defaultProps = {
  onClick: false,
  reminder_ids: [],
  reminderMapping: {},
  allowAdd: true,
};

FollowUpList.propTypes = {
  dashboard: bool,
  onClick: oneOfType([func, bool]),
  reminder_ids: array,
  reminderMapping: object,
  allowAdd: bool.isRequired,
};

export default connect(
  ({
    configure,
    reminders,
    related_reminders,
    slate: { selected_resources },
  }) => ({
    reminders,
    related_reminders,
    selected_resources,
    configure,
  }),
  { selectResource },
)(FollowUpList);
