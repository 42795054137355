import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const FollowUpSurveyTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        availability: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    follow_up_survey: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.description,
        C.completion_message,
        C.action_title
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(FollowUpSurveyTileSettings(experience));
  }

  return schema;
};
