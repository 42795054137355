import React from 'react';

const NavParticipants = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_participants</title>
    <g fill={props.color} fillRule="nonzero">
      <path d="M13.688 5.375a1.188 1.188 0 1 0-2.377.001 1.188 1.188 0 0 0 2.377-.001zm1.187 0a2.376 2.376 0 1 1-4.751-.001 2.376 2.376 0 0 1 4.751.001zM13.69 15.41a.594.594 0 1 1 1.182.118l-.594 5.937a.594.594 0 0 1-.59.535h-2.376a.594.594 0 0 1-.59-.535l-.594-5.937a.594.594 0 1 1 1.182-.118l.54 5.402h1.3l.54-5.402z" />
      <path d="M10.719 14.875a.594.594 0 0 1 0 1.187H8.938a.594.594 0 0 1-.594-.594v-3.525a.594.594 0 1 1 1.187 0v2.932h1.188zM15.469 11.943a.594.594 0 1 1 1.187 0v3.525a.594.594 0 0 1-.593.594H14.28a.594.594 0 1 1 0-1.187h1.188v-2.932z" />
      <path d="M16.656 11.906a.594.594 0 1 1-1.187 0 2.97 2.97 0 0 0-5.938 0 .594.594 0 1 1-1.187 0 4.156 4.156 0 0 1 8.312 0zM19.625 6.563a1.188 1.188 0 1 0-2.376 0 1.188 1.188 0 0 0 2.376 0zm1.188 0a2.376 2.376 0 1 1-4.752-.002 2.376 2.376 0 0 1 4.752.002zM19.63 15.395a.594.594 0 0 1 1.178.147l-.594 4.75a.594.594 0 0 1-.589.52H17.25a.594.594 0 0 1-.576-.45l-.594-2.374a.594.594 0 1 1 1.152-.288l.482 1.925H19.1l.529-4.23z" />
      <path d="M20.813 13.13a.594.594 0 1 1 1.187 0v2.339a.594.594 0 0 1-.594.594H20.22a.594.594 0 0 1 0-1.188h.593v-1.744z" />
      <path d="M22 13.094a.594.594 0 1 1-1.188 0c0-1.815-.887-2.969-2.375-2.969a.594.594 0 1 1 0-1.188c2.221 0 3.563 1.744 3.563 4.157zM4.188 6.563a2.376 2.376 0 1 1 4.75 0 2.376 2.376 0 0 1-4.75 0zm1.187 0a1.188 1.188 0 1 0 2.376-.002 1.188 1.188 0 0 0-2.376.002zM7.768 17.7a.594.594 0 1 1 1.152.288l-.594 2.375a.594.594 0 0 1-.576.45H5.375a.594.594 0 0 1-.59-.52l-.593-4.75a.594.594 0 0 1 1.178-.148l.53 4.23h1.386l.482-1.925z" />
      <path d="M4.781 14.875a.594.594 0 1 1 0 1.188H3.594A.594.594 0 0 1 3 15.469v-2.338a.594.594 0 1 1 1.188 0v1.744h.593z" />
      <path d="M4.188 13.094a.594.594 0 1 1-1.188 0c0-2.414 1.341-4.156 3.563-4.156a.594.594 0 0 1 0 1.187c-1.488 0-2.375 1.153-2.375 2.969z" />
    </g>
  </svg>
);

export default NavParticipants;
