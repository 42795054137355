import _ from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import types from '../constants';
import { RootState, Slate, Slatecoordinates } from '../redux/graph';

export const setSlateCoordinates = (payload: Slatecoordinates) => ({
  type: types.slate.setSlateCoordinates,
  payload,
});

export const resetSlateCoordinates = (payload?: Slatecoordinates) => ({
  type: types.slate.resetSlateCoordinates,
});

export const selectResource = (payload: any) => ({
  type: types.slate.select,
  payload,
});
export const selectClearType = (payload: any) => ({
  type: types.slate.selectClearType,
  payload,
});
export const selectClearAll = (payload?: any) => ({
  type: types.slate.selectClearAll,
  payload,
});

// general slate configuration
export const slateConfiguration = (payload: Slate) => ({
  type: types.slate.slateConfiguration,
  payload,
});

// method used to generate a list of available arms/types to filter
export const typesCollect = (payload: Array<string>) => ({
  type: types.slate.typesCollect,
  payload,
});

// used to filter arms/types within display
export const armsFilter = (payload: any) => ({
  type: types.slate.armFilter,
  payload,
});
export const typeFilter = (payload: any) => ({
  type: types.slate.typeFilter,
  payload,
});

export const modify_resource =
  ({ clear = false, ...payload }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: types.slate.modify_resource,
      payload,
    });
  };

export const toggle_display_reminder = (payload: string) => ({
  type: types.slate.toggleDisplayReminder,
  payload,
});

export const clearResourceOrder = () => ({
  type: types.slate.clearResourceOrder,
});
export const generateResourceOrder = (data: RootState) => {
  const { tiles, reminders, sequences, ...graph } = data;
  let payload = [graph.id];

  if (_.has(graph, 'sequence_ids') && !_.isEmpty(graph.sequence_ids)) {
    _.forEach(graph.sequence_ids, (sequence) => {
      payload.push(sequence);

      if (
        _.has(sequences, sequence) &&
        !_.isEmpty(sequences[sequence]['tile_ids'])
      ) {
        _.forEach(sequences[sequence].tile_ids, (tile) => {
          payload.push(tile);
          // TODO: check why we were using this syntax : !_.isEmpty(tiles[tile],'reminder_ids')
          if (_.has(tiles, tile) && !_.isEmpty(tiles[tile]['reminder_ids'])) {
            _.forEach(tiles[tile].reminder_ids, (reminder) => {
              if (_.has(reminders, reminder)) {
                payload.push(reminder);
              }
            });
          }
        });
      }
    });
  }

  return {
    type: types.slate.getResourceOrder,
    payload,
  };
};

export const setSlateEditorSidebarWidth = (payload: Slate) => ({
  type: types.slate.setSlateSidebarWidth,
  payload,
});
