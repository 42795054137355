import types from '../constants';
import Reminder from '../routes/edit/components/reminder';
import { Reminders } from '../redux/graph';

export const setReminders = (payload: Reminders) => ({
  type: types.reminders.set,
  payload,
});
export const updateReminder = (payload: Reminder) => ({
  type: types.reminders.update,
  payload,
});
export const clearReminders = () => ({ type: types.reminders.clear });
export const deleteReminders = (payload: Reminders) => ({
  type: types.reminders.delete,
  payload,
});
