import { AnyAction, Dispatch } from 'redux';
import types from '../constants';
import { userconst } from '../reducers/contants';
import { User } from '../redux/graph';

type LocalStorageUserData = Pick<User, 'token' | 'username' | 'authorization'>;

export const getStudies = () => ({ type: types.user.get_studies });
export const setStudies = (payload: any) => ({
  type: types.user.set_studies,
  payload,
});

export const setLocalstorage = (userData: LocalStorageUserData) => {
  localStorage.setItem(userconst.token, userData.token);
  localStorage.setItem(userconst.username, userData.username);
  localStorage.setItem(
    userconst.authorization,
    JSON.stringify(userData.authorization),
  );
};

export const unsetLocalStorage = () => {
  localStorage.removeItem(userconst.token);
  localStorage.removeItem(userconst.username);
  localStorage.removeItem(userconst.authorization);
};

export const setCookie = (cname: string, cvalue: string, exdays?: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    expires +
    `;domain=${
      window.env.REACT_APP_TRIAGE_API_DOMAIN || window.location.hostname
    }` +
    ';path=/';
};

export const loginUser = (payload: User) => ({
  type: types.user.log_in,
  payload,
});
export const loggedIn = (payload: Partial<User>) => ({
  type: types.user.logged_in,
  payload,
});
export const logoutUser = () => ({ type: types.user.log_out });
export const assignAuthorization = (payload: User) => ({
  type: types.user.assign_authorization,
  payload,
});

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  // need to add loader
  unsetLocalStorage();
  dispatch(logoutUser());
};
