import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero" stroke="#000" strokeWidth=".7">
          <path fill="#FFF" d="M1.35 6.993h19.3v12.3H1.35z" />
          <path
            d="M1.664 11.143H20M6 19.005V7.594M11 19.005V7.594M16 19.005V7.594M1.664 15.143H20"
            strokeLinecap="square"
          />
        </g>
        <path
          d="M1.623 1.805c.543 0 .936.223 1.178.67.187.346.281.82.281 1.421 0 .57-.085 1.042-.255 1.416-.246.535-.648.802-1.207.802-.504 0-.879-.218-1.125-.656-.205-.365-.307-.855-.307-1.47 0-.477.061-.886.184-1.228.23-.637.648-.955 1.251-.955zm-.006 3.829c.274 0 .491-.121.654-.363.162-.243.243-.694.243-1.354 0-.477-.059-.869-.176-1.176-.117-.308-.345-.462-.683-.462-.31 0-.537.146-.68.438-.144.292-.216.722-.216 1.29 0 .429.046.772.137 1.032.141.397.381.595.721.595zm1.934-1.576h1.47V4.6h-1.47v-.542zm2.288-1.029v-.404c.381-.037.647-.1.797-.186.15-.087.263-.293.337-.617h.416V6h-.563V3.03H5.84zm4.35-1.224c.544 0 .936.223 1.179.67.187.346.28.82.28 1.421 0 .57-.084 1.042-.254 1.416-.246.535-.648.802-1.207.802-.504 0-.879-.218-1.125-.656-.205-.365-.308-.855-.308-1.47 0-.477.062-.886.185-1.228.23-.637.647-.955 1.25-.955zm-.005 3.829c.274 0 .491-.121.653-.363.162-.243.244-.694.244-1.354 0-.477-.059-.869-.176-1.176-.117-.308-.345-.462-.683-.462-.31 0-.537.146-.681.438-.144.292-.215.722-.215 1.29 0 .429.046.772.137 1.032.141.397.381.595.721.595zm3.774-3.938h.688l2.174 3.487V1.696h.554V6h-.653l-2.207-3.483V6h-.556V1.696zm6.507 2.54l-.654-1.901-.694 1.901h1.348zm-.958-2.54h.659L21.728 6h-.64l-.436-1.29H18.95L18.484 6h-.597l1.62-4.304z"
          fill="#000"
        />
      </g>
    </svg>
  );
}
