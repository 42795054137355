import { usePrevious } from '@evidation/utils';
import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';

import Login from 'src/components/login';
import { useTypedSelector } from 'src/redux/utils';

type AuthEntryProps = {
  children: React.ReactElement;
  loginComponent?: React.ComponentType;
};

const AuthEntry: React.FC<AuthEntryProps> = ({
  children,
  loginComponent: LoginComponent = Login,
}) => {
  const user = useTypedSelector((state) => state.user);
  const wasUserAuthenticated = usePrevious(user.authenticated);

  useEffect(() => {
    if (wasUserAuthenticated && !user.authenticated) {
      toastr.error('Your session has expired.', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authenticated]);

  if (user.authenticated) {
    return children;
  }
  return <LoginComponent />;
};
export default AuthEntry;
