import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect
          fill="#000"
          fillRule="nonzero"
          x="6"
          y="5"
          width="1"
          height="6"
          rx=".5"
        />
        <path
          d="M2.738 4.98h.8v1.313h.752v.646h-.752v3.072c0 .164.056.274.167.33a.71.71 0 0 0 .308.048 3.708 3.708 0 0 0 .277-.013V11c-.091.026-.186.045-.284.057a2.695 2.695 0 0 1-.319.018c-.369 0-.62-.095-.751-.284-.132-.189-.198-.434-.198-.736V6.94h-.637v-.646h.637V4.98z"
          fill="#000"
        />
        <path
          d="M21.407 3.055H.351a.364.364 0 0 0-.248.098.328.328 0 0 0-.103.236v9.332c0 .088.038.174.103.236a.364.364 0 0 0 .248.098h21.057a.364.364 0 0 0 .248-.098.328.328 0 0 0 .103-.236V3.39a.328.328 0 0 0-.104-.236.364.364 0 0 0-.248-.098zM.702 3.72h20.354v8.666H.702V3.721z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          stroke="#000"
          strokeWidth=".7"
          fill="none"
          d="M1.35 13.35h19.3v6.3H1.35z"
        />
        <path
          d="M3.724 16a.725.725 0 0 1 0 1.449.725.725 0 0 1 0-1.449zM6.723 16a.725.725 0 0 1 0 1.449.725.725 0 0 1 0-1.449zM9.723 16a.725.725 0 0 1 0 1.449.725.725 0 0 1 0-1.449z"
          fill="#000"
        />
      </g>
    </svg>
  );
}
