// Inspired heavily by: https://stackoverflow.com/a/56823112

import type {
  History,
  Location,
  LocationDescriptor,
  LocationDescriptorObject,
} from 'history';
import { parsePath } from 'history';

type LocationState = History.LocationState;

function locationWithPreservedQueryParams(
  history: History,
  queryParams: string[],
  location: LocationDescriptorObject,
): LocationDescriptorObject {
  const currentParams = new URLSearchParams(history.location.search);
  if (currentParams.toString()) {
    const resultParams = new URLSearchParams();
    for (const queryParam of queryParams) {
      if (currentParams.has(queryParam)) {
        resultParams.set(queryParam, currentParams.get(queryParam)!);
      }
    }
    if (location.search) {
      const newSearchParams = new URLSearchParams(location.search);
      for (const [key, value] of newSearchParams) {
        resultParams.set(key, value);
      }
    }
    location.search = resultParams.toString();
  }
  return location;
}

function createLocationDescriptorObject(
  location: LocationDescriptor,
  state?: LocationState,
): LocationDescriptorObject {
  return typeof location === 'string'
    ? { ...parsePath(location), state }
    : location;
}

/**
 * Creates a patched history object that preserves certain query parameters
 *   when changing the location.
 * @param history The History object to patch.
 * @param getQueryParameters A function that takes the current location and the
 *   new location object and returns an array of query parameters to preserve
 *   for the location change. It is called any time a new location is pushed or
 *   replaced.
 * @returns A patched version of the normal History object returned by
 *   `createHistory`.
 */
export function patchHistoryToPreserveQueryParams(
  history: History,
  getQueryParameters: (
    currentLocation: Location,
    newLocation: LocationDescriptorObject,
  ) => string[],
): History {
  const oldPush = history.push;
  const oldReplace = history.replace;
  history.push = (location: LocationDescriptor, state?: LocationState) => {
    const locationObj = createLocationDescriptorObject(location, state);
    return oldPush.apply(history, [
      locationWithPreservedQueryParams(
        history,
        getQueryParameters(history.location, locationObj),
        locationObj,
      ),
    ]);
  };
  history.replace = (location: LocationDescriptor, state?: LocationState) => {
    const locationObj = createLocationDescriptorObject(location, state);
    return oldReplace.apply(history, [
      locationWithPreservedQueryParams(
        history,
        getQueryParameters(history.location, locationObj),
        locationObj,
      ),
    ]);
  };
  return history;
}
