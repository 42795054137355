export default (target, method, treeData, resource, payload) => {
  return [target].reduce(
    (acc, key) =>
      method({
        acc,
        key,
        ...treeData,
        [resource]: {
          ...treeData[resource],
          [target]: {
            ...treeData[resource][target],
            ...payload,
          },
        },
        insert: {},
      }),
    [],
  );
};
