"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var _reduxForm = require("redux-form");

var reducers = {
  form: _reduxForm.reducer
};
var reducer = (0, _redux.combineReducers)(reducers);
var store = (0, _redux.createStore)(reducer
/* preloadedState, */
, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
var _default = store;
exports["default"] = _default;