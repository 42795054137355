import styled from 'styled-components';
import React from 'react';
import Button from '../../../inputs/Button';
import Modal from '../../../modal/SimpleModal';

const REVERT_MODAL_TITLE = 'Revert to this version?';
const REVERT_MODAL_CONTENT = 'Your current study will revert to the version:';
const REVERT_MODAL_CONTENT_2 =
  'This will overwrite any unpublished changes to the graph';
const REVERT_MODAL_CANCEL_TXT = 'Cancel';
const REVERT_MODAL_CONFIRM_TXT = 'Revert';

const RevertModalContent = styled.div`
  max-width: 450px;
  padding: 2rem;
  & h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  & .label {
    background-color: #faa348;
    color: #fff;
    font-weight: bold;
    padding: 0rem 0.5rem 0rem 0.5rem;
    border-radius: 6px;
  }

  & .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 1rem;
    > * + * {
      margin-left: 1rem;
    }
  }
`;

export default function RevertModal({ onClose, selectedVersion, onRevert }) {
  return (
    <Modal onClose={onClose} center>
      <RevertModalContent>
        <h3>{REVERT_MODAL_TITLE}</h3>
        <p>
          {REVERT_MODAL_CONTENT}{' '}
          <span className="label" data-testid="version-id">
            {selectedVersion.version_id}
          </span>{' '}
          {REVERT_MODAL_CONTENT_2}
        </p>
        <div className="actions">
          <Button innerType="button" onClick={onClose}>
            {REVERT_MODAL_CANCEL_TXT}
          </Button>
          <Button onClick={() => onRevert(selectedVersion.id)} type="primary">
            {REVERT_MODAL_CONFIRM_TXT}
          </Button>
        </div>
      </RevertModalContent>
    </Modal>
  );
}
