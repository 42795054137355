import _ from 'lodash';
export const safe_name_regex = /[^a-zA-Z0-9.]+/g;

export const camelToDash = (str, subst = `_`) => {
  if (str.length > 1) {
    let [letter, next_letter] = str.slice(0, 2);
    const number_to_letter = `${letter}${next_letter}`.match(/[0-9][a-zA-Z]/g);

    const camel_transition =
      letter.match(/[a-zA-Z]/g) && next_letter.match(/[A-Z0-9]/g);
    letter =
      number_to_letter || camel_transition ? `${letter}${subst}` : letter;

    return `${letter}${camelToDash(str.slice(1), subst)}`;
  }

  return str;
};

export const camelToDashStudySlug = (str, subst = `_`) => {
  if (str.length > 1) {
    let [letter, next_letter] = str.slice(0, 2);

    const camel_transition =
      letter.match(/[a-zA-Z]/g) && next_letter.match(/[A-Z]/g);
    letter = camel_transition ? `${letter}${subst}` : letter;

    return `${letter}${camelToDashStudySlug(str.slice(1), subst)}`;
  }

  return str;
};

export const alphaNumericUnderscore = (str = ``, subst = `_`) =>
  str.replace(/[^a-zA-Z0-9]/g, subst);

// (TREATS "SCUBA" AS "Scuba")
const ignoreAcronyms = (str = ``) =>
  str.replace(/([A-Z]{2}[A-Z]+)/g, (match) => _.startCase(match.toLowerCase()));

const stripSpecialChars = (
  str = ``,
  { substitution = `_`, allowHyphens = false } = {},
) => {
  const regex = new RegExp(`[^a-zA-Z0-9${allowHyphens ? '\\-' : ''}]+`, 'g');
  return camelToDash(
    ignoreAcronyms(str).replace(regex, substitution),
    substitution,
  ).toLowerCase();
};

export const parseStudySlug = (str = ``, subst = `_`) =>
  camelToDashStudySlug(
    ignoreAcronyms(str).replace(safe_name_regex, subst),
    subst,
  ).toLowerCase();

export default stripSpecialChars;
