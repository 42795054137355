import { HeadingContainer, TileContent } from '../tile/container';
import React, { Component } from 'react';
import { func, string } from 'prop-types';

import { single_experience_tile_types } from '../../../../constants';
import AddTileUI from './AddTileUi';
import OnAddHOC from '../../hoc/onAddHOC';
import Title from '../title';
import styled from 'styled-components';
import { AuthWrap } from '../../../../components/auth';
import { AuthActions } from '../../../../constants';

const AddTileHeadingContainer = styled(HeadingContainer)`
  cursor: pointer;
  padding: 0;
  display: flex;
  min-height: auto;
  align-items: center;
  padding: 1px 10px 4px;
  background-color: #f3f3f3;
  border-top: ${(props) =>
    !props.has_tiles && `1px solid rgba(0, 0, 0, 0.15);`};
  ${(props) =>
    !props.has_tiles
      ? `border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;`
      : `border-radius: 5px !important;`};

  ${Title} {
    font-size: 12px;
    margin: 2px 0 0 0;
  }

  span {
    width: 40px;
    text-align: center;
    font-size: 20px;
    color: #2965ff;
    margin-left: 0;
    line-height: 1em;
  }
`;

class Add extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      addMode: false,
      title: '',
      type: '',
    };

    this.baseState = this.state;
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { title, type } = this.state;
    const {
      sequence_uuid = undefined,
      uuid = undefined,
      resource,
      onAdd,
      graph: { masonry_enabled = false },
    } = this.props;

    const ext_title = masonry_enabled
      ? { external_title: title, }
      : {};

    if (
      (title && type && title !== `` && type !== `` && sequence_uuid) ||
      uuid
    ) {
      const payload = single_experience_tile_types.includes(type)
        ? {
            resource,
            content: {
              title,
              type,
            },
          }
        : {
            resource,
            content: {
              experiences: {
                default: {
                  title,
                  ...ext_title,
                },
              },
              type,
            },
          };

      onAdd(payload, sequence_uuid, uuid).then(() => {
        this.setState(this.baseState);
      });
    }
  }

  render() {
    const { addMode } = this.state;
    const { has_tiles } = this.props;

    return addMode ? (
      <AddTileUI
        {...this.props}
        {...this.state}
        has_tiles={has_tiles}
        onSubmit={this.onSubmit}
        onChangeField={(field) => this.setState(field)}
        cancel={() => this.setState({ addMode: false, type: '', title: '' })}
      />
    ) : (
      <AuthWrap
        requiredActions={[AuthActions.graphs.publish, AuthActions.tiles.create]}
      >
        <AddTileHeadingContainer
          has_tiles={!has_tiles}
          data-test="addTile"
          onClick={() => this.setState({ addMode: true })}
        >
          <span>+</span>
          <TileContent>
            <Title headingColor="#2965FF">Add A Tile</Title>
          </TileContent>
        </AddTileHeadingContainer>
      </AuthWrap>
    );
  }
}

Add.defaultProps = {
  resource: `tile`,
};

Add.propTypes = {
  resource: string.isRequired,
  sequence_uuid: string.isRequired,
  onAdd: func.isRequired,
};

export default OnAddHOC(Add);
