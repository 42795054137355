import React, { Component } from 'react';
import TransformTree, { reduceSequences } from '../../../utils/translateToTree';

import Toggle from '../../../components/singleToggle';
import _ from 'lodash';
import { apiGraph } from '../../../api';
import clone from '../../../utils/objectClone';
import { connect } from 'react-redux';
import generatePayload from '../../../utils/generatePayload';
import { setGraphSequencesTiles } from '../../../actions/combination';
import styled from 'styled-components';

const DuplicateHolder = styled.div`
  boreder-radius: 6px;
  width: 350px;
  background: white;
  padding: 10px;
  font-size: 12px;

  div {
    display: flex;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    color: #00bbd5;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const LabelHolder = styled.div`
  align-items: center;
  flex: 5 0 0;
`;

const ControlHolder = styled.div`
  align-items: center;
  flex: 1 0 0;
  font-size: 10px;
`;

const NumberInput = styled.input`
  padding: 0;
  border: 0;
  border-bottom: 1px solid #00bbd5;
  width: 50px;
  background: transparent;
  text-align, center;

  &:focus {
    background: #00bbd5;
    color: #FFF;
  }
`;

class DuplicateDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      increment_title: false,
      increment_resources: false,
    };

    this.onKeyboardAction = this.onKeyboardAction.bind(this);
    this.duplicateSequence = this.duplicateSequence.bind(this);
  }

  componentDidMount() {
    window.addEventListener(`keyup`, this.onKeyboardAction);
  }

  componentWillUnmount() {
    window.removeEventListener(`keyup`, this.onKeyboardAction);
  }

  onKeyboardAction(e) {
    // esc = 27
    // enter = 13

    if (e.keyCode === 27) {
      this.props.toggleDropdown();
    }

    if (e.keyCode === 13) {
      this.duplicateSequence();
    }
  }

  duplicateSequence() {
    let content = [];
    const { sequence_uuid, graph, setGraphSequencesTiles } = this.props;

    // creating a dulplicate resource object
    const duplicatedResource = _.first(
      clone(
        generatePayload(
          sequence_uuid,
          reduceSequences,
          this.props,
          `sequences`,
          {},
        ),
      ),
    );

    // remove id from resource
    _.unset(duplicatedResource, 'id');

    for (let i = 0; i < this.state.count; i++) {
      content.push(
        _.merge({}, duplicatedResource, {
          content: { title: `${duplicatedResource.content.title} ${i + 1}` },
        }),
      );
    }

    // geberating tree payload for new sequence
    const newPayload = TransformTree({
      ...this.props,
      insert: {
        target: sequence_uuid,
        content,
      },
    });

    apiGraph.update(graph.id, newPayload).then(setGraphSequencesTiles);
  }

  render() {
    const { increment_title, increment_resources, count } = this.state;
    const { toggleDropdown } = this.props;

    return (
      <DuplicateHolder>
        <div>
          <LabelHolder>How Many Duplicates</LabelHolder>
          <ControlHolder>
            <NumberInput
              defaultValue={count}
              data-test="duplicate_number"
              onChange={({ target: { value: count } }) =>
                this.setState({ count })
              }
              type="number"
            />
          </ControlHolder>
        </div>
        <div>
          <LabelHolder>Increment Title</LabelHolder>
          <ControlHolder>
            <Toggle
              label=""
              data-test="toggle_increment_title"
              on={increment_title}
              onClick={() =>
                this.setState({ increment_title: !increment_title })
              }
            />
          </ControlHolder>
        </div>
        <div>
          <LabelHolder>Increment delays & Reminders</LabelHolder>
          <ControlHolder>
            <Toggle
              label=""
              data-test="toggle_increment_delays_reminders"
              on={increment_resources}
              onClick={() =>
                this.setState({ increment_resources: !increment_resources })
              }
            />
          </ControlHolder>
        </div>
        <div>
          <LabelHolder>
            <button
              data-test="duplicate_cancel"
              onClick={() => toggleDropdown()}
            >
              Cancel (esc)
            </button>
          </LabelHolder>
          <ControlHolder>
            <button
              data-test="duplicate_execute"
              onClick={() => this.duplicateSequence()}
            >
              Duplicate (enter)
            </button>
          </ControlHolder>
        </div>
      </DuplicateHolder>
    );
  }
}

export default connect(state => state, { setGraphSequencesTiles })(
  DuplicateDropdown,
);
