import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    title: yup.string().required('Title is required.'),
    branch_arms: yup.string().when('add_to_existing_branch', {
      is: true,
      then: yup
        .string()
        .required('Please select an existing branch for this sequence.'),
    }),
    on_cap_overflow: yup.string().when('strategy', {
      is: (strategy: string) => strategy === 'list',
      then: yup.string().required("Please choose a cap full behavior")
    }),
    strategy: yup.string().when('branching', {
      is: true,
      then: yup
        .string()
        .oneOf(['list', 'conditional'])
        .required('Please select a branching strategy'),
    }),
    condition: yup.string().when('strategy', {
      is: (strategy: string) => strategy === 'conditional',
      then: yup.string().required('Condition is required'),
    }),
    default_arm: yup.string().when('strategy', {
      is: (strategy: string) => strategy === 'conditional' || strategy === 'list',
      then: yup.string().required('Please select a default branch'),
    }),
    branches: yup.array().when('branching', {
      is: true,
      then: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required('Branch Name is required'),
            condition: yup.string().when('strategy', {
              is: (strategy: string) => strategy === 'conditional',
              then: yup.number().required('Condition is required'),
            }),
            cap: yup
              .number()
              .typeError('Must be a valid number')
              .optional()
              .nullable()
              // we should transform an empty string to null
              // to allow the validation to pass since this is an optional field
              // it will default to ""
              .transform((value, originalValue) =>
                String(originalValue).trim() === '' ? null : value,
              ),
          }),
        )
        .min(2, 'Branching requires at least 2 branches')
        .required(),
    }),
    event_emission: yup.array().when('emit_event', {
      is: true,
      then: yup
        .array()
        .of(
          yup.object().shape({
            node_status: yup.string().required('Node status is required'),
            event_slug: yup.string().required('Event slug is required'),
            event_status: yup.string().required('Event status is required'),
          }),
        )
        .min(
          1,
          'Atleast 1 event must be configured when Emit Event is toggled on.',
        )
        .required(
          'Atleast 1 event must be configured when Emit Event is toggled on.',
        ),
    }),
  })
  .required();

export default schema;
