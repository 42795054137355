import React, { useMemo } from 'react';
import CuriousPanda from '../../../../../../components/fields/CuriousPanda';
import { noop } from '../../../../../../utils/noop';
import * as C from '../../../common';

export const ConnectAppTileSettings = (
  displayGroupNames,
  masonryEnabled = false,
) => {
  return {
    label: 'Settings',
    name: 'content',
    input: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.experience_attribute,
      C.optional,
      C.display_group_name(displayGroupNames),
      C.cancellation__depends_on,
      C.emit_event,
      C.visibility__depends_on,
    ],
  };
};

export default function ConnectAppTileSettingsForm({
  initialValues,
  onChange,
  displayGroupNames = [],
  masonryEnabled = false,
}) {
  const schema = useMemo(() => {
    return [ConnectAppTileSettings(displayGroupNames, masonryEnabled)];
  }, [displayGroupNames, masonryEnabled]);
  return (
    <CuriousPanda
      schema={schema}
      initialValues={initialValues}
      enableReinitialize
      form="connect_app_settings_form"
      onChange={onChange}
      onSubmit={noop}
    >
      {(p) => <div />}
    </CuriousPanda>
  );
}
