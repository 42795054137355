import React, { Component } from 'react';

import _ from 'lodash';
import { apiGraph } from '../../../api';
import { connect } from 'react-redux';
import { loadGraph } from '../../../actions/combination';
import { selectClearType } from '../../../actions/slate_actions';
import { toastr } from 'react-redux-toastr';
import { logError } from '@evidation/logger';
import stripHtml from '../../../utils/stripHtml';
import styled from 'styled-components';
import { WarningCircle } from '@evidation/ui/lib/icons';
import { getRelatedReminderId } from '../../../utils/reminders';

export const text = {
  confirm: (resource) =>
    `Please confirm deletion of the following ${resource}(s), You cannot un-do this:`,
  delete: (resource) => `You have successfully deleted all the ${resource}(s).`,
  resourceDelete: (resource) =>
    `There was a problem deleting the ${resource}(s).`,
  defaultErrMsg: 'Something went wrong removing this item.',
  defaultConfirmMsg: (resource) =>
    `Please confirm deletion of the ${resource}(s). You cannot un-do this:`,
  sequenceScheduleMsg:
    'Deleting a sequence will also delete any attached schedules.',
};

const commonError = (error) => {
  logError(error);
  _.has(error.response, 'data')
    ? _.forEach(error.response.data.errors, (error) => toastr.error(error))
    : toastr.error(text.defaultErrMsg);
};

export const onDeleteMulti = async (
  resources,
  { graph = {}, loadGraph, ...props },
) => {
  const selectedResources = props?.slate?.selected_resources ?? {};
  let filteredResourcesArray = [];
  for (let resource of resources) {
    let filteredResources = [];
    if (resource.resource === 'sequence' || resource.resource === 'reminder') {
      filteredResources.push(resource);
    }

    // Need to test if resource it a tile. If it is there is a possibility of nested tiles
    // that have a parent uuid (like reminders). This case needs to be tested to remove the
    // expected tile without deleting the parent.
    if (resource.resource === 'tile') {
      for (const [key, value] of Object.entries(selectedResources)) {
        if (
          value?.uuid &&
          value?.resource === 'tile' &&
          value.uuid === resource.uuid
        ) {
          filteredResources.push({ ...value, uuid: key });
        }
      }
    }

    for (let res of filteredResources) {
      filteredResourcesArray = [...filteredResourcesArray, res];
    }
  }
  await toastrBulkDelete(filteredResourcesArray, {
    graph,
    loadGraph,
    ...props,
  });
  await loadGraph(graph.id);
};

export const onDelete = (graph, resourceConfig, tiles) => {
  const { id, masonry_enabled } = graph;
  const { resource, sequence_uuid, uuid, reminder_uuid } = resourceConfig;
  switch (resource) {
    case 'sequence':
      return apiGraph.delete_sequence(id, sequence_uuid).catch(commonError);

    case 'tile':
      return apiGraph.delete_tile(id, sequence_uuid, uuid).catch(commonError);

    case 'reminder':
      if (masonry_enabled) {
        const relatedReminderId = getRelatedReminderId({
          reminderId: reminder_uuid,
          tileId: uuid,
          tiles,
        });
        return apiGraph.reminders
          .delete({
            graphId: id,
            sequenceId: sequence_uuid,
            tileId: uuid,
            reminderId: reminder_uuid,
            delayId: relatedReminderId,
          })
          .catch(commonError);
      }
      // TODO: Use Axios.all when we can fix the delete endpoint to work
      // with concurrent requests (SPP-2338, SPP-2339)
      const relatedReminderId = getRelatedReminderId({
        reminderId: reminder_uuid,
        tileId: uuid,
        tiles,
      });
      let promise = apiGraph
        .delete_reminder(id, sequence_uuid, uuid, reminder_uuid)
        .catch(commonError);
      if (relatedReminderId) {
        promise = promise.then(() =>
          apiGraph.delete_reminder(id, sequence_uuid, uuid, relatedReminderId),
        );
      }
      return promise;

    default:
      console.warn(`you can not delete ${resource} of resource`);
      toastr.error(text.resourceDelete(resource));
  }
};

export const Alert = styled.div`
  background: rgba(246, 153, 4, 1);
  color: rgba(26, 26, 26, 1);
  padding: 4px 8px 4px 8px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 8px;
  }
`;

export const ConfirmMessageContainer = styled.div`
  margin: 10px;
  ul {
    margin: 10px;
    max-height: 250px;
    overflow: auto;
    li {
      font-weight: 400;
      margin: 0.3rem 0;
    }
  }
`;

const ConfirmMessageComponent = ({
  resources,
  resourceType,
  sequences,
  tiles,
  reminders,
}) => (
  <ConfirmMessageContainer>
    <h2>{confirmMessage(resourceType)}</h2>
    {resourceType === 'sequence' && (
      <Alert>
        <WarningCircle />
        <p>{text.sequenceScheduleMsg}</p>
      </Alert>
    )}
    <ul>
      {resources.map((resource) => (
        <li
          key={
            resource.uuid || resource.sequence_uuid || resource.reminder_uuid
          }
        >
          {getResourceName(resource, { sequences, tiles, reminders })}
        </li>
      ))}
    </ul>
  </ConfirmMessageContainer>
);

export const toastrBulkDelete = (
  resources,
  {
    graph = {},
    related_reminders,
    loadGraph,
    sequences,
    tiles,
    reminders,
    slate: { selected_resources = {} } = {},
    selectClearType,
  },
) => {
  const resourceType = resources[0]?.resource;
  const promise = new Promise((resolve, reject) => {
    toastr.confirm(null, {
      component: () => (
        <ConfirmMessageComponent
          resourceType={resourceType}
          resources={resources}
          sequences={sequences}
          tiles={tiles}
          reminders={reminders}
        />
      ),
      onCancel: resolve,
      onOk: () => {
        // TODO: we should change this to a Promise.all at SPP-2339 BE change is need
        // to support send all the delete request a the same time SPP-2338
        resources
          .reduce(
            (chain, resource) =>
              chain.then(() => onDelete(graph, resource, tiles)),
            Promise.resolve(),
          )
          .then(() => {
            toastr.success(text.delete(resourceType));
            selectClearType(resourceType);
            loadGraph(graph.id);
            resolve();
          })
          .catch(() => {
            console.warn(`you can not delete ${resourceType} of resource`);
            toastr.error(text.resourceDelete(resourceType));
            loadGraph(graph.id);
            resolve();
          });
      },
    });
  });
  return promise;
};

export const getResourceName = (
  { resource, sequence_uuid, uuid, reminder_uuid },
  { sequences, tiles, reminders },
) => {
  switch (resource) {
    case `sequence`:
      return sequences[sequence_uuid].content.title;
    case `tile`:
      return stripHtml(
        _.has(tiles, `[${uuid}].content.experiences`)
          ? tiles[uuid].content.experiences.default.title
          : tiles[uuid].content.title,
      );
    case `reminder`:
      return reminders[reminder_uuid].content.title;
    default:
      return 'unknow resource';
  }
};

export const confirmMessage = (resource) => {
  switch (resource) {
    case `sequence`:
    case `tile`:
    case `reminder`:
      return text.confirm(resource);
    default:
      return text.defaultConfirmMsg(resource);
  }
};

export default (WrappedComponent) => {
  class Comp extends Component {
    constructor(props) {
      super(props);

      this.onDeleteMulti = onDeleteMulti.bind(this);
    }

    render() {
      return (
        <WrappedComponent
          onDeleteMulti={(resources) =>
            this.onDeleteMulti(resources, this.props)
          }
          {...this.props}
        />
      );
    }
  }

  return connect((state) => state, {
    loadGraph,
    selectClearType,
  })(Comp);
};
