import Attachments from '../communications/attachments';
import incoming from './incoming';
import outgoing from './outgoing';
import Settings from './settings';

export default {
  form: {
    Attachments,
    Settings,
  },
  incoming,
  outgoing,
};
