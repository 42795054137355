import _ from 'lodash';

export default content => {
  let targetContent = content;

  if (_.has(content, `experiences`)) {
    /// checking to see if this tile has experiences
    const firstExperienceKey = _.keys(content.experiences)[0];

    if (_.has(content.experiences[firstExperienceKey], `title`)) {
      targetContent = content.experiences[firstExperienceKey];
    }
  }

  return targetContent['title'];
};
