import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  displayGroupNames,
}) => {
  return [
    [
      {
        label: 'Content',
        name: `content.experiences.${experience}`,
        input: [
          C.title,
          C.external_title,
          C.description,
          C.action_title,
          C.completion_message,
        ],
      },
      {
        label: 'Settings',
        name: `content`,
        input: getInputsForExperience({
          experience,
          mandatoryInputs: [
            C.optional,
            C.display_group_name(displayGroupNames),
            C.experience_attribute,
          ],
          optionalInputs: [
            C.cancellation__depends_on,
            C.emit_event,
            C.merge_data,
            C.stale__depends_on,
            C.visibility__depends_on,
          ],
        }),
      },
    ],
  ];
};
