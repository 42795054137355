import _ from 'lodash';

export function getReminderListFromObjects(object_array) {
  const newOrder = [];

  _.each(object_array, related_tile_obj => {
    if (related_tile_obj !== undefined) {
      const key = Object.keys(related_tile_obj)[0];
      const value = related_tile_obj[key];
      newOrder.push(value);
      newOrder.push(key);
    }
  });

  return newOrder;
}
