import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M8.484 14.36H5.68v-1.864H3.813V9.689H5.68V7.822h2.804v1.866h1.867v2.808H8.484v1.864zm-2.048-.755h1.293v-1.866h1.867v-1.295H7.729V8.578H6.436v1.866H4.57v1.294h1.866v1.867z"
          fillRule="nonzero"
        />
        <path
          d="M2.21 4.047c-.597 0-1.08.483-1.082 1.08v11.795A1.083 1.083 0 0 0 2.21 18h17.576c.595 0 1.078-.484 1.08-1.08V5.127a1.082 1.082 0 0 0-1.08-1.08H2.209zm-.321 12.875V5.127c0-.18.145-.325.325-.325h9.39v12.445H2.21a.324.324 0 0 1-.32-.325zm18.222 0a.324.324 0 0 1-.325.325H12.36V4.802h7.425a.326.326 0 0 1 .326.325v11.795z"
          fillRule="nonzero"
        />
        <path d="M13.567 7.826h4.869v.756h-4.87zM13.567 9.511h3.77v.756h-3.77zM13.567 11.182h3.77v.755h-3.77z" />
      </g>
    </svg>
  );
}
