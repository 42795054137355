import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.168 15.866H8.05v4.273a.47.47 0 0 0 .477.47h2.278a.47.47 0 0 0 .47-.47v-4.273h.852l2.655 2.48c.348.324.806.505 1.282.505h4.015a.739.739 0 0 0 .505-1.283l-2.541-2.374V9.083a3.108 3.108 0 0 0-3.101-2.876H9.54V2.471a.47.47 0 0 0-.47-.47h-1.99a1.568 1.568 0 0 0-1.145 2.697c.306.296.719.455 1.145.44H8.6v1.07H6.3A3.303 3.303 0 0 0 3 9.507v5.19c0 .646.523 1.168 1.167 1.168zm6.168 3.803H8.99v-3.803h1.346v3.803zm5.727-1.758a.937.937 0 0 1-.639-.252l-1.919-1.793h3.883l2.19 2.045h-3.515zM17.1 9.15V14.927h-4.323V9.308a2.16 2.16 0 0 1 2.082-2.24A2.163 2.163 0 0 1 17.1 9.15zm-8.028.062a.613.613 0 1 1 0 1.225.613.613 0 0 1 0-1.225zm-1.99-5.013a.63.63 0 1 1 0-1.257H8.6v1.257h-1.52zM3.94 9.508a2.363 2.363 0 0 1 2.359-2.36H8.6v1.197a1.553 1.553 0 1 0 .94 0V7.147h3.184a3.089 3.089 0 0 0-.881 2.16v5.62H4.168a.23.23 0 0 1-.23-.23l.002-5.189z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}
