import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const SlideOutPanelWrapper = styled.div`
  height: calc(100vh - 62px);
  width: 400px;
  transform: translateX(
    ${(props) =>
      props.open ? '0px' : `${props.from === 'right' ? '400px' : '-400px'}`}
  );
  position: absolute;
  bottom: 0;
  ${(props) => (props.from === 'right' ? 'right: 0;' : '')};
  background-color: white;
  transition: transform 250ms ease-in-out;
  z-index: 10;
`;

/**
 * SlideOutPanel Component
 * @param {Record<string, any>} props
 * @param {(event: any) => void} props.onClickOutside - The handler for when the panel is clicked outside of
 * @param {'left' | 'right'} props.from - which side of the screen should the panel pop out from.
 * @param {boolean} props.open - Show or hide the panel
 * @returns {React.ReactNode} SlideOutPanel component
 * @description This component creates a nice slide out panel that can be used as a sidebar or context menu.
 * @example
 * function MyComponent () {
 *   const [open, setOpen] = useState(true);
 *   return (
 *      <SlideOutPanel open={open} onClickOutside={()  => setOpen(false)}>
 *        Im hidden in a slide out panel
 *      </SlideOutPanel>
 *   )
 * }
 */
export default function SlideOutPanel({ onClickOutside = () => {}, ...rest }) {
  const ref = useRef(null);
  useOnClickOutside(ref, () => onClickOutside());
  return (
    <SlideOutPanelWrapper ref={ref} {...rest} data-testid="slide-out-panel" />
  );
}

SlideOutPanel.propTypes = {
  onClickOutside: PropTypes.func,
  open: PropTypes.bool.isRequired,
  from: PropTypes.oneOf(['left', 'right']),
};
