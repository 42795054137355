import React from 'react';

const NavDisqual = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_disqual</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.503 18.188L2.215 4.518A1 1 0 0 1 3.07 3h18.06a1 1 0 0 1 .855 1.518l-8.288 13.67v2.01a1 1 0 0 1-1 1h-1.194a1 1 0 0 1-1-1v-2.01z"
        stroke={props.color}
        strokeWidth={1.5}
      />
      <path
        d="M14.859 7.515A.467.467 0 0 0 15 7.172a.468.468 0 0 0-.141-.344l-.687-.687A.467.467 0 0 0 13.828 6a.468.468 0 0 0-.343.141L12 7.626l-1.485-1.485A.467.467 0 0 0 10.172 6a.468.468 0 0 0-.344.141l-.687.687A.468.468 0 0 0 9 7.172c0 .134.047.249.141.343L10.626 9l-1.485 1.485a.468.468 0 0 0-.141.343c0 .135.047.25.141.344l.687.687a.468.468 0 0 0 .344.141c.134 0 .249-.047.343-.141L12 10.374l1.485 1.485a.467.467 0 0 0 .343.141c.135 0 .25-.047.344-.141l.687-.687a.467.467 0 0 0 .141-.344.467.467 0 0 0-.141-.343L13.374 9l1.485-1.485z"
        fill={props.color}
      />
    </g>
  </svg>
);

export default NavDisqual;
