import CheckboxList from './checkboxList';
import HorizontalWrapper from './horizontalWrapper';
import React from 'react';

const Horizontal_checkboxlist = props => {
  return (
    <HorizontalWrapper {...props} fieldType="horizontalCheckboxList">
      <CheckboxList {...props} />
    </HorizontalWrapper>
  );
};

export default Horizontal_checkboxlist;
