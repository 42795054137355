import React from 'react';
import { connect } from 'react-redux';
import { submit, isValid } from 'redux-form';

import Button from '../inputs/Button';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledConfirmModal = styled.div`
  text-align: center;
  width: 100%;

  > h3 {
    border-bottom: 1px solid #cccccc;
    font-size: 120%;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  > p {
    margin-bottom: 2rem;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
      width: 48%;
      margin: 0.5rem 0;
    }

    .thirdButton {
      width: 100%;
    }

    .secondButton {
      margin-left: auto;
    }
  }
`;

const defaultHeading = 'Are you sure?';
const defaultMessage = 'Click "Confirm" to confirm this change';
const defaultRemoteSubmitText = 'Save Changes';
const defaultConfirmMessage = 'Confirm';
const defaultCancelMessage = 'Cancel';

/*
   cancelAction: (function) the function called when the cancel button is clicked,
   cancelMessage: (string) the value on the cancel button,
   confirmAction: (function) the function called when the confirm button is clicked,
   confirmMessage: (string) the value on the confirm button,
   heading: (string) the title text for the modal,
   message: (string) the body text for the modal,
 */

export const ConfirmModal = ({
  heading = defaultHeading,
  message = defaultMessage,
  remoteFormKey,
  isRemoteFormValid,
  remoteSubmitText = defaultRemoteSubmitText,
  remoteSubmit,
  confirmAction,
  buttonType = 'primary',
  confirmMessage = defaultConfirmMessage,
  disabled,
  cancelAction,
  cancelMessage = defaultCancelMessage,
}) => {
  const displayRemoteSubmit = !!remoteFormKey && isRemoteFormValid;

  const handleRemoteSubmit = async () => {
    await remoteSubmit(remoteFormKey);
    confirmAction();
  };

  return (
    <StyledConfirmModal data-test="portal-confirm_modal">
      <h3>{heading}</h3>
      <p>{message}</p>
      <div className="buttonContainer">
        {displayRemoteSubmit && isRemoteFormValid && (
          <Button
            className="firstButton"
            data-test="portal-confirm_custom_action"
            onClick={handleRemoteSubmit}
            value={remoteSubmitText}
            type="primary"
          >
            {remoteSubmitText}
          </Button>
        )}
        <Button
          className={displayRemoteSubmit ? 'secondButton' : 'firstButton'}
          data-test="portal-confirm_action"
          onClick={confirmAction}
          type={buttonType}
          value={confirmMessage}
          promptConfirm={false}
          disabled={disabled}
        >
          {confirmMessage}
        </Button>
        <Button
          className={displayRemoteSubmit ? 'thirdButton' : 'secondButton'}
          data-test="portal-cancel_action"
          onClick={cancelAction}
          value={cancelMessage}
        >
          {cancelMessage}
        </Button>
      </div>
    </StyledConfirmModal>
  );
};

ConfirmModal.defaultProps = {
  cancelAction: () => {
    return;
  },
  confirmAction: () => {
    return;
  },
};

ConfirmModal.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  cancelMessage: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  confirmMessage: PropTypes.string,
  heading: PropTypes.string,
  message: PropTypes.string,
  buttonType: PropTypes.string,
};

export default connect(
  (state, props) => ({
    isRemoteFormValid: isValid(props.remoteFormKey)(state),
  }),
  {
    remoteSubmit: submit,
  },
)(ConfirmModal);
