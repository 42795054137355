import { AnyAction } from 'redux';
import types from '../constants';

export const defaultState = {
  job_ids: [],
};

export default (state = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.async_operations.add_job:
      return {
        job_ids: [...state.job_ids, payload],
      };
    case types.async_operations.remove_job:
      return {
        job_ids: [...state.job_ids].filter((id) => id !== payload),
      };
    default:
      return state;
  }
};
