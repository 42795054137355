import * as C from '../common';
import _ from 'lodash';

export default data => {
  const sections = [
    'ehr_settings',
    `contribution_tile`,
    `intro`,
    `find`,
    `connected`,
    `confirm`,
  ];
  const outgoingData = _.pick(data, sections);

  data = _.omit(data, sections);
  data.content = {
    ...data.content,
    ...outgoingData,
    description: !_.isUndefined(outgoingData.contribution_tile)
      ? outgoingData.contribution_tile.description
      : '',
    action_title: !_.isUndefined(outgoingData.contribution_tile)
      ? outgoingData.contribution_tile.button_label
      : '',
  };

  return C.common_outgoing(data);
};
