import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/SimpleModal';
import Button from '../../../../components/inputs/Button';
import CuriousPanda from '../../../../components/fields/CuriousPanda';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { graphQueries } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { validateRichTextLength, validateURL } from '../../forms/common';
import { useQuery, useQueryClient } from 'react-query';
import { queryStores } from '../../../../constants';
import { e2eTestId } from '../../../../utils/e2eTesting';

const text = {
  title: 'Are you sure you want to publish this study?',
  versionNameLabel: 'Version Name',
  getChangesLabel: ({ optional = true }) =>
    `Changes ${optional ? '(optional)' : ''}`,
  linkLabel: 'Link (optional)',
  getDescriptionLabel: ({ optional = true }) =>
    `Description ${optional ? '(optional)' : ''}`,
  cancelLabel: 'Cancel',
  publishLabel: 'Publish',
  errorFetchingVersions: 'Something went wrong fetching the versions.',
  errorFetchingICFReconsent:
    'Something went wrong fetching the ICF edited to reconsent.',
  ICFReconsent: 'List of ICF tiles that need reconsent:',
  notice: 'Notice: ',
  connectAppNotice:
    'There is a Connect App tile in this study, a connection to Flow will be established.',
};

const publishTestId = 'publish_study';

const testIds = {
  mainContainer: `${publishTestId}_main_container`,
  changesField: `${publishTestId}_changes_field`,
  descriptionField: `${publishTestId}_description_field`,
  buttonCancel: `${publishTestId}_button_cancel`,
  buttonPublish: `${publishTestId}_button_publish`,
};

const FORM_NAME = 'publishes';

const getSchema = ({
  version,
  setRequiredFields = false,
  possibleReconsentAnswers = [],
}) => [
  {
    input: [
      {
        label: text.versionNameLabel,
        name: 'version_name_label',
        interface: 'label',
      },
      {
        label: `${version}`,
        name: 'version',
        interface: 'paragraph',
      },
      {
        label: text.getChangesLabel({ optional: !setRequiredFields }),
        name: 'title',
        interface: 'rte',
        ComponentWrapper: StyledWrap,
        required: setRequiredFields,
        dataTestId: testIds.changesField,
      },
      {
        label: text.linkLabel,
        name: 'link',
        interface: 'textinput',
        ComponentWrapper: StyledWrap,
        validate: [validateURL],
      },
      {
        label: text.getDescriptionLabel({ optional: !setRequiredFields }),
        name: 'description',
        interface: 'rte',
        ComponentWrapper: StyledDescriptionWrap,
        validate: [validateRichTextLength(2000)],
        required: setRequiredFields,
        dataTestId: testIds.descriptionField,
      },
      possibleReconsentAnswers.length && {
        name: 'tiles_requiring_reconsent',
        interface: 'checkboxgroup',
        label: text.ICFReconsent,
        possible_answers: possibleReconsentAnswers,
        config: {
          displaySingleOptionLabel: true,
        },
        ComponentWrapper: StyledCheckboxWrap,
      },
    ],
  },
];

const fontBaseStyle = css`
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #444444;
`;

const inputBaseStyle = css`
  width: 100%;
  border-radius: 2px;
  border: 1px solid #676767;
  background-color: #ffffff;

  ${fontBaseStyle}
  mix-blend-mode: normal;
`;

const rteWrapStyles = css`
  ${inputBaseStyle}
  overflow-y: auto;
  padding: 8px;

  div.fr-box,
  div.fr-wrapper,
  div.fr-element {
    height: 100%;
  }
`;

const Container = styled.div`
  width: 850px;
  min-height: 276px;
  padding: 30px 50px;

  form[name='${FORM_NAME}'] {
    label {
      ${fontBaseStyle}
      font-weight: bold;
      color: #1e2834;
      mix-blend-mode: unset;

      margin: 8px 0;
    }

    span#version p {
      ${fontBaseStyle}
      mix-blend-mode: normal;

      margin: 8px 0;
    }
  }
`;

const Title = styled.h2`
  ${fontBaseStyle}
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #1e2834;

  margin-bottom: 16px;
`;

const StyledWrap = styled.div`
  &&& {
    div[class*='RichTextEditor'],
    input[type='text'] {
      ${rteWrapStyles}
      height: 38px;
    }
  }
`;

const StyledCheckboxWrap = styled.div`
  margin-top: 16px;
  .checkboxgroup div {
    margin: 0;
    label {
      margin: 0;
    }
  }
`;

const StyledDescriptionWrap = styled.div`
  &&& {
    div[class*='RichTextEditor'] {
      ${rteWrapStyles}
      height: 86px;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const ButtonContainer = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  height: 45px;
  width: 170px;
  text-transform: uppercase;

  color: #ffffff;
  border-radius: 2px;
  border: 1px solid #979797;
  background-color: #979797;

  ${({ publish }) => {
    if (publish) {
      return css`
        margin-left: 20px;
        border: 1px solid #2965ff;
        background-color: #0f5fbc;
      `;
    }
  }};
`;

const NoticeContainer = styled.div`
  border: 1px solid #ae8f13;
  border-radius: 4px;
  background-color: #fffae5;
  padding: 5px;
  margin-bottom: 5px;
`;

const PublishModal = ({
  onClose = () => {},
  onConfirm = () => {},
  graph: { id, status },
  tiles = {},
}) => {
  const {
    data: versions,
    error,
    isLoading,
  } = useQuery(
    [queryStores.studyVersions, id],
    () => graphQueries.getPublishes(id),
    {
      enabled: id ? true : false,
    },
  );
  const queryClient = useQueryClient();

  const {
    data: editedICF,
    error: errorLoadingEditedICF,
    isLoading: isLoadingEditedICF,
  } = useQuery(
    ['possibleReconsentAnswers', id],
    () => graphQueries.getEditedIcf(id),
    {
      enabled: id ? true : false,
      cacheTime: 0,
    },
  );

  const possibleReconsentAnswers = useMemo(() => {
    return (
      editedICF &&
      editedICF.reduce(
        (acc, { id, title }) => [...acc, { label: title, answer: id }],
        [],
      )
    );
  }, [editedICF]);

  const tileList = Object.values(tiles);
  const connectAppWarning = !!tileList.find(
    (tile) => tile.content.type === 'connect_app',
  );

  useEffect(() => {
    if (error) {
      toastr.error(text.errorFetchingVersions);
    }
    if (errorLoadingEditedICF) {
      toastr.error(text.errorFetchingICFReconsent);
    }
  }, [error, errorLoadingEditedICF]);

  const handleSubmit = ({
    title = '',
    link = '',
    description = '',
    tiles_requiring_reconsent,
  }) => {
    onConfirm({ title, link, description, tiles_requiring_reconsent }, () => {
      queryClient.invalidateQueries([queryStores.studyVersions, id]);
    });
  };

  const setRequiredFields = !['unpublished', 'draft', 'qa'].includes(
    status.toLowerCase(),
  );

  return (
    <Modal onClose={onClose} holderClassName="noMaxWidth" center>
      <Container {...e2eTestId(testIds.mainContainer)}>
        <Title>{text.title}</Title>
        {isLoading || isLoadingEditedICF ? (
          <LoadingContainer>
            <LoadingIndicator data-testid="loadingIndicator" />
          </LoadingContainer>
        ) : (
          <>
            {connectAppWarning && (
              <NoticeContainer>
                <p>
                  <b>{text.notice}</b> {text.connectAppNotice}
                </p>
              </NoticeContainer>
            )}
            <CuriousPanda
              form={FORM_NAME}
              schema={getSchema({
                version: (versions.length || 0) + 1,
                setRequiredFields,
                possibleReconsentAnswers,
              })}
              onSubmit={handleSubmit}
              onChange={() => {}}
              disabled={isLoading || isLoadingEditedICF}
            >
              {({ valid, submitting }) => {
                const disableSubmit = !valid || submitting;

                return (
                  <ButtonContainer>
                    <StyledButton
                      onClick={onClose}
                      {...e2eTestId(testIds.buttonCancel)}
                    >
                      {text.cancelLabel}
                    </StyledButton>
                    <StyledButton
                      type="primary"
                      disabled={disableSubmit}
                      publish
                      {...e2eTestId(testIds.buttonPublish)}
                    >
                      {text.publishLabel}
                    </StyledButton>
                  </ButtonContainer>
                );
              }}
            </CuriousPanda>
          </>
        )}
      </Container>
    </Modal>
  );
};

PublishModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PublishModal;
