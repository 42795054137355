import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: ({ alt_content = undefined }) => {
    return {
      Settings,
    };
  },
  incoming,
  outgoing,
};
