import Icon from '@evidation/icon';
import React from 'react';
import { default_experience_key } from '../../../../constants';
import { disqualification_criteria_items } from '../common';

const deleteIcon = <Icon name="close-action-icon" size="25px" />;

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          {
            label: `Disqualification Criteria`,
            interface: `settings_heading`,
            name: `disqualification_criteria_heading`,
          },
          {
            name: 'disqualification_criteria',
            addButtonLabel: 'Add disqualifying criteria',
            interface: 'array',
            deleteButtonIcon: deleteIcon,
            items: [
              {
                label: 'Disqualifying Attribute',
                interface: 'text_horizontal',
                name: 'attribute',
              },
              {
                name: `disqualification_criteria`,
                interface: `array`,
                addButtonLabel: `Add a strategy`,
                deleteButtonIcon: deleteIcon,
                items: disqualification_criteria_items(
                  'Add item to list',
                  deleteIcon,
                ),
              },
            ],
          },
        ],
      },
    ],
  ];
};
