import types, { omit_from_tile_data } from '../constants';

import _ from 'lodash';
import { AnyAction } from 'redux';
import { TileContent, Tiles } from '../redux/graph';

// this method returns ONLY modified tiles
export const modifyTilesWithData = (
  tiles: Tiles,
  selected_tiles: Array<string>,
  data: Partial<TileContent>,
  clear_other_values = true,
) =>
  _.reduce(
    tiles,
    (acc, tile, key) => {
      if (selected_tiles.includes(key)) {
        // checking to see if we want to clear all other
        // values other than what is contained in
        // `data` property. Otherwise, combine the two.
        let newData = clear_other_values ? data : { ...tile.content, ...data };

        return {
          ...acc,
          [key]: {
            ...tiles[key],
            content: _.omit(newData, omit_from_tile_data),
          },
        };
      } else {
        return acc;
      }
    },
    {},
  );

export const defaultState = {};

export default (state: Tiles = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.slate.toggleDisplayReminder:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          displayReminders: !state[payload].displayReminders ? true : false,
        },
      };

    case types.tiles.bulkEdit:
      return {
        ...state,
        ...modifyTilesWithData(
          state,
          payload.selected_tiles,
          payload.data,
          true,
        ),
      };

    case types.tiles.set:
      return {
        ...state,
        ...payload,
      };

    case types.tiles.update:
      return {
        ...state,
        // have to spread in previous selected state
        [payload.id]: {
          ...payload,
          selected: state[payload.id].selected,
          displayReminders: state[payload.id].displayReminders,
        },
      };

    case types.tiles.delete:
      return {
        ..._.omit(state, payload),
      };

    case types.tiles.clear:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
};
