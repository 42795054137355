import 'react-router-modal/css/react-router-modal.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-dd-menu/dist/react-dd-menu.css';
import './App.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import AppContainer from './components/AppContainer';
import AuthEntry from './components/AuthEntry';
import ErrorPage from './components/ErrorPage';
import { Provider } from 'react-redux';
import React from 'react';
import { render } from 'react-dom';
import store from './reducers';
import { ErrorBoundary } from '@appsignal/react';
import { appSignal } from '@evidation/logger';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReduxToastr from 'react-redux-toastr';

const queryClientDefaultConfiguration = {
  queries: {
    refetchOnMount: false,
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    ...queryClientDefaultConfiguration,
  },
});

render(
  <ErrorBoundary
    instance={appSignal}
    fallback={(error: Error) => (
      <ErrorPage
        error={error}
        appSignalInstance={appSignal}
        displayRedirectLink={true}
        details={null}
      />
    )}
  >
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {window.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
        <AuthEntry>
          <AppContainer />
        </AuthEntry>
        <ReduxToastr newestOnTop timeOut={5000} position="bottom-right" />
      </QueryClientProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById(`root`),
);
