import * as C from '../common';
import _ from 'lodash';

export const initVal = {
  contribution_tile: {
    title: `Connect Health Records`,
    description: `In this step you will find and connect health care provider records for the study. You'll learn more about how we are using this data. You will need your MyChart credentials to complete this step.`,
    button_label: `Connect Health Records`,
  },
  intro: {
    intro_headline: `Find and connect your healthcare providers`,
    intro_desc: `The [study name] study needs a connection to your health care provider to access certain data about your care history.`,
    intro_tip: `Your MyChart credentials for each health care provider you see.`,
    intro_button_label: `FIND YOUR PROVIDER`,
  },
  find: {
    find_title: `Find and Connect Your Provider`,
    find_desc: `Use the search field below to find your provider and connnect. Select "Connect" for the health care provider you inted to connect. You will need your credentials to authenticate. Once the process is complete you will be redirected back to this site.`,
    find_search: `Where have you received care?`,
  },
  connected: {
    connected_title: `Connected Health Records`,
    connected_desc: `Confirm you are finished adding health care providers or add more.`,
    connected_headline: `Great! You've connected to a Provider.`,
    connected_headline_desc: `If you have received care from additional providers, you can connect them by selecting "Add Another Provider" at the bottom of this page.`,
    connected_list_label: `Your Connected Health Providers`,
    connected_add_more: `ADD ANOTHER PROVIDER`,
    connected_done_label: `DONE`,
  },
  confirm: {
    confirm_title: `Are you sure you're done?`,
    confirm_body: `Once you submit these, they cannot be changed.`,
    confirm_back: `Make Changes`,
    confirm_submit: `Submit`,
  },
};

export default (data) => {
  const sections = [
    'contribution_tile',
    'intro',
    'find',
    'connected',
    'confirm',
  ];
  const incoming = _.pick(data.content, sections);
  _.each(sections, (section) => {
    if (_.isUndefined(incoming[section]) && section !== 'ehr_settings')
      incoming[section] = initVal[section];
  });

  data = {
    ...data,
    ...incoming,
  };

  return C.common_incoming(data);
};
