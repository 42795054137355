"use strict";

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.get-own-property-descriptor.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.get-own-property-descriptors.js");

require("core-js/modules/es.object.define-properties.js");

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalTranslate = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _i18next = _interopRequireDefault(require("i18next"));

var _LanguageContext = require("../contexts/LanguageContext");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @description Utility function to translate keys in non-react component function. (i.e.:
 * validation functions for form fields) This will set up the namespace needed for the
 * translations. Warning this requires that a global instance of i18next be initialized
 * otherwise only the key will be returned
 * @param {String} key - identifies the string to return for translation
 * @param {Object} options - i18next options for translations
 * @returns {String}
 */
var globalTranslate = function globalTranslate(key) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _i18next["default"].isInitialized ? _i18next["default"].t(key, _objectSpread({
    ns: _LanguageContext.curiousPandaNamespace
  }, options)) : key;
};

exports.globalTranslate = globalTranslate;