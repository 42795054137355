import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import Close from '../../assets/ActionIcons/Close';

export const ModalHeading = styled.div`
  background-color: #f4f4f4;
  color: #1e2834;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.94px;
  border-bottom: 1px solid #d2d2d2;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

export const ModalTitle = styled.h3`
  padding: 11px 25px;
`;

export const ModalHolder = styled.div`
  font-family: Avenir !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  form {
    &.__curious_panda {
      display: flex;
      flex-flow: column nowrap;

      .support_change {
        display: flex;
        flex-flow: column nowrap;

        #emit_event_instructions,
        #bulk_email_instructions {
          p {
            font-size: 14px;
            opacity: 0.5;
            margin-bottom: 6px;

            :last-child {
              margin-bottom: 15px;
            }
          }
        }
      }

      label {
        font-size: 14px;
        color: #395865;
      }

      input {
        margin-bottom: 15px;
      }

      input.datePicker {
        height: 45px;
        border: 1px solid #d6d6d6;
        border-radius: 2px;
        padding: 0px 10px;
        font-size: 14px;
        display: block;
        width: 100%;
        width: 160px;
        color: transparent;
        text-shadow: 0 0 0 #666666;
        cursor: pointer;
      }
    }
  }

  button {
    margin-top: 20px;
    padding: 15px 0;
    font-size: 14px;
  }
`;

export const Holder = styled.div`
  z-index: 15;
  background: #fafafa;
  border-radius: 6px;
  position: relative;
  max-width: 800px;
  margin: auto;
  &.noMaxWidth {
    max-width: none;
  }
`;

Holder.Close = styled.div`
  position: absolute;
  top: 13px;
  right: 18px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  padding: 15px 20px 20px;

  p {
    padding-bottom: 15px;
  }

  button {
    margin-top: 20px;
    padding: 15px 0;
    font-size: 14px;
  }
`;

/**
 *
 * @type {
 *   React.FC<{
 *    showX: boolean,
 *    onClose: () => void,
 *    modalTitle?: string,
 *    center?: any,
 *    holderClassName?: string,
 *    children: React.ReactNode
 *   }>
 * }
 * @returns {React.FC}
 */
const Modal = ({
  showX,
  onClose,
  modalTitle,
  center,
  holderClassName,
  children,
}) => {
  return (
    <Portal onClose={onClose} center={center}>
      {() => (
        <Holder className={holderClassName}>
          {showX && (
            <Holder.Close data-testid="closeModal" onClick={onClose}>
              <Close size="24" />
            </Holder.Close>
          )}
          {modalTitle && (
            <ModalHeading>
              <ModalTitle>{modalTitle}</ModalTitle>
            </ModalHeading>
          )}
          {children}
        </Holder>
      )}
    </Portal>
  );
};

Modal.defaultProps = {
  showX: false,
  onClose: () => {},
};

Modal.propTypes = {
  showX: PropTypes.bool,
  onClose: PropTypes.func,
  modalTitle: PropTypes.string,
};

export default Modal;
