import styled from 'styled-components';
import { color } from '@evidation/ui';
import { Label, LabelProps, Flex, FlexProps } from '@evidation/ui';

/**
 * Space the form out vertically.
 */
export const StyledForm = styled.form`
  > * + * {
    margin-bottom: 1rem;
  }
`;

const RadioButton = styled.div<{ wide: boolean }>`
  box-sizing: border-box;
  padding: ${(props) => (props.wide ? '8px 16px 8px 16px' : '8px')};
  min-width: ${(props) => (props.wide ? '125px' : '0px')};
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.wide ? 'flex' : 'inline-flex')};
`;

export const RadioLabel = styled(Label)`
  margin-left: ${(props: LabelProps & { pushLeft: boolean }) =>
    props.pushLeft ? '-75%' : '75px'};
  margin-bottom: -25;
  font-weight: 400;
`;

const RadioCircleOutter = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #555;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioCircleInner = styled.div`
  border-radius: 100%;
  height: 8px;
  width: 8px;
  background: ${color.status.informational};
`;

export const BranchRowContainer = styled(Flex)`
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  background: ${(props: FlexProps & { active: boolean }) =>
    props.active ? color.status.informational_subdued : color.background.white};
  border-radius: 4px;
`;

export const BranchRowOptionsContainer = styled(Flex)`
  padding-top: 1rem;
  flex: 1 1 auto;
  padding-bottom: 1.5rem;
`;

export const ErrorMessage = styled.p`
  color: ${color.text.error};
  font-size: 12px;
`;

export const Radio = ({ active = false, wide = false, ...rest }) => (
  <RadioButton wide={wide} {...rest}>
    <RadioCircleOutter>{active && <RadioCircleInner />}</RadioCircleOutter>
  </RadioButton>
);
