"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spacer = exports.StyledModal = exports.Heading = exports.TypingTestContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledReactModal = _interopRequireDefault(require("styled-react-modal"));

var _templateObject;

var TypingTestContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__TypingTestContainer"
})(["h3{font-size:", "rem;}p{margin-bottom:15px;}p:first-child{margin-top:15px;}"], 18 / 16);

exports.TypingTestContainer = TypingTestContainer;

var Heading = _styledComponents["default"].h3.withConfig({
  displayName: "components__Heading"
})(["margin-bottom:10px !important;"]);

exports.Heading = Heading;

var StyledModal = _styledReactModal["default"].styled(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: white;\n  opacity: ", ";\n  transition: all ease 200ms;\n  min-width: 420px;\n\n  @media (max-width: 599px) {\n    min-width: 100%;\n    width: 100%;\n  }\n\n  @media (min-width: 850px) {\n    min-width: 500px;\n  }\n"])), function (props) {
  return props.opacity;
});

exports.StyledModal = StyledModal;
StyledModal.Content = _styledComponents["default"].div.withConfig({
  displayName: "components__Content"
})(["padding:30px;text-align:center;font-size:1rem;"]);

var Spacer = _styledComponents["default"].div.withConfig({
  displayName: "components__Spacer"
})(["height:10px;"]);

exports.Spacer = Spacer;