import { DashboardContainer } from '../../edit/components';
import styled from 'styled-components';

export const Container = styled(DashboardContainer)`
  background: #fafafa;
`;

const grayBorder = `#D2D2D2`;

export const Checkbox = styled.span`
  &:before {
    display: inline-block;
    content: '✔';
    margin-right: 5px;
    color: green;
  }
`;

export const EmptyRewards = styled.div`
  padding: 22px 22px 0;
  background: transparent;
  text-align: center;
  border: none;
  color: #000;
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
`;

EmptyRewards.Button = styled.button`
  border: 2px solid #326bfe;
  border-radius: 2px;
  color: #326bfe;
  background: #fff;
  padding: 10px 15px;
  margin-top: 20px;
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

const HeadingStyle = `
  position: absolute;
  left: 22px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.75px;
  color: #1e2834;
  font-family: Avenir, 'Sans-serif';
`;

export const Preview = styled.div`
  background: #fafafa;
  grid-area: content;
  overflow: scroll;
  position: relative;
  padding: 20px;
`;

Preview.Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 124px);

  font-weight: 500;
  font-size: 24px;
  text-align: center;
  line-height: 33px;
`;

export const Configure = styled.div`
  background: #fff;
  grid-area: settings;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  display: grid;
  height: 100%;
  grid-template-rows: 38vh auto;
  grid-template-columns: auto;
  grid-template-areas: 'list' 'configure';

  > div {
    border-left: 1px solid ${grayBorder};
  }
`;

Configure.Heading = styled.div`
  z-index: 10;
  padding: 18px 15px;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  background: #f4f4f4;
  border-bottom: 1px solid ${grayBorder};

  h3 {
    ${HeadingStyle};
  }
`;

Configure.SubHeading = styled(Configure.Heading)`
  z-index: 9;
  select {
    width: 50%;
  }
`;

Configure.List = styled.div`
  grid-area: list;
  overflow: scroll;
  border-bottom: 1px solid ${grayBorder};
`;

Configure.Content = styled.div`
  grid-area: configure;
  overflow: scroll;
  padding: 22px 22px 80px 22px;
  height: 90%;
`;

export const Add = styled.div`
  padding: 5px;
`;

export const Reward = styled.li`
  justify-content: flex-end;
  display: flex;
  position: relative;
  background: ${(props) =>
    props.active || props.isDragging ? `#E0FFF5` : `#FFF`};

  color: #1e2834;
  font-size: 14px;
  font-weight: 500;
  font-family: Avenir, 'Sans-serif';
  letter-spacing: -0.66px;
  line-height: 19px;
  padding: 12px 22px;
  border-color: #d2d2d2;
  border-style: solid;
  border-width: ${(props) =>
    props.isDragging ? `1px 1px 1px 1px` : `0 0 1px 0`};
  border-radius: ${(props) => (props.isDragging ? `5px` : `0`)};

  .rewardName {
    position: absolute;
    left: 22px;
    color: #111;
    text-decoration: none;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin-right: 20px;

    &:last-child {
      color: #b2b2b2;
      margin-right: 0;
      font-size: 19px;

      &:hover {
        color: #000;
      }
    }
  }

  button {
    padding: 2px;
    background: transparent;
    border: 0;

    &:focus {
      border: 0;
    }
  }
`;

const BigInput = `
  margin-left: 2px;
  margin-right: 2px;
  font-size: 16px;
  padding: 12px 3px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  min-width: 50px;
  width: 70px;
  text-align: center;
`;

export const Select = styled.select`
  ${BigInput};
  border-radius: 0;
  height: 44px;
  width: auto;
`;

export const Panel = {};

Panel.Container = styled.div`
  margin: auto;
  list-style: none;
  max-width: 700px;
  min-width: 500px;
  width: auto;
`;

Panel.Copy = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

Panel.Copy.defaultProps = {
  'data-test': `panelCopy`,
};

Panel.OlList = styled.ol`
  padding: 5px 7px 10px;
  border-radius: 4px;
  background-color: #eaeaea;
  margin: 20px 0 15px;
  list-style-type: none;
`;

Panel.OlList.defaultProps = {
  'data-test': `panelOlList`,
};

Panel.ListOfWords = styled.ol`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style-type: none;

  li {
    margin: 0 5.5px 8px 0;

    &:last {
      margin-right: 0;
    }

    input {
      ${BigInput};
    }
  }
`;

Panel.ListOfWords.defaultProps = {
  'data-test': `panelListOfWords`,
};

Panel.ListOfBuckets = styled.ol`
  > li {
    display: flex;
    padding: 15px 0 5px 0;
    border-bottom: 1px solid #d2d2d2;

    &:last-child {
      padding-top: 20px;
      padding-bottom: 0;
      border-bottom: none;

      span {
        margin-right: 20px;
      }

      button {
        width: 120px;
      }
    }

    input {
      ${BigInput};
    }
  }
`;

Panel.ListOfBuckets.defaultProps = {
  'data-test': 'ListOfBuckets',
};

Panel.ListOfBuckets.defaultProps = {
  'data-test': `panelListOfBuckets`,
};

export const RewardList = styled.li`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 5px 5px 5px 8px;
  width: 100%;

  button {
    margin-top: 2px;
    padding: 3px;
  }
`;

RewardList.defaultProps = {
  'data-test': 'RewardList',
};

RewardList.Name = styled.span``;

RewardList.Name.defaultProps = {
  'data-test': 'RewardName',
};

RewardList.Delete = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
`;

RewardList.Delete.defaultProps = {
  'data-test': 'RewardListDelete',
};

export const AddInput = styled.input`
  margin-top: 5px;
  background: #fff;
  padding: 5px 5px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  width: 100%;
  font-size: 20px;
  line-height: 1em;
  color: #326bfe;
`;

AddInput.defaultProps = {
  'data-test': 'AddInput',
};

AddInput.Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

AddInput.Button.defaultProps = {
  'data-test': 'AddInputButton',
};

AddInput.Container = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    width: 100%;
  }

  button {
    line-height: 45px;
    border: 0;
    margin: 0 5px 0 10px;
    padding: 0;
    background: transparent;
    flex-grow: 1;
    text-align: center;
  }
`;
