import { bool, string, oneOfType, func } from 'prop-types';

import ConfirmModal from './modal/ConfirmModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import PortalModal from './PortalModal';
import React from 'react';

const CustomNavigationPrompt = ({ when, clearEditing = () => {}, ...rest }) => (
  <NavigationPrompt when={when}>
    {({ onConfirm, onCancel }) => (
      <PortalModal onClose={() => onCancel()}>
        <ConfirmModal
          cancelAction={onCancel}
          confirmAction={() => {
            clearEditing();
            onConfirm();
          }}
          {...rest}
        />
      </PortalModal>
    )}
  </NavigationPrompt>
);

CustomNavigationPrompt.defaultProps = {
  message: `Are you sure you want to exit?`,
  heading: `You have unsaved changes`,
  when: false,
  buttonType: `destructive`,
};

CustomNavigationPrompt.propTypes = {
  buttonType: string,
  message: string,
  heading: string,
  when: oneOfType([func, bool]).isRequired,
};

export default CustomNavigationPrompt;
