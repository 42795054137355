import { AddReminderInner, AddReminderOuter } from './components';
import React, { Component } from 'react';
import { func, string } from 'prop-types';

import Add from '../addTileUi/AddTileUi';
import OnAddHOC from '../../hoc/onAddHOC';
import { ReminderNodes, AuthActions } from '../../../../constants';
import { AuthWrap } from '../../../../components/auth';

/**
 * The base tile type for reminders. Currently, a reminder consists of a
 *   Communications tile and a Delay tile, with the former being used as the
 *   "primary" tile for the purposes of ID references. This will be changed when
 *   we implement a real Reminder tile.
 */
const TILE_TYPE = 'communications';

class AddReminder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      addMode: false,
      title: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { title } = this.state;
    const { sequence_uuid = undefined, uuid = undefined, onAdd } = this.props;

    if ((title && sequence_uuid) || uuid) {
      const payload = {
        resource: 'reminder',
        content: {
          title,
          type: TILE_TYPE,
        },
      };

      onAdd(payload, sequence_uuid, uuid).then(() => {
        this.setState({ addMode: true, title });
      });
    }
  }

  render() {
    const { addMode } = this.state;

    return addMode ? (
      <Add
        {...this.props}
        {...this.state}
        type={TILE_TYPE}
        allowChangeType={false}
        tileType={ReminderNodes}
        onSubmit={this.onSubmit}
        onChangeField={(field) => this.setState(field)}
        cancel={() => this.setState({ addMode: false })}
      />
    ) : (
      <AuthWrap
        requiredActions={[AuthActions.tiles.create, AuthActions.graphs.publish]}
      >
        <AddReminderOuter
          data-test="addReminder"
          onClick={() => this.setState({ addMode: true })}
        >
          <AddReminderInner>Add a reminder</AddReminderInner>
        </AddReminderOuter>
      </AuthWrap>
    );
  }
}

AddReminder.propTypes = {
  sequence_uuid: string,
  uuid: string,
  onAdd: func.isRequired,
};

export default OnAddHOC(AddReminder);
