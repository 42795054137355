import styled, { css } from 'styled-components';
import { GLOBAL_HEADER_HEIGHT } from '../../../constants';
import { leftColumn } from '../../../routes/edit/components';
import { isMobile } from 'react-device-detect';

const large_column = 600;

const getSettingsColumnWidth = ({ pathname }) => {
  if (pathname?.includes('permissions')) {
    return `400px`;
  }

  return `${large_column}px`;
};

export const topNavGrid = css`
  grid-template-rows: ${GLOBAL_HEADER_HEIGHT} 66px auto;
  grid-template-columns: ${leftColumn} auto ${getSettingsColumnWidth};
  grid-template-areas:
    'header header header'
    'sidebar application-subnav application-subnav'
    'sidebar content settings';
`;

export const noTopNavGrid = css`
  grid-template-rows: ${GLOBAL_HEADER_HEIGHT} auto;
  grid-template-columns: ${leftColumn} auto ${getSettingsColumnWidth};
  grid-template-areas:
    'header header header'
    'sidebar content settings';
`;

export const configureGrid = css`
  grid-template-rows: ${GLOBAL_HEADER_HEIGHT} auto;
  grid-template-columns: 3fr 8px minmax(0, auto) 15px;
  grid-template-areas:
    'header header header header'
    'content resizer settings settings';
`;

export const SlideOutPanelButtonWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
`;

/**
 * @type {React.FC<React.HTMLProps<HTMLDivElement>> & {Content: React.FC<React.HTMLProps<HTMLDivElement>>}}
 */
export const Application = styled.div`
  z-index: 3;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: grid;

  /* MAIN APPLICATION GRID */
  ${(p) => {
    if (p.isConfigure) return configureGrid;
    if (!p.hasTopNav) return noTopNavGrid;
    else return topNavGrid;
  }}
`;
/**
 * @returns {any}
 */
Application.Content = styled.div`
  background-color: #fafafa;
  position: relative;
  grid-row-start: content;
  grid-row-end: content;
  grid-column-start: content;
  grid-column-end: settings;
  overflow: scroll;
  ${() => (isMobile ? 'height: calc(100vh - 165px)' : '')}
`;
