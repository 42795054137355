import _ from 'lodash';
import { AnyAction } from 'redux';
import types from '../constants';

export const defaultState = {};

export default (state = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.publish_errors.add:
      return {
        // dont currently have the ability to clear these so we're just overwriting
        // ...state,
        ...payload,
      };

    case types.publish_errors.remove:
      let newState = { ...state };
      _.unset(newState, payload);
      return newState;

    case types.publish_errors.clear:
      return defaultState;

    default:
      return state;
  }
};
