import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 3)" fill="none" fillRule="evenodd">
        <path
          fill="#000"
          d="M1 8h10.645v.833H1zM1 14.333h10.645v.834H1zM.833 11.333H17.02v.834H.833z"
        />
        <rect
          stroke="#000"
          fill="none"
          x=".5"
          y=".5"
          width="18"
          height="4.833"
          rx="2"
        />
      </g>
    </svg>
  );
}
