import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillOpacity="0" fillRule="nonzero" stroke="#000">
          <path d="M10.91 18.312L6.945 12.5h7.926l-3.963 5.812zM10.91 2.888L6.945 8.7h7.926L10.91 2.888z" />
        </g>
        <path
          d="M19.566 4.37l-1.554.084 1.204.952c.462.364.602.91.14 1.232-.462.322-.91.042-1.12-.518l-.518-1.386L17.2 6.12c-.21.56-.658.84-1.12.518-.462-.322-.322-.868.14-1.232l1.204-.952-1.554-.084c-.602-.028-1.008-.378-.826-.924.154-.476.7-.546 1.19-.224l1.288.84-.406-1.498c-.154-.56.028-1.064.602-1.064s.756.504.602 1.064l-.406 1.498 1.288-.84c.49-.322 1.036-.252 1.19.224.182.546-.224.896-.826.924z"
          fill="#000"
        />
      </g>
    </svg>
  );
}
