import _ from 'lodash';
import objectifyResourceArray from '../utils/objectifyResourceArray';
import types from '../constants';
import { AnyAction } from 'redux';
import { Slate } from '../redux/graph';

export const defaultState = {
  displayReminders: [],
  filter_arms: [],
  filter_types: [],
  types: [],
  resource_order: [],
  reminder_mapping: {},
  selected_resources: {},
  slate_coordinates: { x: 0, y: 0 },
  sidebar_width: '',
};

export const typesCollectMethod = (types: any) =>
  _.uniq(
    _.reduce(
      types,
      (acc: any, type: any) =>
        type.content && type.content.type ? [...acc, type.content.type] : acc,
      [],
    ),
  );

export default (state: Slate = defaultState, { payload, type }: AnyAction) => {
  switch (type) {
    case types.slate.setSlateCoordinates:
      return {
        ...state,
        slate_coordinates: payload,
      };
    case types.slate.resetSlateCoordinates:
      return {
        ...state,
        slate_coordinates: defaultState.slate_coordinates,
      };
    case types.slate.select:
      const selected_resources = _.reduce(
        payload,
        (selected_resources, v, resourceId) =>
          selected_resources[resourceId]
            ? _.omit(selected_resources, resourceId)
            : { ...selected_resources, [resourceId]: v },
        { ...state.selected_resources },
      );

      return {
        ...state,
        selected_resources,
      };

    case types.slate.selectClearAll:
      return {
        ...state,
        selected_resources: {},
      };

    case types.slate.selectClearType:
      const selected_resources_array = _.reject(state.selected_resources, {
        resource: payload,
      });

      return {
        ...state,
        selected_resources: objectifyResourceArray(selected_resources_array),
      };

    case types.slate.getResourceOrder:
      return {
        ...state,
        resource_order: payload,
      };

    case types.slate.clearResourceOrder:
      return {
        ...state,
        resource_order: [],
      };

    case types.slate.typesCollect:
      return {
        ...state,
        types: _.uniq(typesCollectMethod(payload)),
      };

    case types.slate.armFilter:
      return {
        ...state,
        filter_arms: _.isBoolean(payload)
          ? defaultState.filter_arms
          : _.uniq(_.xor(state.filter_arms, [payload])),
      };

    case types.slate.typeFilter:
      return {
        ...state,
        filter_types: _.isBoolean(payload)
          ? defaultState.filter_types
          : _.uniq(_.xor(state.filter_types, [payload])),
      };

    case types.slate.slateConfiguration:
      return {
        ...state,
        ...payload,
      };
    case types.slate.setSlateSidebarWidth:
      return {
        ...state,
        sidebar_width: payload,
      };

    default:
      return state;
  }
};
