import PropTypes from 'prop-types';
import React from 'react';
import Switch from 'react-toggle-switch';
import styled from 'styled-components';

const P = styled.p`
  font-size: 10px;
`;

const ToggleContainer = styled.div`
  text-align: center;
`;

const SingleToggle = ({ label, children, style, ...etc }) => (
  <ToggleContainer style={style}>
    {label !== `` && (
      <P>
        {label} is {etc.on ? `on` : `off`}
      </P>
    )}
    <Switch {...etc}>{children}</Switch>
  </ToggleContainer>
);

SingleToggle.defaultProps = { style: {}, label: `Test Data` };

SingleToggle.propTypes = {
  label: PropTypes.string,
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SingleToggle;
