import React from 'react';

const NavTimeline = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_timeline</title>
    <g fill={props.color} fillRule="evenodd">
      <path d="M6.531 14.09H3.273V3.274h3.202L6.53 14.09h1.217V2.636a.654.654 0 0 0-.187-.451.657.657 0 0 0-.45-.185H2.636a.656.656 0 0 0-.45.185.654.654 0 0 0-.186.451v12.091c0 .166.068.331.186.452a.656.656 0 0 0 .45.185h4.475c.168 0 .331-.07.45-.185a.654.654 0 0 0 .187-.452v-.637H6.53zM20.531 11.09h-3.258V3.274h3.202l.056 7.817h1.217V2.636a.654.654 0 0 0-.187-.451.657.657 0 0 0-.45-.185h-4.475a.656.656 0 0 0-.45.185.654.654 0 0 0-.186.451v9.091c0 .166.068.331.186.452a.656.656 0 0 0 .45.185h4.475c.168 0 .331-.07.45-.185a.654.654 0 0 0 .187-.452v-.637H20.53zM13.591 20.09h-3.258V3.274h3.202l.056 16.817h1.216V2.636a.654.654 0 0 0-.186-.451.657.657 0 0 0-.45-.185H9.696a.656.656 0 0 0-.45.185.654.654 0 0 0-.186.451v18.091c0 .166.068.331.186.452a.656.656 0 0 0 .45.185h4.475c.167 0 .331-.07.45-.185a.654.654 0 0 0 .186-.452v-.637h-1.216z" />
    </g>
  </svg>
);

export default NavTimeline;
