import HorizontalWrapper from './horizontalWrapper';
import React from 'react';
import TextArea from 'react-autosize-textarea';

const HorizontalTextarea = ({ input, disabled, placeholder, ...rest }) => (
  <HorizontalWrapper {...rest} fieldType="horizontalTextarea">
    <TextArea disabled={disabled} placeholder={placeholder} {...input} />
  </HorizontalWrapper>
);

HorizontalTextarea.defaultProps = {
  disabled: false,
  placeholder: ``,
};

export default HorizontalTextarea;
