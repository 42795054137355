import { Label, WebhookCard as Wrapper } from './components';
import { Webhook } from '../../api/webhooks';

export const WebhookCard: React.FC<{
  webhook: Webhook;
  editWebhook: (webhook: Webhook) => void;
  removeWebhook: (webhook: Webhook) => void;
}> = ({ webhook, editWebhook, removeWebhook }) => {
  return (
    <Wrapper>
      <div className="context-menu">
        <div className="spacer"></div>
        <button
          className="btn-transparent"
          onClick={() => editWebhook(webhook)}
        >
          Edit
        </button>
        <button
          className="btn-transparent remove-webhook"
          onClick={() => removeWebhook(webhook)}
        >
          Remove
        </button>
      </div>
      <div className="heading">
        <div className="title">{webhook.name}</div>
        <div className="spacer"></div>
        <Label>{webhook.target_http_method}</Label>
      </div>
      <div className="content">
        <div className="text-label">URL</div>
        <div className="mono segment">{webhook.target_url}</div>
        <div className="text-label">Event Trigger</div>
        <div className="mono segment">{webhook.webhook_event_trigger}</div>
      </div>
    </Wrapper>
  );
};
