import _ from 'lodash';
import * as C from '../common';
import { alphaNumericUnderscore } from '../stripSpecialCharacters';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

const getExperienceName = (experience, masonryEnabled = false) => {
  return masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;
};

export const validateKey = (value) =>
  _.includes(['c_id', 'e_id', 'custom_id', 'm', 'o', 'i'], value)
    ? 'Please use a "Key" that is not reserved'
    : undefined;

export const validateKeyNames =
  (experience, masonryEnabled) => (value, allValues) => {
    // Note for this validation. It only works as long as the structure of
    // the form doesn't change. If it does the content.experiences.${experience}.custom_query_params
    // will have to be updated.

    const experienceName = getExperienceName(experience, masonryEnabled);

    if (value && _.has(allValues, `${experienceName}.custom_query_params`)) {
      const keys = _.map(
        _.get(allValues, `${experienceName}.custom_query_params`),
        (value) => {
          return value.key;
        },
      );

      return keys.filter((key) => key === value).length > 1
        ? `Key name's must be unique`
        : undefined;
    }

    return undefined;
  };

export const QualtricsSurveyTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.display_group_name(displayGroupNames),
      C.experience_attribute,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const masonryEnabled =
    dataForTile?.qualtrics_survey?.masonryEnabled;

  const compareKeys = validateKeyNames(experience, masonryEnabled);

  // external_title is an experience attribute for both legacy and new, but for legacy we want to
  // also show title here
  const external_title = masonryEnabled ? {} : C.external_title;

  const experienceInputs = [
    C.experienceExternalTitle(masonryEnabled),
    external_title,
    C.description,
    C.completion_message,
    C.action_title,
    C.survey_url,
    C.tooltip,
    {
      name: 'custom_query_params',
      interface: 'array',
      title: 'Custom Query Parameters',
      limitLength: 5,
      items: [
        {
          name: 'key',
          label: 'Key',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          validate: [validateKey, compareKeys],
          maxLength: 5,
          parse: (value) => alphaNumericUnderscore(value),
        },
        {
          name: 'value',
          label: 'Value',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          maxLength: 5,
          parse: (value) => alphaNumericUnderscore(value),
        },
      ],
    },
    ...C.completion_event,
  ];

  const experienceName = getExperienceName(experience, masonryEnabled);

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: experienceInputs,
    },
  ];

  if (!masonryEnabled) {
    schema.push(QualtricsSurveyTileSettings(experience, displayGroupNames));
  }

  return [schema];
};
