import { logError } from '@evidation/logger';
import { Button, Flex, Select, Spacer, Toggle } from '@evidation/ui';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

import { loadGraph } from 'src/actions/combination';
import { apiGraph } from 'src/api';
import type { Form, Graph, RootState } from 'src/redux/graph';

import { CommonProps, IntegrationRef } from '../common';
import {
  componentString,
  ComponentString,
  getCastorEnabledComponentsForTile,
  graphWithCastorIntegration,
  groupComponentsByTileId,
  isGraphEnabled,
  tileWithCastor,
  tileWithoutCastor,
} from './utils';

const MAX_WIDTH = 600;

const text = {
  enable: 'Enable',
  castorTileNotice:
    'If the build does not have Castor EDC tile, please add the Castor EDC tile to the study build to register the participant.',
  screenerDataSentToCastor: 'Screener Data Sent to Castor',
  belowScreenerDataSentToCastor:
    'The below screener data points are configured to be sent to Castor.',
  add: 'Add',
  successMessage: 'Saved integration settings',
  errorMessage: 'Failed to save integration settings',
  selectAComponent: 'Select a Component',
};

const Heading = styled.h3`
  font-weight: normal;
  font-size: 18px;
  border-bottom: 1px solid #d2d2d2;
`;

const ComponentList = styled.ul`
  width: ${MAX_WIDTH}px;

  li {
    .component {
      border: 1px solid #666;
      border-radius: 3px;
      &.unsaved {
        background: #eeffee;
      }

      .label {
        margin-right: 10px;
        padding: 6px;
      }

      button {
        cursor: pointer;
        background: #cccccc;
        border: none;
        border-radius: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

// These come from the Redux state.
export type CastorProps = {
  updateTile?: typeof apiGraph.update_tile;
  updateGraph?: (graph: Graph) => ReturnType<typeof apiGraph.update>;
} & ConnectedProps<typeof connector>;

type ComponentChanges = {
  add: Set<ComponentString>;
  remove: Set<ComponentString>;
};

const apiUpdateGraph = (graph: Graph) =>
  // Need the `as any` because `outgoing` doesn't take a true Graph object for
  //   some reason.
  apiGraph.update(graph.id, graph);

const Castor: React.ForwardRefRenderFunction<
  IntegrationRef,
  CommonProps & CastorProps
> = (
  {
    graph,
    loadGraph,
    tiles,
    sequences,
    onChange,
    updateTile = apiGraph.update_tile,
    updateGraph = apiUpdateGraph,
  },
  ref,
) => {
  const [enabled, setEnabled] = useState(
    !!graph.content.supported_features?.castor_data_pushes,
  );
  const hasEnabledChanged = enabled !== isGraphEnabled(graph);

  const screeners = useMemo(
    () => tiles.filter((tile) => tile.content.type === 'screener'),
    [tiles],
  );
  const componentsByTileId = screeners.reduce<{
    [tileId: string]: Form[];
  }>((componentsByTile, tile) => {
    const components = tile.content.experiences?.default?.form;
    if (components) {
      componentsByTile[tile.id] = components;
    }
    return componentsByTile;
  }, {});

  // Keep track of which components we've added or removed since last saving.
  const [componentChanges, setComponentChanges] = useState<ComponentChanges>({
    add: new Set(),
    remove: new Set(),
  });

  const enabledComponents = useMemo(
    () =>
      screeners
        // Figure out which components were already enabled.
        .reduce<ComponentString[]>(
          (addedComponents, screener) => [
            ...addedComponents,
            ...getCastorEnabledComponentsForTile(screener),
          ],
          [],
        )
        // Adjust the enabled components according to what we've changed so far.
        .filter((component) => !componentChanges.remove.has(component))
        .concat(Array.from(componentChanges.add)),
    [componentChanges, screeners],
  );

  // Remove already selected components from the dropdown list.
  const componentOptions = screeners
    .map((tile) => ({
      label: tile.content.experiences?.default?.title || `Tile ${tile.id}`,
      subOptions: componentsByTileId[tile.id]
        ?.map((component) => ({
          label: component.name,
          value: componentString(tile.id, component.name),
        }))
        .filter((option) => !enabledComponents.includes(option.value)),
    }))
    .filter((optionGroup) => optionGroup.subOptions.length > 0);

  const [selectedComponent, setSelectedComponent] =
    useState<ComponentString | null>(null);

  const addSelectedComponent = () => {
    if (selectedComponent) {
      setComponentChanges((componentChanges) => {
        if (componentChanges.remove.has(selectedComponent)) {
          const remove = new Set(componentChanges.remove);
          remove.delete(selectedComponent);
          return {
            ...componentChanges,
            remove,
          };
        }
        return {
          ...componentChanges,
          add: new Set(componentChanges.add).add(selectedComponent),
        };
      });
    }
    setSelectedComponent(null);
  };
  const removeComponent = (component: ComponentString) => {
    setComponentChanges((componentChanges) => {
      if (componentChanges.add.has(component)) {
        const add = new Set(componentChanges.add);
        add.delete(component);
        return {
          ...componentChanges,
          add,
        };
      }
      return {
        ...componentChanges,
        remove: new Set(componentChanges.remove).add(component),
      };
    });
  };

  // Expose the 'submit' functionality to the parent.
  useImperativeHandle(
    ref,
    () => ({
      submit: async () => {
        try {
          const graphId = graph.id;

          const addedComponentsByTileId = enabled
            ? groupComponentsByTileId(componentChanges.add)
            : {};
          const removedComponentsByTileId = enabled
            ? groupComponentsByTileId(componentChanges.remove)
            : // When disabling a previously enabled integration, clear out all of
              //   the enabled tiles.
              groupComponentsByTileId(enabledComponents);
          const tileIdsToChange = new Set([
            ...Object.keys(addedComponentsByTileId),
            ...Object.keys(removedComponentsByTileId),
          ]);
          for (const tileId of tileIdsToChange) {
            let tile = screeners.find((screener) => screener.id === tileId)!;
            if (addedComponentsByTileId[tileId]) {
              tile = tileWithCastor(tile, addedComponentsByTileId[tileId]);
            }
            if (removedComponentsByTileId[tileId]) {
              tile = tileWithoutCastor(tile, removedComponentsByTileId[tileId]);
            }
            const sequenceId = sequences.find((sequence) =>
              sequence.tile_ids.includes(tileId),
            )!.id;
            await updateTile(graphId, sequenceId, tileId, tile);
          }

          if (hasEnabledChanged) {
            await updateGraph(graphWithCastorIntegration(graph, enabled));
          }
          await loadGraph(graphId);
          toastr.success(text.successMessage, '');
        } catch (error) {
          toastr.error(text.errorMessage, '');
          logError(error);
        }
      },
    }),
    [
      componentChanges.add,
      componentChanges.remove,
      enabled,
      enabledComponents,
      graph,
      hasEnabledChanged,
      loadGraph,
      screeners,
      sequences,
      updateGraph,
      updateTile,
    ],
  );

  const hasChanged =
    componentChanges.add.size > 0 ||
    componentChanges.remove.size > 0 ||
    hasEnabledChanged;
  useEffect(() => {
    onChange({ hasChanged });
  }, [hasChanged, onChange]);

  return (
    <>
      <p>{text.castorTileNotice}</p>
      <Spacer size={10} />
      <Toggle label={text.enable} checked={enabled} onChange={setEnabled} />
      {enabled && (
        <>
          <Spacer size={30} />
          <Heading>{text.screenerDataSentToCastor}</Heading>
          <Spacer size={10} />
          <p>{text.belowScreenerDataSentToCastor}</p>
          {enabledComponents.length > 0 ? (
            <>
              <Spacer size={10} />
              <ComponentList>
                {enabledComponents.map((componentString) => {
                  const [tileId, componentName] = componentString.split(':');
                  const tile = screeners.find((tile) => tile.id === tileId);
                  const label = `${
                    tile?.content.experiences?.default.title ||
                    `Tile ID ${tileId}`
                  } - ${componentName}`;
                  return (
                    <li key={label}>
                      <Flex
                        items="stretch"
                        justify="space-between"
                        className={
                          'component ' +
                          (componentChanges.add.has(componentString)
                            ? 'unsaved'
                            : '')
                        }
                      >
                        <span className="label">{label} </span>
                        <Button
                          onClick={() => removeComponent(componentString)}
                        >
                          X
                        </Button>
                      </Flex>
                    </li>
                  );
                })}
              </ComponentList>
            </>
          ) : null}
          <Spacer size={20} />
          <Flex items="stretch">
            <Select
              options={componentOptions}
              value={selectedComponent ?? undefined}
              placeholder={text.selectAComponent}
              onChange={(option) => {
                setSelectedComponent(option.value);
              }}
              style={{ flex: '1 1 auto', maxWidth: MAX_WIDTH }}
            />
            <Spacer size={10} direction="horizontal" />
            <Button
              disabled={!selectedComponent}
              onClick={addSelectedComponent}
              style={{ flex: '0 0 auto' }}
            >
              {text.add}
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};
const CastorForwarded = React.forwardRef(Castor);
export { CastorForwarded as Castor };
const connector = connect(
  ({ graph, tiles, sequences }: RootState) => ({
    graph,
    tiles: Object.values(tiles),
    sequences: Object.values(sequences),
  }),
  // The `as any` cast is a hack for dealing with async action types.
  { loadGraph: loadGraph as any },
  null,
  { forwardRef: true },
);
export default connector(CastorForwarded);
