import React, { PureComponent } from 'react';
import { DashboardContainer } from '.';
import _ from 'lodash';
import { objectToQueryString } from '@evidation/utils';
import { getSearchParamsFromUrl } from '../../../utils/urlHelpers';
import { connect } from 'react-redux';
import { setSlateCoordinates } from '../../../actions/slate_actions';
import { withRouter } from 'react-router-dom';

class DashboardScrollContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.DashboardContainerRef = React.createRef();
    this.debouncedScrollEventListener = _.debounce(
      this.scrollEventListener,
      420,
      {
        leading: false,
        trailing: true,
      },
    );
  }

  componentDidMount() {
    const dashboardContainer = this.DashboardContainerRef.current;
    const urlParams = getSearchParamsFromUrl();
    const slateCoordinates = this.props.slate_coordinates;

    if (dashboardContainer) {
      if (urlParams.x && urlParams.y) {
        dashboardContainer.scrollTo(urlParams.x, urlParams.y);
      } else if (!_.isEmpty(slateCoordinates)) {
        dashboardContainer.scrollTo(slateCoordinates.x, slateCoordinates.y);
        this.setUrlSearchParams(slateCoordinates);
      }
      dashboardContainer.addEventListener(
        'scroll',
        this.debouncedScrollEventListener,
        true,
      );
    }
  }

  componentWillUnmount() {
    this.DashboardContainerRef.current.removeEventListener(
      'scroll',
      this.debouncedScrollEventListener,
      true,
    );
  }

  scrollEventListener = (e) => {
    const params = {
      x: e.target.scrollLeft,
      y: e.target.scrollTop,
    };
    this.props.setSlateCoordinates(params);
    this.setUrlSearchParams(params);
  };

  setUrlSearchParams = (obj) =>
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: objectToQueryString(obj),
    });

  render() {
    return (
      <DashboardContainer
        background="#1d2934"
        id="t-edit_slate"
        allowScroll={true}
        ref={this.DashboardContainerRef}
      >
        {this.props.children}
      </DashboardContainer>
    );
  }
}

export default connect(
  (state) => {
    const slate_coordinates = state.slate.slate_coordinates;
    return { slate_coordinates };
  },
  {
    setSlateCoordinates,
  },
)(withRouter(DashboardScrollContainer));
