import { triageV3 } from './triage';

/**
 * @description Endpoint polls Triage to see if async job has completed, failed, or is still
 * running.
 * @param {string} id - job_id returned from Triage after making initial call for aysnc operation
 * @returns {AxiosPromise}
 */
export const checkAsyncOperations = id =>
  triageV3({
    method: 'GET',
    url: `/operations/${id}`,
  });
