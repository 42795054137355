import { AnyAction } from 'redux';
import types from '../constants';
import { Pecohorts } from '../redux/graph';

const defaultState = { loading: false, cohorts: {} };

export default (
  state: Pecohorts = defaultState,
  { payload, type }: AnyAction,
) => {
  switch (type) {
    case types.pe_cohorts.start_loading:
      return {
        ...state,
        loading: true,
      };

    case types.pe_cohorts.stop_loading:
      return {
        ...state,
        loading: false,
      };

    case types.pe_cohorts.set:
      return {
        ...state,
        cohorts: payload,
        loading: false,
      };
    case types.pe_cohorts.clear:
      return defaultState;

    default:
      return state;
  }
};
