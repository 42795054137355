"use strict";

require("core-js/modules/es.object.define-property.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAllQuestionsAnswered = exports["default"] = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.every.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.from.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.array.iterator.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.object.keys.js");

var _lodash = _interopRequireDefault(require("lodash"));

var _validations = require("../common/validations");

/*
  generates a list of validations based on the field configuration, which get passed into the redux field
*/
var _default = function _default(props) {
  return [_validations.validateIsObject, validateAllQuestionsAnswered(props), (0, _validations.validateHasAggregateValue)(props), (0, _validations.validateSum)(props)];
};

exports["default"] = _default;

var validateAllQuestionsAnswered = function validateAllQuestionsAnswered(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value) {
      if (!Array.from(Array(7).keys()).map(function (v) {
        return "".concat(name, "_").concat(v);
      }).every(function (v) {
        return _lodash["default"].includes(Object.keys(value), v);
      })) return 'Not all questions have been answered';
    }
  };
};

exports.validateAllQuestionsAnswered = validateAllQuestionsAnswered;