import DropdownMenu from 'react-dd-menu';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const DropDownContainer = styled.div`
  max-height: 80vh;
  overflow: scroll;
`;

class Dropdown extends React.Component {
  state = { isMenuOpen: false };
  toggle = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });
  close = () => {
    this.setState({ isMenuOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { children, toggle, ...rest } = this.props;
    let menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close.bind(this),
      toggle: React.cloneElement(toggle, { onClick: this.toggle }),
      ...rest,
    };

    return (
      <DropdownMenu {...menuOptions}>
        <DropDownContainer className="dropdown">{children}</DropDownContainer>
      </DropdownMenu>
    );
  }
}

Dropdown.defaultProps = {
  align: 'left',
  toggle: <span>•••</span>,
};

Dropdown.propTypes = {
  toggle: PropTypes.node.isRequired,
};

export default Dropdown;
