import { useSelector } from 'react-redux';
import { RootState } from 'src/reducers';
/**
 * useGraph
 * This will eventually house our graph functions as well
 */
export default function useGraph() {
  const graph = useSelector((state: RootState) => state.graph);
  return graph;
}
