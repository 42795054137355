//@ts-nocheck
import EmptyIsFalse from '../../utils/empty_is_false';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

const StyledToggle = styled.label`
  border: 1px solid #999999;
  border-radius: 2px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.19);
  content: 'On';
  display: inline-block;
  padding: 10px 14px !important;
  position: relative;
  height: 22px;
  width: 62px;
  line-height: 1em;

  > input {
    display: none;
  }

  input:checked + .slider {
    background-color: #1ee7a7;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(29px);
    -ms-transform: translateX(29px);
    transform: translateX(29px);
  }

  > .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 31px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      z-index: 1;
    }

    span {
      font-size: 10px;
      font-weight: bold;
      position: absolute;
      text-transform: uppercase;
      top: 2px;
      color: white;
      padding: 0 !important;

      &.left {
        left: 6px;
      }

      &.right {
        color: black;
        right: 4px;
      }
    }
  }
`;

const Toggle = (props) => {
  const value = EmptyIsFalse(props.toggleValue);

  return (
    <StyledToggle
      className={props.className}
      data-test={_.snakeCase(props.name)}
    >
      <input
        name={props.name}
        aria-checked={value}
        checked={value}
        htmlFor={props.for}
        onChange={props.onToggle}
        role="switch"
        type="checkbox"
      />
      <span className="slider">
        <span className="left">On</span>
        <span className="right">Off</span>
      </span>
    </StyledToggle>
  );
};

/*
   onToggle: (function) the function called when the toggle is clicked
   toggleFor: (string) the field or functionality toggled
   toggleValue: (boolean) whether the toggle is on or off
 */

Toggle.propTypes = {
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  toggleFor: PropTypes.string.isRequired,
};

Toggle.defaultProps = {
  className: ``,
};

export default Toggle;
