import React from 'react';

export default function(props) {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="nonzero">
        <rect x="3" y="5" width="1" height="6" rx=".5" />
        <path d="M1.351 3a.364.364 0 0 0-.248.098.328.328 0 0 0-.103.236v14.332c0 .088.038.174.103.236A.364.364 0 0 0 1.35 18h19.057a.364.364 0 0 0 .248-.098.328.328 0 0 0 .103-.236V3.334a.328.328 0 0 0-.104-.236.364.364 0 0 0-.248-.098H1.351zm.35.666h18.355v13.667H1.702V3.666z" />
      </g>
    </svg>
  );
}
