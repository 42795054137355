import { default_experience_key } from '../../../constants';

export default () => {
  return {
    content: {
      experiences: {
        [default_experience_key]: {
          form: [],
        },
      },
    },
  };
};
