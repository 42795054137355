import { SetFieldValue, useFieldArray } from 'react-hook-form';
import { Input, Button, Flex } from '@evidation/ui';
import { PlusCircle, Trash } from '@evidation/ui/lib/icons';
import {
  BranchRowContainer,
  BranchRowOptionsContainer,
  ErrorMessage,
  Radio,
} from '../components';
import { RadioGroup } from '@headlessui/react';
import get from 'lodash.get';

export type Branch = {
  name: string;
  cap?: string;
  condition?: string;
}

type BranchingProps = {
  control: any;
  defaultArm: string;
  setValue: SetFieldValue<any>;
  branches: Branch[] | undefined;
  register: (...args: any) => any;
  branchingStrategy?: string;
  errors?: any;
};

const Branching: React.FC<BranchingProps> = ({
  control,
  defaultArm,
  setValue,
  branches,
  register,
  errors = null,
  branchingStrategy,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: 'branches',
    control,
  });

  const getError = (key: string) => get(errors, `${key}.message`, '');
  return (
    <>
      <Flex items="flex-start">
        {fields.length > 0 && (
          <RadioGroup
            value={defaultArm}
            onChange={(value: string) => {
              setValue('default_arm', value, { shouldValidate: true });
            }}
          >
            <RadioGroup.Label style={{ fontWeight: 400 }}>
              * Default Branch
            </RadioGroup.Label>
            {fields.map((field, fieldIdx) => {
              const fieldIsDefault =
                branches?.[fieldIdx]?.name === defaultArm && defaultArm !== '';
              const value = branches?.[fieldIdx]?.name;
              return (
                <RadioGroup.Option
                  key={`branch_is_default_radio_item_${fieldIdx}`}
                  value={value}
                  disabled={!value}
                >
                  <BranchRowOptionsContainer>
                    <Flex
                      items="flex-start"
                      spaceX={2}
                      style={{ marginTop: fieldIdx > 0 ? '1.5rem' : '0rem' }}
                    >
                      <Radio wide active={fieldIsDefault} />
                    </Flex>
                  </BranchRowOptionsContainer>
                </RadioGroup.Option>
              );
            })}
              <ErrorMessage>{getError('default_arm')}</ErrorMessage>
          </RadioGroup>
        )}
        <div style={{ width: '100%' }}>
          {fields.map((field, fieldIdx) => {
            const fieldIsDefault =
              branches?.[fieldIdx]?.name === defaultArm && defaultArm !== '';
            return (
              <BranchRowContainer
                active={fieldIsDefault}
                items="flex-start"
                spaceX={2}
                key={field.id}
              >
                <Input
                  label="Branch Name"
                  required
                  error={getError(`branches.${fieldIdx}.name`)}
                  id={`id_branches.${fieldIdx}.name`}
                  {...register(`branches.${fieldIdx}.name`)}
                />
                {branchingStrategy === 'conditional' && (
                  <Input
                    label="Condition"
                    required
                    error={getError(`branches.${fieldIdx}.condition`)}
                    id={`id_branches.${fieldIdx}.name`}
                    {...register(`branches.${fieldIdx}.condition`)}
                  />
                )}
                <Input
                  label="Cap"
                  id={`id_branches.${fieldIdx}.cap`}
                  error={getError(`branches.${fieldIdx}.cap`)}
                  {...register(`branches.${fieldIdx}.cap`)}
                />
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    if (defaultArm === branches?.[fieldIdx]?.name) {
                      // reset the default arm if we are deleting its row.
                      setValue('default_arm', '')
                    }
                    remove(fieldIdx)
                  }}
                  title="Remove Branch"
                  className="danger"
                  style={{ alignSelf: 'flex-end' }}
                >
                  <Trash />
                </Button>
              </BranchRowContainer>
            );
          })}
        </div>
      </Flex>
      <Flex items="center">
        <Button
          variant="ghost"
          type="button"
          size="small"
          onClick={() => {
            if (branches?.length === 0) {
              // should always start with 2 rows
              append({ name: '', condition: '', cap: '' });
              append({ name: '', condition: '', cap: '' });
            } else {
              append({ name: '', condition: '', cap: '' });
            }
          }}
        >
          <PlusCircle />
          Add another branch
        </Button>
        <ErrorMessage>{getError('branches')}</ErrorMessage>
      </Flex>
    </>
  );
};

export default Branching;
