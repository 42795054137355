// @type package not found
//@ts-ignore
import { memoize } from 'reselectie';
import _ from 'lodash';
import { RootState } from '../reducers';

const sequencesResourceSelector = (state: RootState) =>
  _.reduce(
    state.graph.sequence_ids,
    (acc: any, id: string) =>
      state.sequences[id] ? [...acc, state.sequences[id]] : acc,
    [],
  );
const transformBack = (acc: any, i: any) => ({ ...acc, [i.id]: i });

export const sequencesSelector = memoize(
  sequencesResourceSelector,
  (items: any) => {
    return _.filter(
      items,
      (i) => !(i.content && i.content.archipelago_identifier),
    ).reduce(transformBack, {});
  },
);

export const archipelagoGroupSelector = memoize(
  sequencesResourceSelector,
  (items: any) =>
    _.reduce(
      items,
      (acc: any, item) => {
        if (!_.has(item.content, `archipelago_identifier`)) return acc;

        const this_archipelago_identifier = item.content.archipelago_identifier;

        if (this_archipelago_identifier) {
          const itemacc = _.has(acc, this_archipelago_identifier)
            ? acc[this_archipelago_identifier]
            : {};
          return {
            ...acc,
            [this_archipelago_identifier]: {
              ...itemacc,
              [item.id]: item,
            },
          };
        }
        return acc;
      },
      {},
    ),
);
