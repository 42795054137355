import { AuthActions } from "../../../constants";

export const checkPermissions = (currentPath, studyPath, authorization) => {
  const study_page_permissions = {};

  study_page_permissions[`${studyPath}/overview`] = [];
  study_page_permissions[`${studyPath}/edit/slate`] = [
    AuthActions.sequences.show,
  ];
  study_page_permissions[`${studyPath}/edit/compensation`] = [
    AuthActions.enrollments.compensate,
  ];
  study_page_permissions[`${studyPath}/recurring_jobs`] = [
    AuthActions.sequences.show,
  ];
  study_page_permissions[`${studyPath}/settings/general`] = [
    AuthActions.sequences.show,
  ];
  study_page_permissions[`${studyPath}/participants`] = [
    AuthActions.enrollments.search,
  ];
  study_page_permissions[`${studyPath}/permissions`] = [
    AuthActions.roles.index,
  ];
  study_page_permissions[`${studyPath}/visit-portal`] = [
    AuthActions.enrollments.cra_search,
    AuthActions.enrollments.process_contributions,
  ];
  study_page_permissions[`${studyPath}/event_delays`] = [
    AuthActions.studies.event_delays,
  ];
  
  const required = study_page_permissions[currentPath] || [];

  return required.length >= 1
    ? required.some((e) => authorization.indexOf(e) > -1)
    : true;
};

