import React from 'react';

const NavPermissions = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <title>icons/nav/nav_permissions</title>
    <g fill={props.color} fillRule="evenodd">
      <path
        d="M13.152 14.05a.636.636 0 0 1 .573-1.136 5.09 5.09 0 1 0-2.805-4.55c0 .805.186 1.581.539 2.283a.636.636 0 0 1-1.137.572 6.363 6.363 0 1 1 12.053-2.855 6.363 6.363 0 0 1-9.223 5.686zM6.455 21.098a.636.636 0 0 1 0 1.273H2.636A.636.636 0 0 1 2 21.735v-2.546a.636.636 0 0 1 1.273 0v1.91h3.182z"
        fillRule="nonzero"
      />
      <path
        d="M3.086 19.64a.636.636 0 0 1-.9-.9l8.255-8.256a.636.636 0 0 1 .9.9l-8.255 8.255zM12.004 15.82a.636.636 0 0 1-.9-.9l1.886-1.887a.636.636 0 1 1 .9.9l-1.886 1.886z"
        fillRule="nonzero"
      />
      <path d="M18.551 7.09a1.266 1.266 0 1 1-2.534 0 1.268 1.268 0 0 1 2.534 0z" />
      <path
        d="M7.09 21.735a.636.636 0 0 1-1.272 0v-2.546a.636.636 0 0 1 1.273 0v2.546z"
        fillRule="nonzero"
      />
      <path
        d="M9 18.553a.636.636 0 1 1 0 1.273H6.455a.636.636 0 0 1 0-1.273H9zM11.557 16.007a.636.636 0 1 1 0 1.273H9.01a.636.636 0 1 1 0-1.273h2.546z"
        fillRule="nonzero"
      />
      <path
        d="M9.636 19.19a.636.636 0 1 1-1.272 0v-2.546a.636.636 0 0 1 1.272 0v2.545zM12.193 16.644a.636.636 0 0 1-1.273 0v-1.265a.636.636 0 0 1 1.273 0v1.265z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default NavPermissions;
