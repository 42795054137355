import React from 'react';
import CreateConnectionForm from './CreateConnectionForm';
import useConnectionForm from './useConnectionForm';
import { ConnectionsContainer, PlusIcon, AddButton } from './components';

const text = {
  AddConnection: 'Add Connection',
};

export default function ConnectAppConnectionForm({
  initialValues,
  onChange,
  slug,
}) {
  const {
    connections,
    handleConnectionFormChange,
    handleRemoveConnection,
    setConnections,
  } = useConnectionForm({
    onChange,
    initialValues,
  });
  return (
    <ConnectionsContainer data-testid="connections_container">
      {connections.map((connection, id) => (
        <CreateConnectionForm
          key={`create_connection_form_item_${id}`}
          initialValues={connection}
          connId={id}
          currentConnections={connections.map(
            (item) => item?.connection?.achievement_slug,
          )}
          onChange={(values, ...args) =>
            handleConnectionFormChange(id, values, ...args)
          }
          onRemove={handleRemoveConnection}
          slug={slug}
        />
      ))}
      <AddButton onClick={() => setConnections([...connections, {}])}>
        <PlusIcon /> {text.AddConnection}
      </AddButton>
    </ConnectionsContainer>
  );
}
